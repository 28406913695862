import React ,{useEffect,useState}from "react";
import {Button} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";

function StartTrade() {
  
  const [loginCheck, setloginCheck] = useState(false);

  useEffect(()=>{
    let userToken = localStorage.getItem("user_token");

    if (userToken) {
      setloginCheck(true);
    } else {
      setloginCheck(false);
    }
  },[0])
  const navigate = useNavigate();

  const navigatepage=(type)=>{
if(type=="trade"){
  navigate("/trade/BTC_USDT")
}else{
  navigate("/register")
}
  }
  return (
    <>
      <section className="section_commen swcirrrr">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="contant_last content_section">
                <h2>Start Your Crypto Journey Now!</h2>
                {loginCheck==true?
                <Button className="file_submint" onClick={()=>navigatepage("trade")}>Get Start</Button>
                
                :
                <Button className="file_submint" onClick={()=>navigatepage("register")}>Sign Up Now</Button>

                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default StartTrade;
