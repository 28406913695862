import React, { useState, useEffect } from "react";
import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import { Link } from "react-router-dom";
import Sidebar from "./Innerpages/Sidebar";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

function Home() {
  var settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  const options = [
    {
      value: 1,
      label: "Leanne Graham",
    },
    {
      value: 2,
      label: "Ervin Howell",
    },
  ];

  const [siteLoader, setSiteLoader] = useState(false);

  return (
    <div className="header ">
      <Header />
      <div>
        <div className="body_hXuuO ">
          <div className="lrtcss-wxjc90 e161ieae2">
            <div className="lrtcss-q484j3 e161ieae1">
              <div className="KuxMenu-root lrtcss-yqpjhy">
                <Sidebar />
              </div>
            </div>
            <div className="lrtcss-1p2kb7b e161ieae0">
              {siteLoader == true ? (
                <i class="fa-solid fa-spinner fa-spin"></i>
              ) : (
                <>
                  <div className="lrtcss-sbd8h eagejp24">
                    <section className="Market_overview_section">
                      <div className="container">
                      <div className="market_title">Market Overview</div>
                        <div className="row">
                        
                        <div className="col-lg-4 mt-5">
                            <h4 className="highlight_title mb-3">Highlight</h4>
                            <div className="highlights">
                            <div className="highlight_content">
                                <div className="highlight_img">
                                    <img src={require("../Image/BTC.png")}/>
                                </div>
                                <div className="coin_name">Bitcoin</div>
                            </div>

                            <div className="text-success"> $2.3</div>
                            <div className="text-danger"> -8.7%</div>
                              
                            </div>
                            <div className="highlights mt-3">
                            <div className="highlight_content">
                                <div className="highlight_img">
                                    <img src={require("../Image/BTC.png")}/>
                                </div>
                                <div className="coin_name">Bitcoin</div>
                            </div>

                            <div className="text-success"> $2.3</div>
                            <div className="text-danger"> -8.7%</div>
                              
                            </div>
                            <div className="highlights mt-3">
                            <div className="highlight_content">
                                <div className="highlight_img">
                                    <img src={require("../Image/BTC.png")}/>
                                </div>
                                <div className="coin_name">Bitcoin</div>
                            </div>

                            <div className="text-success"> $2.3</div>
                            <div className="text-danger"> -8.7%</div>
                              
                            </div>
                        </div>
                        <div className="col-lg-4 mt-5">
                            <h4 className="highlight_title mb-3">New Listing</h4>
                            <div className="highlights">
                            <div className="highlight_content">
                                <div className="highlight_img">
                                    <img src={require("../Image/ETH.png")}/>
                                </div>
                                <div className="coin_name">Ethereum</div>
                            </div>

                            <div className="text-success"> $2.3</div>
                            <div className="text-danger"> -8.7%</div>
                              
                            </div>
                            <div className="highlights mt-3">
                            <div className="highlight_content">
                                <div className="highlight_img">
                                    <img src={require("../Image/ETH.png")}/>
                                </div>
                                <div className="coin_name">Ethereum</div>
                            </div>

                            <div className="text-success"> $2.3</div>
                            <div className="text-danger"> -8.7%</div>
                              
                            </div>
                            <div className="highlights mt-3">
                            <div className="highlight_content">
                                <div className="highlight_img">
                                    <img src={require("../Image/ETH.png")}/>
                                </div>
                                <div className="coin_name">Ethereum</div>
                            </div>

                            <div className="text-success"> $2.3</div>
                            <div className="text-danger"> -8.7%</div>
                              
                            </div>
                        </div>
                        <div className="col-lg-4 mt-5">
                            <h4 className="highlight_title mb-3">Top Volume Coin</h4>
                            <div className="highlights">
                            <div className="highlight_content">
                                <div className="highlight_img">
                                    <img src={require("../Image/BTC.png")}/>
                                </div>
                                <div className="coin_name">Bitcoin</div>
                            </div>

                            <div className="text-success"> $2.3</div>
                            <div className="text-danger"> -8.7%</div>
                              
                            </div>
                            <div className="highlights mt-3">
                            <div className="highlight_content">
                                <div className="highlight_img">
                                    <img src={require("../Image/BTC.png")}/>
                                </div>
                                <div className="coin_name">Bitcoin</div>
                            </div>

                            <div className="text-success"> $2.3</div>
                            <div className="text-danger"> -8.7%</div>
                              
                            </div>
                            <div className="highlights mt-3">
                            <div className="highlight_content">
                                <div className="highlight_img">
                                    <img src={require("../Image/BTC.png")}/>
                                </div>
                                <div className="coin_name">Bitcoin</div>
                            </div>

                            <div className="text-success"> $2.3</div>
                            <div className="text-danger"> -8.7%</div>
                              
                            </div>
                        </div>

                         
                        </div>
                      </div>
                    </section>
                    <section className="Market_overview_section">
                        <div className="container">
                            <div className="market_tab_search_box">
                                <div>
                            <ul class="market_tabs nav nav-tabs" id="myTab" role="tablist">
                          <li class="nav-item">
                            <a
                              class="nav-link active"
                              id="home-tab"
                              data-toggle="tab"
                              href="#home"
                              role="tab"
                              aria-controls="home"
                              aria-selected="true"
                            >
                              Home
                            </a>
                          </li>
                          <li class="nav-item">
                            <a
                              class="nav-link"
                              id="profile-tab"
                              data-toggle="tab"
                              href="#profile"
                              role="tab"
                              aria-controls="profile"
                              aria-selected="false"
                            >
                              Profile
                            </a>
                          </li>
                          <li class="nav-item">
                            <a
                              class="nav-link"
                              id="contact-tab"
                              data-toggle="tab"
                              href="#contact"
                              role="tab"
                              aria-controls="contact"
                              aria-selected="false"
                            >
                              Contact
                            </a>
                          </li>
                        </ul>
                        </div>
                        <div className="search_box mt-5">
                        <i class="ri-search-line"></i>
                        </div>
                            </div>
                   </div>
                        <div class="tab-content" id="myTabContent">
                          <div
                            class="tab-pane fade show active"
                            id="home"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                          >
                            <div className="wallet-table market_overview_table">
                        <div className="container">
                      <table class="table market">
                        <thead>
                          <tr class="market-header">
                            <th scope="col" className="text-left">Name</th>
                            <th scope="col">Price</th>
                            <th scope="col">24 Change</th>
                            <th scope="col">24 Volume</th>  
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="market_overview_table">
                            <th className="table_img1">
                              <img
                                src={require("../Image/BTC.png")}
                                alt="pic"
                              />
                              <p className="coin_title mt-1">
                                BTC <span>Bitcoin</span> {" "}
                              </p>
                            </th>
                            <td>
                              <div className="count_market text-success">
                               $2.3
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count_market text-danger">
                                -8.75%
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count_market">
                               19700
                              </div>{" "}
                            </td>
                            <td className="market_trade_btn">
                               <button className="w-100 ">Trade</button>     
                            </td>

                          </tr>
                          <tr className="market_overview_table">
                            <th className="table_img1">
                              <img
                                src={require("../Image/ETH.png")}
                                alt="pic"
                              />
                              <p className="coin_title mt-1">
                                ETH <span>Ethereum</span> {" "}
                              </p>
                            </th>
                            <td>
                              <div className="count_market text-success">
                               $2.3
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count_market text-danger">
                                -8.75%
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count_market">
                               19700
                              </div>{" "}
                            </td>
                            <td className="market_trade_btn">
                               <button className="w-100 ">Trade</button>     
                            </td>

                          </tr>
                          <tr className="market_overview_table">
                            <th className="table_img1">
                              <img
                                src={require("../Image/BTC.png")}
                                alt="pic"
                              />
                              <p className="coin_title mt-1">
                                BTC <span>Bitcoin</span> {" "}
                              </p>
                            </th>
                            <td>
                              <div className="count_market text-success">
                               $2.3
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count_market text-danger">
                                -8.75%
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count_market">
                               19700
                              </div>{" "}
                            </td>
                            <td className="market_trade_btn">
                               <button className="w-100 ">Trade</button>     
                            </td>

                          </tr>
                          <tr className="market_overview_table">
                            <th className="table_img1">
                              <img
                                src={require("../Image/ETH.png")}
                                alt="pic"
                              />
                              <p className="coin_title mt-1">
                                ETH <span>Ethereum</span> {" "}
                              </p>
                            </th>
                            <td>
                              <div className="count_market text-success">
                               $2.3
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count_market text-danger">
                                -8.75%
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count_market">
                               19700
                              </div>{" "}
                            </td>
                            <td className="market_trade_btn">
                               <button className="w-100 ">Trade</button>     
                            </td>

                          </tr>
                          <tr className="market_overview_table">
                            <th className="table_img1">
                              <img
                                src={require("../Image/BTC.png")}
                                alt="pic"
                              />
                              <p className="coin_title mt-1">
                                BTC <span>Bitcoin</span> {" "}
                              </p>
                            </th>
                            <td>
                              <div className="count_market text-success">
                               $2.3
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count_market text-danger">
                                -8.75%
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count_market">
                               19700
                              </div>{" "}
                            </td>
                            <td className="market_trade_btn">
                               <button className="w-100 ">Trade</button>     
                            </td>

                          </tr>
                          <tr className="market_overview_table">
                            <th className="table_img1">
                              <img
                                src={require("../Image/ETH.png")}
                                alt="pic"
                              />
                              <p className="coin_title mt-1">
                                ETH <span>Ethereum</span> {" "}
                              </p>
                            </th>
                            <td>
                              <div className="count_market text-success">
                               $2.3
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count_market text-danger">
                                -8.75%
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count_market">
                               19700
                              </div>{" "}
                            </td>
                            <td className="market_trade_btn">
                               <button className="w-100 ">Trade</button>     
                            </td>

                          </tr>
                        </tbody>
                      </table>
                      </div>
                    </div>
                          </div>
                          <div
                            class="tab-pane fade"
                            id="profile"
                            role="tabpanel"
                            aria-labelledby="profile-tab"
                          >
                             <div className="wallet-table">
                        <div className="container">
                      <table class="table market">
                        <thead>
                          <tr class="market-header">
                            <th scope="col">Name</th>
                            <th scope="col">Price</th>
                            <th scope="col">24 Change</th>
                            <th scope="col">24 Volume</th>  
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="market_overview_table">
                            <th className="table_img1">
                              <img
                                src={require("../Image/BTC.png")}
                                alt="pic"
                              />
                              <p className="coin_title mt-1">
                                BTC <span>Bitcoin</span> {" "}
                              </p>
                            </th>
                            <td>
                              <div className="count_market text-success">
                               $2.3
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count_market text-danger">
                                -8.75%
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count_market">
                               19700
                              </div>{" "}
                            </td>
                            <td className="market_trade_btn">
                               <button className="w-100 ">Trade</button>     
                            </td>

                          </tr>
                          <tr className="market_overview_table">
                            <th className="table_img1">
                              <img
                                src={require("../Image/ETH.png")}
                                alt="pic"
                              />
                              <p className="coin_title mt-1">
                                ETH <span>Ethereum</span> {" "}
                              </p>
                            </th>
                            <td>
                              <div className="count_market text-success">
                               $2.3
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count_market text-danger">
                                -8.75%
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count_market">
                               19700
                              </div>{" "}
                            </td>
                            <td className="market_trade_btn">
                               <button className="w-100 ">Trade</button>     
                            </td>

                          </tr>
                          <tr className="market_overview_table">
                            <th className="table_img1">
                              <img
                                src={require("../Image/BTC.png")}
                                alt="pic"
                              />
                              <p className="coin_title mt-1">
                                BTC <span>Bitcoin</span> {" "}
                              </p>
                            </th>
                            <td>
                              <div className="count_market text-success">
                               $2.3
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count_market text-danger">
                                -8.75%
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count_market">
                               19700
                              </div>{" "}
                            </td>
                            <td className="market_trade_btn">
                               <button className="w-100 ">Trade</button>     
                            </td>

                          </tr>
                          <tr className="market_overview_table">
                            <th className="table_img1">
                              <img
                                src={require("../Image/ETH.png")}
                                alt="pic"
                              />
                              <p className="coin_title mt-1">
                                ETH <span>Ethereum</span> {" "}
                              </p>
                            </th>
                            <td>
                              <div className="count_market text-success">
                               $2.3
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count_market text-danger">
                                -8.75%
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count_market">
                               19700
                              </div>{" "}
                            </td>
                            <td className="market_trade_btn">
                               <button className="w-100 ">Trade</button>     
                            </td>

                          </tr>
                          <tr className="market_overview_table">
                            <th className="table_img1">
                              <img
                                src={require("../Image/BTC.png")}
                                alt="pic"
                              />
                              <p className="coin_title mt-1">
                                BTC <span>Bitcoin</span> {" "}
                              </p>
                            </th>
                            <td>
                              <div className="count_market text-success">
                               $2.3
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count_market text-danger">
                                -8.75%
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count_market">
                               19700
                              </div>{" "}
                            </td>
                            <td className="market_trade_btn">
                               <button className="w-100 ">Trade</button>     
                            </td>

                          </tr>
                          <tr className="market_overview_table">
                            <th className="table_img1">
                              <img
                                src={require("../Image/ETH.png")}
                                alt="pic"
                              />
                              <p className="coin_title mt-1">
                                ETH <span>Ethereum</span> {" "}
                              </p>
                            </th>
                            <td>
                              <div className="count_market text-success">
                               $2.3
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count_market text-danger">
                                -8.75%
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count_market">
                               19700
                              </div>{" "}
                            </td>
                            <td className="market_trade_btn">
                               <button className="w-100 ">Trade</button>     
                            </td>

                          </tr>
                        </tbody>
                      </table>
                      </div>
                    </div>
                          </div>
                          <div
                            class="tab-pane fade"
                            id="contact"
                            role="tabpanel"
                            aria-labelledby="contact-tab"
                          >
                             <div className="wallet-table">
                        <div className="container">
                      <table class="table market">
                        <thead>
                          <tr class="market-header">
                            <th scope="col">Name</th>
                            <th scope="col">Price</th>
                            <th scope="col">24 Change</th>
                            <th scope="col">24 Volume</th>  
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="market_overview_table">
                            <th className="table_img1">
                              <img
                                src={require("../Image/BTC.png")}
                                alt="pic"
                              />
                              <p className="coin_title mt-1">
                                BTC <span>Bitcoin</span> {" "}
                              </p>
                            </th>
                            <td>
                              <div className="count_market text-success">
                               $2.3
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count_market text-danger">
                                -8.75%
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count_market">
                               19700
                              </div>{" "}
                            </td>
                            <td className="market_trade_btn">
                               <button className="w-100 ">Trade</button>     
                            </td>

                          </tr>
                          <tr className="market_overview_table">
                            <th className="table_img1">
                              <img
                                src={require("../Image/ETH.png")}
                                alt="pic"
                              />
                              <p className="coin_title mt-1">
                                ETH <span>Ethereum</span> {" "}
                              </p>
                            </th>
                            <td>
                              <div className="count_market text-success">
                               $2.3
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count_market text-danger">
                                -8.75%
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count_market">
                               19700
                              </div>{" "}
                            </td>
                            <td className="market_trade_btn">
                               <button className="w-100 ">Trade</button>     
                            </td>

                          </tr>
                          <tr className="market_overview_table">
                            <th className="table_img1">
                              <img
                                src={require("../Image/BTC.png")}
                                alt="pic"
                              />
                              <p className="coin_title mt-1">
                                BTC <span>Bitcoin</span> {" "}
                              </p>
                            </th>
                            <td>
                              <div className="count_market text-success">
                               $2.3
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count_market text-danger">
                                -8.75%
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count_market">
                               19700
                              </div>{" "}
                            </td>
                            <td className="market_trade_btn">
                               <button className="w-100 ">Trade</button>     
                            </td>

                          </tr>
                          <tr className="market_overview_table">
                            <th className="table_img1">
                              <img
                                src={require("../Image/ETH.png")}
                                alt="pic"
                              />
                              <p className="coin_title mt-1">
                                ETH <span>Ethereum</span> {" "}
                              </p>
                            </th>
                            <td>
                              <div className="count_market text-success">
                               $2.3
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count_market text-danger">
                                -8.75%
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count_market">
                               19700
                              </div>{" "}
                            </td>
                            <td className="market_trade_btn">
                               <button className="w-100 ">Trade</button>     
                            </td>

                          </tr>
                          <tr className="market_overview_table">
                            <th className="table_img1">
                              <img
                                src={require("../Image/BTC.png")}
                                alt="pic"
                              />
                              <p className="coin_title mt-1">
                                BTC <span>Bitcoin</span> {" "}
                              </p>
                            </th>
                            <td>
                              <div className="count_market text-success">
                               $2.3
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count_market text-danger">
                                -8.75%
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count_market">
                               19700
                              </div>{" "}
                            </td>
                            <td className="market_trade_btn">
                               <button className="w-100 ">Trade</button>     
                            </td>

                          </tr>
                          <tr className="market_overview_table">
                            <th className="table_img1">
                              <img
                                src={require("../Image/ETH.png")}
                                alt="pic"
                              />
                              <p className="coin_title mt-1">
                                ETH <span>Ethereum</span> {" "}
                              </p>
                            </th>
                            <td>
                              <div className="count_market text-success">
                               $2.3
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count_market text-danger">
                                -8.75%
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count_market">
                               19700
                              </div>{" "}
                            </td>
                            <td className="market_trade_btn">
                               <button className="w-100 ">Trade</button>     
                            </td>

                          </tr>
                        </tbody>
                      </table>
                      </div>
                    </div>
                          </div>
                        </div>
                        </section>

                    
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
