import React, { useState, useEffect } from "react";
import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import { Link } from "react-router-dom";
import Sidebar from "./Innerpages/Sidebar";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

function Home() {
  var settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  const options = [
    {
      value: 1,
      label: "Leanne Graham",
    },
    {
      value: 2,
      label: "Ervin Howell",
    },
  ];

  const [siteLoader, setSiteLoader] = useState(false);

  return (
    <div className="header ">
      <Header />
      <div>
        <div className="body_hXuuO ">
          <div className="lrtcss-wxjc90 e161ieae2">
            <div className="lrtcss-q484j3 e161ieae1">
              <div className="KuxMenu-root lrtcss-yqpjhy">
                <Sidebar />
              </div>
            </div>
            <div className="lrtcss-1p2kb7b e161ieae0">
              {siteLoader == true ? (
                <i class="fa-solid fa-spinner fa-spin"></i>
              ) : (
                <>
                  <div className="lrtcss-sbd8h eagejp24">
                    <div className="wallet_tabs">
                      <ul
                        class="nav nav-pills tab mb-3"
                        id="pills-tab"
                        role="tablist"
                      >
                        <li class="nav-item spot" role="presentation">
                          <button
                            class="nav-link spot-btn active"
                            id="pills-home-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-home"
                            type="button"
                            role="tab"
                            aria-controls="pills-home"
                            aria-selected="true"
                          >
                            Spot
                          </button>
                        </li>
                        <li class="nav-item earn" role="presentation">
                          <button
                            class="nav-link earn-btn"
                            id="pills-profile-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-profile"
                            type="button"
                            role="tab"
                            aria-controls="pills-profile"
                            aria-selected="false"
                          >
                            Earn
                          </button>
                        </li>
                      </ul>
                    </div>

                    <div className="wallet_balance">
                      <h3>My Wallet Balance</h3>

                      <div className="balance_amount">
                        <div className="portfolio">
                          <h4>Total Portfolio</h4>
                          <p className="textsuccess">
                            0.36300000 <span>$</span>
                          </p>
                        </div>
                        <div className="holding">
                          <h4>Total Crypto Holdings</h4>
                          <p className="textsuccess">
                            0.36300000 <span>$</span>
                          </p>
                        </div>
                        <div className="amount">
                          <h4>Total Amount</h4>
                          <p>
                            {" "}
                            <img
                              src={require("../Image/Table_image/coin_img.png")}
                              className="coin_img"
                            />{" "}
                            O INR
                          </p>
                        </div>
                        <div className="fiat-ac">
                          <h4>Fiat A/c</h4>
                          <p>0 INR</p>
                          <p>$ 0</p>
                        </div>
                        <div className="crypto_ac">
                          <h4>Crypto A/c</h4>
                          <p>0 INR</p>
                          <p>$ 0</p>
                        </div>
                      </div>
                    </div>
                    <div className="wallet-table">
                      <table class="table market">
                        <thead>
                          <tr class="market-header">
                            <th scope="col">Asset</th>
                            <th scope="col">Flat A/c</th>
                            <th scope="col">Crypto A/c</th>
                            <th scope="col">Total Balance</th>
                            <div className="zero_balance">
                              <th scope="col">
                                {" "}
                                <i class="ri-search-line"></i>{" "}
                              </th>
                              <th scope="col"> Hide Zero Balance</th>
                            </div>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th className="table_img1">
                              <img
                                src={require("../Image/Table_image/table_img_1.png")}
                                alt="pic"
                              />
                              <div className="ox">
                                OX <span>ZRX/INR</span>{" "}
                              </div>
                            </th>
                            <td>
                              <div className="count">
                                0 <span>$0.00000000</span>
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count">
                                100000<span>$0.36300000</span>
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count">
                                100000.00000000 <span>$0.36300000</span>
                              </div>{" "}
                            </td>
                            <td colSpan={2}>
                              <div className="balance_tabs">
                                <ul
                                  class="nav nav-pills tab mb-3"
                                  id="pills-tab"
                                  role="tablist"
                                >
                                  <li class="nav-item view" role="presentation">
                                    <button
                                      class="nav-link view3 "
                                      id="pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-profile"
                                      aria-selected="false"
                                    >
                                      View
                                    </button>
                                  </li>
                                  <li class="nav-item earn" role="presentation">
                                    <button
                                      class="nav-link view3"
                                      id="pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-profile"
                                      aria-selected="false"
                                    >
                                      Deposit
                                    </button>
                                  </li>

                                  <li class="nav-item earn" role="presentation">
                                    <button
                                      class="nav-link view3"
                                      id="pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-profile"
                                      aria-selected="false"
                                    >
                                      Withdraw
                                    </button>
                                  </li>
                                  <li class="nav-item earn" role="presentation">
                                    <button
                                      class="nav-link view3"
                                      id="pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-profile"
                                      aria-selected="false"
                                    >
                                      Transfer
                                    </button>
                                  </li>
                                </ul>
                              </div>{" "}
                            </td>
                          </tr>
                          <tr>
                            <th className="table_img1">
                              <img
                                src={require("../Image/Table_image/table_img_1.png")}
                                alt="pic"
                              />
                              <div className="ox">
                                OX <span>ZRX/INR</span>{" "}
                              </div>
                            </th>
                            <td>
                              <div className="count">
                                0 <span>$0.00000000</span>
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count">
                                100000<span>$0.36300000</span>
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count">
                                100000.00000000 <span>$0.36300000</span>
                              </div>{" "}
                            </td>
                            <td colSpan={2}>
                              <div className="balance_tabs">
                                <ul
                                  class="nav nav-pills tab mb-3"
                                  id="pills-tab"
                                  role="tablist"
                                >
                                  <li class="nav-item view" role="presentation">
                                    <button
                                      class="nav-link view3 "
                                      id="pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-profile"
                                      aria-selected="false"
                                    >
                                      View
                                    </button>
                                  </li>
                                  <li class="nav-item earn" role="presentation">
                                    <button
                                      class="nav-link view3"
                                      id="pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-profile"
                                      aria-selected="false"
                                    >
                                      Deposit
                                    </button>
                                  </li>

                                  <li class="nav-item earn" role="presentation">
                                    <button
                                      class="nav-link view3"
                                      id="pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-profile"
                                      aria-selected="false"
                                    >
                                      Withdraw
                                    </button>
                                  </li>
                                  <li class="nav-item earn" role="presentation">
                                    <button
                                      class="nav-link view3"
                                      id="pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-profile"
                                      aria-selected="false"
                                    >
                                      Transfer
                                    </button>
                                  </li>
                                </ul>
                              </div>{" "}
                            </td>
                          </tr>
                          <tr>
                            <th className="table_img1">
                              <img
                                src={require("../Image/Table_image/table_img_1.png")}
                                alt="pic"
                              />
                              <div className="ox">
                                OX <span>ZRX/INR</span>{" "}
                              </div>
                            </th>
                            <td>
                              <div className="count">
                                0 <span>$0.00000000</span>
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count">
                                100000<span>$0.36300000</span>
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count">
                                100000.00000000 <span>$0.36300000</span>
                              </div>{" "}
                            </td>
                            <td colSpan={2}>
                              <div className="balance_tabs">
                                <ul
                                  class="nav nav-pills tab mb-3"
                                  id="pills-tab"
                                  role="tablist"
                                >
                                  <li class="nav-item view" role="presentation">
                                    <button
                                      class="nav-link view3 "
                                      id="pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-profile"
                                      aria-selected="false"
                                    >
                                      View
                                    </button>
                                  </li>
                                  <li class="nav-item earn" role="presentation">
                                    <button
                                      class="nav-link view3"
                                      id="pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-profile"
                                      aria-selected="false"
                                    >
                                      Deposit
                                    </button>
                                  </li>

                                  <li class="nav-item earn" role="presentation">
                                    <button
                                      class="nav-link view3"
                                      id="pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-profile"
                                      aria-selected="false"
                                    >
                                      Withdraw
                                    </button>
                                  </li>
                                  <li class="nav-item earn" role="presentation">
                                    <button
                                      class="nav-link view3"
                                      id="pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-profile"
                                      aria-selected="false"
                                    >
                                      Transfer
                                    </button>
                                  </li>
                                </ul>
                              </div>{" "}
                            </td>
                          </tr>
                          <tr>
                            <th className="table_img1">
                              <img
                                src={require("../Image/Table_image/table_img_1.png")}
                                alt="pic"
                              />
                              <div className="ox">
                                OX <span>ZRX/INR</span>{" "}
                              </div>
                            </th>
                            <td>
                              <div className="count">
                                0 <span>$0.00000000</span>
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count">
                                100000<span>$0.36300000</span>
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count">
                                100000.00000000 <span>$0.36300000</span>
                              </div>{" "}
                            </td>
                            <td colSpan={2}>
                              <div className="balance_tabs">
                                <ul
                                  class="nav nav-pills tab mb-3"
                                  id="pills-tab"
                                  role="tablist"
                                >
                                  <li class="nav-item view" role="presentation">
                                    <button
                                      class="nav-link view3 "
                                      id="pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-profile"
                                      aria-selected="false"
                                    >
                                      View
                                    </button>
                                  </li>
                                  <li class="nav-item earn" role="presentation">
                                    <button
                                      class="nav-link view3"
                                      id="pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-profile"
                                      aria-selected="false"
                                    >
                                      Deposit
                                    </button>
                                  </li>

                                  <li class="nav-item earn" role="presentation">
                                    <button
                                      class="nav-link view3"
                                      id="pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-profile"
                                      aria-selected="false"
                                    >
                                      Withdraw
                                    </button>
                                  </li>
                                  <li class="nav-item earn" role="presentation">
                                    <button
                                      class="nav-link view3"
                                      id="pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-profile"
                                      aria-selected="false"
                                    >
                                      Transfer
                                    </button>
                                  </li>
                                </ul>
                              </div>{" "}
                            </td>
                          </tr>
                          <tr>
                            <th className="table_img1">
                              <img
                                src={require("../Image/Table_image/table_img_1.png")}
                                alt="pic"
                              />
                              <div className="ox">
                                OX <span>ZRX/INR</span>{" "}
                              </div>
                            </th>
                            <td>
                              <div className="count">
                                0 <span>$0.00000000</span>
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count">
                                100000<span>$0.36300000</span>
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count">
                                100000.00000000 <span>$0.36300000</span>
                              </div>{" "}
                            </td>
                            <td colSpan={2}>
                              <div className="balance_tabs">
                                <ul
                                  class="nav nav-pills tab mb-3"
                                  id="pills-tab"
                                  role="tablist"
                                >
                                  <li class="nav-item view" role="presentation">
                                    <button
                                      class="nav-link view3 "
                                      id="pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-profile"
                                      aria-selected="false"
                                    >
                                      View
                                    </button>
                                  </li>
                                  <li class="nav-item earn" role="presentation">
                                    <button
                                      class="nav-link view3"
                                      id="pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-profile"
                                      aria-selected="false"
                                    >
                                      Deposit
                                    </button>
                                  </li>

                                  <li class="nav-item earn" role="presentation">
                                    <button
                                      class="nav-link view3"
                                      id="pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-profile"
                                      aria-selected="false"
                                    >
                                      Withdraw
                                    </button>
                                  </li>
                                  <li class="nav-item earn" role="presentation">
                                    <button
                                      class="nav-link view3"
                                      id="pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-profile"
                                      aria-selected="false"
                                    >
                                      Transfer
                                    </button>
                                  </li>
                                </ul>
                              </div>{" "}
                            </td>
                          </tr>
                          <tr>
                            <th className="table_img1">
                              <img
                                src={require("../Image/Table_image/table_img_1.png")}
                                alt="pic"
                              />
                              <div className="ox">
                                OX <span>ZRX/INR</span>{" "}
                              </div>
                            </th>
                            <td>
                              <div className="count">
                                0 <span>$0.00000000</span>
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count">
                                100000<span>$0.36300000</span>
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count">
                                100000.00000000 <span>$0.36300000</span>
                              </div>{" "}
                            </td>
                            <td colSpan={2}>
                              <div className="balance_tabs">
                                <ul
                                  class="nav nav-pills tab mb-3"
                                  id="pills-tab"
                                  role="tablist"
                                >
                                  <li class="nav-item view" role="presentation">
                                    <button
                                      class="nav-link view3 "
                                      id="pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-profile"
                                      aria-selected="false"
                                    >
                                      View
                                    </button>
                                  </li>
                                  <li class="nav-item earn" role="presentation">
                                    <button
                                      class="nav-link view3"
                                      id="pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-profile"
                                      aria-selected="false"
                                    >
                                      Deposit
                                    </button>
                                  </li>

                                  <li class="nav-item earn" role="presentation">
                                    <button
                                      class="nav-link view3"
                                      id="pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-profile"
                                      aria-selected="false"
                                    >
                                      Withdraw
                                    </button>
                                  </li>
                                  <li class="nav-item earn" role="presentation">
                                    <button
                                      class="nav-link view3"
                                      id="pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-profile"
                                      aria-selected="false"
                                    >
                                      Transfer
                                    </button>
                                  </li>
                                </ul>
                              </div>{" "}
                            </td>
                          </tr>
                          <tr>
                            <th className="table_img1">
                              <img
                                src={require("../Image/Table_image/table_img_1.png")}
                                alt="pic"
                              />
                              <div className="ox">
                                OX <span>ZRX/INR</span>{" "}
                              </div>
                            </th>
                            <td>
                              <div className="count">
                                0 <span>$0.00000000</span>
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count">
                                100000<span>$0.36300000</span>
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count">
                                100000.00000000 <span>$0.36300000</span>
                              </div>{" "}
                            </td>
                            <td colSpan={2}>
                              <div className="balance_tabs">
                                <ul
                                  class="nav nav-pills tab mb-3"
                                  id="pills-tab"
                                  role="tablist"
                                >
                                  <li class="nav-item view" role="presentation">
                                    <button
                                      class="nav-link view3 "
                                      id="pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-profile"
                                      aria-selected="false"
                                    >
                                      View
                                    </button>
                                  </li>
                                  <li class="nav-item earn" role="presentation">
                                    <button
                                      class="nav-link view3"
                                      id="pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-profile"
                                      aria-selected="false"
                                    >
                                      Deposit
                                    </button>
                                  </li>

                                  <li class="nav-item earn" role="presentation">
                                    <button
                                      class="nav-link view3"
                                      id="pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-profile"
                                      aria-selected="false"
                                    >
                                      Withdraw
                                    </button>
                                  </li>
                                  <li class="nav-item earn" role="presentation">
                                    <button
                                      class="nav-link view3"
                                      id="pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-profile"
                                      aria-selected="false"
                                    >
                                      Transfer
                                    </button>
                                  </li>
                                </ul>
                              </div>{" "}
                            </td>
                          </tr>
                          <tr>
                            <th className="table_img1">
                              <img
                                src={require("../Image/Table_image/table_img_1.png")}
                                alt="pic"
                              />
                              <div className="ox">
                                OX <span>ZRX/INR</span>{" "}
                              </div>
                            </th>
                            <td>
                              <div className="count">
                                0 <span>$0.00000000</span>
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count">
                                100000<span>$0.36300000</span>
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count">
                                100000.00000000 <span>$0.36300000</span>
                              </div>{" "}
                            </td>
                            <td colSpan={2}>
                              <div className="balance_tabs">
                                <ul
                                  class="nav nav-pills tab mb-3"
                                  id="pills-tab"
                                  role="tablist"
                                >
                                  <li class="nav-item view" role="presentation">
                                    <button
                                      class="nav-link view3 "
                                      id="pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-profile"
                                      aria-selected="false"
                                    >
                                      View
                                    </button>
                                  </li>
                                  <li class="nav-item earn" role="presentation">
                                    <button
                                      class="nav-link view3"
                                      id="pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-profile"
                                      aria-selected="false"
                                    >
                                      Deposit
                                    </button>
                                  </li>

                                  <li class="nav-item earn" role="presentation">
                                    <button
                                      class="nav-link view3"
                                      id="pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-profile"
                                      aria-selected="false"
                                    >
                                      Withdraw
                                    </button>
                                  </li>
                                  <li class="nav-item earn" role="presentation">
                                    <button
                                      class="nav-link view3"
                                      id="pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-profile"
                                      aria-selected="false"
                                    >
                                      Transfer
                                    </button>
                                  </li>
                                </ul>
                              </div>{" "}
                            </td>
                          </tr>
                          <tr>
                            <th className="table_img1">
                              <img
                                src={require("../Image/Table_image/table_img_1.png")}
                                alt="pic"
                              />
                              <div className="ox">
                                OX <span>ZRX/INR</span>{" "}
                              </div>
                            </th>
                            <td>
                              <div className="count">
                                0 <span>$0.00000000</span>
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count">
                                100000<span>$0.36300000</span>
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count">
                                100000.00000000 <span>$0.36300000</span>
                              </div>{" "}
                            </td>
                            <td colSpan={2}>
                              <div className="balance_tabs">
                                <ul
                                  class="nav nav-pills tab mb-3"
                                  id="pills-tab"
                                  role="tablist"
                                >
                                  <li class="nav-item view" role="presentation">
                                    <button
                                      class="nav-link view3 "
                                      id="pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-profile"
                                      aria-selected="false"
                                    >
                                      View
                                    </button>
                                  </li>
                                  <li class="nav-item earn" role="presentation">
                                    <button
                                      class="nav-link view3"
                                      id="pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-profile"
                                      aria-selected="false"
                                    >
                                      Deposit
                                    </button>
                                  </li>

                                  <li class="nav-item earn" role="presentation">
                                    <button
                                      class="nav-link view3"
                                      id="pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-profile"
                                      aria-selected="false"
                                    >
                                      Withdraw
                                    </button>
                                  </li>
                                  <li class="nav-item earn" role="presentation">
                                    <button
                                      class="nav-link view3"
                                      id="pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-profile"
                                      aria-selected="false"
                                    >
                                      Transfer
                                    </button>
                                  </li>
                                </ul>
                              </div>{" "}
                            </td>
                          </tr>
                          <tr>
                            <th className="table_img1">
                              <img
                                src={require("../Image/Table_image/table_img_1.png")}
                                alt="pic"
                              />
                              <div className="ox">
                                OX <span>ZRX/INR</span>{" "}
                              </div>
                            </th>
                            <td>
                              <div className="count">
                                0 <span>$0.00000000</span>
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count">
                                100000<span>$0.36300000</span>
                              </div>{" "}
                            </td>
                            <td>
                              <div className="count">
                                100000.00000000 <span>$0.36300000</span>
                              </div>{" "}
                            </td>
                            <td colSpan={2}>
                              <div className="balance_tabs">
                                <ul
                                  class="nav nav-pills tab mb-3"
                                  id="pills-tab"
                                  role="tablist"
                                >
                                  <li class="nav-item view" role="presentation">
                                    <button
                                      class="nav-link view3 "
                                      id="pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-profile"
                                      aria-selected="false"
                                    >
                                      View
                                    </button>
                                  </li>
                                  <li class="nav-item earn" role="presentation">
                                    <button
                                      class="nav-link view3"
                                      id="pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-profile"
                                      aria-selected="false"
                                    >
                                      Deposit
                                    </button>
                                  </li>

                                  <li class="nav-item earn" role="presentation">
                                    <button
                                      class="nav-link view3"
                                      id="pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-profile"
                                      aria-selected="false"
                                    >
                                      Withdraw
                                    </button>
                                  </li>
                                  <li class="nav-item earn" role="presentation">
                                    <button
                                      class="nav-link view3"
                                      id="pills-profile-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-profile"
                                      aria-selected="false"
                                    >
                                      Transfer
                                    </button>
                                  </li>
                                </ul>
                              </div>{" "}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
