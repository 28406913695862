import {useState, useEffect} from "react";
import {useParallax, ParallaxProvider, Parallax} from "react-scroll-parallax";
function Security() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Function to check if the device is a mobile device
    const detectMobile = () => {
      const mobileCheck = window.matchMedia("(max-width: 768px)").matches;
      setIsMobile(mobileCheck);
    };

    // Initial check on component mount
    detectMobile();

    // Event listener for window resize
    const handleResize = () => {
      detectMobile();
    };
    window.addEventListener("resize", handleResize);

    // Cleanup function
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <ParallaxProvider>
        <section className="secutiry_deture">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3">
                <Parallax speed={110} easing="easeInQuad" disabled={isMobile}>
                  <div className="image_scroll">
                    <div className="img_1 mar-top-minie">
                      <img
                        src={require("../../Image/new_image_home/X Mockup-1.png")}
                        className="new_icon"
                      />
                    </div>
                    <div className="img_1">
                      <img
                        src={require("../../Image/new_image_home/X_Mockup-2.png")}
                        className="new_icon"
                      />
                    </div>
                    <div className="img_1">
                      <img
                        src={require("../../Image/new_image_home/X_Mockup-2.png")}
                        className="new_icon"
                      />
                    </div>
                  </div>
                </Parallax>
              </div>
              {/* <div className="col-lg-3">
                <Parallax speed={60} easing="easeInQuad">
                  <div className="image_scroll mar-top-minie-2">
                    <div className="img_1 ">
                      <img
                        src={require("../../Image/new_image_home/X_Mockup-3.png")}
                        className="new_icon"
                      />
                    </div>
                    <div className="img_1">
                      <img
                        src={require("../../Image/new_image_home/X_Mockup.png")}
                        className="new_icon"
                      />
                    </div>
                    <div className="img_1">
                      <img
                        src={require("../../Image/new_image_home/X_Mockup-2.png")}
                        className="new_icon"
                      />
                    </div>
                  </div>
                </Parallax>
              </div>
              <div className="col-lg-3">
                <Parallax speed={100} easing="easeInQuad">
                  <div className="image_scroll">
                    <div className="img_1 mar-top-minie-3">
                      <img
                        src={require("../../Image/new_image_home/X Mockup-1.png")}
                        className="new_icon"
                      />
                    </div>
                    <div className="img_1">
                      <img
                        src={require("../../Image/new_image_home/X_Mockup-2.png")}
                        className="new_icon"
                      />
                    </div>
                    <div className="img_1">
                      <img
                        src={require("../../Image/new_image_home/X_Mockup-2.png")}
                        className="new_icon"
                      />
                    </div>
                  </div>
                </Parallax>
              </div> */}
              <div className="col-lg-6 d-flex align-items-center">
                <div className="content_center_mobile">
                  <h3>
                    Stay ahead and become a Crypto Trailblazer with Our App!
                  </h3>
                  <div className="qr-section">
                    <div className="app_link">
                      <a href="">
                        <img
                          src={require("../../Image/new_image_home/Appstore.png")}
                          className="img-fluid"
                        />
                      </a>
                      <a href="">
                        <img
                          src={require("../../Image/new_image_home/playstore.png")}
                          className="img-fluid"
                        />
                      </a>
                    </div>
                    <div className="qrsectiondata">
                      <img
                        src={require("../../Image/new_image_home/qrsection.png")}
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <Parallax speed={150} easing="easeInQuad" disabled={isMobile}>
                  <div className="image_scroll mar-top-minie-4">
                    <div className="img_1 ">
                      <img
                        src={require("../../Image/new_image_home/X_Mockup-3.png")}
                        className="new_icon"
                      />
                    </div>
                    <div className="img_1">
                      <img
                        src={require("../../Image/new_image_home/X_Mockup.png")}
                        className="new_icon"
                      />
                    </div>
                    <div className="img_1">
                      <img
                        src={require("../../Image/new_image_home/X_Mockup-2.png")}
                        className="new_icon"
                      />
                    </div>
                  </div>
                </Parallax>
              </div>
            </div>
          </div>
        </section>
      </ParallaxProvider>
    </>
  );
}

export default Security;
