import { useState, useEffect } from "react";
import { useParallax, ParallaxProvider, Parallax } from "react-scroll-parallax";

function OurCryptoex() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Function to check if the device is a mobile device
    const detectMobile = () => {
      const mobileCheck = window.matchMedia("(max-width: 768px)").matches;
      setIsMobile(mobileCheck);
    };

    // Initial check on component mount
    detectMobile();

    // Event listener for window resize
    const handleResize = () => {
      detectMobile();
    };
    window.addEventListener("resize", handleResize);

    // Cleanup function
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <ParallaxProvider>
        <section className="section_contner ">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mb-4">
                <div className="content_feturee">
                  <h3>Salient Features of Our Exchange</h3>
                </div>
              </div>

              <div className="col-lg-8">
                <div className="section_card_with_image bg_img_nrw">
                  <div className="width_innwe">
                    <img
                      src={require("../../Image/new_image_home/Buycrypto.png")}
                      className="img-fluid"
                    />
                    <h4>Wide Range of Assets</h4>
                    <p>
                      We provide access to a diverse selection of
                      cryptocurrencies, allowing users to trade various digital
                      assets and explore new investment opportunities.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 d-flex">
                <div className="section_card_with_image min-hew">
                  <div className="ineer_image">
                    <img
                      src={require("../../Image/new_image_home/Portfolio.png")}
                      className="img-fluid"
                    />
                    <h4>Diversified Crypto Portfolio</h4>
                    <p>
                      Diversify your crypto portfolio for stability and growth.
                      Spread investments across various cryptocurrencies for
                      risk management and potential returns
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 d-flex">
                <div className="section_card_with_image">
                  <div className="ineer_image">
                    <img
                      src={require("../../Image/new_image_home/secutity.png")}
                      className="img-fluid"
                    />
                    <h4>Robust Security Measures</h4>
                    <p>
                      We employ advanced security protocols to ensure the safety
                      of our users assets, including multi-factor
                      authentication, cold storage for the majority of funds,
                      and regular security audits.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 d-flex">
                {" "}
                <div className="section_card_with_image">
                  <div className="ineer_image">
                    <img
                      src={require("../../Image/new_image_home/fees.png")}
                      className="img-fluid"
                    />
                    <h4>Competitive Trading Fees</h4>
                    <p>
                      Our platform offers significantly lower trading fees
                      compared to other global exchanges. Users can easily earn
                      while buying, selling, and withdrawing funds.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 d-flex">
                <div className="section_card_with_image">
                  <div className="ineer_image">
                    <img
                      src={require("../../Image/new_image_home/customer.png")}
                      className="img-fluid"
                    />
                    <h4>Fastest Customer Support</h4>
                    <p>
                      Our dedicated customer support team is available around
                      the clock to assist users with any queries or issues they
                      may encounter, ensuring a smooth trading experience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </ParallaxProvider>
    </>
  );
}

export default OurCryptoex;
