import React, { useState, useEffect } from "react";
import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./login_regi_footer";
import Select from "react-dropdown-select";
import { Link, useNavigate } from "react-router-dom";
import OTPInput, { ResendOTP } from "otp-input-react";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";

function Home() {
  const [OTP, setOTP] = useState("");

  const [buttonLoader, setbuttonLoader] = useState(false);
  const [buttonLoader1, setbuttonLoader1] = useState(false);

  const navigate = useNavigate();

  const options = [
    {
      value: 1,
      label: "Leanne Graham",
    },
    {
      value: 2,
      label: "Ervin Howell",
    },
  ];
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  const [siteLoader, setSiteLoader] = useState(false);
  const formSubmit = async (payload) => {
    if (OTP !== "") {
      var obj = {
        emailOtp: OTP,
        email: localStorage.getItem("useremail"),
      };

      var data = {
        apiUrl: apiService.emailotpverify,
        payload: obj,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setbuttonLoader(false);
      if (resp.status) {
        toast.success(resp.Message);
        navigate("/login");
      } else {
        toast.error(resp.Message);
      }
    } else {
      toast.error("Enter OTP");
    }
  };

  const resendOTP = async (payload) => {
    var obj = {
      email: localStorage.getItem("useremail"),
    };

    var data = {
      apiUrl: apiService.resendCode,
      payload: obj,
    };
    setbuttonLoader1(true);
    var resp = await postMethod(data);
    setbuttonLoader1(false);
    if (resp.status) {
      toast.success(resp.Message);
    } else {
      toast.error(resp.Message);
    }
  };

  return (
    <div className="header ">
      <Header />
      <main>
        <div className="login_form">
          <div className="container">
            {siteLoader == true ? (
              <i class="fa-solid fa-spinner fa-spin"></i>
            ) : (
              <>
                <div className="row justify-content-center">
                  <div className="col-lg-6">
                    <div className="card_login mt25">
                      <h3 className="text-center">Verify Email</h3>
                      <div>
                        <div class="form_login_input">
                          <label className="mb-4 text-center">
                            Enter OTP which we send on your register email
                          </label>
                          <div className="OTP_inpu">
                            <OTPInput
                              value={OTP}
                              onChange={setOTP}
                              autoFocus
                              OTPLength={4}
                              otpType="number"
                              disabled={false}
                              // secure
                            />
                          </div>
                        </div>

                        <div class="form_login_input">
                          {buttonLoader == false ? (
                            <Button onClick={formSubmit}>Continue</Button>
                          ) : (
                            <Button>Loading...</Button>
                          )}
                        </div>

                        {buttonLoader1 == false ? (
                          <Link
                            to=""
                            onClick={resendOTP}
                            className="pass_forgee mt-3 d-block"
                          >
                            Resend OTP
                          </Link>
                        ) : (
                          <span className="d-flex justify-content-center mt-4">
                            <i class="fa-solid fa-spinner fa-sm icons"></i>
                          </span>
                        )}

                        {/* <Link to="" className="pass_forgee mt-3 d-block">
                      Resend OTP
                    </Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Home;
