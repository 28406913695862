import {
  AppBar,
  Button,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AddBusinessRoundedIcon from "@mui/icons-material/AddBusinessRounded";
import DrawerComp from "./Drawer";
import React, {useEffect, useContextt} from "react";
import useState from "react-usestateref";
import {
  removeAuthToken,
  getAuthToken,
  getSocketToken,
} from "../core/lib/localStorage";
import {removeAuthorization} from "../core/service/axios";
import {Link, useNavigate} from "react-router-dom";
import {socket} from "./context/socket";
import apiService from "../core/service/detail";
import {getMethod, postMethod} from "../core/service/common.api";
import Moment from "moment";
import {toast} from "react-toastify";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const Header = () => {
  const [value, setValue] = useState();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const [isOpen, setIsOpen, isOpenref] = useState(true);
  const [loginCheck, setloginCheck] = useState(false);
  const [notifications, setNotification] = React.useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchTfaData();
    socket.on("sitesettings", function (res) {
      console.log(res);
      fetchTfaData();
    });
    fetchTheme();

    let token_socket = localStorage.getItem("socket_token");
    if (token_socket) {
      socket.connect();
      let socketToken = token_socket.split("_")[0];
      socket.on("socketResponse" + socketToken, function (res) {
        console.log("notify response====", res);
        if (res.Reason == "notify") {
          getNotifications();
        }

        if (res.Message == "account_deactivate") {
          toast.error("Your account deactivated by admin");
          logout();
        }
      });
    }

    let userToken = localStorage.getItem("user_token");

    if (userToken) {
      setloginCheck(true);
      getNotifications();
    } else {
      setloginCheck(false);
    }
  }, [0]);
  const changeTheme = (value) => {
    localStorage.setItem("theme", value);
    var settheme = value == "light" ? true : false;
    setIsOpen(settheme);

    var current_url = window.location.href.split("/")[3];
    if (current_url == "trade") {
      window.location.reload(true);
    } else {
      document.body.classList.toggle("light", isOpenref.current);
    }
  };
  const fetchTfaData = async () => {
    try {
      var data = {
        apiUrl: apiService.getSiteDatas,
      };
      var resp = await getMethod(data);
      console.log(resp.data, "=--=-=-resp-=-=-=-=-=resp=-=-");
      if (resp.data.undermaintenenceStatus == "DeActive") {
        console.log(resp.data, "=--=-=-=-=-=resp=-=-");
        navigate("/undermaintanance");
        localStorage.setItem("sitestats", resp.data.undermaintenenceStatus);
      } else {
      }
    } catch (error) {}
  };
  const fetchTheme = () => {
    var theme = localStorage.getItem("theme");
    if (theme != undefined) {
      if (theme == "dark") {
        setIsOpen(true);
        document.body.classList.toggle("light", isOpenref.current);
      } else {
        setIsOpen(false);
      }
    } else {
      localStorage.setItem("theme", "dark");
      setIsOpen(true);
    }
  };
  const logout = async () => {
    var data = {
      apiUrl: apiService.logout,
    };
    var resp = await getMethod(data);
    if (resp) {
      await removeAuthorization();
      await removeAuthToken();
      localStorage.clear();
      navigate("/login");
    }
  };

  const [navbar, setNavbar] = useState(false);

  const changeHeader = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeHeader);

  const getNotifications = async () => {
    try {
      var data = {
        apiUrl: apiService.getnotifications,
      };
      var resp = await getMethod(data);
      if (resp.status) {
        setNotification(resp.Message);
      } else {
      }
    } catch (error) {}
  };

  const clickNotify = async (params) => {
    try {
      var obj = {
        _id: params,
      };
      var data = {
        apiUrl: apiService.updateNotifyRecord,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
      } else {
      }
    } catch (error) {}
  };

  const [dropstatus, setdropstatus] = useState(false);


  const dropdowns = async () => {
    if(dropstatus == true){
      setdropstatus(false);
    }else{
      setdropstatus(true);
    }
  };

  const [modal1, setModal1] = useState(false);

  const toggle1 = async (e) => {
    console.log("e====", e);
    setModal1(!modal1);
  };

  const goto1 = () => setModal1(!modal1);

  return (
    <React.Fragment>
      <AppBar sx={{background: "#000000"}} className="header_padidng">
        <Toolbar sx={{height: "51px"}}>
          <div className="imag">
            <Link className="nav_brand" to="/">
              {" "}
              {/* <img
                src={require("../Image/WhatsAp.png")}
                className="blackshow"
              /> */}
              <img src={require("../Image/nav_bar/Logolight.png")} 
                className="blackshow"
              />
              
            </Link>
          </div>
          {isMatch ? (
            <>
              <DrawerComp />
            </>
          ) : (
            <>
              <div className="nav_link_section mr-auto ml-5">
                <div className="dropdown1">
                  <button onClick={dropdowns}>
                    Trade <i class="ri-arrow-down-s-fill"></i>
                  </button>
                  <div className={ dropstatus == true ? `dropdown-content1 d-block ` : `dropdown-content1` }>
                    <a href="/trade/BTC_USDT" className="mb-3">
                      <div>
                        <img src={require("../Image/nav_bar/spot.png")} />
                      </div>
                      <div className="content">
                        <p>Spot</p>
                        <span>Buy and Sell on the Spot with more than 20+ markets</span>
                      </div>
                    </a>
                    {/* <a href="/p2p" className="mb-3">
                      <div>
                        <img src={require("../Image/nav_bar/p2p.png")} />
                      </div>
                      <div className="content">
                        <p>P2P</p>
                        <span>Bank transfer and 20+ options</span>
                      </div>
                    </a> */}
                {loginCheck == false ? (
                  ""
                ):(
                  <>
                  <a href="/Convert" className="mb-3">
                  <div>
                    <img src={require("../Image/nav_bar/swap.png")} />
                  </div>
                  <div className="content">
                    <p>Swap</p>
                    <span>Easiest way to swap your crypto</span>
                  </div>
                </a>
                <a href="/buycrypto" className="">
                  <div>
                    <img src={require("../Image/nav_bar/buycrypto.png")} />
                  </div>
                  <div className="content">
                    <p>Buy Crypto / OnRamp</p>
                    <span>Easiest way to buy crypto</span>
                  </div>
                </a>
                  </>
                )}
                 
                  </div>
                </div>
                {/* <a href="">Market</a>
                <a href="">Earn</a>
                <a href="">Finance</a> */}
                {loginCheck == false ? (
                // <a href="">Institutional</a>
                ""
                ):(
                <Link to="/dashboard">Dashboard</Link>
                )}
               
              </div>

              {loginCheck == false ? (
                <>
                  <Link to="/login" className="Butn_header">
                    Login
                  </Link>
                  <Link to="/register" className="Butn_header mr-0 only_border">
                    Register
                  </Link>
                </>
              ) : (
                <>
                  <Link to="#" onClick={toggle1} className="Butn_header">
                    logout
                  </Link>
                </>
              )}


              <Modal
          isOpen={modal1}
          toggle={toggle1}
          className="rem10 checkout_modal mt40"
        >
          <ModalBody className="p-0">
            <div class="Frame-14722">
              <span class="Item-Details h2">
                <i class="ri-logout-box-line mr-2"></i> Logout{" "}
              </span>
              <div class="">
                <i class="ri-close-fill  Close" onClick={goto1}></i>
              </div>
            </div>

            <div className="py-2">
              <p className="Deletecontent my-4">
                Are you sure want to logout this session ?
              </p>

              <div className="row flex-row p-3">
                <div className="col-md-6">
                  <div className="form_login_input">
                    <Button className="discardbtn" onClick={goto1}>
                      Discard
                    </Button>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form_login_input">
                    <Button onClick={logout}>Logout</Button>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
            
              {/* <Button
                sx={{marginLeft: "10px"}}
                variant="contained"
                className="header_btn"
                data-toggle="modal"
                data-target="#wallet"
              >
                Connect Wallet
              </Button> */}
            </>
          )}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default Header;
