import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import { Link } from "react-router-dom";
import Sidebar from "./Innerpages/Sidebar";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import Moment from "moment";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function Home() {
  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  const network_options = [
    { value: "erc20token", label: "ERC20" },
    { value: "bep20token", label: "BEP20" },
    { value: "trc20token", label: "TRC20" },
    { value: "rptc20token", label: "RPTC20" },
  ];

  const [allCurrency, setallCurrency, allCurrencyref] = useState([]);
  const [allCrypto, setallCrypto, allCryptoref] = useState([]);
  const [currency, setcurrency, currencyref] = useState("USDT");
  const [cointype, setcointype, cointyperef] = useState("");
  const [address, setAddress, addressref] = useState();
  const [view, setview, viewref] = useState("");
  const [bankwire, setBankwire] = useState("");
  const [depositHistory, setdepositHistory] = useState([]);
  const [kycStatus, setkycStatus] = useState(0);
  const [sitekycStatus, setsitekycStatus] = useState("DeActive");
  const [cur_currency, setcur_currency, cur_currencyref] = useState("");
  const [network_currency, setcur_network, network_currencyref] = useState([]);
  const [network_default, setnet_default, net_defaultref] = useState("");
  const [Fullname, Setfullname, Fullnameref] = useState("Tether");
  const [Image, setImage, Imageref] = useState("");
  const [Networks, setNetworks, Networksref] = useState("");
  const [siteLoader, setSiteLoader] = useState(false);

  useEffect(() => {
    getAllcurrency();
    getdepositHistory();
    getKYCstatus();
    getSiteSettingstatus();
    getTransaction();
  }, [0]);
  console.log(
    addressref.current,
    "--=-=-addressref=-=-=addressref=-=-=-=-addressref"
  );

  const getTransaction = async () => {
    var data = {
      apiUrl: apiService.transaction,
    };
    var resp = await getMethod(data);
    if (resp.message == true) {
      getdepositHistory();
    }
  };

  const getAllcurrency = async () => {
    var data = {
      apiUrl: apiService.walletcurrency,
    };
    setSiteLoader(true);
    var resp = await getMethod(data);
    setSiteLoader(false);
    if (resp) {
      var currArrayCrypto = [];
      var data = resp.data;
      setallCrypto(data);
      console.log(allCryptoref.current, "allcrypto");
      for (var i = 0; i < data.length; i++) {
        if (data[i].depositStatus == "Active") {
          var obj = {
            value: data[i]._id,
            label: data[i].currencySymbol,
            coinType: data[i].coinType,
            erc20token: data[i].erc20token,
            bep20token: data[i].bep20token,
            trc20token: data[i].trc20token,
            rptc20token: data[i].rptc20token,
            currencyName: data[i].currencyName,
            imgurl: data[i].Currency_image,
          };
          currArrayCrypto.push(obj);
        }
      }
      console.log("network_currencyref===", network_currencyref.current);
      setallCurrency(currArrayCrypto);
    }
  };

  const getKYCstatus = async () => {
    var data = {
      apiUrl: apiService.getKYCStatus,
    };
    var getKYC = await getMethod(data);
    console.log("getkyc===", getKYC);
    if (getKYC.status) {
      console.log(getKYC.Message.kycstatus, "========");
      setkycStatus(getKYC.Message.kycstatus);
    } else {
      setkycStatus(0);
    }
  };

  const getSiteSettingstatus = async () => {
    var data = {
      apiUrl: apiService.getSiteDatas,
    };
    var response = await getMethod(data);
    console.log("getkyc1===", response);
    if (response.status) {
      console.log(response.data.kycStatus, "==kyc======", kycStatus);
      setsitekycStatus(response.data.kycStatus);
    } else {
      setkycStatus(0);
    }
  };

  const getdepositHistory = async () => {
    var obj = {
      apiUrl: apiService.deposit_history,
      payload: { FilPerpage: 5, FilPage: 1 },
    };
    var deposit_history_list = await postMethod(obj);
    if (deposit_history_list) {
      setdepositHistory(deposit_history_list.crypto_deposit);
    }
  };

  const onSelect = async (option) => {
    console.log(option, "-=-onSelecttop");
    //setAddress()

    // if(option.label == "RPTC" || option.label == "RWT")
    // {
    //    setmaintain(true);
    // }
    // else
    // {
    //   setmaintain(false);
    setcur_network([]);
    setnet_default("");
    setcurrency(option.label);
    Setfullname(option.currencyName);
    setImage(option.imgurl);
    setcointype(option.coinType);
    let indexData = allCryptoref.current.findIndex(
      (x) => x._id == option.value
    );
    if (indexData != -1) {
      var currencydata = allCryptoref.current[indexData];
      console.log("currencydata===", currencydata);
      setcur_currency(currencydata);

      var network_cur = {};
      var network_names = [];
      if (currencydata.currencyType == "2") {
        if (currencydata.erc20token == "1") {
          network_cur = {
            value: "erc20token",
            label: "ERC20",
          };
          network_names.push(network_cur);
        }
        if (currencydata.bep20token == "1") {
          network_cur = {
            value: "bep20token",
            label: "BEP20",
          };
          network_names.push(network_cur);
        }
        if (currencydata.trc20token == "1") {
          network_cur = {
            value: "trc20token",
            label: "TRC20",
          };
          network_names.push(network_cur);
        }
        setcur_network(network_names);
        console.log("network_currencyref===", network_currencyref.current);
        setnet_default(network_currencyref.current[0].label);
      }

      if (currencydata.coinType == "1" && currencydata.currencyType == "1") {
        var obj = {
          currency: option.label,
          currId: option.value,
        };
        var data = {
          apiUrl: apiService.generateAddress,
          payload: obj,
        };
        setview("load");
        var resp = await postMethod(data);
        console.log(resp, "=-=-=resp-=-=--");
        if (resp.status) {
          setview("view");
          setAddress(resp.Message);
          console.log(
            addressref.current,
            "--=-=-addressref=-=-=addressref=-=-=-=-addressref"
          );
        } else {
          //toast.error("Something went wrong, please try again later");
        }
      } else {
        var obj = {
          currency: option.label,
        };
        var data = {
          apiUrl: apiService.bankwire,
          payload: obj,
        };

        var resp = await postMethod(data);
        console.log(resp, "=-=-=fiat deposit resp-=-=--");
        if (resp.status) {
          setBankwire(resp.data);
        } else {
          //toast.error("Something went wrong, please try again later");
        }
      }
    }
    //}
  };

  const copy = async (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Address copied");
  };

  const onSelect_network = async (option) => {
    setNetworks(option.label);
    console.log(option, "-=-onSelect_network");
    if (
      cur_currencyref.current.coinType == "1" &&
      cur_currencyref.current.currencyType == "2"
    ) {
      var obj = {
        currency: cur_currencyref.current.currencySymbol,
        currId: cur_currencyref.current._id,
        network: option.value,
      };
      console.log("call here 1111", obj);
      var data = {
        apiUrl: apiService.generateAddress_network,
        payload: obj,
      };
      setview("load");
      var resp = await postMethod(data);
      console.log(resp, "=-=-=resp-=-=--");
      if (resp.status) {
        setview("view");
        setAddress(resp.Message);
      } else {
        //toast.error("Something went wrong, please try again later");
      }
    }
  };

  return (
    <div className="header ">
      <Header />
      <div>
        <div className="body_hXuuO ">
          <div className="lrtcss-wxjc90 e161ieae2">
            <div className="lrtcss-q484j3 e161ieae1">
              <div className="KuxMenu-root lrtcss-yqpjhy">
                <Sidebar />
              </div>
            </div>
            <div className="lrtcss-1p2kb7b e161ieae0">
              {siteLoader == true ? (
                <i class="fa-solid fa-spinner fa-spin"></i>
              ) : (
                <>
                  <div className="lrtcss-sbd8h eagejp24">
                    <section className="deposit_crypto_section">
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="deposit_crypto_header">
                            <div className="deposit_left_heading">
                              <h2>Deposit Crypto</h2>
                            </div>
                          </div>

                          {kycStatus == 1 ? (
                            <section className="select_coin_section ">
                              <div className="select-coin mt-5">
                                <div className="select_content">
                                  <div className="select_text">Select Coin</div>
                                  <div
                                    className="selection_dropdown"
                                    data-toggle="modal"
                                    data-target="#myModal"
                                  >
                                    <div className="slt_dropdown">
                                      {Imageref.current == "" ? (
                                        ""
                                      ) : (
                                        <img src={Imageref.current} />
                                      )}
                                      {Imageref.current == "" ? (
                                        <span className="spn_usdt">
                                          Select Currency
                                        </span>
                                      ) : (
                                        <span className="spn_usdt">
                                          {currencyref.current}
                                        </span>
                                      )}

                                      {Imageref.current == "" ? (
                                        ""
                                      ) : (
                                        <span className="spn_tether">
                                          {Fullnameref.current}
                                        </span>
                                      )}
                                    </div>
                                    <div className="drp_down_logo">
                                      <i class="ri-arrow-down-s-line"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="coin_list">
                                <p>
                                  {allCurrencyref.current &&
                                  allCurrencyref.current.length > 0
                                    ? allCurrencyref.current
                                        .slice(0, 5)
                                        .map((item, i) => {
                                          return (
                                            <span
                                              className="coin-item"
                                              onClick={() => onSelect(item)}
                                            >
                                              <img src={item.imgurl} />
                                              {item.label}
                                            </span>
                                          );
                                        })
                                    : ""}
                                </p>
                              </div>

                              {cur_currencyref.current.currencyType == "2" ? (
                                <section className="select_network_section">
                                  <div className="select_content">
                                    <div className="select_text">
                                      Select a network
                                    </div>
                                    <div
                                      className="selection_dropdown"
                                      data-toggle="modal"
                                      data-target="#selectnetwork"
                                    >
                                      <div className="slt_dropdown">
                                        <i class="ri-search-line"></i>
                                        {Networksref.current == "" ? (
                                          <span className="spn_tether">
                                            Select Network
                                          </span>
                                        ) : (
                                          <span className="spn_tether">
                                            {Networksref.current}
                                          </span>
                                        )}
                                      </div>
                                      <div className="drp_down_logo">
                                        <i class="ri-arrow-down-s-line"></i>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              ) : (
                                ""
                              )}

                              {viewref.current == "view" ? (
                                <>
                                  <div className="QR_code my-3">
                                    {addressref.current != "" ? (
                                      <img
                                        src={
                                          addressref.current == undefined
                                            ? ""
                                            : addressref.current.qrcode
                                        }
                                        className="d-block mx-auto"
                                        width="50%"
                                      />
                                    ) : (
                                      <img
                                        src={require("../Image/Vector.png")}
                                        className=""
                                        width="50%"
                                      />
                                    )}

                                    <div className="select_text text-center mt-3">
                                      Scan QR
                                    </div>
                                  </div>

                                  <section className="select_network_section">
                                    <div className="select_content mb-4 ">
                                      <div className="select_text">
                                        {" "}
                                        Address
                                      </div>
                                      <div className="selection_dropdown">
                                        <div className="slt_dropdown">
                                          <input
                                            type="text"
                                            placeholder="Address"
                                            value={
                                              addressref.current == undefined
                                                ? ""
                                                : addressref.current.address
                                            }
                                            readOnly
                                            className="walletr_inpur"
                                          />
                                        </div>
                                        <div
                                          className="drp_down_logo text_purble"
                                          onClick={() =>
                                            copy(addressref.current.address)
                                          }
                                        >
                                          <i class="ri-clipboard-line"></i>
                                        </div>
                                      </div>
                                    </div>
                                  </section>
                                </>
                              ) : viewref.current == "load" ? (
                                <div className="spinner_icon">
                                  <div class="spinner-border" role="status">
                                    <i
                                      class="fa fa-spinner d-none"
                                      style={{ color: "white" }}
                                    ></i>{" "}
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </section>
                          ) : (
                            <div className="warning_content faq_list">
                              <h2>
                                Please complete the KYC verification process to
                                gain access to the page.
                              </h2>
                              <Link to="/Identity">Verify KYC Now</Link>
                            </div>
                          )}
                        </div>

                        <div className="col-lg-4">
                          <section className="faq-section">
                            <div className="faq_list">
                              <h3>FAQ</h3>
                              <div className="Accordion">
                                <Accordion className="Accordionfaq">
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                  >
                                    1. How do I deposit crypto into my Sellcrypt
                                    account?
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    To deposit is to transfer existing digital
                                    assets into an account at Sellcrypt. If it's
                                    a transfer from on-chain sources, its status
                                    will be available on the blockchain used for
                                    the deposit or transfer.
                                  </AccordionDetails>
                                </Accordion>
                                <Accordion className="Accordionfaq">
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                  >
                                    2. What should I do if I don't receive my
                                    deposits or if I deposit at an incorrect
                                    address?
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    Verify that the transaction was successful
                                    and that the funds were sent to the correct
                                    address. Use a block explorer for the
                                    respective blockchain to check the
                                    transaction status and address details.
                                    Contact the exchange's customer support.
                                    Provide them with the transaction ID,
                                    deposit address, and any other relevant
                                    details. They may be able to assist you in
                                    locating the funds.
                                  </AccordionDetails>
                                </Accordion>
                                <Accordion className="Accordionfaq">
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                  >
                                    3. What should I do if I deposit the wrong
                                    crypto?{" "}
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    If you sent the wrong cryptocurrency to your
                                     wallet and the wallet does not support the
                                    cryptocurrency you mistakenly sent, recovery
                                    options may be limited. Contact the
                                    exchange's customer support. Provide them
                                    with the transaction ID, deposit address,
                                    and any other relevant details.
                                  </AccordionDetails>
                                </Accordion>
                                <Accordion className="Accordionfaq">
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                  >
                                    4. Sellcrypt allows for depositing
                                    cryptocurrencies into wallets.
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <ul className="ml-3">
                                      <li>BTC</li>
                                      <li>BSC</li>
                                      <li>TRX</li>
                                      <li>MATIC</li>
                                    </ul>
                                  </AccordionDetails>
                                </Accordion>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </section>
                    {kycStatus == 1 ? (
                      <section className="Deposit_history_section">
                        <h3 className="deposit_table_title">Deposit History</h3>
                        <div className="deposit_history">
                          <table class="table market">
                            <thead>
                              <tr class="market-header deposit">
                                <th scope="col">Date</th>
                                <th scope="col"> Time</th>
                                <th scope="col"> Currency</th>
                                <th scope="col"> Amount</th>
                                <th scope="col"> Transaction Id</th>
                                <th scope="col"> Status</th>
                              </tr>
                            </thead>

                            <tbody>
                              {depositHistory && depositHistory.length > 0 ? (
                                depositHistory.map((item, i) => {
                                  return (
                                    <tr>
                                      <td>
                                        {Moment(item.date).format("YYYY-MM-DD")}
                                      </td>
                                      <td>
                                        {Moment(item.date).format("HH:mm:ss")}
                                      </td>
                                      <td>{item.currencySymbol}</td>
                                      <td>
                                        {parseFloat(item.amount).toFixed(8)}
                                      </td>
                                      <td className="d-flex">
                                        <span className="hash_code">
                                          {item.txnid}
                                        </span>
                                        <i
                                          class="fa fa-clone"
                                          aria-hidden="true"
                                          onClick={() => copy(item.txnid)}
                                          style={{ cursor: "pointer" }}
                                        ></i>
                                      </td>
                                      <td>
                                        <span className="text-green">
                                          Completed
                                        </span>
                                      </td>
                                    </tr>
                                    // <tr>
                                    //   <td>20:20:45</td>
                                    //   <td>BTC</td>
                                    //   <td>1000 INR</td>
                                    //   <td>BSC</td>
                                    //   <td>
                                    //     <span class="hash_code">
                                    //       3423423423423vrfsfsdfe55345dfg454
                                    //     </span>
                                    //   </td>
                                    //   <td>ID0922201</td>
                                    //   <td>
                                    //     <span className="text-red">Canceled</span>
                                    //   </td>
                                    // </tr>
                                    // <tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan={6}>
                                    <div className="empty_data">
                                      <div className="empty_data_img">
                                        <img
                                          src={require("../Image/Table_image/no-record-light.png")}
                                        />
                                      </div>

                                      <div className="no_records_text">
                                        No Records Found{" "}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </section>
                    ) : (
                      ""
                    )}

                    <div class="modal fade " id="myModal" role="dialog">
                      <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content select_coin">
                          <div class="modal-header">
                            <h4 class="modal-title">Coin</h4>
                            <button
                              type="button"
                              class="close"
                              data-dismiss="modal"
                            >
                              &times;
                            </button>
                          </div>
                          <div class="modal-body">
                            {/* <div className="selection_dropdown">
                          <div className="slt_dropdown">
                            <i class="ri-search-line"></i>
                            <span className="spn_tether">Search</span>
                          </div>
                        </div> */}

                            <section
                              className="top_coin_section"
                              data-dismiss="modal"
                            >
                              <h3>Top</h3>
                              <p>
                                {allCurrencyref.current &&
                                allCurrencyref.current.length > 0
                                  ? allCurrencyref.current
                                      .slice(0, 5)
                                      .map((item, i) => {
                                        return (
                                          <span
                                            className="top_coin_item"
                                            onClick={() => onSelect(item)}
                                          >
                                            {" "}
                                            {item.label}{" "}
                                          </span>
                                        );
                                      })
                                  : ""}
                              </p>
                            </section>

                            <section
                              className="cryptocoin_items"
                              data-dismiss="modal"
                            >
                              {allCurrencyref.current &&
                              allCurrencyref.current.length > 0
                                ? allCurrencyref.current.map((item, i) => {
                                    return (
                                      <div
                                        className="cryptocoin_list"
                                        onClick={() => onSelect(item)}
                                      >
                                        <div className="cryptocoin_logo">
                                          <img src={item.imgurl} />
                                        </div>
                                        <div className="list_content">
                                          <h3>{item.label}</h3>
                                          <span> {item.currencyName} </span>
                                        </div>
                                      </div>
                                    );
                                  })
                                : ""}
                            </section>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="modal fade " id="selectnetwork" role="dialog">
                      <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content select_coin">
                          <div class="modal-header">
                            <h4 class="modal-title">Select Network</h4>
                            <button
                              type="button"
                              class="close"
                              data-dismiss="modal"
                            >
                              &times;
                            </button>
                          </div>
                          <div class="modal-body">
                            <div className="selection_network_warning mb-3">
                              <p>
                                <i class="ri-error-warning-line"></i>Ensure that
                                the selected deposit network is the same as the
                                withdrawal network. Otherwise, your assets could
                                be lost.
                              </p>
                            </div>

                            <section
                              className="cryptocoin_items"
                              data-dismiss="modal"
                            >
                              {network_currencyref.current &&
                              network_currencyref.current.length > 0
                                ? network_currencyref.current.map((item, i) => {
                                    console.log(item, "network");
                                    return (
                                      <div
                                        className="cryptocoin_list"
                                        onClick={() => onSelect_network(item)}
                                      >
                                        <div className="list_content">
                                          <h3> {item.label} </h3>
                                          {/* <span> {item.value} </span> */}
                                        </div>
                                      </div>
                                    );
                                  })
                                : ""}
                            </section>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
