import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./Footer";

import Sidebar from "./Innerpages/Sidebar";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { toast } from "react-toastify";

import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { getMethod } from "../core/service/common.api";

import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { env } from "../core/service/envconfig";

function Home() {
  const options = ["one", "two", "three"];

  const [idproof, setidproof, idproofref] = useState("");
  const [valididproof, setvalididproof] = useState(0);
  const [idproofLoad, setidproofLoad] = useState(false);

  const [addressProof, setaddressProof, addressProofref] = useState("");
  const [validaddressProof, setvalidaddressProof] = useState(0);
  const [addressProofLoad, setaddressProofLoad] = useState(false);

  const [addresIDFront, setaddresIDFront] = useState("");
  const [validaddresIDFront, setvalidaddresIDFront] = useState(0);
  const [addresIDFrontLoad, setaddresIDFrontLoad] = useState(false);

  const [addresIDBack, setaddresIDBack] = useState("");
  const [validaddresIDBack, setvalidaddresIDBack] = useState(0);
  const [addresIDBackLoad, setaddresIDBackLoad] = useState(false);

  const [photoproof, setphotoproof, photoproofref] = useState("");
  const [photoproofname, setphotoproofname, photoproofnameref] = useState("");
  const [idproofname, setidproofname, idproofnameref] = useState("");
  const [addressproofname, setaddressproofname, addressproofnameref] =
    useState("");

  const [validphotoproof, setvalidphotoproof] = useState(0);
  const [photoproofLoad, setphotoproofLoad] = useState(false);
  const [getKYCData, setgetKYCData] = useState("");
  const [siteLoader, setSiteLoader] = useState(false);
  const [kycLoader, setkycLoader] = useState(false);
  const [user, Setuser] = useState("");

  const value = {
    idNumber: "",
    AadhaarNumber: "",
  };
  const [formValue, setformValue] = useState(value);

  const imageUpload = (type, val) => {
    try {
      const fileExtension = val.name.split(".").at(-1);
      const fileSize = val.size;
      const fileName = val.name;
      if (
        fileExtension != "png" &&
        fileExtension != "jpg" &&
        fileExtension != "jpeg"
      ) {
        toast.error(
          "File does not support. You must use .png or .jpg or .jpeg "
        );
      } else if (fileSize > 10000000) {
        toast.error("Please upload a file smaller than 1 MB");
      } else {
        type == "idproof"
          ? setidproofLoad(true)
          : type == "addressProof"
            ? setaddressProofLoad(true)
            : type == "addressProofFront"
              ? setaddresIDFrontLoad(true)
              : type == "addressProofBack"
                ? setaddresIDBackLoad(true)
                : setphotoproofLoad(true);
        const data = new FormData();
        data.append("file", val);
        data.append("upload_preset", env.upload_preset);
        data.append("cloud_name", env.cloud_name);
        fetch(
          "https://api.cloudinary.com/v1_1/" + env.cloud_name + "/auto/upload",
          { method: "post", body: data }
        )
          .then((resp) => resp.json())
          .then((data) => {
            if (type == "idproof") {
              setidproofLoad(false);
              setvalididproof(2);
              if (
                fileExtension == "pdf" ||
                fileExtension == "odt" ||
                fileExtension == "doc"
              ) {
                setvalididproof(1);
              }

              setidproof(data.secure_url);
              setidproofname(val.name);
            }
            if (type == "addressProof") {
              setaddressProofLoad(false);
              setvalidaddressProof(2);
              if (
                fileExtension == "pdf" ||
                fileExtension == "odt" ||
                fileExtension == "doc"
              ) {
                setvalidaddressProof(1);
              }
              setaddressproofname(val.name);

              setaddressProof(data.secure_url);
            }
            if (type == "photoproof") {
              setphotoproofLoad(false);
              setvalidphotoproof(2);
              if (
                fileExtension == "pdf" ||
                fileExtension == "odt" ||
                fileExtension == "doc"
              ) {
                setvalidphotoproof(1);
              }
              setphotoproofname(val.name);

              setphotoproof(data.secure_url);
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error("Please try again later");
          });
      }
    } catch (error) {
      toast.error("Please try again later");
    }
  };

  const cancelData = () => {
    try {
      setphotoproofname("");
      setidproofname("");
      setaddressproofname("");
      setidproof("");
      setaddressProof("");
      setphotoproof("");
      setvalididproof(0);
      setvalidaddressProof(0);
      setvalidphotoproof(0);
    } catch (error) { }
  };
  const cancelidproof = () => {
    try {
      setidproofname("");
      setidproof("");
      setvalididproof(0);
    } catch (error) { }
  };
  const canceladdressproof = () => {
    try {
      setaddressproofname("");
      setaddressProof("");
      setvalidaddressProof(0);
    } catch (error) { }
  };
  const cancelphotoproof = () => {
    try {
      setphotoproofname("");
      setphotoproof("");
      setvalidphotoproof(0);
    } catch (error) { }
  };
  const uploadKYC = async () => {
    try {
      if (
        idproofref.current != "" &&
        addressProofref.current != "" &&
        photoproofref.current != ""
      ) {
        var obj = {
          addressProof: idproofref.current,
          idproof: addressProofref.current,
          photoproof: photoproofref.current,
        };

        var data = {
          apiUrl: apiService.savekyc,
          payload: obj,
        };
        setkycLoader(true);
        var resp = await postMethod(data);
        if (resp.status) {
          setkycLoader(false);
          getKyc();
          toast.success(resp.Message);
        } else {
          toast.error(resp.Message);
          setkycLoader(false);
        }
      } else {
        toast.error("Please give all proof");
      }
    } catch (error) { }
  };

  const getKyc = async () => {
    try {

      var data = {
        apiUrl: apiService.getKYC,
      };
      setSiteLoader(false);
      var resp = await getMethod(data);
      setSiteLoader(true);
      if (resp.status) {
        Setuser(resp.datas.userDetails);
        console.log(user, "user");
        if (resp.datas.kycDetails) {
          setvalididproof(2);
          setvalidaddressProof(2);
          setvalidaddresIDFront(2);
          setvalidaddresIDBack(2);
          setvalidphotoproof(2);
        }
        if (Object.keys(resp.datas.kycDetails).length > 0) {
          var kycData = resp.datas.kycDetails;

          setgetKYCData(kycData);
          console.log(kycData.proof1, "kycData.proof1");
          setidproof(kycData.proof1);
          console.log(idproofref.current, "kycData.proof1");

          formValue.idNumber = kycData.IdNumber;
          setaddressProof(kycData.proof2);
          setaddresIDFront(kycData.proof3);
          setaddresIDBack(kycData.proof4);
          setphotoproof(kycData.proof3);
          var filetype_front = kycData.proof1.split(".").pop().trim();
          var filetype_back = kycData.proof2.split(".").pop().trim();
          var filetype_photoproof = kycData.proof3.split(".").pop().trim();
          if (
            filetype_front == "pdf" ||
            filetype_front == "doc" ||
            filetype_front == "docx"
          ) {
            setvalididproof(1);
          }
          if (
            filetype_back == "pdf" ||
            filetype_back == "doc" ||
            filetype_back == "docx"
          ) {
            setvalidaddressProof(1);
          }
          if (
            filetype_photoproof == "pdf" ||
            filetype_photoproof == "doc" ||
            filetype_photoproof == "docx"
          ) {
            setvalidphotoproof(1);
          }

          if (kycData.kycStatus == 3) {
            setvalididproof(2);
            setvalidaddressProof(2);
            setvalidphotoproof(2);
          }
        }
      }
    } catch (error) { }
  };

  useEffect(() => {
    getKyc();
  }, [0]);

  const getValue = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setformValue(formData);
  };

  return (
    <div className="header ">
      <Header />
      <div>
        <div className="body_hXuuO ">
          <div className="lrtcss-wxjc90 e161ieae2">
            <div className="lrtcss-q484j3 e161ieae1">
              <div className="KuxMenu-root lrtcss-yqpjhy">
                <Sidebar />
              </div>
            </div>
            <div className="lrtcss-1p2kb7b ma-000">

              {siteLoader == false ? <i class="fa-solid fa-spinner fa-spin"></i> : (

                <><div class="lrtcss-1urys0g e1epgi046">
                  <span>Individual verification</span>
                  {getKYCData.kycStatus == 3 ? (
                    <div className="lrtcss-1wiku5 e1epgi045 -red">
                      <span>Status: Rejected</span>
                    </div>
                  ) : getKYCData.kycStatus == 2 ? (
                    <div className="lrtcss-1wiku5 e1epgi045">
                      <span>Status: Pending</span>
                    </div>
                  ) : getKYCData.kycStatus == 1 ? (
                    <div className="lrtcss-1wiku5 e1epgi045 -green">
                      <span>Status: verified</span>
                    </div>
                  ) : (
                    <div className="lrtcss-1wiku5 e1epgi045 -red">
                      <span>Status: Not Upload</span>
                    </div>
                  )}
                </div>
                  <div class="lrtcss-ac8m87 e8w7gdo16">
                    <section className="deposit_crypto_section kyc_section mb-5">
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="faq-section faq_list">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="">
                                  <h3 className="">
                                    Standard Identity Verification
                                  </h3>
                                  <p>
                                    It only takes 3-5 minutes to verify your
                                    account. Complete to unlock deposit and trading
                                    permissions.
                                  </p>
                                </div>
                                <div className="flexbutton">
                                  <Button
                                    className="priomary"
                                    data-toggle="modal"
                                    data-target="#myModal"
                                  >
                                    Verify
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mt-4">
                            <div className="faq_list fetures_account">
                              <h3 className="">Account Features</h3>
                              <div className="deposit_history">
                                <table class="table market">
                                  <thead>
                                    <tr class="market-header deposit">
                                      <th scope="col">Features</th>
                                      <th scope="col">
                                        {" "}
                                        Unverified{" "}
                                        <span className="color_text_over">
                                          Current
                                        </span>
                                      </th>
                                      <th scope="col">
                                        {" "}
                                        Verified
                                      </th>
                                    </tr>
                                  </thead>
                                  <tr>
                                    <td>
                                      <b>Withdrawals</b>
                                    </td>
                                    <td>
                                      <b>0 USDT</b>
                                    </td>
                                    <td className="colorpurpule">999,999 USDT</td>
                                  </tr>
                                 
                                  <tr>
                                    <td>
                                      <b>Trading</b>
                                    </td>
                                    <td className="colorpurpule text-center">
                                      <i class="ri-close-line"></i>
                                    </td>
                                    <td className="colorpurpule text-center">
                                      <i class="ri-check-line"></i>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Deposits/Crypto Purchases</b>
                                    </td>
                                    <td className="colorpurpule text-center">
                                      <i class="ri-close-line"></i>
                                    </td>
                                    <td className="colorpurpule text-center">
                                      <i class="ri-check-line"></i>
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <section className="faq-section">
                            <div className="faq_list">
                              <h3>FAQ</h3>
                              <div className="Accordion">
                                <Accordion className="Accordionfaq">
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    className="Accordionhead"
                                    id="panel1-header"
                                  >1. What is identity verification?
                                  </AccordionSummary>
                                  <AccordionDetails className="Accordionans">Identity verification is the process of confirming that a person's identity matches the information provided.</AccordionDetails>
                                </Accordion>
                                <Accordion className="Accordionfaq">
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                  >2. Why do I need to complete identity verification?</AccordionSummary>
                                  <AccordionDetails>This helps prevent fraud and ensures that services are provided to legitimate individuals.</AccordionDetails>
                                </Accordion>
                                <Accordion className="Accordionfaq">
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                  >3. Which documents and information are required for identity verification?</AccordionSummary>
                                  <AccordionDetails>The verification process typically involves providing personal information along with supporting documents for a national ID, address proof, and photo proof.</AccordionDetails>

                                </Accordion>
                                <Accordion className="Accordionfaq">
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                  >4. Where can I find help for issues regarding identity verification?</AccordionSummary>
                                  <AccordionDetails>Contact the exchange's customer support. Use this link. https://cex.beleaftechnologies.com/support
                                  </AccordionDetails>

                                </Accordion>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </section>
                  </div>
                </>)}
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <div class="modal fade bd-example-modal-lg" id="myModal" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content select_coin">
            <div class="modal-header">
              <h4 class="modal-title ml-0">Identity Verification</h4>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div class="modal-body pt-0">
              {getKYCData.kycStatus == 3 ? (
                <div className="col-lg-12 p-0">
                  <label className="lable_KYC -red">
                    {" "}
                    {getKYCData.rejectReson}{" "}
                  </label>
                </div>
              ) : (
                ""
              )}

              <div className="row">
                <div className="col-lg-4">
                  <div className="check_radio">
                    <ul class="nav nav-tabs">
                      <li>
                        <a data-toggle="tab" href="#menu2">
                          <div className="image_div">
                            <div className="cicler">
                              <img
                                src="https://assets.staticimg.com/g-biz/externals/2022-06-01/43ef79d27324eaf9.svg"
                                className=""
                              />
                            </div>
                            Government Issued ID
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="upload">
                    <div class="mb-6 pt-4">
                      <div class="formbold-mb-5 formbold-file-input">
                        {
                          user.kycstatus == 0 ? (
                            <input
                              type="file"
                              name="image"
                              onChange={(e) =>
                                imageUpload("idproof", e.target.files[0])
                              }
                              id="file1"
                            />
                          ) : ("")}


                        <label for="file1">
                          {idproofLoad == false ? (
                            valididproof == 0 ? (
                              <div>
                                <span class="formbold-drop-file">
                                  {" "}
                                  Drop files here{" "}
                                </span>

                                <span class="formbold-or"> Or </span>
                                <span class="formbold-browse"> Browse </span>
                              </div>
                            ) : valididproof == 1 ? (
                              <span className="">
                                <i class="bi bi-file-earmark-bar-graph"></i>
                              </span>
                            ) : (
                              <img
                                src={idproofref.current}
                                className="img-fluid"
                                alt="National Id Front"
                              />
                            )
                          ) : (
                            <div
                              class="spinner-border spinner_kyc text-white"
                              role="status"
                            >
                              <i
                                class="fa fa-spinner d-none"
                              ></i>{" "}
                            </div>
                          )}

                          {/* {idproofref.current == "" ? (
                            <div>
                              <span class="formbold-drop-file">
                                {" "}
                                Drop files here{" "}
                              </span>

                              <span class="formbold-or"> Or </span>
                              <span class="formbold-browse"> Browse </span>
                            </div>
                          ) : (
                            ""
                          )} */}
                        </label>
                      </div>

                      {idproofnameref.current == "" ? (
                        ""
                      ) : (
                        <div class="formbold-file-list formbold-mb-5">
                          <div class="formbold-file-item d-flex justify-content-between">
                            <span class="formbold-file-name">
                              {" "}
                              {idproofnameref.current}{" "}
                            </span>
                            <button onClick={cancelidproof} >
                              <svg
                                width="10"
                                height="10"
                                viewBox="0 0 10 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M0.279337 0.279338C0.651787 -0.0931121 1.25565 -0.0931121 1.6281 0.279338L9.72066 8.3719C10.0931 8.74435 10.0931 9.34821 9.72066 9.72066C9.34821 10.0931 8.74435 10.0931 8.3719 9.72066L0.279337 1.6281C-0.0931125 1.25565 -0.0931125 0.651788 0.279337 0.279338Z"
                                  fill="currentColor"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M0.279337 9.72066C-0.0931125 9.34821 -0.0931125 8.74435 0.279337 8.3719L8.3719 0.279338C8.74435 -0.0931127 9.34821 -0.0931123 9.72066 0.279338C10.0931 0.651787 10.0931 1.25565 9.72066 1.6281L1.6281 9.72066C1.25565 10.0931 0.651787 10.0931 0.279337 9.72066Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="check_radio">
                    {/* <label className="lable_KYC">Select Verification Type</label> */}
                    <ul class="nav nav-tabs">
                      <li>
                        <a data-toggle="tab" href="#menu2">
                          <div className="image_div">
                            <div className="cicler">
                              <img
                                src="https://assets.staticimg.com/g-biz/externals/2022-06-01/43ef79d27324eaf9.svg"
                                className=""
                              />
                            </div>
                            Proof Of Address
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="upload">
                    <div class="mb-6 pt-4">
                      <div class="formbold-mb-5 formbold-file-input">
                        {user.kycstatus == 0 ? (
                          <input
                            type="file"
                            name="image"
                            onChange={(e) =>
                              imageUpload("addressProof", e.target.files[0])
                            }
                            id="file2"
                          />
                        ) : ("")}


                        <label for="file2">
                          {addressProofLoad == false ? (
                            validaddressProof == 0 ? (
                              <div>
                                <span class="formbold-drop-file">
                                  {" "}
                                  Drop files here{" "}
                                </span>
                                <span class="formbold-or"> Or </span>
                                <span class="formbold-browse"> Browse </span>
                              </div>
                            ) : validaddressProof == 1 ? (
                              <span className="">
                                <i class="bi bi-file-earmark-bar-graph"></i>
                              </span>
                            ) : (
                              <img
                                src={addressProofref.current}
                                className="img-fluid"
                                alt="National Id Front"
                              />
                            )
                          ) : (
                            <div
                              class="spinner-border spinner_kyc text-white"
                              role="status"
                            >
                              <i
                                class="fa fa-spinner d-none"

                              ></i>{" "}
                            </div>
                          )}

                          {/* {addressProofref.current == "" ? (
                            <div>
                              <span class="formbold-drop-file">
                                {" "}
                                Drop files here{" "}
                              </span>
                              <span class="formbold-or"> Or </span>
                              <span class="formbold-browse"> Browse </span>
                            </div>
                          ) : (
                            ""
                          )} */}
                        </label>
                      </div>

                      {addressproofnameref.current == "" ? (
                        ""
                      ) : (
                        <div class="formbold-file-list formbold-mb-5">
                          <div class="formbold-file-item d-flex justify-content-between">
                            <span class="formbold-file-name">
                              {" "}
                              {addressproofnameref.current}{" "}
                            </span>
                            <button onClick={canceladdressproof} >
                              <svg
                                width="10"
                                height="10"
                                viewBox="0 0 10 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M0.279337 0.279338C0.651787 -0.0931121 1.25565 -0.0931121 1.6281 0.279338L9.72066 8.3719C10.0931 8.74435 10.0931 9.34821 9.72066 9.72066C9.34821 10.0931 8.74435 10.0931 8.3719 9.72066L0.279337 1.6281C-0.0931125 1.25565 -0.0931125 0.651788 0.279337 0.279338Z"
                                  fill="currentColor"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M0.279337 9.72066C-0.0931125 9.34821 -0.0931125 8.74435 0.279337 8.3719L8.3719 0.279338C8.74435 -0.0931127 9.34821 -0.0931123 9.72066 0.279338C10.0931 0.651787 10.0931 1.25565 9.72066 1.6281L1.6281 9.72066C1.25565 10.0931 0.651787 10.0931 0.279337 9.72066Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="check_radio">
                    {/* <label className="lable_KYC">Select Verification Type</label> */}
                    <ul class="nav nav-tabs">
                      <li>
                        <a data-toggle="tab" href="#menu2">
                          <div className="image_div">
                            <div className="cicler">
                              <img
                                src="https://assets.staticimg.com/g-biz/externals/2022-06-01/43ef79d27324eaf9.svg"
                                className=""
                              />
                            </div>
                            Photo Proof
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="upload">
                    <div class="mb-6 pt-4">
                      <div class="formbold-mb-5 formbold-file-input">

                        {user.kycstatus == 0 ? (
                          <input
                            type="file"
                            name="image"
                            onChange={(e) =>
                              imageUpload("photoproof", e.target.files[0])
                            }
                            id="file3"
                          />
                        ) : ("")}

                        <label for="file3">
                          {photoproofLoad == false ? (
                            validphotoproof == 0 ? (
                              <div>
                                <span class="formbold-drop-file">
                                  {" "}
                                  Drop files here{" "}
                                </span>
                                <span class="formbold-or"> Or </span>
                                <span class="formbold-browse"> Browse </span>
                              </div>
                            ) : validphotoproof == 1 ? (
                              <span className="">
                                <i class="bi bi-file-earmark-bar-graph"></i>
                              </span>
                            ) : (
                              <img
                                src={photoproofref.current}
                                className="img-fluid"
                                alt="National Id Front"
                              />
                            )
                          ) : (
                            <div
                              class="spinner-border spinner_kyc text-white"
                              role="status"
                            >
                              <i
                                class="fa fa-spinner d-none"
                              ></i>{" "}
                            </div>
                          )}

                          {/* {photoproofref.current == "" ? (
                            <div>
                              <span class="formbold-drop-file">
                                {" "}
                                Drop files here{" "}
                              </span>
                              <span class="formbold-or"> Or </span>
                              <span class="formbold-browse"> Browse </span>
                            </div>
                          ) : (
                            ""
                          )} */}
                        </label>
                      </div>

                      {photoproofnameref.current == "" ? (
                        ""
                      ) : (
                        <div class="formbold-file-list formbold-mb-5">
                          <div class="formbold-file-item d-flex justify-content-between">
                            <span class="formbold-file-name">
                              {" "}
                              {photoproofnameref.current}{" "}
                            </span>
                            <button onClick={cancelphotoproof} >
                              <svg
                                width="10"
                                height="10"
                                viewBox="0 0 10 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M0.279337 0.279338C0.651787 -0.0931121 1.25565 -0.0931121 1.6281 0.279338L9.72066 8.3719C10.0931 8.74435 10.0931 9.34821 9.72066 9.72066C9.34821 10.0931 8.74435 10.0931 8.3719 9.72066L0.279337 1.6281C-0.0931125 1.25565 -0.0931125 0.651788 0.279337 0.279338Z"
                                  fill="currentColor"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M0.279337 9.72066C-0.0931125 9.34821 -0.0931125 8.74435 0.279337 8.3719L8.3719 0.279338C8.74435 -0.0931127 9.34821 -0.0931123 9.72066 0.279338C10.0931 0.651787 10.0931 1.25565 9.72066 1.6281L1.6281 9.72066C1.25565 10.0931 0.651787 10.0931 0.279337 9.72066Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              {getKYCData.kycStatus == 2 || getKYCData.kycStatus == 1 ? (
                ""
              ) : (
                <div>
                  <Button className="mr-2" data-dismiss="modal" onClick={cancelData}>Cancel</Button>
                  {kycLoader == false ? (
                    <Button onClick={uploadKYC} data-dismiss="modal">Upload</Button>
                  ) : (
                    <Button>Loading...</Button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
