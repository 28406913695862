import React, {useEffect, useState} from "react";
import logo from "./logo.svg";
import {removeAuthToken} from "../src/core/lib/localStorage";
// import "./App.css";
import Home from "./pages/HomeNew";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Refferal from "./pages/Refferal";
import Applayform from "./pages/Applayform";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Forget from "./pages/Forget";
import ConfirmNewpass from "./pages/ConfirmNewpass";
import OTP from "./pages/OTP_pass";
import Sucesslogin from "./pages/Sucesslogin";
import Deposit from "./pages/Deposit";
import Bankdetails from './pages/Bankdetail'
import Support from './pages/Support'
import Dashboard from "./pages/Dashboard";
// import Wallet from "./pages/Wallet";
import P2p from "./pages/P2p";
import P2pChat from "./pages/P2p_chat";
import Convert from "./pages/Convert";

import TFA from "./pages/TFA";
import ChangePassword from "./pages/ChangePassword";
import Orders from "./pages/Orderhistory";

import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Wallet from './pages/Wallet'
import P2PostAd from './pages/P2ppostad'
import Security from "./pages/Security";
import Staking from "./pages/Staking";
import Trade from "./pages/Trade";
import Kyc from "./pages/Kyc";
import History from "./pages/History";
import Withdraw from "./pages/Withdraw";
import NewRefferal from "./pages/NewRefferal";
import Launchpad from "./pages/Launchpad";
import Launch_Apply from "./pages/Launch_Apply";
import Launchbuy from "./pages/Lanchpadbuy";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Uniramp from "./pages/Uniramp";



import Market from "./pages/Market";
// import Login from "./pages/Login";

import AOS from "aos";
import "aos/dist/aos.css";
function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {}, []);

  const [isActive, setIsActive] = useState(true);

  function RequireAuth({children}) {
    var data = localStorage.getItem("user_token");
    return data  ? children : removeAuthToken();
  }

  return (
    <>
      <BrowserRouter>
        <ToastContainer />
        <Routes>
          {/* <Route path="/" element={<Home />} /> */}
          {/* <Route path="/refferal" element={<Refferal />} /> */}
          {/* <Route path="/applay" element={<Applayform />} /> */}
          {/* <Route path="/" element={<Login />} /> */}
          <Route path="/" element={<Home />} />
          <Route path="/register" element={<Register />} />

          <Route path="/trade/:pair" element={<Trade />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forget" element={<Forget />} />
          <Route path="/newpass" element={<ConfirmNewpass />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/Wallet" element={<Wallet />} />
          <Route path="/Convert" element={<Convert />} />
          <Route path="/Withdraw" element={<Withdraw />} />
          <Route path="/security" element={<Security />} />
          <Route path="/Identity" element={<Kyc />} />
          <Route path="/History" element={<History />} />
          <Route path="/Orders" element={<Orders />} /> 
          <Route path="/otp" element={<OTP />} />
          <Route path="/verify-tfa" element={<TFA />} />
          <Route path="/changepassword" element={<ChangePassword />} />
          <Route path="/deposit" element={<Deposit />} />
          <Route path="/sucess" element={<Sucesslogin />} />
          <Route path="/staking" element={<Staking />} />
          <Route path="/launchpad" element={<Launchpad/>}/>
          <Route path="/launchpad_apply" element={<Launch_Apply/>}/>
          <Route path="/launchpad_buy/:id" element={<Launchbuy/>}/>
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/buycrypto" element={<Uniramp />} />



          <Route path="/market" element={<Market/>}/>
          {/* <Route path="/dashboard" element={<Dashboard />} />*/}
        

          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />
            <Route
            path="/referral"
            element={
              <RequireAuth>
                <NewRefferal />
              </RequireAuth>
            }
          />
           <Route
            path="/support"
            element={
              <RequireAuth>
                <Support />
              </RequireAuth>
            }
          />
            <Route
            path="/paymentmethod"
            element={
              <RequireAuth>
                <Bankdetails />
              </RequireAuth>
            }
          />
          {/* <Route
            path="/ "
            element={
              <RequireAuth>
                <Wallet />
              </RequireAuth>
            }
          /> */}
          <Route
            path="/p2p"
            element={
              <RequireAuth>
                <P2p />
              </RequireAuth>
            }
          />
            <Route
            path="/p2p/post-Ad"
            element={
              <RequireAuth>
                <P2PostAd />
              </RequireAuth>
            }
          />
          <Route
            path="/P2pchat"
            element={
              <RequireAuth>
                <P2pChat />
              </RequireAuth>
            }
          />
          <Route
            path="/Convert"
            element={
              <RequireAuth>
                <Convert />
              </RequireAuth>
            }
          />
          <Route
            path="/security"
            element={
              <RequireAuth>
                <Security />
              </RequireAuth>
            }
          />
          <Route
            path="/deposit"
            element={
              <RequireAuth>
                <Deposit />
              </RequireAuth>
            }
          />
          <Route
            path="/sucess"
            element={
              <RequireAuth>
                <Sucesslogin />
              </RequireAuth>
            }
          />
          <Route
            path="/staking"
            element={
              <RequireAuth>
                <Staking />
              </RequireAuth>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
