import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useNavigate } from "react-router-dom";
import { socket } from "./context/socket";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Button } from "@mui/material";

import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  removeAuthToken,
  getAuthToken,
  getSocketToken,
} from "../core/lib/localStorage";
import { removeAuthorization } from "../core/service/axios";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";

import {
  ListItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const DrawerComp = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [loginCheck, setloginCheck] = useState(false);

  const drawerWidth = 240;
  const zIndex1 = 2000000;

  const navigate = useNavigate();

  const classes = useState();

  const logout = async () => {
    var data = {
      apiUrl: apiService.logout,
    };
    var resp = await getMethod(data);
    if (resp) {
      await removeAuthorization();
      await removeAuthToken();
      localStorage.clear();
      navigate("/login");
    }
  };

  useEffect(() => {
    let userToken = localStorage.getItem("user_token");
    if (userToken) {
      setloginCheck(true);
    } else {
      setloginCheck(false);
    }
  }, [0]);

  const [modal1, setModal1] = useState(false);

  const toggle1 = async (e) => {
    console.log("e====", e);
    setModal1(!modal1);
    setOpenDrawer(false);

  };

  const goto1 = () => setModal1(!modal1);

  return (
    <React.Fragment>
      <Drawer
        anchor="left"
        open={openDrawer}
        sx={{
          display: { xs: "block", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            zIndex: zIndex1,
          },
        }}
        onClose={() => setOpenDrawer(false)}
      >
        <List className="">
          <ListItemButton>
            {" "}
            <ListItemIcon>
              <ListItemText>
                <Link className="nav_brand " to="/">
                  {" "}
                  <img
                    src={require("../Image/nav_bar/Logolight.png")}
                    className="blackshow"
                  />
                </Link>
              </ListItemText>
            </ListItemIcon>
          </ListItemButton>

          <Accordion className={`${classes.accordion} acrdion px-0`}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              className="inner_text_accoridion  dropdown1"
            >
              <p className="m-0"> Trade </p>
            </AccordionSummary>
            <AccordionDetails className="display_accodion px-0">
              <List className="drawclass px-0">
                <ListItemButton>
                  {" "}
                  <ListItemIcon>
                    <ListItemText>
                      <Link to="/trade/BTC_USDT" className="link_tage px-0">
                        <i class="ri-token-swap-line"></i>
                        Spot
                      </Link>
                    </ListItemText>
                  </ListItemIcon>
                </ListItemButton>
                <ListItemButton>
                  {" "}
                  <ListItemIcon>
                    <ListItemText>
                      <Link to="/Convert" className="link_tage px-0">
                        <i class="ri-exchange-dollar-fill"></i>
                        Swap
                      </Link>
                    </ListItemText>
                  </ListItemIcon>
                </ListItemButton>
                <ListItemButton>
                  {" "}
                  <ListItemIcon>
                    <ListItemText>
                      <Link to="/buycrypto" className="link_tage px-0">
                        <i class="ri-currency-line"></i>
                        Buy Crypto / onRamp
                      </Link>
                    </ListItemText>
                  </ListItemIcon>
                </ListItemButton>
              </List>
            </AccordionDetails>
          </Accordion>

          {loginCheck == false ? (
            <div className="drawclass">
              <ListItemButton>
                {" "}
                <ListItemIcon>
                  <ListItemText>
                    <Link to="/login" className="link_tage">
                      <i class="ri-login-box-line"></i>
                      Login
                    </Link>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>
              <ListItemButton>
                {" "}
                <ListItemIcon>
                  <ListItemText>
                    <Link to="/register" className="link_tage">
                      <i class="ri-user-shared-2-fill"></i>
                      Register
                    </Link>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>
            </div>
          ) : (
            <div className="drawclass">
              <ListItemButton>
                {" "}
                <ListItemIcon>
                  <ListItemText>
                    <Link to="/dashboard" className="link_tage">
                      <i class="ri-dashboard-line"></i>
                      Dashboard
                    </Link>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>
              <ListItemButton>
                {" "}
                <ListItemIcon>
                  <ListItemText>
                    <Link to="/Security" className="link_tage">
                      <i class="ri-file-shield-2-line m-0"></i>
                      <i class=""></i>
                      Security
                    </Link>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>
              <ListItemButton>
                {" "}
                <ListItemIcon>
                  <ListItemText>
                    <Link to="/Identity" className="link_tage">
                      <i class="ri-user-follow-line"></i>
                      Identity Verification
                    </Link>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>
              <ListItemButton>
                {" "}
                <ListItemIcon>
                  <ListItemText>
                    <Link to="/deposit" className="link_tage">
                      <i class="ri-luggage-deposit-line"></i>
                      Deposit
                    </Link>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>
              <ListItemButton>
                {" "}
                <ListItemIcon>
                  <ListItemText>
                    <Link to="/Withdraw" className="link_tage">
                      <i class="ri-bank-line"></i>
                      Withdrawal
                    </Link>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>
              <ListItemButton>
                {" "}
                <ListItemIcon>
                  <ListItemText>
                    <Link to="/History" className="link_tage">
                      <i class="ri-history-line"></i>
                      History
                    </Link>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>
              <ListItemButton>
                {" "}
                <ListItemIcon>
                  <ListItemText>
                    <Link to="/Orders" className="link_tage">
                      <i class="ri-file-history-line"></i>
                      Orders
                    </Link>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>
              <ListItemButton>
                {" "}
                <ListItemIcon>
                  <ListItemText>
                    <Link to="/support" className="link_tage">
                      <i class="ri-questionnaire-line"></i>
                      Support
                    </Link>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>
              <ListItemButton>
                {" "}
                <ListItemIcon>
                  <ListItemText>
                    <Link to="#" onClick={toggle1} className="link_tage">
                      <i class="ri-logout-box-line"></i>
                      Logout
                    </Link>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>
            </div>
          )}
        </List>
      </Drawer>
      <IconButton
        sx={{ color: "white", marginLeft: "auto" }}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <MenuIcon color="white" />
      </IconButton>
      <Modal
        isOpen={modal1}
        toggle={toggle1}
        className="rem10 checkout_modal mt40"
      >
        <ModalBody className="p-0">
          <div class="Frame-14722">
            <span class="Item-Details h2">
              <i class="ri-logout-box-line mr-2"></i> Logout{" "}
            </span>
            <div class="">
              <i class="ri-close-fill  Close" onClick={goto1}></i>
            </div>
          </div>

          <div className="py-2">
            <p className="Deletecontent my-4">
              Are you sure want to logout this session ?
            </p>

            <div className="row flex-row px-3 py-1 " >
              <div className="col-md-6 mt-2">
                <div className="form_login_input">
                  <Button className="discardbtn" onClick={goto1}>
                    Discard
                  </Button>
                </div>
              </div>

              <div className="col-md-6 mt-2">
                <div className="form_login_input">
                  <Button onClick={logout}>Logout</Button>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default DrawerComp;
