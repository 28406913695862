import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Dropdown } from "semantic-ui-react";
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import moment from "moment";
import useState from "react-usestateref";
import useStateRef from "react-usestateref";

function Home() {
  const navigate = useNavigate();

  const [fromTab, setFromTab] = useState([]);
  const [toTab, setToTab] = useState([]);
  const [fromCurrency, setfromCurrency, fromref] = useState("");
  const [toCurrency, settoCurrency, toref] = useState("USDT");
  const [appendFromData, setappendFromData] = useState("");
  const [appendToData, setappendFToData] = useState("");
  const [fromcurrencyImage, setFromcurrencyImage] = useState("");
  const [tocurrencyImage, setTocurrencyImage] = useState("");
  const [swapTab, setswapTab] = useState(false);
  const [fromAmount, setfromAmount] = useState(0);
  const [toAmount, settoAmount] = useState(0);
  const [minMax, setMinMax] = useState(10);
  const [price, setPrice, priceRef] = useStateRef(0);
  const [estimateFee, setEstimationFee] = useState(0);
  const [totatlAmount, setTotalAmount] = useState(0);
  const [sessionHistory, setsessionHistory] = useState([]);
  const [totalPage, setTotalpages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [sitekycStatus, setsitekycStatus] = useState("DeActive");
  const [kycStatus, setkycStatus, kycstatusref] = useState(0);
  const [ButtonLoader, setButtonLoader] = useState(false);
  const [currencyLabel, setCurrencyLabel] = useState("");
  const [currencyImg, setCurrencyImg] = useState("");
  const [toCurrencyLabel, setToCurrencyLabel] = useState("");
  const [toCurrencyImg, setToCurrencyImg] = useState("");

  const [allCurrencyFiat, setfromCurrencyRef, fromCurrencyRef] = useState([]);
  const [toCurrencyRefs, setToCurrencyRef, toCurrencyRef] = useState([]);

  const [fromSwap, setfromSwapRef, fromSwapRef] = useState([]);
  const [toSwap, settoSwapRef, toSwapRef] = useState([]);
  const [siteLoader, setSiteLoader] = useState(false);

  const recordPerPage = 5;
  const pageRange = 5;

  useEffect(() => {
    getUserbalance();
    swaphistory();
    getKYCstatus();
    getSiteSettingstatus();
  }, [0]);

  const onSelect = async (option, type) => {
    console.log("call onselect====", option);
    console.log("call type====", type);
    if (type == "fromTab") {
      var findIndexing = fromTab.findIndex((x) => x.currid == option.value);
      console.log("findIndexing===", findIndexing);
      if (findIndexing != -1) {
        setappendFromData(fromTab[findIndexing]);
        setFromcurrencyImage(fromTab[findIndexing].image);
        setfromCurrency(fromTab[findIndexing].currencySymbol);
        swapPrice();
        // setAmount("","");
      }
    } else if (type == "toTab") {
      var findIndexingTo = fromTab.findIndex((x) => x.currid == option.value);
      if (findIndexing != -1) {
        settoCurrency(fromTab[findIndexingTo].currencySymbol);
        setappendFToData(fromTab[findIndexingTo]);
        setTocurrencyImage(fromTab[findIndexingTo].image);
        swapPrice();
        // setAmount("","");
      }
    }
  };

  const getUserbalance = async () => {
    var data1 = {
      apiUrl: apiService.getUserBalanceSwap,
    };
    var resp = await getMethod(data1);
    console.log(resp, "resp");
    if (resp.status) {
      setFromTab(resp.data);
      setToTab(resp.data);
      var currArrayCrypto = [];
      var currArrayFiat = [];
      var data = resp.data;
      for (var i = 0; i < data.length; i++) {
        if (data[i].currencySymbol) {
          var obj = {
            value: data[i].currid,
            label: data[i].currencySymbol,
            key: data[i].currencySymbol,
            text: data[i].currencySymbol,
            image: {
              avatar: true,
              src: data[i].image,
            },
          };
          currArrayFiat.push(obj);
          currArrayCrypto.push(obj);
        }
      }
      setToCurrencyRef(currArrayFiat);
      setfromCurrencyRef(currArrayCrypto);
    } else {
    }
  };

  const defaulatCurrFiat = fromCurrencyRef.current[1];
  const defaulatCurrTo = toCurrencyRef.current[3];

  const swaping = async () => {
    try {
      console.log("interchangeble");
    } catch (error) { }
  };

  const setAmount = async (value, type) => {
    console.log("value", value, "type", type);
    console.log(appendFromData, "appendFromData");

      try {
        if (!isNaN(value)) {
          type == "fromAmount" ? setfromAmount(value) : settoAmount(value);
          var obj = {
            from: appendFromData.currencySymbol,
            to: appendToData.currencySymbol,
          };
          var data = {
            apiUrl: apiService.currencyConversion,
            payload: obj,
          };
          if (fromCurrency != "" && toCurrency != "") {
            var resp = await postMethod(data);
            if (resp.status) {
              var fee = (+value * +appendFromData.swapFee) / 100;
              console.log("fee===", fee);
              setEstimationFee(fee);
              var total = +value + +fee;
              console.log("total===", total);
              //setTotalAmount(parseFloat(total).toFixed(8));
              setTotalAmount(total);

              setPrice(resp.Message);
              console.log("price===", resp.Message);
              if (type == "fromAmount") {
                var amount = Number(resp.Message) * Number(value);
                console.log("amount===", amount);
                // setfromAmount(parseFloat(value).toFixed(8));
                // settoAmount(parseFloat(amount).toFixed(8));
                setfromAmount(value);
                settoAmount(amount);
              } else if (type == "toAmount") {
                var amount = Number(value) / Number(resp.Message);
                // setfromAmount(parseFloat(amount).toFixed(8));
                // settoAmount(parseFloat(value).toFixed(8));
                setfromAmount(amount);
                settoAmount(value);
              }
            }
          } else {
          }
        } else {
          type == "fromAmount" ? setfromAmount(0) : settoAmount(0);
        }
      } catch (error) { }


  };

  const swapAmount = async () => {
    console.log(appendFromData.currencySymbol, appendToData.currencySymbol);
    try {
      if (
        appendFromData.currencySymbol != undefined &&
        appendToData.currencySymbol != undefined
      ) {
        if (appendFromData.currencySymbol != appendToData.currencySymbol) {
          if (fromAmount > 0 && toAmount > 0) {
            var obj = {
              from: appendFromData.currencySymbol,
              to: appendToData.currencySymbol,
              from_id: appendFromData.currid,
              to_id: appendToData.currid,
              fromAmount: +fromAmount,
              toAmount: +toAmount,
              fee: +estimateFee,
              withFee: +totatlAmount,
              currentPrice: +priceRef.current,
            };

            console.log(obj, "obj");

            var data = {
              apiUrl: apiService.swapping,
              payload: obj,
            };
            setButtonLoader(true);
            var resp = await postMethod(data);
            setButtonLoader(false);
            if (resp.status) {
              setfromAmount(0);
              settoAmount(0);
              swaphistory(1);
              getUserbalance();
              toast.success(resp.Message);
              navigate("/dashboard");
            } else {
              swaphistory(1);
              toast.error(resp.Message);
            }
          } else {
            toast.error("Please enter amount");
          }
        } else {
          toast.error("Same currency should not allow the swapping");
        }
      } else {
        toast.error("Please choose the swapping currencies");
      }
    } catch (error) { }
  };

  const swaphistory = async (page) => {
    try {
      var payload = {
        perpage: 5,
        page: page,
      };
      var data = {
        apiUrl: apiService.swappingHistory,
        payload: payload,
      };

      var resp = await postMethod(data);
      if (resp.status) {
        setsessionHistory(resp.data.data);
        setTotalpages(resp.data.total);
      }
    } catch (error) { }
  };

  const handlePageChange = (pageNumber) => {
    swaphistory(pageNumber);
    setCurrentPage(pageNumber);
  };

  const maxBalance = async () => {
    try {
      var fee =
        (+appendFromData.currencyBalance * +appendFromData.swapFee) / 100;
      var amount = appendFromData.currencyBalance - fee;
      setfromAmount(amount.toFixed(8));
      console.log("=-=-=-=-=-=-=-===-=maxBalance=-=-=-==-=-==-");
      setAmount(amount.toFixed(8), "fromAmount");
    } catch (error) { }
  };

  const swapPrice = async () => {
    try {
      console.log(toref.current);
      console.log(fromref.current);
      var obj = {
        from: fromref.current != undefined ? fromref.current : "BTC",
        to:
          toref.current != undefined ||
            toref.current != null ||
            toref.current != ""
            ? toref.current
            : "USDT",
      };
      console.log("swap===", obj);
      var data = {
        apiUrl: apiService.currencyConversion,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        setPrice(resp.Message);
      }
    } catch (error) { }
  };

  const handleOnChange_from = (data) => {
    console.log("handleOnChange_from", data);
    setAmount();
    setfromSwapRef(data.value);
    setCurrencyLabel(data.label);
    setCurrencyImg(data.image.src);
    var findIndexing = fromTab.findIndex((x) => x.currid == data.value);
    console.log("findIndexing===", findIndexing);
    if (findIndexing != -1) {
      console.log("fromTab[findIndexing]", fromTab[findIndexing]);
      setappendFromData(fromTab[findIndexing]);
      setFromcurrencyImage(fromTab[findIndexing].image);
      setfromCurrency(fromTab[findIndexing].currencySymbol);
      swapPrice();
    }
  };

  const handleOnChange_to = (data) => {
    setAmount();
    console.log("handleOnChange_to", data);
    settoSwapRef(data.value);
    setToCurrencyLabel(data.label);
    setToCurrencyImg(data.image.src);
    console.log(data.image, "=-=-=-=-");
    var findIndexingTo = toTab.findIndex((x) => x.currid == data.value);
    console.log("findIndexingTo===", findIndexingTo);
    if (findIndexingTo != -1) {
      settoCurrency(fromTab[findIndexingTo].currencySymbol);
      setappendFToData(fromTab[findIndexingTo]);
      setTocurrencyImage(fromTab[findIndexingTo].image);
      swapPrice();
    }
  };

  const getKYCstatus = async () => {
    var data = {
      apiUrl: apiService.getKYCStatus,
    };
    setSiteLoader(true);
    var getKYC = await getMethod(data);
    console.log(getKYC, "getkyc");

    if (getKYC.status == true) {
      setSiteLoader(false);
      console.log(getKYC.Message.kycstatus, "getkyc");
      setkycStatus(getKYC.Message.kycstatus);
    } else {
      setkycStatus(0);
    }
  };

  const getSiteSettingstatus = async () => {
    var data = {
      apiUrl: apiService.getSiteDatas,
    };
    var response = await getMethod(data);
    console.log("getkyc1===", response);

    if (response.status) {
      //console.log(response.data.kycStatus, "==kyc======");
      // setsitekycStatus(response.data.kycStatus);
    } else {
      // setkycStatus(0);
    }
  };

  const historyNav = () => {
    navigate("/History");
  };

  return (
    <div className="header ">
      <Header />
      <div>
        <div className="body_hXuuO height_100">
          <div className="lrtcss-wxjc90 e161ieae2">
            <div className="lrtcss-1p2kb7b e161ieae0">
              {siteLoader == true ? (
                <i class="fa-solid fa-spinner fa-spin"></i>
              ) : (
                <>
                  <section className="convert_section">
                    <div className="convert-bg">
                      <img
                        src={require("../Image/Swap/convert-bg.png")}
                        className="convert-image"
                      />
                    </div>

                    {kycStatus == 1 ? (
                      <section className="convert-form">
                        <div className="container">
                          <div className="row justify-content-center  ">
                            <div className="col-lg-6 col-md-9 swap-convert">
                              <div className="convert-content">
                                <div className="contvert-text">Convert</div>
                                <div
                                  className="history-icon"
                                  onClick={historyNav}
                                >
                                  <i class="ri-file-history-line"></i>
                                  <p>History</p>
                                </div>
                              </div>

                              <div className="pay_balance mt-4">
                                <div className="pay">Pay</div>
                                <div className="available-balance">
                                  Available Balance : {" "}
                                  {appendFromData == ""
                                    ? "0.0000"
                                    : Number(
                                      appendFromData.currencyBalance
                                    ).toFixed(8)}{" "}
                                </div>
                              </div>

                              <div className="pay-currency mt-2">
                                <div className="input">
                                  <input
                                    type="number"
                                    placeholder="Amount"
                                    min="0"
                                    value={fromAmount}
                                    onChange={(e) =>
                                      setAmount(e.target.value, "fromAmount")
                                    }
                                  />
                                </div>
                                <div className="max-dropdown">
                                  <Button
                                    className="priomary"
                                    data-toggle="modal"
                                    data-target="#myModal"
                                  >
                                    {fromSwapRef.current == "" ? (
                                      <>Select Coin</>
                                    ) : (
                                      <>
                                        <img
                                          src={currencyImg}
                                          style={{
                                            height: "25px",
                                            width: "25px",
                                            marginRight: "10px",
                                          }}
                                        />
                                        {currencyLabel}
                                      </>
                                    )}
                                  </Button>
                                  <div>
                                    {/* <Dropdown
                                  inline
                                  options={friendOptions}
                                  defaultValue={friendOptions[0].value}
                                /> */}
                                  </div>
                                </div>
                              </div>

                              <div className="swap-icon">
                                <i class="ri-swap-line"></i>
                              </div>
                              <div className="pay_balance mt-0">
                                <div className="pay">Get</div>
                              </div>

                              <div className="pay-currency mt-2">
                                <div className="input">
                                  <input
                                    type="number"
                                    placeholder="Amount"
                                    value={toAmount}
                                    onChange={(e) =>
                                      setAmount(e.target.value, "toAmount")
                                    }
                                  />
                                </div>
                                <div className="max-dropdown">
                                  <Button
                                    className="priomary"
                                    data-toggle="modal"
                                    data-target="#myModalsecond"
                                  >
                                    {toSwapRef.current == "" ? (
                                      <>Select Coin</>
                                    ) : (
                                      <>
                                        <img
                                          src={toCurrencyImg}
                                          style={{
                                            height: "25px",
                                            width: "25px",
                                            marginRight: "10px",
                                          }}
                                        />
                                        {toCurrencyLabel}
                                      </>
                                    )}
                                  </Button>

                                  <div>
                                    {/* <Dropdown
                                  inline
                                  options={friendOptions}
                                  defaultValue={friendOptions[0].value}
                                /> */}
                                  </div>
                                </div>
                              </div>

                              <div className="price-icon mt-2">
                                <div className="price-text">
                                  {" "}
                                  <i class="ri-information-fill"></i>
                                  <span>Price:</span>
                                </div>
                                <div className="usdt-btc">
                                  <p>
                                    {fromref.current != "" &&
                                      toref.current != "" ? (
                                      <span>
                                        {" "}
                                        1 {fromref.current} ={" "}
                                        {Number(priceRef.current).toFixed(8) ==
                                          0 ? (
                                          <span>0</span>
                                        ) : (
                                          Number(priceRef.current).toFixed(8)
                                        )}{" "}
                                        {toref.current}{" "}
                                      </span>
                                    ) : (
                                      <span>0.0000</span>
                                    )}
                                  </p>
                                </div>
                              </div>

                              <div className="price-icon mt-2">
                                <div className="price-text">
                                  {" "}
                                  <i class="ri-information-fill"></i>
                                  <span> Swap fee:</span>
                                </div>
                                <div className="usdt-btc">
                                  <p>
                                    {appendFromData == ""
                                      ? "0.0%"
                                      : appendFromData.swapFee}
                                  </p>
                                </div>
                              </div>

                              <div className="price-icon mt-2">
                                <div className="price-text">
                                  {" "}
                                  <i class="ri-information-fill"></i>
                                  <span> Total Amount :</span>
                                </div>
                                <div className="usdt-btc">
                                  <p>
                                    {" "}
                                    {totatlAmount == 0 ? (
                                      <span>0.0000</span>
                                    ) : (
                                      Number(totatlAmount).toFixed(4)
                                    )}{" "}
                                  </p>
                                </div>
                              </div>

                              {ButtonLoader == false ? (
                                <div className="confirm-btn">
                                  <button onClick={swapAmount}>
                                    {" "}
                                    Confirm{" "}
                                  </button>
                                </div>
                              ) : (
                                <div className="confirm-btn without_data mt-5">
                                  <button>Loading ..</button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </section>
                    ) : (
                      <div className="warning_content">
                        <h2>
                          Please complete the KYC verification process to gain
                          access to the page.
                        </h2>
                        <Link to="/Identity">Verify KYC Now</Link>
                      </div>
                    )}

                    {/* <section className="trading_accordion_section mb-5">
                      <div className="container">
                        <div className="trading_accordion">
                          <div className="faq-content">
                            <div className="faq-title">FAQ</div>
                           
                          </div>
                          <div class="container">
                            <div id="accordion" class="accordion">
                              <div class="card swap mb-0">
                                <div
                                  class="card-header collapsed p2p chat swap"
                                  data-toggle="collapse"
                                  href="#collapseOne"
                                >
                                  <a class="card-title p2p swap">
                                    1. Will I be charged a trading fee?
                                  </a>
                                </div>
                                <div
                                  id="collapseOne"
                                  class="card-body collapse swap"
                                  data-parent="#accordion"
                                >
                                  <p>
                                    Sellcrypt charges 0 fees for Convert. Due to
                                    market volatility, the conversion spread
                                    contained in the conversion price allows
                                    Sellcrypt to lock in your price for a short
                                    time. Therefore, the amount of crypto shown
                                    for the conversion will be the least amount
                                    you will receive once the conversion is
                                    completed.
                                  </p>
                                </div>
                                <div
                                  class="card-header collapsed p2p chat swap"
                                  data-toggle="collapse"
                                  data-parent="#accordion"
                                  href="#collapseTwo"
                                >
                                  <a class="card-title p2p swap">
                                    2. What advantages does Convert have
                                    compared to spot trading?
                                  </a>
                                </div>
                                <div
                                  id="collapseTwo"
                                  class="card-body collapse swap"
                                  data-parent="#accordion"
                                >
                                  <p>
                                    There is no need to go through tedious
                                    operations on the spot market or to
                                    understand the complete fee structure. With
                                    Convert, crypto conversions can be executed
                                    with the click of a button. There are no
                                    hidden fees. What you see is what you get.
                                  </p>
                                </div>
                                <div
                                  class="card-header collapsed p2p chat swap"
                                  data-toggle="collapse"
                                  data-parent="#accordion"
                                  href="#collapseThree"
                                >
                                  <a class="card-title p2p swap">
                                    3. Are there any restrictions to the use of
                                    Convert?
                                  </a>
                                </div>
                                <div
                                  id="collapseThree"
                                  class="collapse"
                                  data-parent="#accordion"
                                >
                                  <div class="card-body swap">
                                    Fewer coin types are supported for
                                    conversions compared to spot trading. The
                                    number of supported coin types will increase
                                    with time. Transactions have max and min
                                    amount limitations, with the specific
                                    amounts shown in the amount entry field.
                                  </div>
                                </div>
                                <div
                                  class="card-header collapsed p2p chat swap"
                                  data-toggle="collapse"
                                  data-parent="#accordion"
                                  href="#collapseFour"
                                >
                                  <a class="card-title p2p swap">
                                    4. How are transactions settled?
                                  </a>
                                </div>
                                <div
                                  id="collapseFour"
                                  class="collapse"
                                  data-parent="#accordion"
                                >
                                  <div class="card-body swap">
                                    You have the option of either using just
                                    your Funding Account, using just your
                                    Trading Account, or using both your Funding
                                    and Trading Accounts for the conversion.
                                    Once the conversion is completed, the
                                    settled crypto will be credited to your
                                    Trading Account.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section> */}
                  </section>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />

      <div class="modal fade " id="myModal" role="dialog">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content select_coin">
            <div class="modal-header">
              <h4 class="modal-title">Coin</h4>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div class="modal-body">
              {/* <div className="selection_dropdown">
                <div className="slt_dropdown">
                  <i class="ri-search-line"></i>
                  <span className="spn_tether">Search</span>
                </div>
              </div> */}

              <section className="top_coin_section" data-dismiss="modal">
                <h3>Top</h3>
                <p>
                  {fromCurrencyRef.current && fromCurrencyRef.current.length > 0
                    ? fromCurrencyRef.current.slice(0, 5).map((item, i) => {
                      return (
                        <span
                          className="top_coin_item"
                          onClick={() => handleOnChange_from(item)}
                        >
                          {" "}
                          {item.label}{" "}
                        </span>
                      );
                    })
                    : ""}
                </p>
              </section>

              <section className="cryptocoin_items" data-dismiss="modal">
                {fromCurrencyRef.current && fromCurrencyRef.current.length > 0
                  ? fromCurrencyRef.current.map((item, i) => {
                    return (
                      <div
                        className="cryptocoin_list"
                        onClick={() => handleOnChange_from(item)}
                      >
                        <div className="cryptocoin_logo">
                          <img src={item.image.src} />
                        </div>
                        <div className="list_content">
                          <h3>{item.label}</h3>
                          <span> {item.currencyName} </span>
                        </div>
                      </div>
                    );
                  })
                  : ""}
              </section>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade " id="myModalsecond" role="dialog">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content select_coin">
            <div class="modal-header">
              <h4 class="modal-title">Coin</h4>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div class="modal-body">
              {/* <div className="selection_dropdown">
                <div className="slt_dropdown">
                  <i class="ri-search-line"></i>
                  <span className="spn_tether">Search</span>
                </div>
              </div> */}

              <section className="top_coin_section" data-dismiss="modal">
                <h3>Top</h3>
                <p>
                  {toCurrencyRef.current && toCurrencyRef.current.length > 0
                    ? toCurrencyRef.current.slice(0, 5).map((item, i) => {
                      return (
                        <span
                          className="top_coin_item"
                          onClick={() => handleOnChange_to(item)}
                        >
                          {" "}
                          {item.label}{" "}
                        </span>
                      );
                    })
                    : ""}
                </p>
              </section>

              <section className="cryptocoin_items" data-dismiss="modal">
                {toCurrencyRef.current && toCurrencyRef.current.length > 0
                  ? toCurrencyRef.current.map((item, i) => {
                    return (
                      <div
                        className="cryptocoin_list"
                        onClick={() => handleOnChange_to(item)}
                      >
                        <div className="cryptocoin_logo">
                          <img src={item.image.src} />
                        </div>
                        <div className="list_content">
                          <h3>{item.label}</h3>
                          <span> {item.currencyName} </span>
                        </div>
                      </div>
                    );
                  })
                  : ""}
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
