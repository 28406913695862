import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Button } from "@mui/material";
import OTPInput, { ResendOTP } from "otp-input-react";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "./Innerpages/Sidebar";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { getMethod } from "../core/service/common.api";
import { removeAuthorization } from "../core/service/axios";
import { removeAuthToken } from "../core/lib/localStorage";
import { PhoneInput } from "react-contact-number-input";

function Home() {
  const navigate = useNavigate();
  const [loaderButton, setloaderButton] = useState(false);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [tfaDetails, setTfaDetails] = useState({});
  const [tfaCode, setTfaCode] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [addDetails, setaddDetails] = useState({});
  const [siteLoader, setSiteLoader] = useState(false);

  const initialFormValue = {
    oldpassword: "",
    password: "",
    confirmPassword: "",
  };
  const initailValue = {
    email: "",
  };

  const [formValue, setFormValue] = useState(initialFormValue);
  const [mailValue, setmailValue] = useState(initailValue);

  const { email } = mailValue;
  const { oldpassword, password, confirmPassword } = formValue;
  const [emailValidate, setemailValidate] = useState(false);
  const [passwordValidate, setpasswordValidate, passwordValidateref] =
    useState(false);
  const [
    confirmPasswordValidate,
    setconfirmPasswordValidate,
    confirmPasswordValidateref,
  ] = useState(false);
  const [oldpassvalidate, setoldpassvalidate, oldpassvalidateref] =
    useState(false);
  const [details, setDetails, setDetailsref] = useState("");
  const [passHide, setPasshide] = useState(false);
  const [inputType, setinputType] = useState("password");
  const [passHidconf, setPasshideconf] = useState(false);
  const [inputTypeconf, setinputTypeconf] = useState("password");
  const [passHidnew, setPasshidenew] = useState(false);
  const [inputTypenew, setinputTypenew] = useState("password");
  const [mobileNumber, setMobileNumber] = useState("");
  const [Email, setEmail] = useState("");
  const [validationnErr, setvalidationnErr] = useState("");
  const [mobileValidate, setmobileValidate, mobileValidateref] =
    useState(false);
  const [mobilevalue, setmobileValue, mobilevalueref] = useState("");

  useEffect(() => {
    fetchTfaData();
    postProfile();
    FindData();
  }, [0]);

  const validate = async (values) => {
    const errors = {};
    if (values.oldpassword == "") {
      errors.oldpassword = "Old Password is required";
      setoldpassvalidate(true);
    } else {
      setoldpassvalidate(false);
    }

    if (values.password == "") {
      setpasswordValidate(true);
      errors.password = "Password is required";
    } else if (values.password.length < 8 || values.password.length > 45) {
      setpasswordValidate(true);
      errors.password =
        "New Password is required and shouldnot below 8 above 45letters";
    } else if (!values.password.match(/[a-z]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least lower character";
    } else if (!values.password.match(/[A-Z]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least upper character";
    } else if (!values.password.match(/[0-9]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter alone digit character";
    } else if (values.password == values.oldpassword) {
      errors.password = "Old Password and New password should not be same";
      setpasswordValidate(true);
    } else {
      setpasswordValidate(false);
    }

    if (values.confirmPassword == "") {
      errors.confirmPassword = "Confirm password is a required";
      setconfirmPasswordValidate(true);
    } else if (values.confirmPassword != values.password) {
      setconfirmPasswordValidate(true);
      errors.confirmPassword = "Password and confirm password does not match";
    } else {
      setconfirmPasswordValidate(false);
    }
    setvalidationnErr(errors);
    return errors;
  };
  const [buttonStatus, setButtonstatus] = useState(false);
  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    validate(formData);
    if (
      confirmPasswordValidateref.current == false &&
      passwordValidateref.current == false &&
      oldpassvalidateref.current == false
    ) {
      setButtonstatus(true);
    } else {
      setButtonstatus(false);
    }
  };

  const formSubmit = async () => {
    validate(formValue);
    if (
      confirmPasswordValidateref.current == false &&
      passwordValidateref.current == false &&
      oldpassvalidateref.current == false
    ) {
      var obj = {
        oldPass: formValue.oldpassword,
        password: formValue.password,
        cpass: formValue.confirmPassword,
      };

      var data = {
        apiUrl: apiService.changePassword,
        payload: obj,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      localStorage.setItem("useremail", resp.email);
      setbuttonLoader(false);
      if (resp.status) {
        toast(resp.Message);
        await removeAuthorization();
        await removeAuthToken();
        localStorage.clear();
        navigate("/login");
        window.location.reload(true);
      } else {
        toast(resp.Message);
      }
    }
  };
  const handleSubmit = async () => {
    setValidationErrors({});
    if (!tfaCode || tfaCode === "") {
      toast("2FA code is required");
    } else {
      let tfaStatus = tfaDetails;
      var data = {
        apiUrl: apiService.changeTfaStatus,
        payload: {
          userToken: tfaCode,
          tfaStatus: tfaStatus,
        },
      };
      setloaderButton(true);
      var resp = await postMethod(data);
      if (resp.status) {
        setloaderButton(false);
        toast.success(resp.Message);
        setTfaCode("");
        fetchTfaData();
        postProfile();
        if (typeof resp?.errors !== "undefined") {
          const isErrorEmpty = Object.keys(resp?.errors).length === 0;
          if (!isErrorEmpty) {
            setValidationErrors(resp?.errors);
          }
        } else {
        }
      } else {
        setloaderButton(false);
        toast.error(resp.Message);
      }
    }
  };

  const fetchTfaData = async () => {
    try {
      var data = {
        apiUrl: apiService.getTfaDetials,
      };
      var resp = await getMethod(data);

      setTfaDetails(resp.data.tfastatus);
      setaddDetails(resp.data);
    } catch (error) {}
  };

  const passwordHide = (data) => {
    if (data == "hide") {
      setPasshide(true);
      setinputType("text");
    } else {
      setPasshide(false);
      setinputType("password");
    }
  };

  const passwordHideconf = (data) => {
    if (data == "hide") {
      setPasshideconf(true);
      setinputTypeconf("text");
    } else {
      setPasshideconf(false);
      setinputTypeconf("password");
    }
  };

  const passwordHidenewP = (data) => {
    if (data == "hide") {
      setPasshidenew(true);
      setinputTypenew("text");
    } else {
      setPasshidenew(false);
      setinputTypenew("password");
    }
  };

  const profileForm = {
    profileemail: "",
    username: "",
    profilemobileNumber: "",
  };

  const [profileformValue, setprofileFormValue] = useState(profileForm);
  const { profileemail, username, profilemobileNumber } = profileformValue;
console.log("profilemobileNumber",profilemobileNumber)
  const handleChange_profile = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...profileformValue, ...{ [name]: value } };
    setprofileFormValue(formData);
    setnumberErr("");
  };

  const postProfile = async () => {
    try {
      setSiteLoader(true);
      var data = {
        apiUrl: apiService.getUserDetails,
      };
      var resp = await getMethod(data);
      setSiteLoader(false);
      if (resp.status) {
        profileformValue.username = resp.data.username;
        profileformValue.profileemail = resp.data.email;
        profileformValue.profilemobileNumber = resp.data.mobileNumber;
        setDetails(resp.data.registertype);
        console.log("registertpe", resp.data.registertype);
        setMobileNumber(resp.data.username);
        setEmail(resp.data.email);

        localStorage.setItem("eligibleforEarn", resp.data.kycstatus);
        localStorage.setItem("tfa_status", resp.data.tfastatus);
      }
    } catch (error) {}
  };
  const mobileNumbersubmit = async () => {
    const errors = {};
    formValue["mobile"] =
      profileformValue.profilemobileNumber != ""
        ? profileformValue.profilemobileNumber
        : mobilevalueref.current;

    setvalidationnErr(errors);

    console.log(formValue.mobile, numberErr, "numberErr");

    if (formValue.mobile != "" && numberErr == "") {
      console.log(formValue.mobile, "formValue.mobile");
      var obj = {
        Mobile: formValue.mobile,
        Email: Email,
        username: profileformValue.username,
      };
      var data = {
        apiUrl: apiService.mobilenumberupdate,
        payload: obj,
      };
      var resp = await postMethod(data);
      toast(resp.Message);
      profileformValue.profilemobileNumber = resp.data.mobileNumber;
      returnmain();
    } else {
      console.log("hbbhbbbbb");
    }
  };
  
  const emailChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...mailValue, ...{ [name]: value } };
    setmailValue(formData);
  };
  const emailSubmit = async () => {
    const errors = {};
    if (mailValue.email == "") {
      errors.email = "Email is a required field";
      setemailValidate(true);
    }
    setvalidationnErr(errors);
    if (mailValue.email != "") {
      var obj = {
        Email: mailValue.email,
        Mobilenumber: mobileNumber,
      };
      var data = {
        apiUrl: apiService.emailupdate,
        payload: obj,
      };
      var resp = await postMethod(data);
      toast(resp.Message);
    }
  };

  const copy = async (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Secret Key Copied");
  };

  const [showmain, setshowmain] = useState(true);
  const [changePasswordmove, setchagepasswordmove] = useState(false);
  const [tfamove, settfamove] = useState(false);
  const [phonemove, setphonemove] = useState(false);
  const [antimove, setantimove] = useState(false);

  const movechange = async (e) => {
    setshowmain(false);
    settfamove(false);
    setphonemove(false);
    setantimove(false);
    setchagepasswordmove(true);
  };

  const movetfa = async (e) => {
    setshowmain(false);
    setphonemove(false);
    setantimove(false);
    setchagepasswordmove(false);
    settfamove(true);
  };

  const movephone = async (e) => {
    setshowmain(false);
    setphonemove(true);
    setantimove(false);
    setchagepasswordmove(false);
    settfamove(false);
  };
  const moveanti = async (e) => {
    setshowmain(false);
    setchagepasswordmove(false);
    settfamove(false);
    setphonemove(false);
    setantimove(true);
  };

  const returnmain = async (e) => {
    setshowmain(true);
    settfamove(false);
    setphonemove(false);
    setantimove(false);
    setchagepasswordmove(false);
  };

  const data = {
    APcode: "",
    changeAPcode: "",
    Status: "",
  };

  const [formData, setformData] = useState(data);
  const [otpPage, setotpPage] = useState(true);
  const [codePage, setcodePage] = useState(true);
  const [changeCode, setchangeCode] = useState(false);
  const [Anticode, setAnticode] = useState("");
  const [checked, setChecked] = useState(false);
  const [AntiPcode, setAntiPcode] = useState(false);
  const [changeAnticode, setchangeAnticode] = useState(false);
  const [APcodevalied, setAPcodevalied] = useState("");
  const [APcodes, setAPcodes] = useState("");
  const [OTP, setOTP] = useState("");
  const [mobileInput, setmobileInput] = useState("");
  const [numberErr, setnumberErr] = useState("");

  const getValue = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let values = { ...formData, ...{ [name]: value } };
    setformData(values);
  };
  const condition = () => {
    const Err = {};
    if (formData.APcode == "") {
      setAntiPcode(true);
      Err.APcode = "Anti phishing code is required";
    } else if (formData.APcode.length < 4) {
      Err.APcode = "Minimum 4 characters Only allowed";
      setAntiPcode(true);
    } else if (formData.APcode.length > 20) {
      Err.APcode = "Maximum 20 characters Only allowed";
      setAntiPcode(true);
    } else if (!formData.APcode.match(/[!@#$%^&*(),.?":{}|<>]/g)) {
      setAntiPcode(true);
      Err.APcode = "Please enter at least one special character";
    }

    if (formData.changeAPcode == "") {
      Err.changeAPcode = "Anti phishing code required";
      setchangeAnticode(true);
    } else if (formData.changeAPcode.length < 4) {
      Err.changeAPcode = "Minimum 4 characters Only allowed";
      setchangeAnticode(true);
    } else if (formData.changeAPcode.length > 20) {
      Err.changeAPcode = "Maximum 20 characters Only allowed";
      setchangeAnticode(true);
    }
    setAPcodevalied(Err);
    return Err;
  };

  const createCode = async () => {
    if (APcodes == "") {
      condition(formData);
      if (
        formData.APcode != "" &&
        formData.APcode.length > 4 &&
        formData.APcode.length < 21
      ) {
        formData["Status"] = checked;
        var data = {
          apiUrl: apiService.antiphishingcode,
          payload: formData,
        };
        var responce = await postMethod(data);
        if (responce.Status == true) {
          toast.success(responce.Message);
          setotpPage(false);
        }
      }
    } else {
      toast.error("Already code created");
    }
  };

  const verifyOTP = async () => {
    console.log(formData.APcode, "formData.APcode");
    console.log(formData.changeAPcode, "formData.changeAPcode");
    if (OTP != "") {
      var obj = {
        OTP: OTP,
        changeAPcode: formData.changeAPcode,
        APcode: formData.APcode,
      };
      var data = {
        apiUrl: apiService.verificationOtp,
        payload: obj,
      };
      var responce = await postMethod(data);
      if (responce.Status == true) {
        toast.success(responce.Message);
        window.location.reload();
        setchangeCode(true);
        FindData();
      }
    } else {
      toast.error("Enter OTP");
    }
  };

  const changeAntiCode = async () => {
    condition(formData);
    if (
      formData.changeAPcode != "" &&
      formData.changeAPcode.length > 4 &&
      formData.changeAPcode.length < 21
    ) {
      var data = {
        apiUrl: apiService.changeAntiPhising,
        payload: formData,
      };
      var responce = await postMethod(data);
      if (responce.Status == true) {
        toast.success(responce.Message);
        setcodePage(false);
        console.log(formData.changeAPcode, "formData.changeAPcode");
      } else {
        toast.error(responce.Message);
        setcodePage(true);
      }
    }
  };

  const FindData = async () => {
    var data = {
      apiUrl: apiService.findDetails,
      payload: formData,
    };
    var responce = await postMethod(data);
    if (responce.data != null) {
      if (responce.data.APcode != "") {
        var str = responce.data.APcode;
        setAPcodes(str);
        var replaced = str.replace(/.(?=.{4,}$)/g, "*");
        setAnticode(replaced);
        setchangeCode(true);
      } else {
        setchangeCode(false);
      }
    }
  };
  const handleOnChange = (type) => {
    console.log(type, "=-=-=-=-=");
    var number = type.countryCode + " " + type.phoneNumber;
    setmobileValue(number);
    console.log(number, "=-=-=-=-=");

    setnumberErr(number==""||number==null||number==undefined?"Enter valied number":"");
  };

  return (
    <div className="header ">
      <Header />
      <div>
        <div className="body_hXuuO ">
          <div className="lrtcss-wxjc90 e161ieae2">
            <div className="lrtcss-q484j3 e161ieae1">
              <div className="KuxMenu-root lrtcss-yqpjhy">
                <Sidebar />
              </div>
            </div>
            <div className="lrtcss-1p2kb7b ma-000">
              {siteLoader == true ? (
                <i class="fa-solid fa-spinner fa-spin"></i>
              ) : (
                <>
                  {showmain == true ? (
                    <div className="step_1">
                      {profilemobileNumber == "" ||profilemobileNumber=="null null" ||profilemobileNumber==undefined||profilemobileNumber==undefined &&
                      localStorage.getItem("tfa_status") == 0 &&
                      changeCode == false ? (
                        <div class="lrtcss-1urys0g  e1epgi046">
                          <span className="text-white">Security Settings</span>
                          <div class="lrtcss-1wiku5 -red e1epgi045">
                            <svg
                              class="ICSecuritySetting_svg__icon"
                              viewBox="0 0 1024 1024"
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="currentColor"
                            >
                              <path d="M755.488 414.176a42.656 42.656 0 10-60.352-60.352L469.312 579.648 350.144 460.48a42.656 42.656 0 10-60.352 60.352l149.344 149.344a42.656 42.656 0 0060.352 0l256-256z"></path>
                              <path d="M571.968 58.336a213.216 213.216 0 00-119.584 0L208.192 129.6A170.688 170.688 0 0085.344 293.44v133.856A604.192 604.192 0 00441.6 978.272a172.001 172.001 0 00140.928 0 604.16 604.16 0 00356.16-550.88V293.408A170.624 170.624 0 00815.872 129.6L572 58.368zm-95.68 81.92a127.84 127.84 0 0171.744 0l243.872 71.232a85.344 85.344 0 0161.408 81.92v133.984a518.784 518.784 0 01-305.824 473.056 86.496 86.496 0 01-70.944 0 518.88 518.88 0 01-305.92-473.152V293.44c0-37.92 25.024-71.296 61.44-81.92l244.192-71.264z"></path>
                            </svg>
                            <span>Security: Low</span>
                          </div>
                        </div>
                      ) : profilemobileNumber == "" ||
                        localStorage.getItem("tfa_status") == 0 ||
                        changeCode == false ? (
                        <div class="lrtcss-1urys0g  e1epgi046">
                          <span>Security Settings</span>
                          <div class="lrtcss-1wiku5 e1epgi045">
                            <svg
                              class="ICSecuritySetting_svg__icon"
                              viewBox="0 0 1024 1024"
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="currentColor"
                            >
                              <path d="M755.488 414.176a42.656 42.656 0 10-60.352-60.352L469.312 579.648 350.144 460.48a42.656 42.656 0 10-60.352 60.352l149.344 149.344a42.656 42.656 0 0060.352 0l256-256z"></path>
                              <path d="M571.968 58.336a213.216 213.216 0 00-119.584 0L208.192 129.6A170.688 170.688 0 0085.344 293.44v133.856A604.192 604.192 0 00441.6 978.272a172.001 172.001 0 00140.928 0 604.16 604.16 0 00356.16-550.88V293.408A170.624 170.624 0 00815.872 129.6L572 58.368zm-95.68 81.92a127.84 127.84 0 0171.744 0l243.872 71.232a85.344 85.344 0 0161.408 81.92v133.984a518.784 518.784 0 01-305.824 473.056 86.496 86.496 0 01-70.944 0 518.88 518.88 0 01-305.92-473.152V293.44c0-37.92 25.024-71.296 61.44-81.92l244.192-71.264z"></path>
                            </svg>
                            <span>Security: Medium</span>
                          </div>
                        </div>
                      ) : (
                        <div class="lrtcss-1urys0g  e1epgi046">
                          <span className="text-white">Security Settings</span>
                          <div class="lrtcss-1wiku5 -green e1epgi045">
                            <svg
                              class="ICSecuritySetting_svg__icon"
                              viewBox="0 0 1024 1024"
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="currentColor"
                            >
                              <path d="M755.488 414.176a42.656 42.656 0 10-60.352-60.352L469.312 579.648 350.144 460.48a42.656 42.656 0 10-60.352 60.352l149.344 149.344a42.656 42.656 0 0060.352 0l256-256z"></path>
                              <path d="M571.968 58.336a213.216 213.216 0 00-119.584 0L208.192 129.6A170.688 170.688 0 0085.344 293.44v133.856A604.192 604.192 0 00441.6 978.272a172.001 172.001 0 00140.928 0 604.16 604.16 0 00356.16-550.88V293.408A170.624 170.624 0 00815.872 129.6L572 58.368zm-95.68 81.92a127.84 127.84 0 0171.744 0l243.872 71.232a85.344 85.344 0 0161.408 81.92v133.984a518.784 518.784 0 01-305.824 473.056 86.496 86.496 0 01-70.944 0 518.88 518.88 0 01-305.92-473.152V293.44c0-37.92 25.024-71.296 61.44-81.92l244.192-71.264z"></path>
                            </svg>
                            <span>Security: High</span>
                          </div>
                        </div>
                      )}

                      <div class="lrtcss-ac8m87 e8w7gdo16">
                        <div className="header_content w-100">
                          <h3>Dual-Factor Security Check</h3>
                          <p>Secure your account by activating both Email and Mobile Authentication. With these safeguards in place, you'll gain the ability to track every transaction in real time, ensuring enhanced protection for all your activities.</p>
                        </div>

                        <div className="row w-100">
                          <div className="col-lg-12">
                            <div className="lrtcss-14z62gk e8w7gdo11">
                              <div className="lrtcss-1nkcrl4 e8w7gdo10">
                                <div class="lrtcss-1945s3i e8w7gdo9 w-100">
                                  <div class="lrtcss-xjkkcr e8w7gdo8">
                                    <img
                                      alt=""
                                      src={require("../Image/Security _page/email_logo.png")}
                                    />
                                  </div>
                                  <div className="grow_1">
                                    <div class="lrtcss-1c963u1 e8w7gdo7">
                                      <span>Email</span>
                                    </div>
                                    <div class="lrtcss-6kgahy e8w7gdo5">
                                      <span>To safeguard your transactions and account, use your email.</span>
                                    </div>
                                  </div>
                                  <div class="lrtcss-mivjb5 e8w7gdo3">
                                    <div class="lrtcss-9h3gn9 -green e8w7gdo4 ">
                                      <svg
                                        class="ICSuccess-filled_svg__icon"
                                        viewBox="0 0 1024 1024"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                      >
                                        <path d="M180.128 180.128C265.024 95.232 382.4 42.656 512 42.656s246.976 52.576 331.872 137.472C928.768 265.024 981.344 382.4 981.344 512s-52.576 246.976-137.472 331.872C758.976 928.768 641.6 981.344 512 981.344s-246.976-52.576-331.872-137.472C95.232 758.976 42.656 641.6 42.656 512s52.576-246.976 137.472-331.872zm575.36 234.048a42.656 42.656 0 10-60.352-60.352L469.312 579.648l-97.824-97.824a42.656 42.656 0 10-60.352 60.352l128 128a42.656 42.656 0 0060.352 0l256-256z"></path>
                                      </svg>
                                      <span>Enabled</span>
                                    </div>
                                    <div class="lrtcss-1o5xb0b e8w7gdo2">
                                      <Button
                                        class="lrtcss-1d4kpr5"
                                        type="button"
                                        onClick={movephone}
                                      >
                                        View
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="lrtcss-14z62gk e8w7gdo11">
                              <div className="lrtcss-1nkcrl4 e8w7gdo10">
                                <div class="lrtcss-1945s3i e8w7gdo9 w-100">
                                  <div class="lrtcss-xjkkcr e8w7gdo8">
                                    <img
                                      alt=""
                                      src={require("../Image/Security _page/phone_logo.png")}
                                    />
                                  </div>
                                  <div className="grow_1">
                                    <div class="lrtcss-1c963u1 e8w7gdo7">
                                      <span>Phone</span>
                                    </div>
                                    <div class="lrtcss-6kgahy e8w7gdo5">
                                      <span>To safeguard your transactions and account, use your phone number.</span>
                                    </div>
                                  </div>
                                  <div class="lrtcss-mivjb5 e8w7gdo3">
                                    <>
                                      {profilemobileNumber == "" ? (
                                        <div class="lrtcss-9h3gn9 -red e8w7gdo4">
                                          <svg
                                            class="ICInfo-filled_svg__icon"
                                            viewBox="0 0 1024 1024"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                          >
                                            <path d="M512 42.656c259.2 0 469.344 210.112 469.344 469.344S771.2 981.344 512 981.344 42.656 771.2 42.656 512 252.768 42.656 512 42.656zm0 640a53.344 53.344 0 100 106.656 53.344 53.344 0 000-106.656zm0-469.312c-23.552 0-42.656 19.104-42.656 42.656v341.344a42.656 42.656 0 0085.312 0V256c0-23.552-19.104-42.656-42.656-42.656z"></path>
                                          </svg>
                                          <span>Not Enabled</span>
                                        </div>
                                      ) : (
                                        <div class="lrtcss-9h3gn9 -green e8w7gdo4">
                                          <svg
                                            class="ICSuccess-filled_svg__icon"
                                            viewBox="0 0 1024 1024"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                          >
                                            <path d="M180.128 180.128C265.024 95.232 382.4 42.656 512 42.656s246.976 52.576 331.872 137.472C928.768 265.024 981.344 382.4 981.344 512s-52.576 246.976-137.472 331.872C758.976 928.768 641.6 981.344 512 981.344s-246.976-52.576-331.872-137.472C95.232 758.976 42.656 641.6 42.656 512s52.576-246.976 137.472-331.872zm575.36 234.048a42.656 42.656 0 10-60.352-60.352L469.312 579.648l-97.824-97.824a42.656 42.656 0 10-60.352 60.352l128 128a42.656 42.656 0 0060.352 0l256-256z"></path>
                                          </svg>
                                          <span>
                                            {profilemobileNumber.replace(
                                              /.(?=.{4,}$)/g,
                                              "*"
                                            )}
                                          </span>
                                        </div>
                                      )}
                                    </>

                                    <div class="lrtcss-1o5xb0b e8w7gdo2">
                                      {profilemobileNumber == "" ? (
                                        <Button
                                          class="lrtcss-1d4kpr5"
                                          type="button"
                                          onClick={movephone}
                                        >
                                          Bind
                                        </Button>
                                      ) : (
                                        <Button
                                          class="lrtcss-1d4kpr5"
                                          type="button"
                                          onClick={movephone}
                                        >
                                          Change
                                        </Button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="lrtcss-14z62gk e8w7gdo11">
                              <div className="lrtcss-1nkcrl4 e8w7gdo10">
                                <div class="lrtcss-1945s3i e8w7gdo9 w-100">
                                  <div class="lrtcss-xjkkcr e8w7gdo8">
                                    <img
                                      alt=""
                                      src={require("../Image/Security _page/phone_logo.png")}
                                    />
                                  </div>
                                  <div className="grow_1">
                                    <div class="lrtcss-1c963u1 e8w7gdo7">
                                      <span>2FA</span>
                                    </div>
                                    <div class="lrtcss-6kgahy e8w7gdo5">
                                      <span>
                                      Use 2FA to protect your account and transactions.
                                      </span>
                                    </div>
                                  </div>
                                  <div class="lrtcss-mivjb5 e8w7gdo3">
                                    <>
                                      {localStorage.getItem("tfa_status") ==
                                      0 ? (
                                        <div class="lrtcss-9h3gn9 -red e8w7gdo4">
                                          <svg
                                            class="ICInfo-filled_svg__icon"
                                            viewBox="0 0 1024 1024"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                          >
                                            <path d="M512 42.656c259.2 0 469.344 210.112 469.344 469.344S771.2 981.344 512 981.344 42.656 771.2 42.656 512 252.768 42.656 512 42.656zm0 640a53.344 53.344 0 100 106.656 53.344 53.344 0 000-106.656zm0-469.312c-23.552 0-42.656 19.104-42.656 42.656v341.344a42.656 42.656 0 0085.312 0V256c0-23.552-19.104-42.656-42.656-42.656z"></path>
                                          </svg>
                                          <span>Not Enabled</span>
                                        </div>
                                      ) : (
                                        <div class="lrtcss-9h3gn9 -green e8w7gdo4">
                                          <svg
                                            class="ICSuccess-filled_svg__icon"
                                            viewBox="0 0 1024 1024"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                          >
                                            <path d="M180.128 180.128C265.024 95.232 382.4 42.656 512 42.656s246.976 52.576 331.872 137.472C928.768 265.024 981.344 382.4 981.344 512s-52.576 246.976-137.472 331.872C758.976 928.768 641.6 981.344 512 981.344s-246.976-52.576-331.872-137.472C95.232 758.976 42.656 641.6 42.656 512s52.576-246.976 137.472-331.872zm575.36 234.048a42.656 42.656 0 10-60.352-60.352L469.312 579.648l-97.824-97.824a42.656 42.656 0 10-60.352 60.352l128 128a42.656 42.656 0 0060.352 0l256-256z"></path>
                                          </svg>
                                          <span>Enable</span>
                                        </div>
                                      )}
                                    </>
                                    <div class="lrtcss-1o5xb0b e8w7gdo2">
                                      {localStorage.getItem("tfa_status") ==
                                      0 ? (
                                        <Button
                                          class="lrtcss-1d4kpr5"
                                          type="button"
                                          onClick={movetfa}
                                        >
                                          Bind
                                        </Button>
                                      ) : (
                                        <Button
                                          class="lrtcss-1d4kpr5"
                                          type="button"
                                          onClick={movetfa}
                                        >
                                          Change
                                        </Button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="lrtcss-ac8m87 e8w7gdo16">
                        <div className="header_content w-100">
                          <h3>Advanced Settings</h3>
                        </div>

                        <div className="row w-100">
                          <div className="col-lg-12">
                            <div className="lrtcss-14z62gk e8w7gdo11">
                              <div className="lrtcss-1nkcrl4 e8w7gdo10">
                                <div class="lrtcss-1945s3i e8w7gdo9 w-100">
                                  <div class="lrtcss-xjkkcr e8w7gdo8">
                                    <img
                                      alt=""
                                      src={require("../Image/Security _page/login_logo.png")}
                                    />
                                  </div>
                                  <div className="grow_1">
                                    <div class="lrtcss-1c963u1 e8w7gdo7">
                                      <span>Login Password</span>
                                    </div>
                                    <div class="lrtcss-6kgahy e8w7gdo5">
                                      <span>For account login.</span>
                                    </div>
                                  </div>
                                  <div class="lrtcss-mivjb5 e8w7gdo3">
                                    <div
                                      class="lrtcss-1o5xb0b e8w7gdo2"
                                      onClick={movechange}
                                    >
                                      <Button
                                        class="lrtcss-1d4kpr5 mr-4"
                                        type="button"
                                      >
                                        Change
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          
                          <div className="col-lg-12">
                            <div className="lrtcss-14z62gk e8w7gdo11">
                              <div className="lrtcss-1nkcrl4 e8w7gdo10">
                                <div class="lrtcss-1945s3i e8w7gdo9 w-100">
                                  <div class="lrtcss-xjkkcr e8w7gdo8">
                                    <img
                                      alt=""
                                      src={require("../Image/Security _page/email_logo2.png")}
                                    />
                                  </div>
                                  <div className="grow_1">
                                    <div class="lrtcss-1c963u1 e8w7gdo7">
                                      <span>Email Anti-Phishing Code</span>
                                    </div>
                                    <div class="lrtcss-6kgahy e8w7gdo5">
                                      <span>
                                        Displayed in emails from Sellcrypt to
                                        safeguard against phishing attempts.
                                      </span>
                                    </div>
                                  </div>
                                  <div class="lrtcss-mivjb5 e8w7gdo3">
                                    <div class="lrtcss-1o5xb0b e8w7gdo2">
                                      <Button
                                        class="lrtcss-1d4kpr5 mr-4"
                                        type="button"
                                        onClick={moveanti}
                                      >
                                        Setting
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : changePasswordmove == true ? (
                    <div className="step_2">
                      <div class="lrtcss-1urys0g e1epgi046">
                        <span
                          className="headeing_with_back"
                          onClick={returnmain}
                        >
                          <a className="backicon">
                            <i class="ri-arrow-left-s-line"></i>
                          </a>{" "}
                          Change Password
                        </span>
                      </div>
                      <div class="lrtcss-ac8m87 e8w7gdo16">
                        <div className="login_form">
                          <div className="mb-5">
                            <div className="row justify-content-center w-100">
                              <div className="col-lg-8">
                                <div className="card_login w-100 mb-5">
                                  <h3 className="mb-3">Change Password</h3>
                                  <div className="warning_alter">
                                    <p>
                                      1. To protect your account security, keep
                                      your login password safe and do not
                                      disclose it to others.
                                    </p>
                                    <p>
                                      2. Please do not use your previous
                                      passwords.
                                    </p>
                                  </div>
                                  <div>
                                    <label className="lable_text">
                                      Old Password
                                    </label>
                                    <div class="form_login_input position_eye">
                                      <input
                                        type={inputType}
                                        name="oldpassword"
                                        placeholder="Old Password"
                                        className="form-control"
                                        maxLength={40}
                                        value={oldpassword}
                                        onChange={handleChange}
                                      />
                                      <div className="eye_content">
                                        {passHide == false ? (
                                          <i
                                            className="fa fa-eye-slash icons"
                                            onClick={() => passwordHide("hide")}
                                          ></i>
                                        ) : (
                                          <i
                                            className="fa fa-eye icons"
                                            onClick={() => passwordHide("show")}
                                          ></i>
                                        )}
                                      </div>
                                      {oldpassvalidate == true ? (
                                        <small className="errorcss">
                                          {validationnErr.oldpassword}
                                        </small>
                                      ) : (
                                        ""
                                      )}
                                    </div>

                                    <label className="lable_text">
                                      New Password
                                    </label>
                                    <div class="form_login_input position_eye">
                                      <input
                                        type={inputTypenew}
                                        name="password"
                                        maxLength={40}
                                        placeholder="New Password"
                                        className="form-control"
                                        value={password}
                                        onChange={handleChange}
                                      />

                                      <div className="eye_content">
                                        {passHidnew == false ? (
                                          <i
                                            className="fa fa-eye-slash icons"
                                            onClick={() =>
                                              passwordHidenewP("hide")
                                            }
                                          ></i>
                                        ) : (
                                          <i
                                            className="fa fa-eye icons"
                                            onClick={() =>
                                              passwordHidenewP("show")
                                            }
                                          ></i>
                                        )}
                                      </div>
                                      {passwordValidate == true ? (
                                        <small className="errorcss">
                                          {" "}
                                          {validationnErr.password}{" "}
                                        </small>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <label className="lable_text">
                                      Confirm Password
                                    </label>
                                    <div class="form_login_input position_eye">
                                      <input
                                        type={inputTypeconf}
                                        name="confirmPassword"
                                        maxLength={40}
                                        placeholder="Confirm Password"
                                        className="form-control"
                                        value={confirmPassword}
                                        onChange={handleChange}
                                      />
                                      <div className="eye_content">
                                        {passHidconf == false ? (
                                          <i
                                            className="fa fa-eye-slash icons"
                                            onClick={() =>
                                              passwordHideconf("hide")
                                            }
                                          ></i>
                                        ) : (
                                          <i
                                            className="fa fa-eye icons"
                                            onClick={() =>
                                              passwordHideconf("show")
                                            }
                                          ></i>
                                        )}
                                      </div>
                                      {confirmPasswordValidate == true ? (
                                        <small className="errorcss">
                                          {" "}
                                          {validationnErr.confirmPassword}{" "}
                                        </small>
                                      ) : (
                                        ""
                                      )}
                                    </div>

                                    <div class="form_login_input">
                                      {buttonLoader == false ? (
                                        <Button
                                          className="mt-4"
                                          onClick={formSubmit}
                                        >
                                          Submit
                                        </Button>
                                      ) : (
                                        <Button className="mt-4">
                                          loading...
                                        </Button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : tfamove == true ? (
                    <>
                      <div className="step_2">
                        <div class="lrtcss-1urys0g e1epgi046">
                          <span className="headeing_with_back">
                            <a href="" className="backicon">
                              <i
                                class="ri-arrow-left-s-line"
                                onClick={returnmain}
                              ></i>
                            </a>{" "}
                            2FA Authentication
                          </span>
                          <div class="lrtcss-1wiku5 e1epgi045">
                            {tfaDetails === 0 ? (
                              <>
                                <svg
                                  class="ICSecuritySetting_svg__icon"
                                  viewBox="0 0 1024 1024"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="currentColor"
                                >
                                  <path d="M755.488 414.176a42.656 42.656 0 10-60.352-60.352L469.312 579.648 350.144 460.48a42.656 42.656 0 10-60.352 60.352l149.344 149.344a42.656 42.656 0 0060.352 0l256-256z"></path>
                                  <path d="M571.968 58.336a213.216 213.216 0 00-119.584 0L208.192 129.6A170.688 170.688 0 0085.344 293.44v133.856A604.192 604.192 0 00441.6 978.272a172.001 172.001 0 00140.928 0 604.16 604.16 0 00356.16-550.88V293.408A170.624 170.624 0 00815.872 129.6L572 58.368zm-95.68 81.92a127.84 127.84 0 0171.744 0l243.872 71.232a85.344 85.344 0 0161.408 81.92v133.984a518.784 518.784 0 01-305.824 473.056 86.496 86.496 0 01-70.944 0 518.88 518.88 0 01-305.92-473.152V293.44c0-37.92 25.024-71.296 61.44-81.92l244.192-71.264z"></path>
                                </svg>
                                <span>Disable</span>
                              </>
                            ) : (
                              <>
                                <svg
                                  class="ICSecuritySetting_svg__icon"
                                  viewBox="0 0 1024 1024"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="currentColor"
                                >
                                  <path d="M755.488 414.176a42.656 42.656 0 10-60.352-60.352L469.312 579.648 350.144 460.48a42.656 42.656 0 10-60.352 60.352l149.344 149.344a42.656 42.656 0 0060.352 0l256-256z"></path>
                                  <path d="M571.968 58.336a213.216 213.216 0 00-119.584 0L208.192 129.6A170.688 170.688 0 0085.344 293.44v133.856A604.192 604.192 0 00441.6 978.272a172.001 172.001 0 00140.928 0 604.16 604.16 0 00356.16-550.88V293.408A170.624 170.624 0 00815.872 129.6L572 58.368zm-95.68 81.92a127.84 127.84 0 0171.744 0l243.872 71.232a85.344 85.344 0 0161.408 81.92v133.984a518.784 518.784 0 01-305.824 473.056 86.496 86.496 0 01-70.944 0 518.88 518.88 0 01-305.92-473.152V293.44c0-37.92 25.024-71.296 61.44-81.92l244.192-71.264z"></path>
                                </svg>
                                <span>Enable</span>
                              </>
                            )}
                          </div>
                        </div>
                        <div class="lrtcss-ac8m87 e8w7gdo16">
                          <div className="login_form">
                            <div className="mb-5">
                              <div className="row justify-content-center w-100">
                                <div className="col-lg-8">
                                  <div className="card_login w-100 mb-5">
                                    <h3 className="mb-3">
                                      {tfaDetails === 0 ? (
                                        <h3>Enable 2FA </h3>
                                      ) : (
                                        <h3>Disable 2FA </h3>
                                      )}
                                    </h3>
                                    <div className="warning_alter">
                                      <p>
                                        Protect your transactions and account
                                        security, keep your login password safe
                                        and do not disclose it to others.
                                      </p>
                                    </div>
                                    <div>
                                      {tfaDetails === 0 ? (
                                        <>
                                          <label className="lable_text">
                                            Scan QR
                                          </label>
                                          <div class="form_login_input position_eye">
                                            {tfaDetails === 0 ? (
                                              <img
                                                src={addDetails.tfa_url}
                                                className="d-block mx-auto Qrdesign"
                                                width="25%"
                                              />
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}

                                      {tfaDetails === 0 ? (
                                        <>
                                          <label className="lable_text">
                                            Secret Key
                                          </label>
                                          <div class="form_login_input position_eye">
                                            <div className="selection_dropdown">
                                              <div className="slt_dropdown">
                                                <input
                                                  type="text"
                                                  placeholder="Address"
                                                  value={
                                                    addDetails.tfaenablekey ==
                                                    undefined
                                                      ? ""
                                                      : addDetails.tfaenablekey
                                                  }
                                                  readOnly
                                                  className="walletr_inpur"
                                                />
                                              </div>
                                              <div
                                                className="drp_down_logo text_purble"
                                                onClick={() =>
                                                  copy(addDetails.tfaenablekey)
                                                }
                                              >
                                                <i class="ri-clipboard-line"></i>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}

                                      <label className="lable_text">
                                        2FA Code
                                      </label>
                                      <div class="form_login_input position_eye">
                                        <input
                                          className="form-control"
                                          type="number"
                                          min="0"
                                          placeholder="Enter Your Code"
                                          name="tfaCode"
                                          value={tfaCode}
                                          onChange={(e) =>
                                            setTfaCode(e.target.value)
                                          }
                                        />
                                      </div>

                                      {tfaDetails === 0 ? (
                                        loaderButton == false ? (
                                          <div className="form_login_input">
                                            <Button
                                              className=""
                                              onClick={handleSubmit}
                                            >
                                              Enable
                                            </Button>
                                          </div>
                                        ) : (
                                          <div className="form_login_input">
                                            <Button>loading...</Button>
                                          </div>
                                        )
                                      ) : loaderButton == false ? (
                                        <div className="form_login_input">
                                          <Button onClick={handleSubmit}>
                                            Disable
                                          </Button>
                                        </div>
                                      ) : (
                                        <div className="form_login_input">
                                          <Button>loading...</Button>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : phonemove == true ? (
                    <div className="step_2">
                      <div class="lrtcss-1urys0g e1epgi046">
                        <span className="headeing_with_back">
                          <a href="" className="backicon">
                            <i
                              class="ri-arrow-left-s-line"
                              onClick={returnmain}
                            ></i>
                          </a>{" "}
                          Profile
                        </span>
                      </div>
                      <div class="lrtcss-ac8m87 e8w7gdo16">
                        <div className="w-100">
                          <div className="my-5">
                            <div className="row justify-content-center w-100">
                              <div className="col-lg-6">
                                <div className="card_login w-100 mb-5">
                                  <div>
                                    <label className="lable_text">
                                      User Name
                                    </label>
                                    <div class="form_login_input position_eye">
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={username}
                                        disabled
                                        name="username"
                                        onChange={handleChange_profile}
                                      />
                                    </div>

                                    <label className="lable_text">Email</label>
                                    <div class="form_login_input position_eye">
                                      <input
                                        type="text"
                                        disabled
                                        className="form-control"
                                        value={Email}
                                      />
                                    </div>
                                    <label className="lable_text">
                                      Phone Number
                                    </label>

                                    {profilemobileNumber == "" ? (
                                      <>
                                        <div class="form_login_input position_eye  w-100">
                                          <PhoneInput
                                            class="mobileInput  w-100"
                                            onChange={handleOnChange}
                                          />
                                        </div>

                                        {numberErr != "" ? (
                                          <small className="errorcss">
                                            {" "}
                                            {numberErr}{" "}
                                          </small>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    ) : (
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={profilemobileNumber}
                                        name="profilemobileNumber"
                                        onChange={handleChange_profile}
                                      />
                                    )}

                                    <div class="form_login_input">
                                      {buttonLoader == false ? (
                                        <Button
                                          className="mt-4"
                                          onClick={mobileNumbersubmit}
                                        >
                                          Submit
                                        </Button>
                                      ) : (
                                        <Button className="mt-4">
                                          loading...
                                        </Button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : antimove == true ? (
                    <div className="step_2">
                      <div class="lrtcss-1urys0g e1epgi046">
                        <span
                          className="headeing_with_back"
                          onClick={returnmain}
                        >
                          <a className="backicon">
                            <i class="ri-arrow-left-s-line"></i>
                          </a>{" "}
                          Email Anti Phishing Authentication
                        </span>
                      </div>

                      <div class="lrtcss-ac8m87 e8w7gdo16">
                        <div>
                          <div className="my-5">
                            <div className="row justify-content-center w-100">
                              <div className="col-lg-10">
                                {changeCode == false ? (
                                  <div className="card_login w-100 mb-5">
                                    <div className="errorcss">
                                      <p className="text-center w-100 mb-4">
                                        {" "}
                                        <i class="fa-solid fa-circle-info"></i>{" "}
                                        do not disclose your password or
                                        verification codes to anyone, include
                                        our support
                                      </p>
                                    </div>

                                    <h3 className="mb-3">
                                      What is Anti-Phishing Code?
                                    </h3>
                                    <div className="warning_alter">
                                      <p>
                                        An Anti-Phishing code is a code that
                                        helps to prevent Phishing Attempts form
                                        fake Sellcrypt website or mail addresses{" "}
                                      </p>
                                    </div>

                                    <h3 className="mb-3">How does it Work?</h3>
                                    <div className="warning_alter">
                                      <p>
                                        once you've send your unique
                                        Anti-Phishing code, it will be included
                                        in all offical Sellcrypt email
                                      </p>
                                    </div>

                                    <div className="form_login_input mt-3">
                                      <Button
                                        className="priomary"
                                        data-toggle="modal"
                                        data-target="#myModal"
                                      >
                                        Create Anti-Phishing Code
                                      </Button>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="card_login w-100 mb-5">
                                    <div className="errorcss"></div>

                                    <h3 className="mb-3">
                                      Change Anti-Phishing Code
                                    </h3>
                                    <div className="warning_alter">
                                      <p>
                                        do not disclose your password or
                                        verification codes to anyone, include
                                        our support
                                      </p>
                                    </div>

                                    <section className="select_network_section">
                                      <div className="select_content mb-4 ">
                                        <div className="select_text">
                                          Old Anti-Phishing Code
                                        </div>
                                        <div className="selection_dropdown">
                                          <div className="slt_dropdown">
                                            <input
                                              type="text"
                                              value={Anticode}
                                              className="form-control"
                                              disabled
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </section>

                                    <div className="form_login_input mt-3">
                                      <Button
                                        className="priomary"
                                        data-toggle="modal"
                                        data-target="#changemyModal"
                                      >
                                        Change Anti-Phishing Code
                                      </Button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <div class="modal fade " id="myModal" role="dialog">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content select_coin">
            <div class="modal-header">
              <h4 class="modal-title ml-0">Create Anti-Phishing Code</h4>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div class="modal-body pt-0">
              {otpPage == true ? (
                <section className="select_network_section">
                  <div className="select_content mb-4 ">
                    <div className="select_text"> Anti-Phishing Code</div>
                    <div className="selection_dropdown">
                      <div className="slt_dropdown">
                        <input
                          type="text"
                          name="APcode"
                          maxLength={40}
                          placeholder="Enter Code"
                          onChange={getValue}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    {AntiPcode == true ? (
                      <p className="errorcss"> {APcodevalied.APcode} </p>
                    ) : (
                      ""
                    )}
                  </div>
                </section>
              ) : (
                <section className="select_network_section">
                  <div className="select_content mb-4 ">
                    <div className="select_text"> Anti-Phishing Code</div>
                    <div className="OTP_inpu">
                      <OTPInput
                        value={OTP}
                        onChange={setOTP}
                        autoFocus
                        OTPLength={4}
                        otpType="number"
                        disabled={false}
                        secure
                      />
                    </div>
                  </div>
                </section>
              )}
            </div>
            <div class="modal-footer">
              {otpPage == true ? (
                <Button onClick={createCode}>Create</Button>
              ) : (
                <Button onClick={verifyOTP} data-dismiss="modal">
                  Verify OTP
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade " id="changemyModal" role="dialog">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content select_coin">
            <div class="modal-header">
              <h4 class="modal-title ml-0">Change Anti-Phishing Code</h4>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div class="modal-body pt-0">
              {codePage == true ? (
                <section className="select_network_section">
                  <div className="select_content mb-4 ">
                    <div className="select_text">Anti-Phishing Code</div>
                    <div className="selection_dropdown">
                      <div className="slt_dropdown">
                        <input
                          type="text"
                          name="changeAPcode"
                          maxLength={40}
                          placeholder="Enter Code"
                          onChange={getValue}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    {changeAnticode == true ? (
                      <p className="errorcss"> {APcodevalied.changeAPcode} </p>
                    ) : (
                      ""
                    )}
                  </div>
                </section>
              ) : (
                <section className="select_network_section">
                  <div className="select_content mb-4 ">
                    <div className="select_text"> Email verification Code</div>
                    <div className="">
                      <div className="OTP_inpu">
                        <OTPInput
                          value={OTP}
                          onChange={setOTP}
                          autoFocus
                          OTPLength={4}
                          otpType="number"
                          disabled={false}
                          secure
                        />
                      </div>
                    </div>
                  </div>
                </section>
              )}
            </div>
            <div class="modal-footer">
              {codePage == true ? (
                <Button onClick={changeAntiCode}>Create</Button>
              ) : (
                <Button onClick={verifyOTP} data-dismiss="modal">
                  Verify OTP
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
