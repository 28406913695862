import React, { useEffect } from "react";
import Header from "./Header";
import { Button, Container } from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import useState from "react-usestateref";
import { getAuthToken } from "../core/lib/localStorage";

import { Link, useNavigate } from "react-router-dom";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import Pagination from "./pagination";
import DropdownMenu from "./DropdownMenu";
import countrylist from "./country.json";
import $ from "jquery";
import useStateRef from "react-usestateref";

function Home() {
  const inputType = "password";
  const options = ["one", "two", "three"];
  const countryOptions = countrylist.data.map((country) => ({
    key: country.name,
    text: country.name,
    value: country.name,
  }));

  const preferPayment = [
    { value: "All payments", text: "All payments" },
    {
      value: "Bank Transfer",
      text: "Bank Transfer",
    },
    { value: "UPI ID", text: "UPI ID" },
    { value: "Paytm", text: "Paytm" },
  ];

  const [getP2POrders, setgetAllp2pOrders, getP2POrdersref] = useState([]);
  const [buyP2POrders, setbuyP2POrders, buyP2POrdersref] = useState([]);
  const [sellP2POrders, setsellP2POrders, sellP2POrdersref] = useState([]);
  const [sendDatas, setSendDatas, sendDatasref] = useState("");
  const [show, setShow, showref] = useState(false);
  const [allCurrency, setallCurrency, allCurrencyref] = useState([]);
  const [allCurrencyFiat, setallCurrencyFiat, allCurrencyFiatref] = useState(
    []
  );
  const [activetab, setActive, activetabref] = useState("All");
  const [activetype, setActivetype, activetyperef] = useState("BTC");
  const [fiatCurrency, setfiatCurrency, fiatCurrencyref] = useState("USD");
  const [profileDatas, setprofileData, profileDatasref] = useState("");
  const [loginTrue, setloginTrue, loginTrueref] = useState(false);

  const [p2pbalance, setp2pbalance, p2pbalanceref] = useState("");
  const [p2pData, setp2pData, p2pDataref] = useState("");

  const [currentp2pBuy, setcurrentp2pBuy, currentp2pBuyref] = useState([]);
  const [currentp2pSell, setcurrentp2pSell, currentp2pSellref] = useState([]);

  const [notification, Setnotification, notificationref] = useState([]);
  const [notifyCurrentPage, setnotifyCurrentPage, notifyCurrentPageref] =
    useState();
  const [notifytotalpage, Setnotifytotalpage, notifytotalpageref] = useState(0);

  const [p2pOrders, setp2pOrders, p2pOrdersref] = useState([]);
  const [p2pcurrentpage, setp2pcurrentpage, p2pcurrentpageref] = useState(1);
  const [p2ptotalpage, setp2pTotalpages, p2ptotalpageref] = useState(0);

  const [p2pHistory, setp2pHistory, p2pHistoryref] = useState([]);
  const [historycurrentpage, sethistorycurrentpage, historycurrentpageref] =
    useState(1);
  const [historytotalpage, sethistoryTotalpages, historytotalpageref] =
    useState(0);
  const [authtoken, setauthtoken] = useState(false);
  const [p2pLoader, setp2pLoader, p2pLoaderref] = useState(false);
  let navigate = useNavigate();
  useEffect(() => {
    getAllp2pOrders();
    getAllcurrency();
    let user_token = getAuthToken();
    console.log("user_token===", typeof user_token);
    console.log("user_token.length===", user_token.length);
    if (user_token != "" && user_token != undefined && user_token != null) {
      setauthtoken(user_token);
      setloginTrue(true);
      getProfile();
      notify();
      getp2pOrders();
      getp2pHistory();
      // let socket_token = localStorage.getItem("socket_token");
      // let socketsplit = socket_token.split("_");
      // socket.connect();

      // socket.off("socketResponse");
      // socket.on("socketResponse", function (res) {
      //   if (res.Reason == "ordercancel") {
      //     getAllp2pOrders();
      //   }
      // });

      if (
        countryName != "" &&
        countryName != null &&
        countryName != undefined &&
        amountValue != "" &&
        amountValue != null &&
        amountValue != undefined &&
        paymentmethod != "" &&
        paymentmethod != null &&
        paymentmethod != undefined
      ) {
        var data = [];
        for (var i = 0; i < getP2POrdersref.current.length; i++) {
          if (
            getP2POrders[i].location == countryName &&
            getP2POrders[i].price == amountValue &&
            getP2POrders[i].paymentMethod == paymentmethod
          ) {
            var obj = {
              firstCurrency: getP2POrders[i].firstCurrency,
              fromLimit: getP2POrders[i].fromLimit,
              location: getP2POrders[i].location,
              orderId: getP2POrders[i].orderId,
              orderType: getP2POrders[i].orderType,
              orders_count: getP2POrders[i].orders_count,
              paymentMethod: getP2POrders[i].paymentMethod,
              price: getP2POrders[i].price,
              processAmount: getP2POrders[i].processAmount,
              profile_image: getP2POrders[i].profile_image,
              rating: getP2POrders[i].rating,
              secondCurrnecy: getP2POrders[i].secondCurrnecy,
              toLimit: getP2POrders[i].toLimit,
              totalAmount: getP2POrders[i].totalAmount,
              user_id: getP2POrders[i].user_id,
              username: getP2POrders[i].username,
            };
            data.push(obj);
          }
        }
        console.log(data, "data");
        if (data == "") {
          setbuyP2POrders([]);
          setsellP2POrders([]);
        } else {
          setgetAllp2pOrders(data);
          var buy = data.filter((data) => data.orderType == "sell");
          buy.sort(function (a, b) {
            return a.price - b.price;
          });
          setbuyP2POrders(buy);

          var sell = data.filter((data) => data.orderType == "buy");
          sell.sort(function (a, b) {
            return b.price - a.price;
          });
          setsellP2POrders(sell);
        }
      }
      setauthtoken(true);
    } else {
      setauthtoken(false);
      setloginTrue(false);
    }
  }, []);

  const getProfile = async () => {
    try {
      var data = {
        apiUrl: apiService.getUserDetails,
      };
      var resp = await getMethod(data);
      if (resp.status) {
        console.log("resp.status", resp.data);
        setprofileData(resp.data);
      }
    } catch (error) {}
  };

  const getAllp2pOrders = async () => {
    try {
      setp2pLoader(true);
      var onj = {
        currency:
          fiatCurrencyref.current && fiatCurrencyref.current != "all"
            ? fiatCurrencyref.current
            : "",
      };
      var data = {
        apiUrl: apiService.p2pGetOrder,
        payload: onj,
      };
      var resp = await postMethod(data);
      console.log(resp.Message, "-=-=-resp=-=-");
      if (resp.status == true) {
        setp2pLoader(false);
        var data = resp.Message;
        console.log(resp.Message,"p2p data");
        setgetAllp2pOrders(resp.Message);
        var buy = data.filter((data) => data.orderType == "sell");
        buy.sort(function (a, b) {
          return a.price - b.price;
        });
        setbuyP2POrders(buy);

        var sell = data.filter((data) => data.orderType == "buy");
        sell.sort(function (a, b) {
          return b.price - a.price;
        });
        setsellP2POrders(sell);

        var buy_active = data.filter(
          (data) =>
            data.orderType == "sell" &&
            data.firstCurrency == activetabref.current
        );
        setcurrentp2pBuy(buy_active);
        var sell_active = data.filter(
          (data) =>
            data.orderType == "buy" &&
            "sell" + data.firstCurrency == activetabref.current
        );
        setcurrentp2pSell(sell_active);

      }
    } catch (error) {}
  };

  const getAllcurrency = async () => {
    var data = {
      apiUrl: apiService.getP2Pcurrency,
    };
    var resp = await getMethod(data);
    if (resp) {
      var currArrayCrypto = [];
      var currArrayFiat = [{ value: "all", label: "Select Currency" }];
      var data = resp.data;
      var obj1 = {
        id: "All",
        currencySymbol: "All",
      };
      currArrayCrypto.push(obj1);
      for (var i = 0; i < data.length; i++) {
        if (data[i].coinType == "1") {
          var obj = {
            id: data[i]._id,
            currencySymbol: data[i].currencySymbol,
          };
          currArrayCrypto.push(obj);
        }
        if (data[i].coinType == "2") {
          var obj = {
            value: data[i]._id,
            label: data[i].currencySymbol,
          };
          currArrayFiat.push(obj);
        }
        console.log("currArrayCrypto====", currArrayCrypto);
        console.log("currArrayFiat=====", currArrayFiat);
      }

      setallCurrency(currArrayCrypto);
      setallCurrencyFiat(currArrayFiat);
    }
  };

  const defaulatCurrFiat = allCurrencyFiatref.current[0];

  const onSelect = async (option) => {
    console.log(option, "-=-onSelecttop");

    if (option.label == "Select Currency") {
      console.log("call currency all");
      setfiatCurrency(option.value);
      console.log("call currency all", fiatCurrencyref.current);
      getAllp2pOrders();
      setActive(activetabref.current);
      setActivetype("buy");
    } else {
      setfiatCurrency(option.label);
      var onj = {
        currency: option.label,
      };
      var data = {
        apiUrl: apiService.p2pGetOrder,
        payload: onj,
      };
      var resp = await postMethod(data);
      if (resp) {
        var data = resp.Message;
        setgetAllp2pOrders(resp.Message);
      }
    }
  };

  const handleChange = async (e) => {
    const newActiveTab = e.target.getAttribute("data-tab");
    console.log("newActiveTab===", newActiveTab);

    setActive(newActiveTab);
    console.log("activetabref.current===", activetabref.current);
    setActivetype("buy");
    getAllp2pOrders();
  };

  const handleChange_type = async (e) => {
    const type = e.target.getAttribute("data-tab");
    if (type == "Buy") {
      setActive("All");
      $("a#All").trigger("click");
    } else {
      setActive("sellAll");
      $("a#sellAll").trigger("click");
    }
    console.log("activetabref.current buy sell===", activetabref.current);
  };

  const getp2pOrder = async (data) => {
    setp2pData(data);
    var onj = {
      fromCurrency: data.fromCurrency,
    };
    var data = {
      apiUrl: apiService.getp2pBalance,
      payload: onj,
    };
    var resp = await postMethod(data);
    console.log(resp.Message, "-=-=-resp=-=-");
    if (resp) {
      var data = resp.Message;
      setp2pbalance(resp.p2pbalance);
    }
  };

  const navp2ppage = async (orderid) => {
    navigate("/p2p/confirm-order/" + orderid);
  };

  const navp2pview = async (orderid) => {
    navigate("/p2p/view-order/" + orderid);
  };

  const navlogin = async () => {
    navigate("/login");
  };

  const notifyrecordPerPage = 5;

  const notifypageRange = 5;
  const notify = async (page) => {
    var payload = {
      perpage: 5,
      page: page,
    };

    var Notification = {
      apiUrl: apiService.getnotification,
      payload: payload,
    };
    var resp = await postMethod(Notification);
    if (resp.status) {
      Setnotification(resp.data.data);
      console.log(resp.data.data, "=-=-resp.data.data-==-=");

      Setnotifytotalpage(resp.data.total);
      console.log(resp.data.total, "=-=resp.data.total=-=-=");
    } else {
    }
  };

  const handlenotifyPageChange = (pageNumber) => {
    notify(pageNumber);
    setnotifyCurrentPage(pageNumber);
  };

  const navchatpage = async (link) => {
    navigate(link);
  };

  const getp2pOrders = async (page) => {
    try {
      var data = {
        apiUrl: apiService.p2pOrders,
        payload: { FilPerpage: 5, FilPage: page },
      };
      var p2p_orders_list = await postMethod(data);
      if (p2p_orders_list.status == true) {
        if (p2p_orders_list.returnObj.Message.fromCurrency != null) {
          setp2pOrders(p2p_orders_list.returnObj.Message);
          console.log(
            p2p_orders_list.returnObj.Message,
            "==============message"
          );
          setp2pTotalpages(p2p_orders_list.returnObj.total);
        }
      }
    } catch (error) {}
  };
  const [countryName, setCountryName] = useState("");
  const [paymentmethod, setPaymentMethod] = useState();
  // Other state variables and functions

  const choosecountryfilter = (e, value) => {
    // getAllp2pOrders();
    console.log(e, "--", value.value);
    setCountryName(value.value);
    var data = [];
    for (var i = 0; i < getP2POrdersref.current.length; i++) {
      if (getP2POrders[i].location == value.value) {
        var obj = {
          firstCurrency: getP2POrders[i].firstCurrency,
          fromLimit: getP2POrders[i].fromLimit,
          location: getP2POrders[i].location,
          orderId: getP2POrders[i].orderId,
          orderType: getP2POrders[i].orderType,
          orders_count: getP2POrders[i].orders_count,
          paymentMethod: getP2POrders[i].paymentMethod,
          price: getP2POrders[i].price,
          processAmount: getP2POrders[i].processAmount,
          profile_image: getP2POrders[i].profile_image,
          rating: getP2POrders[i].rating,
          secondCurrnecy: getP2POrders[i].secondCurrnecy,
          toLimit: getP2POrders[i].toLimit,
          totalAmount: getP2POrders[i].totalAmount,
          user_id: getP2POrders[i].user_id,
          username: getP2POrders[i].username,
        };
        data.push(obj);
      }
    }
    console.log(data, "data");
    if (data == "") {
      setbuyP2POrders([]);
      setsellP2POrders([]);
    } else {
      setgetAllp2pOrders(data);
      var buy = data.filter((data) => data.orderType == "sell");
      buy.sort(function (a, b) {
        return a.price - b.price;
      });
      setbuyP2POrders(buy);

      var sell = data.filter((data) => data.orderType == "buy");
      sell.sort(function (a, b) {
        return b.price - a.price;
      });
      setsellP2POrders(sell);
    }
  };

  const [amountValue, setamountValue] = useStateRef("");

  const amountfilteration = (value) => {
    if (value.toString().length > 20) {
    } else {
      setamountValue(value);
      const result = getP2POrdersref.current.filter((word) => {
        return word.price.toLowerCase().includes(value);
      });

      console.log(result, "result");

      if (result == "") {
        setbuyP2POrders([]);
        setsellP2POrders([]);
      } else {
        setgetAllp2pOrders(result);
        var buy = result.filter((data) => data.orderType == "sell");
        buy.sort(function (a, b) {
          return a.price - b.price;
        });
        setbuyP2POrders(buy);

        var sell = result.filter((data) => data.orderType == "buy");
        sell.sort(function (a, b) {
          return b.price - a.price;
        });
        setsellP2POrders(sell);
      }
    }
  };

  const paymentfilteration = (e, value) => {
    console.log(e, "--", value.value);
    setPaymentMethod(value.value);
    var data = [];
    for (var i = 0; i < getP2POrdersref.current.length; i++) {
      if (getP2POrders[i].paymentMethod == value.value) {
        var obj = {
          firstCurrency: getP2POrders[i].firstCurrency,
          fromLimit: getP2POrders[i].fromLimit,
          location: getP2POrders[i].location,
          orderId: getP2POrders[i].orderId,
          orderType: getP2POrders[i].orderType,
          orders_count: getP2POrders[i].orders_count,
          paymentMethod: getP2POrders[i].paymentMethod,
          price: getP2POrders[i].price,
          processAmount: getP2POrders[i].processAmount,
          profile_image: getP2POrders[i].profile_image,
          rating: getP2POrders[i].rating,
          secondCurrnecy: getP2POrders[i].secondCurrnecy,
          toLimit: getP2POrders[i].toLimit,
          totalAmount: getP2POrders[i].totalAmount,
          user_id: getP2POrders[i].user_id,
          username: getP2POrders[i].username,
        };
        data.push(obj);
      }
    }
    console.log(data, "data");
    if (data == "") {
      setbuyP2POrders([]);
      setsellP2POrders([]);
    } else {
      setgetAllp2pOrders(data);
      var buy = data.filter((data) => data.orderType == "sell");
      buy.sort(function (a, b) {
        return a.price - b.price;
      });
      setbuyP2POrders(buy);

      var sell = data.filter((data) => data.orderType == "buy");
      sell.sort(function (a, b) {
        return b.price - a.price;
      });
      setsellP2POrders(sell);
    }
  };

  const p2precordpage = 5;
  const p2ppagerange = 5;
  const handlepagep2p = (p2ppage) => {
    console.log(p2ppage, "==-=-=-p2ppage==-==-=");
    getp2pOrders(p2ppage);
    setp2pcurrentpage(p2ppage);
  };

  const navpage = async (link) => {
    navigate("/p2p/view-order/" + link);
  };

  const getp2pHistory = async (page) => {
    var data = {
      apiUrl: apiService.p2pHistory,
      payload: { FilPerpage: 5, FilPage: page },
    };
    var p2p_orders_list = await postMethod(data);
    if (p2p_orders_list.status) {
      setp2pHistory(p2p_orders_list.returnObj.Message);
      sethistoryTotalpages(p2p_orders_list.returnObj.total);
    }
  };

  const historyrecordpage = 5;
  const historypagerange = 5;
  const handlepagep2phistory = (p2ppage) => {
    console.log(p2ppage, "==-=-=-p2ppage==-==-=");
    getp2pHistory(p2ppage);
    sethistorycurrentpage(p2ppage);
  };

  const navpostad = async () => {
    navigate("/p2p/post-ad");
  };

  return (
    <div className="header ">
      <Header />
      <div>
        <div className="body_hXuuO ">
          <div className="lrtcss-wxjc90 e161ieae2">
            <div className="lrtcss-1p2kb7b e161ieae0">
              <section className="p2p_title_section">
                <div className="container">
                  <div className=" p2p_bg">
                    <div className="p2p_header">
                      <h1>
                        Buy/Sell with<span>Zero Fees</span>on the <br /> Peer to
                        peer(P2P) <br />
                        marketplace
                      </h1>

                      <p>
                        Buy USDT using USD at the best prices, no matter your
                        location. Choose from a variety of flexible payment{" "}
                        <br /> methods, including credit and debit cards, bank
                        transfers, and more. P2P trading on Sellcrypt is both
                        secure and <br /> free.
                      </p>
                    </div>
                  </div>
                </div>
              </section>

              <section className="p2p_buy_sell_section">
                <div className="container">
                  <div className="p2p_tabs mb-5">
                    <div class="p2p_padding mt-5">
                      <ul class="nav nav-tabs" id="myTabs">
                        <li class="nav-item">
                          <a
                            class="nav-link active"
                            id="home-tab"
                            data-toggle="tab"
                            href="#Buy"
                            data-tab="Buy"
                            onClick={handleChange_type}
                          >
                            Buy
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            id="profile-tab"
                            data-toggle="tab"
                            href="#Sell"
                            data-tab="Sell"
                            onClick={handleChange_type}
                          >
                            Sell
                          </a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" href="/p2p/post-Ad">
                            Post Ad
                          </a>
                        </li>
                      </ul>

                      <div class="p2p_tab tab-content mt-2">
                        <div class="tab-pane fade show active" id="Buy">
                          <div className="tab_methods  ">
                            <div className="wallet_tab p2p_sell_tabs">
                              <ul
                                class="nav nav-pills tab mb-3 mt-5"
                                id="pills-tab"
                                role="tablist"
                              >
                                {allCurrencyref.current &&
                                  allCurrencyref.current.map((item, i) => {
                                    var classactive = "";
                                    // if (i == 0) {
                                    //   classactive = "active";
                                    // }
                                    if (
                                      activetabref.current ==
                                      item.currencySymbol
                                    ) {
                                      classactive = " active-button";
                                    }
                                    return (
                                      <li
                                        class={`nav-item buy  ${classactive}`}
                                      >
                                        <a
                                          data-toggle="tab"
                                          data-tab={`${item.currencySymbol}`}
                                          href={`#${item.currencySymbol}`}
                                          onClick={handleChange}
                                          class={` nav-link buy-btn ${classactive}`}
                                          id={`${item.currencySymbol}`}
                                        >
                                          {item.currencySymbol}
                                        </a>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                          </div>

                          <div className="p2p_table">
                            {activetabref.current == "All" ? (
                              <div
                                id="All"
                                class="tab-pane fade in active show"
                              >
                                <table class="table table-striped">
                                  <thead>
                                    <tr>
                                      <th scope="col">Merchant</th>
                                      <th scope="col">Price</th>
                                      <th scope="col">
                                        Available | Order Limit
                                      </th>
                                      <th scope="col">Payment Methods</th>
                                      <th scope="col" className="trade_fees">
                                        Trade <span>0 fee(s)</span>{" "}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {buyP2POrdersref.current.length > 0 ? (
                                      buyP2POrdersref.current.map(
                                        (orders, i) => {
                                          let available =
                                            orders.totalAmount -
                                            +orders.processAmount;
                                          return activetabref.current ==
                                            "All" && available > 0 ? (
                                            <tr className="p2p_row">
                                              <th scope="row">
                                                {" "}
                                                <div className="p2p_row_trades">
                                                  {" "}
                                                  <div className="row_profile">
                                                    DA
                                                  </div>{" "}
                                                  <div className="profile_content">
                                                    <h3>
                                                      {orders.username}
                                                      {orders.profile_image !=
                                                        null &&
                                                      orders.profile_image !=
                                                        "" ? (
                                                        <img
                                                          src={
                                                            orders.profile_image
                                                          }
                                                          className="img-fluid"
                                                        />
                                                      ) : (
                                                        <i class="ri-bookmark-3-fill"></i>
                                                      )}
                                                    </h3>{" "}
                                                    <p>
                                                      {" "}
                                                      {
                                                        orders.orders_count
                                                      } |{" "}
                                                      {orders.rating}
                                                      %
                                                    </p>
                                                  </div>{" "}
                                                </div>
                                              </th>
                                              <td className="p2p_row_price">
                                                <h3>
                                                  {orders && orders.price}
                                                  <span>
                                                    {orders &&
                                                      orders.secondCurrnecy}
                                                  </span>
                                                </h3>
                                              </td>
                                              <td>
                                                <div className="p2p_order_limit">
                                                  <h3>
                                                    {orders && orders.fromLimit}{" "}
                                                    - {orders && orders.toLimit}{" "}
                                                    <span> USDT</span>
                                                  </h3>
                                                  <h3>
                                                    {orders &&
                                                      orders.available_qty}{" "}
                                                    <span>
                                                      {" "}
                                                      {orders &&
                                                        orders.firstCurrency}
                                                    </span>
                                                  </h3>
                                                </div>
                                              </td>
                                              <td className="p2p_row_img">
                                                {" "}
                                                {orders && orders.paymentMethod}
                                              </td>
                                              <td className="p2p_row_button">
                                                {" "}
                                                {/* <button
                                      data-toggle="modal"
                                      data-target="#largeScreenModal"
                                    >
                                      Buy
                                    </button>{" "} */}
                                                {loginTrue == true ? (
                                                  profileDatasref.current &&
                                                  profileDatasref.current._id ==
                                                    orders.user_id ? (
                                                    <div className=" ">
                                                      <button
                                                        className="p2p2"
                                                        onClick={() =>
                                                          navp2pview(
                                                            orders.orderId
                                                          )
                                                        }
                                                      >
                                                        View
                                                      </button>
                                                    </div>
                                                  ) : (
                                                    <div className=" ">
                                                      <button
                                                        className="p2p2"
                                                        // onClick={() =>
                                                        //   navp2ppage(
                                                        //     orders.orderId
                                                        //   )
                                                        // }
                                                        data-toggle="modal"
                                      data-target="#largeScreenModal"
                                                      >
                                                        Buy
                                                      </button>
                                                    </div>
                                                  )
                                                ) : (
                                                  <div className=" ">
                                                    <button
                                                      className="p2p2"
                                                      onClick={() => navlogin()}
                                                    >
                                                      Login
                                                    </button>
                                                  </div>
                                                )}
                                              </td>
                                            </tr>
                                          ) : (
                                            ""
                                          );
                                        }
                                      )
                                    ) : (
                                      <tr>
                                        <td colSpan={5}>
                                          <div className="empty_data">
                                            <div className="empty_data_img">
                                              <img
                                                src={require("../Image/Table_image/no-record-light.png")}
                                              />
                                            </div>

                                            <div className="no_records_text">
                                              No Records Found
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            ) : (
                              ""
                            )}

                            {allCurrencyref.current &&
                              allCurrencyref.current.map((item, j) => {
                                return activetabref.current ==
                                  item.currencySymbol &&
                                  item.currencySymbol != "All" ? (
                                  <div
                                    id={`${item.currencySymbol}`}
                                    class={`tab-pane fade in ${
                                      activetabref.current ==
                                      item.currencySymbol
                                        ? "active show"
                                        : ""
                                    }`}
                                  >
                                    <table class="table table-striped">
                                      <thead>
                                        <tr>
                                          <th scope="col">Merchant</th>
                                          <th scope="col">Price</th>
                                          <th scope="col">
                                            Available | Order Limit
                                          </th>
                                          <th scope="col">Payment Methods</th>
                                          <th
                                            scope="col"
                                            className="trade_fees"
                                          >
                                            Trade <span>0 fee(s)</span>{" "}
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {currentp2pBuyref.current.length > 0 ? (
                                          currentp2pBuyref.current.map(
                                            (orders, i) => {
                                              let available =
                                                orders.totalAmount -
                                                +orders.processAmount;
                                              return available > 0 ? (
                                                <tr className="p2p_row">
                                                  <th scope="row">
                                                    {" "}
                                                    <div className="p2p_row_trades">
                                                      {" "}
                                                      <div className="row_profile">
                                                        DA
                                                      </div>{" "}
                                                      <div className="profile_content">
                                                        <h3>
                                                          {orders.username}
                                                          {orders.profile_image !=
                                                            null &&
                                                          orders.profile_image !=
                                                            "" ? (
                                                            <img
                                                              src={
                                                                orders.profile_image
                                                              }
                                                              className="img-fluid"
                                                            />
                                                          ) : (
                                                            <i class="ri-bookmark-3-fill"></i>
                                                          )}
                                                        </h3>{" "}
                                                        <p>
                                                          {" "}
                                                          {
                                                            orders.orders_count
                                                          }{" "}
                                                          |{" "}
                                                          {orders.rating}
                                                          %
                                                        </p>
                                                      </div>{" "}
                                                    </div>
                                                  </th>
                                                  <td className="p2p_row_price">
                                                    <h3>
                                                      {orders && orders.price}
                                                      <span>
                                                        {orders &&
                                                          orders.secondCurrnecy}
                                                      </span>
                                                    </h3>
                                                  </td>
                                                  <td>
                                                    <div className="p2p_order_limit">
                                                      <h3>
                                                        {orders &&
                                                          orders.fromLimit}{" "}
                                                        -{" "}
                                                        {orders &&
                                                          orders.toLimit}{" "}
                                                        <span> USDT</span>
                                                      </h3>
                                                      <h3>
                                                        {orders &&
                                                          orders.available_qty}{" "}
                                                        <span>
                                                          {" "}
                                                          {orders &&
                                                            orders.firstCurrency}
                                                        </span>
                                                      </h3>
                                                    </div>
                                                  </td>
                                                  <td className="p2p_row_img">
                                                    {" "}
                                                    {orders &&
                                                      orders.paymentMethod}
                                                  </td>
                                                  <td className="p2p_row_button">
                                                    {" "}
                                                    {/* <button
                                      data-toggle="modal"
                                      data-target="#largeScreenModal"
                                    >
                                      Buy
                                    </button>{" "} */}
                                                    {loginTrue == true ? (
                                                      profileDatasref.current &&
                                                      profileDatasref.current._id ==
                                                        orders.user_id ? (
                                                        <div className=" ">
                                                          <button
                                                            className="p2p2"
                                                            onClick={() =>
                                                              navp2pview(
                                                                orders.orderId
                                                              )
                                                            }
                                                          >
                                                            View
                                                          </button>
                                                        </div>
                                                      ) : (
                                                        <div className=" ">
                                                          <button
                                                            className="p2p2"
                                                            // onClick={() =>
                                                            //   navp2ppage(
                                                            //     orders.orderId
                                                            //   )
                                                            // }
                                                            data-toggle="modal"
                                      data-target="#largeScreenModal"
                                                          >
                                                            Buy
                                                          </button>
                                                        </div>
                                                      )
                                                    ) : (
                                                      <div className=" ">
                                                        <button
                                                          className="p2p2"
                                                          onClick={() =>
                                                            navlogin()
                                                          }
                                                        >
                                                          Login
                                                        </button>
                                                      </div>
                                                    )}
                                                  </td>
                                                </tr>
                                              ) : (
                                                ""
                                              );
                                            }
                                          )
                                        ) : (
                                          <tr>
                                            <td colSpan={5}>
                                              <div className="empty_data">
                                                <div className="empty_data_img">
                                                  <img
                                                    src={require("../Image/Table_image/no-record-light.png")}
                                                  />
                                                </div>

                                                <div className="no_records_text">
                                                  No Records Found
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                ) : (
                                  ""
                                );
                              })}
                          </div>
                        </div>

                        <div class="tab-pane fade" id="Sell">
                          <div className="tab_methods wallet_tab">
                            <ul
                              class="nav nav-pills tab mt-5 mb-3"
                              id="pills-tab"
                              role="tablist"
                            >
                              {allCurrencyref.current &&
                                allCurrencyref.current.map((item, i) => {
                                  var classactive = "";
                                  // if (i == 0) {
                                  //   classactive = "active";
                                  // }
                                  if (
                                    activetabref.current ==
                                    "sell" + item.currencySymbol
                                  ) {
                                    classactive = "active-button";
                                  }
                                  return (
                                    <li class={`nav-item sell  ${classactive}`}>
                                      <a
                                        data-toggle="tab"
                                        data-tab={`sell${item.currencySymbol}`}
                                        href={`#sell${item.currencySymbol}`}
                                        onClick={handleChange}
                                        class={` nav-link buy-btn ${classactive}`}
                                        id={`sell${item.currencySymbol}`}
                                      >
                                        {item.currencySymbol}
                                      </a>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>

                          <div className="p2p_table">
                            {activetabref.current == "sellAll" ? (
                              <table class="table table-striped">
                                <thead>
                                  <tr>
                                    <th scope="col">Merchant</th>
                                    <th scope="col">Price</th>
                                    <th scope="col">Available | Order Limit</th>
                                    <th scope="col">Payment Methods</th>
                                    <th scope="col" className="trade_fees">
                                      Trade <span>0 fee(s)</span>{" "}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {sellP2POrdersref.current.length > 0 ? (
                                    sellP2POrdersref.current.map(
                                      (orders, i) => {
                                        let available =
                                          orders.totalAmount -
                                          +orders.processAmount;
                                        return activetabref.current ==
                                          "sellAll" && available > 0 ? (
                                          <tr className="p2p_row">
                                            <th scope="row">
                                              {" "}
                                              <div className="p2p_row_trades">
                                                {" "}
                                                <div className="row_profile">
                                                  DA
                                                </div>{" "}
                                                <div className="profile_content">
                                                  <h3>
                                                    {orders.username}
                                                    {orders.profile_image !=
                                                      null &&
                                                    orders.profile_image !=
                                                      "" ? (
                                                      <img
                                                        src={
                                                          orders.profile_image
                                                        }
                                                        className="img-fluid"
                                                      />
                                                    ) : (
                                                      <i class="ri-bookmark-3-fill"></i>
                                                    )}
                                                  </h3>{" "}
                                                  <p>
                                                    {" "}
                                                    {orders.orders_count} |{" "}
                                                    {orders.rating}%
                                                  </p>
                                                </div>{" "}
                                              </div>
                                            </th>
                                            <td className="p2p_row_price">
                                              <h3>
                                                {orders && orders.price}
                                                <span>
                                                  {orders &&
                                                    orders.secondCurrnecy}
                                                </span>
                                              </h3>
                                            </td>
                                            <td>
                                              <div className="p2p_order_limit">
                                                <h3>
                                                  {orders && orders.fromLimit} -{" "}
                                                  {orders && orders.toLimit}{" "}
                                                  <span> USDT</span>
                                                </h3>
                                                <h3>
                                                  {/* {orders &&
                                                    orders.available_qty}{" "} */}
                                                  <span>
                                                    {" "}
                                                    {orders &&
                                                      orders.firstCurrency}
                                                  </span>
                                                </h3>
                                              </div>
                                            </td>
                                            <td className="p2p_row_img">
                                              {" "}
                                              {orders && orders.paymentMethod}
                                            </td>
                                            <td className="p2p_row_button">
                                              {" "}
                                              {/* <button
                                      data-toggle="modal"
                                      data-target="#largeScreenModal"
                                    >
                                      Buy
                                    </button>{" "} */}
                                              {loginTrue == true ? (
                                                profileDatasref.current &&
                                                profileDatasref.current._id ==
                                                  orders.user_id ? (
                                                  <div className=" ">
                                                    <button
                                                      className="p2p2"
                                                      onClick={() =>
                                                        navp2pview(
                                                          orders.orderId
                                                        )
                                                      }
                                                    >
                                                      View
                                                    </button>
                                                  </div>
                                                ) : (
                                                  <div className=" ">
                                                    <button
                                                      className="p2p2"
                                                      // onClick={() =>
                                                      //   navp2ppage(
                                                      //     orders.orderId
                                                      //   )
                                                      // }

                                                      data-toggle="modal"
                                      data-target="#largeScreenModal"
                                                    >
                                                      Buy
                                                    </button>
                                                  </div>
                                                )
                                              ) : (
                                                <div className=" ">
                                                  <button
                                                    className="p2p2"
                                                    onClick={() => navlogin()}
                                                  >
                                                    Login
                                                  </button>
                                                </div>
                                              )}
                                            </td>
                                          </tr>
                                        ) : (
                                          ""
                                        );
                                      }
                                    )
                                  ) : (
                                    <tr>
                                      <td colSpan={5}>
                                        <div className="empty_data">
                                          <div className="empty_data_img">
                                            <img
                                              src={require("../Image/Table_image/no-record-light.png")}
                                            />
                                          </div>

                                          <div className="no_records_text">
                                            No Records Found
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            ) : (
                              ""
                            )}

                            {allCurrencyref.current &&
                              allCurrencyref.current.map((item, j) => {
                                return activetabref.current ==
                                  "sell" + item.currencySymbol &&
                                  "sell" + item.currencySymbol != "sellAll" ? (
                                  <div
                                    id={`sell${item.currencySymbol}`}
                                    class={`tab-pane fade in ${
                                      activetabref.current ==
                                      "sell" + item.currencySymbol
                                        ? "active show"
                                        : ""
                                    }`}
                                  >
                                    <table class="table table-striped">
                                      <thead>
                                        <tr>
                                          <th scope="col">Merchant</th>
                                          <th scope="col">Price</th>
                                          <th scope="col">
                                            Available | Order Limit
                                          </th>
                                          <th scope="col">Payment Methods</th>
                                          <th
                                            scope="col"
                                            className="trade_fees"
                                          >
                                            Trade <span>0 fee(s)</span>{" "}
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {currentp2pSellref.current.length >
                                        0 ? (
                                          currentp2pSellref.current.map(
                                            (orders, i) => {
                                              let available =
                                                orders.totalAmount -
                                                +orders.processAmount;
                                              return available > 0 ? (
                                                <tr className="p2p_row">
                                                  <th scope="row">
                                                    {" "}
                                                    <div className="p2p_row_trades">
                                                      {" "}
                                                      <div className="row_profile">
                                                        DA
                                                      </div>{" "}
                                                      <div className="profile_content">
                                                        <h3>
                                                          {orders.username}
                                                          {orders.profile_image !=
                                                            null &&
                                                          orders.profile_image !=
                                                            "" ? (
                                                            <img
                                                              src={
                                                                orders.profile_image
                                                              }
                                                              className="img-fluid"
                                                            />
                                                          ) : (
                                                            <i class="ri-bookmark-3-fill"></i>
                                                          )}
                                                        </h3>{" "}
                                                        <p>
                                                          {" "}
                                                          {
                                                            orders.orders_count
                                                          }{" "}
                                                          |{" "}
                                                          {orders.rating}
                                                          %
                                                        </p>
                                                      </div>{" "}
                                                    </div>
                                                  </th>
                                                  <td className="p2p_row_price">
                                                    <h3>
                                                      {orders && orders.price}
                                                      <span>
                                                        {orders &&
                                                          orders.secondCurrnecy}
                                                      </span>
                                                    </h3>
                                                  </td>
                                                  <td>
                                                    <div className="p2p_order_limit">
                                                      <h3>
                                                        {orders &&
                                                          orders.fromLimit}{" "}
                                                        -{" "}
                                                        {orders &&
                                                          orders.toLimit}{" "}
                                                        <span> USDT</span>
                                                      </h3>
                                                      <h3>
                                                        {orders &&
                                                          orders.available_qty}{" "}
                                                        <span>
                                                          {" "}
                                                          {orders &&
                                                            orders.firstCurrency}
                                                        </span>
                                                      </h3>
                                                    </div>
                                                  </td>
                                                  <td className="p2p_row_img">
                                                    {" "}
                                                    {orders &&
                                                      orders.paymentMethod}
                                                  </td>
                                                  <td className="p2p_row_button">
                                                    {" "}
                                                    {/* <button
                                      data-toggle="modal"
                                      data-target="#largeScreenModal"
                                    >
                                      Buy
                                    </button>{" "} */}
                                                    {loginTrue == true ? (
                                                      profileDatasref.current &&
                                                      profileDatasref.current._id ==
                                                        orders.user_id ? (
                                                        <div className=" ">
                                                          <button
                                                            className="p2p2"
                                                            onClick={() =>
                                                              navp2pview(
                                                                orders.orderId
                                                              )
                                                            }
                                                          >
                                                            View
                                                          </button>
                                                        </div>
                                                      ) : (
                                                        <div className=" ">
                                                          <button
                                                            className="p2p2"
                                                            // onClick={() =>
                                                            //   navp2ppage(
                                                            //     orders.orderId
                                                            //   )
                                                            // }

                                                            data-toggle="modal"
                                      data-target="#largeScreenModal"
                                                          >
                                                            Buy
                                                          </button>
                                                        </div>
                                                      )
                                                    ) : (
                                                      <div className=" ">
                                                        <button
                                                          className="p2p2"
                                                          onClick={() =>
                                                            navlogin()
                                                          }
                                                        >
                                                          Login
                                                        </button>
                                                      </div>
                                                    )}
                                                  </td>
                                                </tr>
                                              ) : (
                                                ""
                                              );
                                            }
                                          )
                                        ) : (
                                          <tr>
                                            <td colSpan={5}>
                                              <div className="empty_data">
                                                <div className="empty_data_img">
                                                  <img
                                                    src={require("../Image/Table_image/no-record-light.png")}
                                                  />
                                                </div>

                                                <div className="no_records_text">
                                                  No Records Found
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                ) : (
                                  ""
                                );
                              })}
                          </div>
                        </div>
                      </div>

                      <div className="p2p_pagination">
                        <nav aria-label="Page navigation example">
                          <ul class="pagination">
                            <li class="page-item">
                              <a
                                class="page-link"
                                href="#"
                                aria-label="Previous"
                              >
                                <span aria-hidden="true">&laquo;</span>
                              </a>
                            </li>
                            <li class="page-item">
                              <a class="page-link" href="#">
                                1
                              </a>
                            </li>
                            <li class="page-item">
                              <a class="page-link" href="#">
                                2
                              </a>
                            </li>
                            <li class="page-item">
                              <a class="page-link" href="#">
                                3
                              </a>
                            </li>
                            <li class="page-item">
                              <a class="page-link" href="#" aria-label="Next">
                                <span aria-hidden="true">&raquo;</span>
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>

                      <div
                        class="modal fade"
                        id="largeScreenModal"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="largeScreenModalLabel"
                        aria-hidden="true"
                      >
                        <div
                          class="modal-dialog modal-xl modal-dialog-centered "
                          role="document"
                        >
                          <div class="modal-content p2p">
                            <div class="modal-header">
                              <h5
                                class="modal-title"
                                id="exampleModalLongTitle"
                              ></h5>
                              <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>

                            <div class="modal-body">
                              <div className="row">
                                <div className="col-lg-4 modal_text">
                                  <div className="p2p_row_trades">
                                    {" "}
                                    <div className="row_profile">DA</div>{" "}
                                    <div className="profile_content">
                                      <h3>
                                        Dastgeer Traders{" "}
                                        <i class="ri-bookmark-3-fill"> </i>
                                        <i class="ri-medal-line"></i>
                                      </h3>{" "}
                                      <p>1,627 order(s) | 99.14%</p>
                                    </div>{" "}
                                  </div>
                                  <div className="p2p_modal_contant">
                                    <h3>Remarks</h3>
                                    <p>
                                      My income is from Russia. i amtransfering
                                      my own money from Russia to your account.
                                      I am transfering my own money from Russia
                                      to your account.So i will deal in cash
                                      deposit only... My income is from Russia.
                                      i amtransfering my own money from Russia
                                      to your account. I am transfering my own
                                      money from Russia to your account.So i
                                      will deal in cash deposit only... My
                                      income is from Russia. i amtransfering my
                                      own money from Russia to your account. I
                                      am transfering my own money from Russia to
                                      your account.So i will deal in cash
                                      deposit only...
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-3">
                                  <div className="modal_available">
                                    <div>
                                      <h3>Available</h3>
                                      <p>446.013334</p>
                                    </div>
                                    <div>
                                      <h3>Limit</h3>
                                      <p>1-456 INR</p>
                                    </div>
                                  </div>
                                  <div className="modal_available mt-3">
                                    <div>
                                      <h3>Price</h3>
                                      <p>90 INR/USDT</p>
                                    </div>
                                    <div className="payment_methods">
                                      <h3>Payment Methods</h3>
                                      <img
                                        src={require("../Image/Table_image/modal_currency.png")}
                                        alt="pic"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="col-lg-5 buy_order">
                                  <div>
                                    <p>
                                      Buy(Remaining Daily Limit:40,000,000INR)
                                    </p>
                                  </div>
                                  <div className="buy-amt">
                                    <div className="flex-grow-1">
                                      <input
                                        type="text"
                                        className="bg_trans_non"
                                      />
                                    </div>
                                    <div>INR</div>
                                  </div>
                                  <div className="usd-amt">
                                    <div className="flex-grow-1">
                                      <input
                                        type="text"
                                        className="bg_trans_non"
                                      />
                                    </div>
                                    <div>USDT</div>
                                  </div>

                                  <div className="place-order">
                                    <p>
                                      Trading password is not required when
                                      placing buy <br />
                                      orders.
                                    </p>
                                    <button>Place Order</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="modal fade"
                        id="lgScreen"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="largeScreenModalLabel"
                        aria-hidden="true"
                      >
                        <div
                          class="modal-dialog modal-xl modal-dialog-centered "
                          role="document"
                        >
                          <div class="modal-content p2p">
                            <div class="modal-header">
                              <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>

                            <div class="modal-body">
                              <div className="row">
                                <div className="col-lg-4 modal_text">
                                  <div className="p2p_row_trades">
                                    {" "}
                                    <div className="row_profile">DA</div>{" "}
                                    <div className="profile_content">
                                      <h3>
                                        Dastgeer Traders{" "}
                                        <i class="ri-bookmark-3-fill"> </i>
                                        <i class="ri-medal-line"></i>
                                      </h3>{" "}
                                      <p>1,627 order(s) | 99.14%</p>
                                    </div>{" "}
                                  </div>
                                  <div className="p2p_modal_contant">
                                    <h3>Remarks</h3>
                                    <p>
                                      My income is from Russia. i amtransfering
                                      my own money from Russia to your account.
                                      I am transfering my own money from Russia
                                      to your account.So i will deal in cash
                                      deposit only... My income is from Russia.
                                      i amtransfering my own money from Russia
                                      to your account. I am transfering my own
                                      money from Russia to your account.So i
                                      will deal in cash deposit only...
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-3">
                                  <div className="modal_available">
                                    <div>
                                      <h3>Available</h3>
                                      <p>446.013334</p>
                                      <h3>Price</h3>
                                      <p>90 INR/USDT</p>
                                    </div>
                                  </div>
                                  <div className="modal_available sell ">
                                    <div className="mt-3">
                                      <h3>Limit</h3>
                                      <p>1-456 INR</p>
                                    </div>
                                    <div className="payment_methods mt-4">
                                      <h3>Payment Methods</h3>
                                      <img
                                        src={require("../Image/Table_image/modal_currency.png")}
                                        alt="pic"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="col-lg-5 buy_order">
                                  <div>
                                    <p>
                                      Sell(Remaining Daily Limit:40,000,000INR)
                                    </p>
                                  </div>
                                  <div className="buy-amt sell">
                                    <div className="flex-grow-1">
                                      <input
                                        type="text"
                                        className="bg_trans_non"
                                      />
                                    </div>
                                    <div>INR</div>
                                  </div>
                                  <div className="text-danger">
                                    <p>The amount cannot be less than 200000</p>
                                  </div>

                                  <div className="usd-amt">
                                    <div className="flex-grow-1">
                                      <input
                                        type="text"
                                        className="bg_trans_non"
                                      />
                                    </div>
                                    <div>USDT</div>
                                  </div>

                                  <div className="place-order">
                                    {/* <p>
                                      Trading password <br />
                                    </p>
                                    <div className="star">
                                      <div className="star-1">*</div>
                                      <div className="star-1">*</div>
                                      <div className="star-1">*</div>
                                      <div className="star-1">*</div>
                                      <div className="star-1">*</div>
                                      <div className="star-1">*</div>
                                    </div> */}
                                    <button>Place Order</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              {/*  */}

              {/* P2P Work Section */}

              {/* <section className="p2p_work_section">
                <div className="container">
                  <div className="p2p_works">
                    <div className="p2p_works_title">How P2P Works</div>

                    <div class="container mt-5">
                      <ul class="nav nav-tabs p2p_work" id="myTabs">
                        <li class="nav-item p2p_buy_btn d-flex">
                          <a
                            class="nav-link active"
                            id="tab1-tab"
                            data-toggle="tab"
                            href="#tab1"
                          >
                            How to Buy
                          </a>
                        </li>
                        <li class="nav-item p2p_sell_btn">
                          <a
                            class="nav-link"
                            id="tab2-tab"
                            data-toggle="tab"
                            href="#tab2"
                          >
                            How to Sell
                          </a>
                        </li>
                      </ul>

                      <div class="tab-content mt-2">
                        <div class="tab-pane fade show active" id="tab1">
                          <div className="p2p_works_steps d-flex">
                            <div className="work_step1">
                              <div className="line_container">
                                <img
                                  src={require("../Image/Table_image/buy_order.png")}
                                />
                                <div className="line_icon"></div>
                              </div>
                              <h3>Place Buy Order</h3>
                              <p>
                                After creating a buy order, the seller's assets
                                for this <br /> transaction will be escrowed by
                                Sellcrypt platform.
                              </p>
                            </div>
                            <div className="work_step1">
                              <div className="line_container">
                                <img
                                  src={require("../Image/Table_image/seller_pic.png")}
                                />
                                <div className="line_icon"></div>
                              </div>
                              <h3>Pay the Seller</h3>
                              <p>
                                Pay the seller using the payment method
                                specified in the <br /> transaction information.
                                Once you have paid, tap or click <br /> Payment
                                Complete on Sellcrypt P2P.
                              </p>
                            </div>
                            <div className="work_step1">
                              <img
                                src={require("../Image/Table_image/crypto.png")}
                              />
                              <h3>Receive Your Crypto</h3>
                              <p>
                                Once the seller confirms they have received the
                                payment, <br /> the escrowed crypto will be
                                released to you.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane fade" id="tab2">
                          <div className="p2p_works_steps d-flex">
                            <div className="work_step1">
                              <div className="line_container">
                                <img
                                  src={require("../Image/Table_image/buy_order.png")}
                                />
                                <div className="line_icon"></div>
                              </div>
                              <h3>Place Sell Order</h3>
                              <p>
                                After creating a buy order, the seller's assets
                                for this <br /> transaction will be escrowed by
                                Sellcrypt platform.
                              </p>
                            </div>
                            <div className="work_step1">
                              <div className="line_container">
                                <img
                                  src={require("../Image/Table_image/seller_pic.png")}
                                />
                                <div className="line_icon"></div>
                              </div>
                              <h3>Confirm Receipt of Payment</h3>
                              <p>
                                Pay the seller using the payment method
                                specified in the <br /> transaction information.
                                Once you have paid, tap or click <br /> Payment
                                Complete on Sellcrypt P2P.
                              </p>
                            </div>
                            <div className="work_step1">
                              <img
                                src={require("../Image/Table_image/crypto.png")}
                              />
                              <h3>Release Crypto</h3>
                              <p>
                                Once the seller confirms they have received the
                                payment, <br /> the escrowed crypto will be
                                released to you.
                              </p>
                            </div>
                          </div>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section> */}

              {/* <section className="Sellcrypt_about_section">
                <div className="container">
                  {" "}
                  <div className="ustd_Sellcrypt">
                    <div className="Sellcrypt_title">Why Buy USDT on Sellcrypt?</div>

                    <div className="about-Sellcrypt">
                      <div className="">
                        <img
                          src={require("../Image/Table_image/zero-fees.png")}
                        />
                        <h3>Zero Fees</h3>
                        <p>
                          Sellcrypt charges no fees on P2P trading, so you can buy{" "}
                          <br /> USDT freely without worrying about extra
                          charges <br /> eating into your profits, whether
                          you're a trader or a <br /> merchant.
                        </p>
                      </div>
                      <div className="">
                        <img
                          src={require("../Image/Table_image/security.png")}
                        />
                        <h3>Built-in Security</h3>
                        <p>
                          Robust security guarantees a peace of mind as you{" "}
                          <br /> trade. With our escrow services, feedback
                          rating <br /> system, and advanced security features,
                          trade USDT <br /> with confidence on the Sellcrypt P2P
                          marketplace.
                        </p>
                      </div>
                      <div className="">
                        <img
                          src={require("../Image/Table_image/flexible.png")}
                        />
                        <h3>Flexible Payment Methods</h3>
                        <p>
                          With over 100 payment methods to buy or sell USDT,
                          including <br /> 30+ local currencies, you've the
                          freedom to define how you <br /> want to purchase USDT
                          in the way that suits you best.
                        </p>
                      </div>
                    </div>
                    <div className="about-Sellcrypt2">
                      <div className="">
                        <img
                          src={require("../Image/Table_image/community.png")}
                        />
                        <h3>Be Part of A Global Crypto Community</h3>
                        <p>
                          Our global Sellcrypt community is 30 million users <br />
                          strong. Join us from anywhere in the world and trade{" "}
                          <br />
                          USDT with others just like you.
                        </p>
                      </div>
                      <div className="">
                        <img
                          src={require("../Image/Table_image/best-price.png")}
                        />
                        <h3>Trade USDT at the Best Prices</h3>
                        <p>
                          Choose the best price for your needs, or create your
                          own <br /> quote in the market. You can also become a
                          merchant and <br /> earn as you complete trades on the
                          platform.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section> */}

              {/* <section className="trading_tutorial_section">
                <div className="container">
                  <div className="trading_tutorial">
                    <div className="container">
                      <div className="tutorial-heading">
                        <div className="tutorial-title">
                          P2P Trading Tutorial
                        </div>
                        <div className="tutorial-guides">More guides</div>
                      </div>

                      <div className="tutorial-steps">
                        <div className="tutorial-step1 padding">
                          <h3>
                            How to Buy crypto assets via P2P trading (Website)?
                          </h3>
                          <p>
                            P2P trading is a must-learn for every crypto user,
                            especially newcomers. You can buy cryptocurrency
                            from the Sellcrypt P2P website in just a few
                            clicks.Before trading on...
                          </p>
                          <span className="view-green">
                            learn more <i class="ri-arrow-right-line"></i>
                          </span> 
                        </div>
                        <div className="tutorial-step1">
                          <h3>
                            How to Buy crypto assets via P2P trading (on Sellcrypt
                            APP)?
                          </h3>
                          <p>
                            Tap [Buy] and select the crypto you want to buy. You
                            will see the available offers on the market. Tap
                            [Buy] next to the preferred offer. You will see the
                            seller’s payment...
                          </p>
                          <span className="view-green">
                            learn more <i class="ri-arrow-right-line"></i>
                          </span>
                        </div>
                      </div>
                      <div className="tutorial-steps">
                        <div className="tutorial-step1">
                          <h3>
                            Everything You Should Know About Sellcrypt P2P Trading
                          </h3>
                          <p>
                            P2P trading is a must-learn for every crypto user,
                            especially newcomers. You can buy cryptocurrency
                            from the Sellcrypt P2P website in just a few
                            clicks.Before trading on...
                          </p>
                          <span className="view-green">
                            learn more <i class="ri-arrow-right-line"></i>
                          </span>
                        </div>
                        <div className="tutorial-step1">
                          <h3>
                            How to Buy crypto assets via P2P trading (on Sellcrypt
                            APP)?
                          </h3>
                          <p>
                            Tap [Buy] and select the crypto you want to buy. You
                            will see the available offers on the market. Tap
                            [Buy] next to the preferred offer. You will see the
                            seller’s payment...
                          </p>
                          <span className="view-green">
                            learn more <i class="ri-arrow-right-line"></i>
                          </span>
                        </div>
                      </div>

                      <div className="view-green">
                        View More<i class="ri-arrow-down-s-line"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </section> */}

              {/* <section className="trading_accordion_section mb-5">
                <div className="container">
                  <div className="trading_accordion">
                    <h1>P2P Trading FAQ</h1>
                    <div class="container">
                      <div id="accordion" class="accordion">
                        <div class="card p2p mb-0">
                          <div
                            class="card-header collapsed p2p"
                            data-toggle="collapse"
                            href="#collapseOne"
                          >
                            <a class="card-title p2p">
                              What is P2P cryptocurrency trading?
                            </a>
                          </div>
                          <div
                            id="collapseOne"
                            class="card-body collapse"
                            data-parent="#accordion"
                          >
                            <p>
                              Anim pariatur cliche reprehenderit, enim eiusmod
                              high life accusamus terry richardson ad squid. 3
                              wolf moon officia aute, non cupidatat skateboard
                              dolor brunch. Food truck quinoa nesciunt laborum
                              eiusmod. Brunch 3 wolf moon tempor, sunt aliqua
                              put a bird on it squid single-origin coffee nulla
                              assumenda shoreditch et. Nihil anim keffiyeh
                              helvetica, craft beer labore wes anderson cred
                              nesciunt sapiente ea proident. Ad vegan excepteur
                              butcher vice lomo. Leggings occaecat craft beer
                              farm-to-table, raw denim aesthetic synth nesciunt
                              you probably haven't heard of them accusamus
                              labore sustainable VHS.
                            </p>
                          </div>
                          <div
                            class="card-header collapsed p2p"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseTwo"
                          >
                            <a class="card-title p2p">
                              Which is the best P2P crypto exchange?
                            </a>
                          </div>
                          <div
                            id="collapseTwo"
                            class="card-body collapse"
                            data-parent="#accordion"
                          >
                            <p>
                              Anim pariatur cliche reprehenderit, enim eiusmod
                              high life accusamus terry richardson ad squid. 3
                              wolf moon officia aute, non cupidatat skateboard
                              dolor brunch. Food truck quinoa nesciunt laborum
                              eiusmod. Brunch 3 wolf moon tempor, sunt aliqua
                              put a bird on it squid single-origin coffee nulla
                              assumenda shoreditch et. Nihil anim keffiyeh
                              helvetica, craft beer labore wes anderson cred
                              nesciunt sapiente ea proident. Ad vegan excepteur
                              butcher vice lomo. Leggings occaecat craft beer
                              farm-to-table, raw denim aesthetic synth nesciunt
                              you probably haven't heard of them accusamus
                              labore sustainable VHS.
                            </p>
                          </div>
                          <div
                            class="card-header collapsed p2p"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseThree"
                          >
                            <a class="card-title p2p">
                              What are the costs of a P2P transaction?
                            </a>
                          </div>
                          <div
                            id="collapseThree"
                            class="collapse"
                            data-parent="#accordion"
                          >
                            <div class="card-body">
                              Anim pariatur cliche reprehenderit, enim eiusmod
                              high life accusamus terry richardson ad squid. 3
                              wolf moon officia aute, non cupidatat skateboard
                              dolor brunch. Food truck quinoa nesciunt laborum
                              eiusmod. Brunch 3 wolf moon tempor, sunt aliqua
                              put a bird on it squid single-origin coffee nulla
                              assumenda shoreditch et. samus labore sustainable
                              VHS.
                            </div>
                          </div>
                          <div
                            class="card-header collapsed p2p"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseFour"
                          >
                            <a class="card-title p2p">
                              What's a merchant on Sellcrypt P2P?
                            </a>
                          </div>
                          <div
                            id="collapseFour"
                            class="collapse"
                            data-parent="#accordion"
                          >
                            <div class="card-body ">
                              Anim pariatur cliche reprehenderit, enim eiusmod
                              high life accusamus terry richardson ad squid. 3
                              wolf moon officia aute, non cupidatat skateboard
                              dolor brunch. Food truck quinoa nesciunt laborum
                              eiusmod. Brunch 3 wolf moon tempor, sunt aliqua
                              put a bird on it squid single-origin coffee nulla
                              assumenda shoreditch et. samus labore sustainable
                              VHS.
                            </div>
                          </div>
                          <div
                            class="card-header collapsed p2p"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseFive"
                          >
                            <a class="card-title p2p">
                              How profitable is being a Sellcrypt P2P Merchant?
                            </a>
                          </div>
                          <div
                            id="collapseFive"
                            class="collapse"
                            data-parent="#accordion"
                          >
                            <div class="card-body">
                              Anim pariatur cliche reprehenderit, enim eiusmod
                              high life accusamus terry richardson ad squid. 3
                              wolf moon officia aute, non cupidatat skateboard
                              dolor brunch. Food truck quinoa nesciunt laborum
                              eiusmod. Brunch 3 wolf moon tempor, sunt aliqua
                              put a bird on it squid single-origin coffee nulla
                              assumenda shoreditch et. samus labore sustainable
                              VHS.
                            </div>
                          </div>
                          <div
                            class="card-header collapsed p2p"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseSix"
                          >
                            <a class="card-title p2p">
                              How can I protect myself from P2P scams?
                            </a>
                          </div>
                          <div
                            id="collapseSix"
                            class="collapse"
                            data-parent="#accordion"
                          >
                            <div class="card-body">
                              Anim pariatur cliche reprehenderit, enim eiusmod
                              high life accusamus terry richardson ad squid. 3
                              wolf moon officia aute, non cupidatat skateboard
                              dolor brunch. Food truck quinoa nesciunt laborum
                              eiusmod. Brunch 3 wolf moon tempor, sunt aliqua
                              put a bird on it squid single-origin coffee nulla
                              assumenda shoreditch et. samus labore sustainable
                              VHS.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section> */}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Home;
