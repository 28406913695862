import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "./Innerpages/Sidebar";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import Moment from "moment";
import { useLocation } from "react-router-dom";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Home() {
  const navigate = useNavigate();
  const [allCurrency, setallCurrency, allCurrencyref] = useState([]);
  const [allCrypto, setallCrypto, allCryptoref] = useState([]);
  const [currency, setcurrency, currencyref] = useState();
  const [cointype, setcointype, cointyperef] = useState("");
  const [balance, setBalance, balanceref] = useState("");
  const [view, setview, viewref] = useState(false);
  const [bankwire, setBankwire] = useState("");
  const [newAddres, setnewAddres] = useState("");
  const [newAddresErr, setnewAddresErr] = useState("");
  const [withdrawHistory, setwithdrawHistory] = useState([]);
  const [currentcurrency, setcurrentcurrency, currentcurrencyref] =
    useState("");
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [withdrawType, setwithdrawType] = useState("");

  const [network_currency, setcur_network, network_currencyref] = useState([]);
  const [network_default, setnet_default, net_defaultref] = useState("");
  const [network_current, setnet_current, network_currentref] = useState("");
  const [show_otp, setshow_otp, show_otpref] = useState(false);

  const bankdetails = () => { };
  const withdrawAction = async (data) => {
    var obj = {
      withdraw_id: data,
    };
    var data = {
      apiUrl: apiService.confirmWithdraw,
      payload: obj,
    };
    var resp = await postMethod(data);
    if (resp.status) {
      toast.success(resp.message, {
        toastId: "3",
      });
      //window.location.href = "/transaction";
      navigate("/withdraw");
    } else {
      toast.error(resp.message, {
        toastId: "3",
      });
      navigate("/withdraw");
    }
  };

  const qry_search = useLocation().search;
  const confirmation = new URLSearchParams(qry_search).get("transaction");
  if (confirmation != "" && confirmation != null) {
    withdrawAction(confirmation);
  }

  const initialFormValue = {
    amount: "",
    withAddress: "",
    tfa: "",
    withdraw_otp: "",
  };

  const [formValue, setFormValue] = useState(initialFormValue);
  const [amountValidate, setamountValidate] = useState(false);
  const [withAddressValidate, setwithAddress] = useState(false);
  const [tfaValidate, settfaValidate] = useState(false);
  const [otpValidate, setotpValidate] = useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [sitekycStatus, setsitekycStatus, sitekycStatusref] =
    useState("DeActive");
  const [kycStatus, setkycStatus] = useState(0);
  const [Fullname, Setfullname, Fullnameref] = useState();
  const [Image, setImage, Imageref] = useState("");
  const [Networks, setNetworks, Networksref] = useState("");
  const [newFullname, setnewFullname, newFullnameref] = useState("Tether");
  const [newImage, setnewImage, newImageref] = useState("");
  const [newNetworks, setnewNetworks, newNetworksref] = useState("");
  const [siteLoader, setSiteLoader] = useState(false);

  const { amount, withAddress, tfa, withdraw_otp } = formValue;

  useEffect(() => {
    getKYCstatus();
    getAllcurrency();
    getwithdrawHistory(1);
    getAddress();
    getSiteSettingstatus();
    var status = localStorage.getItem("withdraw_status");
    if (status == null) {
      localStorage.setItem("withdraw_status", false);
    }
    if (status == "false") {
      setalertTab("show");
    } else {
      setalertTab("remove");
    }
  }, [0]);

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    console.log(e, "eeeeeeeee");
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    validate(formData);
  };

  const handleChangs = async (e) => {
    setnewAddres(e.target.value);
    formValue["withAddress"] = e.target.value;
  };


  const getAllcurrency = async () => {
    var data = {
      apiUrl: apiService.walletcurrency,
    };
    var resp = await getMethod(data);
    if (resp) {
      var currArrayCrypto = [];
      var data = resp.data;
      setallCrypto(data);
      for (var i = 0; i < data.length; i++) {
        if (data[i].withdrawStatus == "Active") {
          var obj = {
            value: data[i]._id,
            label: data[i].currencySymbol,
            coinType: data[i].coinType,
            erc20token: data[i].erc20token,
            bep20token: data[i].bep20token,
            trc20token: data[i].trc20token,
            rptc20token: data[i].rptc20token,
            currencyName: data[i].currencyName,
            imgurl: data[i].Currency_image,
          };
          currArrayCrypto.push(obj);
        }
      }
      setallCurrency(currArrayCrypto);
    }
  };
  const onSelect1 = async (option) => {
    console.log(option, "-=-=-=-=option-=-=-=");
    setnewCurrency(option.label);
    setnewFullname(option.currencyName);
    setnewImage(option.imgurl);
  };
  const onSelect = async (option) => {
    formValue.withAddress = null;
    setwithdrawAddress("");
    setcurrAddres([]);
    console.log(currAddresref.current, "=-=currAddresref=--=-");
    setnet_default(null);
    setwithdrawAddress(null);
    setcur_network("");
    setcurrency(option.label);
    Setfullname(option.currencyName);
    setImage(option.imgurl);
    setcointype(option.coinType);
    setnet_current("");
    getAddress();
    let indexData = allCryptoref.current.findIndex(
      (x) => x._id == option.value
    );
    if (option.label == "USD") {
      toast.error("Fiat withdraw is not allowed by the site");
    } else {
      if (indexData != -1) {
        var currencydata = allCryptoref.current[indexData];
        setcurrentcurrency(currencydata);
        setcur_network([]);
        setnet_default("");
        var network_cur = {};
        var network_names = [];
        if (currencydata.currencyType == "2") {
          if (currencydata.erc20token == "1") {
            network_cur = {
              value: "erc20token",
              label: "ERC20",
            };
            network_names.push(network_cur);
          }
          if (currencydata.bep20token == "1") {
            network_cur = {
              value: "bep20token",
              label: "BEP20",
            };
            network_names.push(network_cur);
          }
          if (currencydata.trc20token == "1") {
            network_cur = {
              value: "trc20token",
              label: "TRC20",
            };
            network_names.push(network_cur);
          }

          if (currencydata.rptc20token == "1") {
            network_cur = {
              value: "rptc20token",
              label: "RPTC20",
            };
            network_names.push(network_cur);
          }
          setcur_network(network_names);
          // setnet_default(network_currencyref.current[0].label);
        }
        setwithdrawType(currencydata.coinType);
        // if (currencydata.coinType== "1") {
        var obj = {
          currency: option.label,
          currId: option.value,
        };
        var data = {
          apiUrl: apiService.user_balance,
          payload: obj,
        };

        var resp = await postMethod(data);
        if (resp.status) {
          setview(true);
          setBalance(resp.data);
        } else {
        }
        // } else {
        // }
      }
    }
  };

  const nav_page = async (link) => {
    navigate(link);
  };

  const validate = async (values) => {
    const errors = {};
    if (!values.amount) {
      errors.amount = "Amount is a required field";
      setamountValidate(true);
    }
    if (!values.withAddress) {
      errors.withAddress = "Destination address is a required field";
      setwithAddress(true);
    }
    if (!values.tfa) {
      errors.tfa = "2FA is a required field";
      settfaValidate(true);
    }

    if (!values.withdraw_otp) {
      errors.withdraw_otp = "Withdraw OTP is a required field";
      setotpValidate(true);
    }

    setvalidationnErr(errors);
    return errors;
  };
  const validateFiat = async (values) => {
    const errors = {};
    if (!values.amount) {
      errors.amount = "Amount is a required field";
      setamountValidate(true);
    }
    if (!values.tfa) {
      errors.tfa = "2FA is a required field";
      settfaValidate(true);
    }
    if (!values.withdraw_otp) {
      errors.withdraw_otp = "Withdraw OTP is a required field";
      setotpValidate(true);
    }
    setvalidationnErr(errors);
    return errors;
  };

  const validate_preview = async (values) => {
    const errors = {};
    if (!values.amount) {
      errors.amount = "Amount is a required field";
      setamountValidate(true);
    }
    if (!values.withAddress) {
      errors.withAddress = "Destination address is a required field";
      setwithAddress(true);
    }

    setvalidationnErr(errors);
    return errors;
  };
  const validateFiat_preview = async (values) => {
    const errors = {};
    if (!values.amount) {
      errors.amount = "Amount is a required field";
      setamountValidate(true);
    }
    setvalidationnErr(errors);
    return errors;
  };

  const validate_submit = async (values) => {
    const errors = {};
    if (!values.tfa) {
      errors.tfa = "2FA is a required field";
      settfaValidate(true);
    }

    if (!values.withdraw_otp) {
      errors.withdraw_otp = "Withdraw OTP is a required field";
      setotpValidate(true);
    }

    setvalidationnErr(errors);
    return errors;
  };
  const validateFiat_submit = async (values) => {
    const errors = {};
    if (!values.tfa) {
      errors.tfa = "2FA is a required field";
      settfaValidate(true);
    }
    if (!values.withdraw_otp) {
      errors.withdraw_otp = "Withdraw OTP is a required field";
      setotpValidate(true);
    }
    setvalidationnErr(errors);
    return errors;
  };

  const getwithdrawHistory = async (page) => {
    var data = {
      apiUrl: apiService.withdraw_history,
      payload: { FilPerpage: 5, FilPage: page },
    };
    var withdraw_history_list = await postMethod(data);
    if (withdraw_history_list) {
      setwithdrawHistory(withdraw_history_list.result);
    }
  };

  const getKYCstatus = async () => {
    var data = {
      apiUrl: apiService.getKYCStatus,
    };
    setSiteLoader(true);

    var getKYC = await getMethod(data);
    setSiteLoader(false);

    if (getKYC.status) {
      setkycStatus(getKYC.Message.kycstatus);
      setBankwire(getKYC.bankdatastatus);
    } else {
      setkycStatus(0);
    }
  };

  const onSelect_network = async (option) => {
    setNetworks(option.label);
    setnet_current(option.label);
  };
  const onSelect_address = async (option) => {
    console.log(option, "=-=-=-=option=-=-=-=-=option=-=-=-option=-=-=");
    setwithdrawAddress(option.label);
    formValue.withAddress = option.label;
  };

  const withdrawPreview = async () => {
    try {
      console.log(formValue, "=-=-=v=-formValue-=-formValue=-=-vformValue");
      formValue["withAddress"] = newAddres;
      console.log(withAddress, "withAddress");
      if (
        currentcurrency.currencySymbol == "USD" ||
        currentcurrency.currencySymbol == "INR"
      ) {
        if (bankwire == 1) {
          if (withdrawType == "2") {
            validateFiat_preview(formValue);
            if (formValue.amount != "") {
              if (+formValue.amount > 0) {
                if (+balanceref.current.balance > +formValue.amount) {
                  if (currentcurrency.minWithdrawLimit > formValue.amount) {
                    toast.error(
                      "Please enter greater than " +
                      currentcurrency.minWithdrawLimit +
                      " amount"
                    );
                  } else if (
                    currentcurrency.maxWithdrawLimit < formValue.amount
                  ) {
                    toast.error(
                      "Please enter less than " +
                      currentcurrency.maxWithdrawLimit +
                      " amount"
                    );
                  } else {
                    var data = {
                      apiUrl: apiService.send_otp,
                    };
                    setbuttonLoader(true);
                    var resp = await postMethod(data);
                    if (resp.status) {
                      toast.success(resp.message);
                      setbuttonLoader(false);
                      setshow_otp(true);
                    } else {
                      toast.error(resp.message);
                      setbuttonLoader(false);
                    }
                  }
                } else {
                  toast.error("Insufficient Balance!");
                  setbuttonLoader(false);
                }
              } else {
                toast.error("Please give valid withdraw amount!");
                setbuttonLoader(false);
              }
            }
          } else {

            validate_preview(formValue);
            if (formValue.amount != "" && formValue.withAddress != "") {
              if (+formValue.amount > 0) {
                if (+balanceref.current.balance > +formValue.amount) {
                  if (currentcurrency.minWithdrawLimit > formValue.amount) {
                    toast.error(
                      "Please enter greater than " +
                      currentcurrency.minWithdrawLimit +
                      " amount"
                    );
                  } else if (
                    currentcurrency.maxWithdrawLimit < formValue.amount
                  ) {
                    toast.error(
                      "Please enter less than " +
                      currentcurrency.maxWithdrawLimit +
                      " amount"
                    );
                  } else {
                    var data = {
                      apiUrl: apiService.send_otp,
                    };
                    setbuttonLoader(true);
                    var resp = await postMethod(data);
                    if (resp.status) {
                      toast.success(resp.message);
                      setbuttonLoader(false);
                      setshow_otp(true);
                    } else {
                      toast.error(resp.message);
                      setbuttonLoader(false);
                    }
                  }
                } else {
                  toast.error("Insufficient Balance");
                  setbuttonLoader(false);
                }
              } else {
                toast.error("Please give valid withdraw amount!");
              }
            }
          }
        } else {
          toast.error("Kindly update your Bank details");
          navigate("/bankdetails");
        }
      } else {
        if (withdrawType == "2") {
          validateFiat_preview(formValue);
          if (formValue.amount != "") {
            if (+formValue.amount > 0) {
              if (+balanceref.current.balance > +formValue.amount) {
                if (currentcurrency.minWithdrawLimit > formValue.amount) {
                  toast.error(
                    "Please enter greater than " +
                    currentcurrency.minWithdrawLimit +
                    " amount"
                  );
                } else if (
                  currentcurrency.maxWithdrawLimit < formValue.amount
                ) {
                  toast.error(
                    "Please enter less than " +
                    currentcurrency.maxWithdrawLimit +
                    " amount"
                  );
                } else {
                  var data = {
                    apiUrl: apiService.send_otp,
                  };
                  setbuttonLoader(true);
                  var resp = await postMethod(data);
                  if (resp.status) {
                    toast.success(resp.message);
                    setbuttonLoader(false);
                    setshow_otp(true);
                  } else {
                    toast.error(resp.message);
                    setbuttonLoader(false);
                  }
                }
              } else {
                toast.error("Insufficient Balance!");
                setbuttonLoader(false);
              }
            } else {
              toast.error("Please give valid withdraw amount!");
              setbuttonLoader(false);
            }
          }
        } else {
          validate_preview(formValue);
          if (formValue.amount != "" && formValue.withAddress != "") {
            if (+formValue.amount > 0) {
              if (+balanceref.current.balance > +formValue.amount) {
                if (currentcurrency.minWithdrawLimit > formValue.amount) {
                  toast.error(
                    "Please enter greater than " +
                    currentcurrency.minWithdrawLimit +
                    " amount"
                  );
                } else if (
                  currentcurrency.maxWithdrawLimit < formValue.amount
                ) {
                  toast.error(
                    "Please enter less than " +
                    currentcurrency.maxWithdrawLimit +
                    " amount"
                  );
                } else {
                  var data = {
                    apiUrl: apiService.send_otp,
                  };
                  setbuttonLoader(true);
                  var resp = await postMethod(data);
                  if (resp.status) {
                    toast.success(resp.message);
                    setbuttonLoader(false);
                    setshow_otp(true);
                  } else {
                    toast.error(resp.message);
                    setbuttonLoader(false);
                  }
                }
              } else {
                toast.error("Insufficient Balance");
                setbuttonLoader(false);
              }
            } else {
              toast.error("Please give valid withdraw amount!");
            }
          }
        }
      }
    } catch (error) { }
  };
  const withdrawSubmit = async () => {
    try {
      if (withdrawType == "2") {
        validateFiat_submit(formValue);

        if (localStorage.getItem("tfa_status") == 1) {
          if (formValue.amount != "" && formValue.tfa != "") {
            if (+formValue.amount > 0) {
              if (currentcurrency.minWithdrawLimit > formValue.amount) {
                toast.error(
                  "Please enter greater than " +
                  currentcurrency.minWithdrawLimit +
                  " amount"
                );
              } else if (currentcurrency.maxWithdrawLimit < formValue.amount) {
                toast.error(
                  "Please enter less than " +
                  currentcurrency.maxWithdrawLimit +
                  " amount"
                );
              } else {
                var obj = {
                  amount: formValue.amount,
                  tfaCode: formValue.tfa,
                  currency_symbol: currentcurrency.currencySymbol,
                  currId: currentcurrency._id,
                  withdrawOtp: formValue.withdraw_otp,
                  tfa_status: localStorage.getItem("tfa_status"),
                };
                var data = {
                  apiUrl: apiService.submitfiatWithdraw,
                  payload: obj,
                };
                setbuttonLoader(true);
                var resp = await postMethod(data);
                if (resp.status) {
                  toast.success(resp.message);
                  getwithdrawHistory(1);
                  setbuttonLoader(false);
                  setshow_otp(false);
                  formValue.amount = "";
                  setwithdrawAddress("");
                  formValue.tfa = "";
                  formValue.withdraw_otp = "";
                  //window.location.reload(false);
                } else {
                  toast.error(resp.message);
                  setbuttonLoader(false);
                  // formValue.amount = "";
                  // formValue.withAddress = "";
                  // formValue.tfa = "";
                  // formValue.withdraw_otp = "";
                  //window.location.reload(false);
                }
              }
            } else {
              toast.error("Please give valid withdraw amount!");
            }
          } else {
            toast.error("Please give all the fields !");
          }
        } else {
          if (formValue.amount != "") {
            if (+formValue.amount > 0) {
              if (currentcurrency.minWithdrawLimit > formValue.amount) {
                toast.error(
                  "Please enter greater than " +
                  currentcurrency.minWithdrawLimit +
                  " amount"
                );
              } else if (currentcurrency.maxWithdrawLimit < formValue.amount) {
                toast.error(
                  "Please enter less than " +
                  currentcurrency.maxWithdrawLimit +
                  " amount"
                );
              } else {
                var obj = {
                  amount: formValue.amount,
                  tfaCode: formValue.tfa,
                  currency_symbol: currentcurrency.currencySymbol,
                  currId: currentcurrency._id,
                  withdrawOtp: formValue.withdraw_otp,
                  tfa_status: localStorage.getItem("tfa_status"),
                };
                var data = {
                  apiUrl: apiService.submitfiatWithdraw,
                  payload: obj,
                };
                setbuttonLoader(true);
                var resp = await postMethod(data);
                if (resp.status) {
                  toast.success(resp.message);
                  getwithdrawHistory(1);
                  setbuttonLoader(false);
                  setshow_otp(false);
                  formValue.amount = "";
                  setwithdrawAddress("");
                  formValue.tfa = "";
                  formValue.withdraw_otp = "";
                  //window.location.reload(false);
                } else {
                  toast.error(resp.message);
                  setbuttonLoader(false);
                  // formValue.amount = "";
                  // formValue.withAddress = "";
                  // formValue.tfa = "";
                  // formValue.withdraw_otp = "";
                  //window.location.reload(false);
                }
              }
            } else {
              toast.error("Please give valid withdraw amount!");
            }
          } else {
            toast.error("Please give all the fields !");
          }
        }
      } else {
        validate_submit(formValue);

        if (localStorage.getItem("tfa_status") == 1) {
          if (
            formValue.amount != "" &&
            formValue.withAddress != "" &&
            formValue.tfa != ""
          ) {
            if (+formValue.amount > 0) {
              if (currentcurrency.minWithdrawLimit > formValue.amount) {
                toast.error(
                  "Please enter greater than " +
                  currentcurrency.minWithdrawLimit +
                  " amount"
                );
              } else if (currentcurrency.maxWithdrawLimit < formValue.amount) {
                toast.error(
                  "Please enter less than " +
                  currentcurrency.maxWithdrawLimit +
                  " amount"
                );
              } else {
                var obj = {
                  amount: formValue.amount,
                  withdraw_address: formValue.withAddress,
                  tfaCode: formValue.tfa,
                  currency_symbol: currentcurrency.currencySymbol,
                  currId: currentcurrency._id,
                  network: network_currentref.current,
                  withdrawOtp: formValue.withdraw_otp,
                  tfa_status: localStorage.getItem("tfa_status"),
                };
                // console.log("submit withdraw params---",obj);
                // return;
                var data = {
                  apiUrl: apiService.submitWithdraw,
                  payload: obj,
                };
                setbuttonLoader(true);
                var resp = await postMethod(data);
                if (resp.status) {
                  toast.success(resp.message);
                  getwithdrawHistory(1);
                  setbuttonLoader(false);
                  setshow_otp(false);
                  formValue.amount = "";
                  setwithdrawAddress("");
                  formValue.tfa = "";
                  formValue.withdraw_otp = "";
                  //window.location.reload(false);
                } else {
                  toast.error(resp.message);
                  setbuttonLoader(false);
                  // formValue.amount = "";
                  // formValue.withAddress = "";
                  // formValue.tfa = "";
                  //window.location.reload(false);
                }
              }
            } else {
              toast.error("Please give valid withdraw amount!");
            }
          } else {
            toast.error("Please give all the fields !");
          }
        } else {
          if (formValue.amount != "" && formValue.withAddress != "") {
            if (+formValue.amount > 0) {
              if (currentcurrency.minWithdrawLimit > formValue.amount) {
                toast.error(
                  "Please enter greater than " +
                  currentcurrency.minWithdrawLimit +
                  " amount"
                );
              } else if (currentcurrency.maxWithdrawLimit < formValue.amount) {
                toast.error(
                  "Please enter less than " +
                  currentcurrency.maxWithdrawLimit +
                  " amount"
                );
              } else {
                var obj = {
                  amount: formValue.amount,
                  withdraw_address: formValue.withAddress,
                  tfaCode: formValue.tfa,
                  currency_symbol: currentcurrency.currencySymbol,
                  currId: currentcurrency._id,
                  network: network_currentref.current,
                  withdrawOtp: formValue.withdraw_otp,
                  tfa_status: localStorage.getItem("tfa_status"),
                };
                // console.log("submit withdraw params---",obj);
                // return;
                var data = {
                  apiUrl: apiService.submitWithdraw,
                  payload: obj,
                };
                setbuttonLoader(true);
                var resp = await postMethod(data);
                if (resp.status) {
                  toast.success(resp.message);
                  getwithdrawHistory(1);
                  setbuttonLoader(false);
                  setshow_otp(false);
                  formValue.amount = "";
                  setwithdrawAddress("");
                  formValue.tfa = "";
                  formValue.withdraw_otp = "";
                  //window.location.reload(false);
                } else {
                  toast.error(resp.message);
                  setbuttonLoader(false);
                  // formValue.amount = "";
                  // formValue.withAddress = "";
                  // formValue.tfa = "";
                  //window.location.reload(false);
                }
              }
            } else {
              toast.error("Please give valid withdraw amount!");
            }
          }
        }
      }
    } catch (error) { }
  };

  const [newCurrency, setnewCurrency, newCurrencyref] = useState("USDT");
  const [newCurrencyErr, setnewCurrencyErr] = useState(false);
  const [newNetwork, setnewNetwork] = useState("");
  const [alertTab, setalertTab] = useState("hide");
  const [allAddress, setallAddress, allAddressref] = useState([]);
  const [currAddres, setcurrAddres, currAddresref] = useState([]);
  const [withdrawAddress, setwithdrawAddress, withdrawAddressref] = useState();
  const [addnewadd, Setaddnewadd, addnewaddref] = useState(false);

  const getAddress = async () => {
    var data = {
      apiUrl: apiService.getAddress,
    };
    var resp = await getMethod(data);
    if (resp.status == true) {
      setallAddress(resp.data);
      var data = [];
      for (let i = 0; i < resp.data.length; i++) {
        const element = resp.data[i];
        if (currencyref.current == element.currency) {
          var dropData = {
            value: element.currency,
            label: element.address,
          };
          data.push(dropData);
        }
        setcurrAddres(data);
      }
    } else {
      setallAddress([]);
    }
  };
  const Addaddress = async () => {
    Setaddnewadd(false);
    if (newAddres == "") {
      setnewAddresErr(true);
    } else if (newCurrency == "") {
      setnewCurrencyErr(true);
      setnewAddresErr(false);
    } else {
      setnewCurrencyErr(false);
      var obj = {
        Address: newAddres,
        currency: newCurrency,
        network: newNetwork,
      };
      var data = {
        apiUrl: apiService.Addaddress,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status == true) {
        toast.success(resp.message);
        setnewAddres("");
        setnewCurrency("");
        setnewNetwork("");
        getAddress();
      } else {
        toast.error(resp.message);
      }
    }
  };

  const copy = async (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Address copied");
  };

  const addnewaddress = async () => {
    Setaddnewadd(true);
  };

  const addAddress = async (_id) => {
    setnewAddres(_id.address);
    formValue["withAddress"] = _id.address;
  };

  const deleteAddress = async (_id) => {
    alert("Are You Delete This Address")
    var data = {
      apiUrl: apiService.removeAddress,
      payload: { _id: _id },
    };
    var resp = await postMethod(data);
    if (resp.status == true) {
      toast.success(resp.message);
      getAddress();
    } else {
      toast.error(resp.message);
    }
  };

  const cancel = () => {
    setalertTab("show");
    navigate("/dashboard");
  };
  const agree = () => {
    setalertTab("remove");
    localStorage.setItem("withdraw_status", "true");
  };

  const getSiteSettingstatus = async () => {
    var data = {
      apiUrl: apiService.getSiteDatas,
    };
    var response = await getMethod(data);
    if (response.status) {
      setsitekycStatus(response.data.kycStatus);
    } else {
      setkycStatus(0);
    }
  };
  return (
    <div className="header ">
      <Header />
      <div>
        <div className="body_hXuuO ">
          <div className="lrtcss-wxjc90 e161ieae2">
            <div className="lrtcss-q484j3 e161ieae1">
              <div className="KuxMenu-root lrtcss-yqpjhy">
                <Sidebar />
              </div>
            </div>
            <div className="lrtcss-1p2kb7b e161ieae0">
              <div className="lrtcss-sbd8h eagejp24">
                {siteLoader == true ? (
                  <i class="fa-solid fa-spinner fa-spin"></i>
                ) : (
                  <>
                    <section className="deposit_crypto_section">
                      <div className="row">
                        <div className="col-lg-8 ">
                          <div className="deposit_crypto_header">
                            <div className="deposit_left_heading">
                              <h2>Withdraw Crypto</h2>
                            </div>
                            {/* <div className="deposit_right_btn">
                          <button>
                            Deposit Fiat <i class="ri-arrow-right-line"></i>
                          </button>
                        </div> */}
                          </div>

                          {kycStatus == 1 ? (
                            <section className="select_coin_section ">
                              <div className="select-coin mt-5">
                                <div className="select_content mb-4 ">
                                  <div className="select_text">Select Coin</div>
                                  <div
                                    className="selection_dropdown"
                                    data-toggle="modal"
                                    data-target="#myModal"
                                  >
                                    <div className="slt_dropdown">
                                      {Imageref.current == "" ? (
                                        ""
                                      ) : (
                                        <img src={Imageref.current} />
                                      )}
                                      <span className="spn_usdt">
                                        {currencyref.current == "" ||
                                          currencyref.current == null ||
                                          currencyref.current == undefined
                                          ? "Select Currency"
                                          : currencyref.current}
                                      </span>
                                      <span className="spn_tether">
                                        {Fullnameref.current == "" ||
                                          Fullnameref.current == null ||
                                          Fullnameref.current == undefined
                                          ? ""
                                          : Fullnameref.current}
                                      </span>
                                    </div>
                                    <div className="drp_down_logo">
                                      <i class="ri-arrow-down-s-line"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {show_otpref.current == false &&
                                currentcurrencyref.current.currencyType == "2" ? (
                                <section className="select_network_section">
                                  <div className="select_content">
                                    <div className="select_text">
                                      Select a network
                                    </div>
                                    <div
                                      className="selection_dropdown"
                                      data-toggle="modal"
                                      data-target="#selectnetwork"
                                    >
                                      <div className="slt_dropdown">
                                        <i class="ri-search-line"></i>

                                        {Networksref.current == "" ? (
                                          <span className="spn_tether">
                                            Select Network
                                          </span>
                                        ) : (
                                          <span className="spn_tether">
                                            {Networksref.current}
                                          </span>
                                        )}
                                      </div>
                                      <div className="drp_down_logo">
                                        <i class="ri-arrow-down-s-line"></i>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              ) : (
                                ""
                              )}

                              {withdrawType == "1" &&
                                show_otpref.current == false ? (
                                <section className="select_network_section">
                                  <div className="select_content mb-4 ">
                                    <div className="select_text">
                                      To Address
                                    </div>
                                    <div className="selection_dropdown">
                                      <div className="slt_dropdown">
                                        <input
                                          type="text"
                                          placeholder="Enter To Address"
                                          name="Address"
                                          value={newAddres}
                                          onChange={handleChangs}


                                          className="walletr_inpur"
                                        />
                                      </div>
                                      <div
                                        className="drp_down_logo text_purble"
                                        data-toggle="modal"
                                        data-target="#Adresss"
                                      >
                                        Saved Addresses
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    {withAddressValidate == true ? (
                                      <p className="text-danger">
                                        {" "}
                                        {validationnErr.withAddress}{" "}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>{" "}
                                </section>
                              ) : (
                                ""
                              )}

                              {show_otpref.current == false ? (
                                <section className="select_network_section">
                                  <div className="select_content mb-4 ">
                                    <div className="select_text"> Amount</div>
                                    <div className="selection_dropdown">
                                      <div className="slt_dropdown">
                                        <input
                                          type="number"
                                          pattern="[0-9]*"
                                          onKeyDown={(evt) =>
                                            ["e", "E", "+", "-"].includes(
                                              evt.key
                                            ) && evt.preventDefault()
                                          }
                                          placeholder="Enter Amount"
                                          min={1}
                                          minLength={1}
                                          name="amount"
                                          value={amount}
                                          onChange={handleChange}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    {amountValidate == true ? (
                                      <p className="text-danger">
                                        {" "}
                                        {validationnErr.amount}{" "}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </section>
                              ) : (
                                ""
                              )}

                              {show_otpref.current == true ? (
                                <div div className="mt-3">
                                  {localStorage.getItem("tfa_status") == 0 ||
                                    1 ? (
                                    <>
                                      <section className="select_network_section">
                                        <div className="select_content mb-4 ">
                                          <div className="select_text">2FA</div>
                                          <div className="selection_dropdown">
                                            <div className="slt_dropdown">
                                              <input
                                                type="text"
                                                placeholder="Enter 2FA Code"
                                                name="tfa"
                                                value={tfa}
                                                onChange={handleChange}
                                                className="walletr_inpur"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div>
                                          {tfaValidate == true ? (
                                            <p className="text-danger">
                                              {" "}
                                              {validationnErr.tfa}{" "}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </section>
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  <section className="select_network_section">
                                    <div className="select_content mb-4 ">
                                      <div className="select_text">
                                        Withdraw OTP
                                      </div>
                                      <div className="selection_dropdown">
                                        <div className="slt_dropdown">
                                          <input
                                            type="text"
                                            placeholder="Enter Withdraw OTP"
                                            name="withdraw_otp"
                                            value={withdraw_otp}
                                            onChange={handleChange}
                                            className="walletr_inpur"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      {otpValidate == true ? (
                                        <p className="text-danger">
                                          {" "}
                                          {validationnErr.withdraw_otp}{" "}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </section>
                                </div>
                              ) : (
                                ""
                              )}

                              {show_otpref.current == false &&
                                viewref.current == true ? (
                                <div className="balance_balance w-100">
                                  <div className="withdraw_fees">
                                    <div className="fees_text">Fees </div>
                                    <div className="fees_value">
                                      {currentcurrencyref.current.withdrawFee} %
                                    </div>
                                  </div>

                                  <div className="withdraw_fees">
                                    <div className="fees_text">
                                      Minimum withdraw{" "}
                                    </div>
                                    <div className="fees_value">
                                      {
                                        currentcurrencyref.current
                                          .minWithdrawLimit
                                      }{" "}
                                    </div>
                                  </div>

                                  <div className="withdraw_fees">
                                    <div className="fees_text">
                                      Maximum withdraw{" "}
                                    </div>
                                    <div className="fees_value">
                                      {
                                        currentcurrencyref.current
                                          .maxWithdrawLimit
                                      }{" "}
                                    </div>
                                  </div>



                                  {balanceref.current.network != "" ? (

                                    <div className="withdraw_fees">
                                      <div className="fees_text">
                                        Network{" "}
                                      </div>
                                      <div className="fees_value">
                                        {balanceref.current.network}{" "}
                                      </div>
                                    </div>

                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}

                              {buttonLoader == false ? (
                                <div className="flexbutton">
                                  {localStorage.getItem("tfa_status") == 0 ? (
                                    <Button
                                      className="priomary"
                                      onClick={() => nav_page("/security")}
                                    >
                                      Enable 2FA
                                    </Button>
                                  ) : show_otpref.current == true ? (
                                    <Button
                                      className="priomary"
                                      onClick={() => withdrawSubmit()}
                                    >
                                      Submit
                                    </Button>
                                  ) : (
                                    <Button
                                      className="priomary"
                                      onClick={() => withdrawPreview()}
                                    >
                                      Submit
                                    </Button>
                                  )}
                                </div>
                              ) : (
                                <div className="submit_btn">
                                  <Button className="priomary">
                                    Loading...
                                  </Button>
                                </div>
                              )}
                            </section>
                          ) : (
                            <div className="warning_content faq_list">
                              <h2>
                                Please complete the KYC verification process to
                                gain access to the page.
                              </h2>
                              <Link to="/Identity">Verify KYC Now</Link>
                            </div>
                          )}
                        </div>

                        <div className="col-lg-4">
                          <section className="faq-section">
                            <div className="faq_list">
                              <h3>FAQ</h3>

                              <div className="Accordion">
                                <Accordion className="Accordionfaq">
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                  >1. How do I withdraw crypto from my Sellcrypt account?
                                  </AccordionSummary>
                                  <AccordionDetails>If you enable two-factor authentication, you will receive a withdrawal OTP after submitting your withdrawal request. You will need to wait for the admin's approval, which typically takes up to 24 hours. Once the admin approves the transaction, the withdrawal will be processed.</AccordionDetails>
                                </Accordion>
                                <Accordion className="Accordionfaq">
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                  >2. What should I do if I didn't receive my withdraw or I withdraw  to an incorrect address?</AccordionSummary>
                                  <AccordionDetails>  If you entered the wrong withdrawal address, please contact our team within 24 hours. Your funds will be returned, but a fee may apply.</AccordionDetails>
                                </Accordion>

                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </section>

                    {kycStatus == 1 ? (
                      <section className="Deposit_history_section">
                        <h3 className="deposit_table_title mt-4">
                          Recent Withdrawals
                        </h3>
                        <div className="deposit_history">
                          <table class="table market">
                            <thead>
                              <tr class="market-header deposit">
                                <th scope="col">Date</th>
                                <th scope="col"> Time</th>
                                <th scope="col"> Currency</th>
                                <th scope="col"> Amount</th>
                                <th scope="col"> Address</th>
                                <th scope="col"> Transaction Id</th>
                                <th scope="col"> Status</th>
                              </tr>
                            </thead>

                            <tbody>
                              {withdrawHistory && withdrawHistory.length > 0 ? (
                                withdrawHistory.map((item, i) => {
                                  return (
                                    <tr>
                                      <td>
                                        {Moment(item.created_at).format(
                                          "DD.MM.YYYY"
                                        )}
                                      </td>
                                      <td>
                                        {Moment(item.created_at).format(
                                          "HH:mm a"
                                        )}
                                      </td>
                                      <td>{item.currency}</td>
                                      <td>
                                        {parseFloat(item.amount).toFixed(8)}
                                      </td>
                                      <td>
                                        <p className="hash_code">
                                          {item.withdraw_address}
                                        </p>
                                      </td>
                                      <td>
                                        <p className="hash_code">
                                          {item.txn_id}
                                        </p>
                                      </td>
                                      <td>
                                        {item.status == "Completed" ? (
                                          <span className="text-green">
                                            {item.status}
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                        {item.status !== "Completed" ? (
                                          <span className="text-red">
                                            {item.status}
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan={7}>
                                    <div className="empty_data">
                                      <div className="empty_data_img">
                                        <img
                                          src={require("../Image/Table_image/no-record-light.png")}
                                        />
                                      </div>

                                      <div className="no_records_text">
                                        No Records Found
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </section>
                    ) : (
                      ""
                    )}
                  </>
                )}

                <div class="modal fade " id="Adresss" role="dialog">
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content select_coin">
                      <div class="modal-header">
                        <h4 class="modal-title">Select Address</h4>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                        >
                          &times;
                        </button>
                      </div>
                      <div class="modal-body">
                        {addnewaddref.current == false ? (
                          <>
                            {allAddressref.current &&
                              allAddressref.current.length > 0 ? (
                              allAddressref.current.map((data, i) => {
                                return (
                                  <div class="formbold-file-list formbold-mb-5">
                                    <div class="formbold-file-item">
                                      <span
                                        class="formbold-file-name"
                                        onClick={() => addAddress(data)}
                                        data-dismiss="modal"
                                      >
                                        {data.address} {data.currency}
                                      </span>
                                      <button>
                                        <i
                                          class="ri-delete-bin-line red-color"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            deleteAddress(data._id)
                                          }
                                        ></i>
                                      </button>
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <div className="content_vallet">
                                <img
                                  src="https://assets.staticimg.com/kucoin-main-web/2.3.30/media/86ff4ecaa2d1ec6ddaf3.png"
                                  className="image_venter"
                                />
                                <p>
                                  Save your frequently used addresses to the
                                  address book for convenient withdrawals.
                                  Differentiate addresses by adding remarks.
                                </p>
                              </div>
                            )}

                            <div class="modal-footer">
                              <button
                                type="button"
                                class="btn btn-default"
                                onClick={addnewaddress}
                              >
                                Add New Address
                              </button>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="coin_form card_login border_non p-0">
                              <div className="select-coin">
                                <div className="select_content mb-4 ">
                                  <div className="select_text">Select Coin</div>
                                  <div
                                    className="selection_dropdown"
                                    data-toggle="modal"
                                    data-target="#coinmyModal"
                                  >
                                    <div className="slt_dropdown">
                                      {newImageref.current == "" ? (
                                        <img
                                          src={require("../Image/Table_image/chat_logo.png")}
                                        />
                                      ) : (
                                        <img src={newImageref.current} />
                                      )}
                                      <span className="spn_usdt">
                                        {newCurrencyref.current}
                                      </span>
                                      <span className="spn_tether">
                                        {newFullnameref.current}
                                      </span>
                                    </div>
                                    <div className="drp_down_logo">
                                      <i class="ri-arrow-down-s-line"></i>
                                    </div>
                                  </div>
                                  {newImageref.current == "" ? (
                                    <p style={{ color: "red" }}>
                                      Currency is required
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div>
                                <label className="lable_new_section">
                                  Wallet Address
                                </label>
                                <div class="form_login_input">
                                  <input
                                    type="text"
                                    placeholder="Enter To Address"
                                    name="Address"
                                    value={newAddres}
                                    onChange={(e) =>
                                      setnewAddres(e.target.value)
                                    }
                                    class="form-control"
                                  />
                                </div>
                                {newAddresErr == true ? (
                                  <p style={{ color: "red" }}>
                                    Address is required
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div>
                                <label className="lable_new_section">
                                  Network
                                </label>
                                <div class="form_login_input">
                                  <input
                                    class="form-control"
                                    type="text"
                                    placeholder="Enter network (Optional)"
                                    value={newNetwork}
                                    onChange={(e) =>
                                      setnewNetwork(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              {/* <div>
                                <label className="lable_new_section">
                                  Wallet Remarks
                                </label>
                                <div class="form_login_input">
                                  <input
                                    type="text"
                                    name="email"
                                    class="form-control"
                                    placeholder="Email address"
                                  />
                                </div>
                              </div> */}
                            </div>

                            <div class="modal-footer">
                              <button
                                type="button"
                                class="btn btn-default"
                                onClick={Addaddress}
                              >
                                Add Address
                              </button>
                            </div>
                          </>
                        )}

                        {/* <div class="formbold-file-list formbold-mb-5">
                          <div class="formbold-file-item">
                            <span class="formbold-file-name">
                              {" "}
                              243rHBHEBDFJHBUVhbdfye79q8w...
                            </span>
                            <button>
                              <i class="ri-delete-bin-line red-color"></i>
                            </button>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="modal fade " id="myModal" role="dialog">
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content select_coin">
                      <div class="modal-header">
                        <h4 class="modal-title">Coin</h4>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                        >
                          &times;
                        </button>
                      </div>
                      <div class="modal-body">
                        {/* <div className="selection_dropdown">
                          <div className="slt_dropdown">
                            <i class="ri-search-line"></i>

                            <span className="spn_tether">Search</span>
                          </div>
                        </div> */}

                        <section
                          className="top_coin_section"
                          data-dismiss="modal"
                        >
                          <h3>Top</h3>
                          <p>
                            {allCurrencyref.current &&
                              allCurrencyref.current.length > 0
                              ? allCurrencyref.current
                                .slice(0, 5)
                                .map((item, i) => {
                                  return (
                                    <span
                                      className="top_coin_item"
                                      onClick={() => onSelect(item)}
                                    >
                                      {" "}
                                      {item.label}{" "}
                                    </span>
                                  );
                                })
                              : ""}
                          </p>
                        </section>

                        <section
                          className="cryptocoin_items"
                          data-dismiss="modal"
                        >
                          {allCurrencyref.current &&
                            allCurrencyref.current.length > 0
                            ? allCurrencyref.current.map((item, i) => {
                              return (
                                <div
                                  className="cryptocoin_list"
                                  onClick={() => onSelect(item)}
                                >
                                  <div className="cryptocoin_logo">
                                    <img src={item.imgurl} />
                                  </div>
                                  <div className="list_content">
                                    <h3>{item.label}</h3>
                                    <span> {item.currencyName} </span>
                                  </div>
                                </div>
                              );
                            })
                            : ""}
                        </section>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="modal fade " id="coinmyModal" role="dialog">
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content select_coin">
                      <div class="modal-header">
                        <h4 class="modal-title">Coin</h4>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                        >
                          &times;
                        </button>
                      </div>
                      <div class="modal-body">
                        <section
                          className="cryptocoin_items"
                          data-dismiss="modal"
                        >
                          {allCurrencyref.current &&
                            allCurrencyref.current.length > 0
                            ? allCurrencyref.current.map((item, i) => {
                              return (
                                <div
                                  className="cryptocoin_list"
                                  onClick={() => onSelect1(item)}
                                >
                                  <div className="cryptocoin_logo">
                                    <img src={item.imgurl} />
                                  </div>
                                  <div className="list_content">
                                    <h3>{item.label}</h3>
                                    <span> {item.currencyName} </span>
                                  </div>
                                </div>
                              );
                            })
                            : ""}
                        </section>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="modal fade " id="selectnetwork" role="dialog">
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content select_coin">
                      <div class="modal-header">
                        <h4 class="modal-title">Select Network</h4>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                        >
                          &times;
                        </button>
                      </div>
                      <div class="modal-body">
                        <div className="selection_network_warning">
                          <p>
                            <i class="ri-error-warning-line"></i>Ensure that the
                            selected deposit network is the same as the
                            withdrawal network. Otherwise, your assets could be
                            lost.
                          </p>
                        </div>

                        <section
                          className="cryptocoin_items"
                          data-dismiss="modal"
                        >
                          {network_currencyref.current &&
                            network_currencyref.current.length > 0
                            ? network_currencyref.current.map((item, i) => {
                              console.log(item, "network");
                              return (
                                <div
                                  className="cryptocoin_list"
                                  onClick={() => onSelect_network(item)}
                                >
                                  <div className="list_content">
                                    <h3> {item.label} </h3>
                                    <span> {item.value} </span>
                                  </div>
                                </div>
                              );
                            })
                            : ""}
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
