import React, { useEffect } from "react";
import Header from "./Header";
import { Button, Container } from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import { Link, useNavigate } from "react-router-dom";
// import Pagination from "./pagination";
import Dropdown from "react-dropdown";
import useState from "react-usestateref";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import Pagination from "react-js-pagination";
var moment = require("moment");



function Home() {
  const options = [
    {
      value: 1,
      label: "Leanne Graham",
    },
    {
      value: 2,
      label: "Ervin Howell",
    },
  ];
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const [perpage, setperpage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [stakeDetails, setstakeDetails] = useState([]);
  const [totalactive, settotalactive] = useState(0);
  const [staking1, setstaking1] = useState("");
  const [staking2, setstaking2] = useState("");
  const [staking3, setstaking3] = useState("");
  const [staking4, setstaking4] = useState("");
  const [userBalance, setuserBalance] = useState([]);
  const [currentPack, setcurrentPack] = useState("");
  const [stakeBalance, setStakeBalance] = useState("");
  const [fromDates, setfromData] = useState("");
  const [toDates, settoDate] = useState("");
  const [apy, setAPY] = useState(0);
  const [apr, setAPR] = useState(0);
  const [FlexibleEarn, setFlexibleEarn] = useState(0);

  const [authToken, setauthToken] = useState(false);
  const [stakeValue, setstakeValue] = useState(0);
  const [currentDuration, setcurrentDuration] = useState(0);
  const [currentDurationFlex, setcurrentDurationFlex] = useState(0);
  const [TotalFlexible, setTotalFlexible] = useState(0);

  const [userTotlaInterest, setuserTotlaInterest] = useState(0);
  const [usetDailyInterest, setusetDailyInterest] = useState(0);
  const [stakeHistory, setstakeHistory, stakeHistoryref] = useState([]);
  const [validation, setValidation] = useState(false);
  const [validationErr, setValidationErr] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [fixedvalue, setFixedValue] = useState(4);
  const [totalStakedValue, settotalStakedValue] = useState(0);
  const [totallockedValue, settotallockedValue] = useState(0);
  const [totalStakedValueINR, settotalStakedValueINR] = useState(0);
  const [totallockedValueINR, settotallockedValueINR] = useState(0);
  const [stakedcurrency, setStakedCurrency] = useState(0);
  const [interest, setInterest] = useState(0);
  const [stakingType, setstakingType] = useState("fixed");
  const [selectedFlex, setselectedFlex] = useState("");
  const [adminProfit, setAdminprofit] = useState(0);
  const [flexibleStartDate, setflexibleStartDate] = useState("");
  const [flexibleEndDate, setflexibleEndDate] = useState("");

  const [yieldDuration, setyieldDuration] = useState(0);
  const [yiledSelectDur, setyiledSelectDur] = useState("");

  const [yieldID, setyieldID] = useState("");
  const [yieldData, setYieldData] = useState("");
  const [currentYieldYear, setcurrentYieldYear] = useState(365);
  const [yieldAPY, setyieldAPY] = useState(0);
  const [yiledToDate, setyiledToDate] = useState("");
  const [yiledStakeType, setyiledStakeType] = useState(0);
  const [yiledDays, setYiledDays] = useState(0);
  const [YieldEstimation, setYieldEstimation] = useState(0);
  const [YieldValue, setYieldValue] = useState(0);

  const [YieldFirst, setYieldFirst] = useState(0);
  const [YieldSecond, setYieldSecond] = useState(0);
  const [YieldThird, setYieldThird] = useState(0);
  const [YieldFourth, setYieldFourth] = useState(0);

  const [stake_type, set_type, stake_typeref] = useState("");
  const [siteLoader, setSiteLoader] = useState(false);

  const recordPerPage = 10;
  const pageRange = 10;
  const navigate = useNavigate();

  const data = [
    {
      value: "",
      label: "All",
    },
    {
      value: "fixed",
      label: "Fixed",
    },
    {
      value: "Flexible",
      label: "Flexible",
    },
    {
      value: "yield",
      label: "Yield",
    },
  ];

  useEffect(() => {
    let getToken = localStorage.getItem("user_token");
    if (getToken != "" && getToken != undefined && getToken != null) {
      setauthToken(true);
      getBalance();
      getStakingHistory();
      getStakeTotalDetails();
      getTodayDate();
    } else {
      setauthToken(false);
    }

    getStakeDetails(1);
  }, [0]);

  const getTodayDate = async () => {
    var d = new Date();
    var fromDate =
      (await d.getDate()) +
      "/" +
      (d.getMonth() + 1) +
      "/" +
      d.getFullYear() +
      " " +
      d.getHours() +
      ":" +
      d.getMinutes();
    setfromData(fromDate);
    setflexibleStartDate(fromDate);

    var myDate = new Date(d.getTime() + 365 * 24 * 60 * 60 * 1000);

    var toDate =
      (await myDate.getDate()) +
      "/" +
      (myDate.getMonth() + 1) +
      "/" +
      myDate.getFullYear() +
      " " +
      myDate.getHours() +
      ":" +
      myDate.getMinutes();
    setflexibleEndDate(toDate);
  };

  const getStakeDetails = async (pages) => {
    var obj = {
      FilPerpage: perpage,
      FilPage: pages,
    };
    var data = {
      apiUrl: apiService.getStatkingDetails,
      payload: obj,
    };
    var resp = await postMethod(data);
    if (resp.status) {
      var datas = resp.data.result;
      settotalactive(resp.data.count);
      console.log(datas, "=-=-=-=-data");
      setstakeDetails(datas);
    } else {
      setstakeDetails([]);
    }
  };

  const activePageChange = (pageNumber) => {
    setCurrentPage(pageNumber); // call API to get data based on pageNumber
    getStakeDetails(pageNumber);
  };

  const chooseDuration = async (selectedduration, data, duration) => {
    try {
      console.log(
        selectedduration,
        "=-==-selectedduration-=-= ",
        data,
        duration
      );
      getStakeTotalDetails(data.currencySymbol);
      setcurrentDuration(+duration);
      if (selectedduration == "stake1") {
        setAPY(data.FistDurationAPY);
      } else if (selectedduration == "stake2") {
        setAPY(data.SecondDurationAPY);
      } else if (selectedduration == "stake3") {
        setAPY(data.ThirdDurationAPY);
      } else if (selectedduration == "stake4") {
        setAPY(data.FourthDurationAPY);
      } else {
        setAPY(0);
      }
      var obj = {
        status: selectedduration,
        id: data._id,
      };
      setstaking1(obj);

      dataCalculation(selectedduration, data, duration);
    } catch (error) {}
  };

  const chooseDurationYield = async (selectedduration, data) => {
    try {
      if (yiledStakeType == 1) {
        if (selectedduration == "stake1") {
          setyieldAPY(data.yiledAPR_1_firstDuration);
          setyieldDuration(data.yiled_1_firstDuration);
        } else if (selectedduration == "stake2") {
          setyieldAPY(data.yiledAPR_1_secondDuration);
          setyieldDuration(data.yiled_1_secondDuration);
        } else if (selectedduration == "stake3") {
          setyieldAPY(data.yiledAPR_1_thirdDuration);
          setyieldDuration(data.yiled_1_thirdDuration);
        } else if (selectedduration == "stake4") {
          setyieldAPY(data.yiledAPR_1_fourthDuration);
          setyieldDuration(data.yiled_1_fourthDuration);
        } else {
          setyieldAPY(0);
        }
      } else if (yiledStakeType == 3) {
        if (selectedduration == "stake1") {
          setyieldAPY(data.yiledAPR_3_firstDuration);
          setyieldDuration(data.yiled_3_firstDuration);
        } else if (selectedduration == "stake2") {
          setyieldAPY(data.yiledAPR_3_secondDuration);
          setyieldDuration(data.yiled_3_secondDuration);
        } else if (selectedduration == "stake3") {
          setyieldAPY(data.yiledAPR_3_thirdDuration);
          setyieldDuration(data.yiled_3_thirdDuration);
        } else if (selectedduration == "stake4") {
          setyieldAPY(data.yiledAPR_3_fourthDuration);
          setyieldDuration(data.yiled_3_fourthDuration);
        } else {
          setyieldAPY(0);
        }
      } else if (yiledStakeType == 5) {
        if (selectedduration == "stake1") {
          setyieldAPY(data.yiledAPR_5_firstDuration);
          setyieldDuration(data.yiled_5_firstDuration);
        } else if (selectedduration == "stake2") {
          setyieldAPY(data.yiledAPR_5_secondDuration);
          setyieldDuration(data.yiled_5_secondDuration);
        } else if (selectedduration == "stake3") {
          setyieldAPY(data.yiledAPR_5_thirdDuration);
          setyieldDuration(data.yiled_5_thirdDuration);
        } else if (selectedduration == "stake4") {
          setyieldAPY(data.yiledAPR_5_fourthDuration);
          setyieldDuration(data.yiled_5_fourthDuration);
        } else {
          setyieldAPY(0);
        }
      }

      setyieldID(data._id);
      setYieldData(data);
      // var chkDuration = duration == 30 ? "Monthly" : duration == 90 ? "Quarterly" : duration == 180 ? "Half Yearly" : "Yearly"
      // setyiledSelectDur(chkDuration);
      //setYiledDays(duration);
    } catch (error) {}
  };

  const chooseDurationFlexible = async (selectedduration, data, duration) => {
    try {
      console.log(
        selectedduration,
        "=-==-selectedduration-=-= ",
        data,
        duration
      );
      setselectedFlex(data);
      getStakeTotalDetails(data.currencySymbol);
      setcurrentDurationFlex(+duration);
      if (selectedduration == "stake1") {
        setInterest(data.APRinterest);
        setAPR(data.firstInterest);
        setAdminprofit(data.firstProfit);
      } else if (selectedduration == "stake2") {
        setInterest(data.APRinterest);
        setAPR(data.secondInterest);
        setAdminprofit(data.secondProfit);
      } else if (selectedduration == "stake3") {
        setInterest(data.APRinterest);
        setAPR(data.thirdInterst);
        setAdminprofit(data.thirdProfit);
      } else if (selectedduration == "stake4") {
        setInterest(data.APRinterest);
        setAPR(data.fourthInterest);
        setAdminprofit(data.fourthProfit);
      } else {
        setInterest(0);
        setAPR(0);
      }
      var obj = {
        status: selectedduration,
        id: data._id,
      };
      setstaking2(obj);

      dataCalculation(selectedduration, data, duration);
    } catch (error) {}
  };

  const dataCalculation = async (stakePosition, statkeDetails, duration) => {
    console.log("=-=-duration=-=", duration);
    try {
      var plans = parseFloat(duration);
      var d = new Date();
      var fromDate =
        (await d.getDate()) +
        "/" +
        (d.getMonth() + 1) +
        "/" +
        d.getFullYear() +
        " " +
        d.getHours() +
        ":" +
        d.getMinutes();
      setfromData(fromDate);

      var myDate = new Date(new Date().getTime() + plans * 24 * 60 * 60 * 1000);

      var toDate =
        (await myDate.getDate()) +
        "/" +
        (myDate.getMonth() + 1) +
        "/" +
        myDate.getFullYear() +
        " " +
        myDate.getHours() +
        ":" +
        myDate.getMinutes();
      if (stakingType == "yield") {
        setyiledToDate(toDate);
      } else {
        settoDate(toDate);
      }
    } catch (error) {
      toast.error("Please try again later");
    }
  };

  const getBalance = async () => {
    try {
      var data = {
        apiUrl: apiService.getUserBalanceAll,
      };
      var resp = await getMethod(data);
      if (resp.status) {
        setuserBalance(resp.data);
        console.log(resp.data, "=-=-resp-=-=-=resp-=-resp.data");
      }
    } catch (error) {
      // toast.error("Please try again later");
    }
  };

  // const getStakingHistory = async () => {
  //   try {
  //     var data = {
  //       apiUrl: apiService.getAllstakingHistory,
  //     };
  //     var resp = await getMethod(data);
  //     if (resp.status) {
  //       // setuserBalance(resp.data);
  //       console.log(resp.data, "=-=-resp-=-=-=resp-=-resp.data");
  //       setstakeHistory(resp.data);
  //     }
  //   } catch (error) {
  //     // toast.error("Please try again later");
  //   }
  // };

  const getStakingHistory = async () => {
    try {
      var data = {
        apiUrl: apiService.getAllstakingHistory,
        payload: { type: stake_typeref.current },
      };
      var resp = await postMethod(data);
      if (resp.status) {
        // setuserBalance(resp.data);
        console.log(resp.data, "=-=-resp-=-=-=resp-=-resp.data");
        setstakeHistory(resp.data);
      }
    } catch (error) {
      // toast.error("Please try again later");
    }
  };

  const stakeNow = (currentPack, type) => {
    try {
      setstakeValue(0);
      setuserTotlaInterest(0);
      setFlexibleEarn(0);
      setInterest(currentPack.APRinterest);
      setstakingType(type);
      console.log(currentPack);
      setcurrentPack(currentPack);
      var index = userBalance.findIndex(
        (x) => x.currencySymbol == currentPack.currencySymbol
      );
      if (index != -1) {
        let findCurrency = userBalance[index];
        setStakeBalance(findCurrency);
      }
      var obj = {
        status: "",
        id: currentPack._id,
      };
      setstaking2(obj);
    } catch (error) {
      // toast.error("Please try again later");
    }
  };

  const stakeNowYield = (currentPack, type) => {
    try {
      console.log(currentPack, "-=--currentPack=-=-=");
      var obj = {
        status: "",
        id: currentPack._id,
      };
      setstaking2(obj);
      console.log(obj, "===-status=--");
      // setstakeValue(0);
      // setuserTotlaInterest(0);
      // setFlexibleEarn(0);
      // setInterest(currentPack.APRinterest);
      // setstakingType(type);
      // console.log(currentPack);
      setcurrentPack(currentPack);
      setstakingType("yield");
      // if(yiledStakeType==1)
      // {
      //   setyieldAPY(currentPack.yiledAPRinterest_1);
      // }
      // else if(yiledStakeType==3)
      // {
      //   setyieldAPY(currentPack.yiledAPRinterest_3);
      // }
      // else if(yiledStakeType==5)
      // {
      //   setyieldAPY(currentPack.yiledAPRinterest_5);
      // }

      var index = userBalance.findIndex(
        (x) => x.currencySymbol == currentPack.currencySymbol
      );
      console.log(userBalance, index, "-=-=index-==-index-=-=-=index=-==");
      if (index != -1) {
        let findCurrency = userBalance[index];
        setStakeBalance(findCurrency);
      }
    } catch (error) {
      toast.error("Please try again later");
    }
  };

  const stakeAmount = (e) => {
    try {
      setstakeValue(e.target.value);
      setYieldValue(e.target.value);
      var amount = parseFloat(e.target.value);
      if (stakingType == "fixed") {
        var dailyinterest = amount * (+apy / 100 / 365);
        var totalInterest = parseFloat(dailyinterest) * currentDuration;
        setuserTotlaInterest(totalInterest);
        setusetDailyInterest(dailyinterest);
      } else if (stakingType == "flexible") {
        console.log("amount::::", amount, "interest:::", interest);
        var dailyinterestDate = amount * (+interest / 100 / 365);
        var totalInterestFlex = parseFloat(dailyinterestDate) * 1;
        setFlexibleEarn(dailyinterestDate);
        setTotalFlexible(totalInterestFlex);
      } else if (stakingType == "yield") {
      }
    } catch (error) {
      // toast.error("Please try again later");
    }
  };

  const choosePlan = async () => {
    try {
      toast.error("Please choose staking plan");
    } catch (error) {
      // toast.error("Please try again later");
    }
  };

  const confirmStack = async () => {
    try {
      if (stakeValue > 0) {
        setValidation(false);
        console.log("confirm staking");
        if (stakingType == "fixed") {
          var obj = {
            stakingPlan: currentDuration,
            totalInterest: userTotlaInterest,
            dailyinterest: usetDailyInterest,
            startDate: fromDates,
            endDate: toDates,
            currentAPY: apy,
            stakeMore: staking1,
            stakeAmont: stakeValue,
            type: "fixed",
          };
        } else if (stakingType == "yield") {
          console.log("****stakingTypeyieldyieldstakingType*****8");
          var plan = {
            id: currentPack._id,
            stats: "statke1",
          };
          var obj = {
            stakingPlan: yiledSelectDur,
            totalInterest: YieldEstimation,
            dailyinterest: 0,
            startDate: fromDates,
            endDate: yiledToDate,
            currentAPY: yieldAPY,
            stakeMore: plan,
            stakeAmont: +YieldValue,
            type: "yield",
          };
        } else {
          var obj = {
            //stakingPlan: currentDurationFlex,
            stakingPlan: 0,
            totalInterest: TotalFlexible,
            dailyinterest: FlexibleEarn,
            startDate: fromDates,
            endDate: flexibleEndDate,
            currentAPY: interest,
            stakeMore: staking2,
            stakeAmont: stakeValue,
            type: "flexible",
          };
        }
        console.log(obj, "****stakingTypeyieldyieldstakingType*****8");

        var data = {
          apiUrl: apiService.confirmStaking,
          payload: obj,
        };
        setButtonLoader(true);
        // return false;
        var resp = await postMethod(data);

        setButtonLoader(false);
        if (resp.status) {
          await toast.success(resp.Message);
          window.location.reload();
        } else {
          toast.error(resp.Message);
        }
      } else {
        setValidation(true);
        toast.error("Enter stake amount");
      }
    } catch (error) {
      // toast.error("Please try again later");
    }
  };

  const confirmStackYield = async () => {
    try {
      if (stakeValue > 0) {
        setValidation(false);
        console.log("confirm staking");
        if (YieldEstimation > 0) {
          var plan = {
            id: currentPack._id,
            stats: "Yield Staking",
          };
          var obj = {
            stakingPlan: yiledSelectDur,
            totalInterest: YieldEstimation,
            dailyinterest: 0,
            startDate: fromDates,
            endDate: yiledToDate,
            currentAPY: yieldAPY,
            stakeMore: plan,
            stakeAmont: +YieldValue,
            type: "yield",
            totalPlan: yiledStakeType,
            yieldDuration: yieldDuration,
            currentYieldYear: currentYieldYear,
          };
          console.log(obj, "=-=-=obj-=-=");
          //return false
          var data = {
            apiUrl: apiService.confirmStaking,
            payload: obj,
          };
          setButtonLoader(true);
          // return false;
          var resp = await postMethod(data);

          setButtonLoader(false);
          if (resp.status) {
            await toast.success(resp.Message);
            window.location.reload();
          } else {
            toast.error(resp.Message);
          }
        } else {
          toast.error("Calculate your amount!");
        }
      } else {
        setValidation(true);
        toast.error("Enter stake amount");
      }
    } catch (error) {
      // toast.error("Please try again later");
    }
  };

  const maximum = async () => {
    try {
      setstakeValue(
        parseFloat(stakeBalance.currencyBalance).toFixed(fixedvalue)
      );
      var amount = parseFloat(stakeBalance.currencyBalance);
      console.log(amount, "9090");
      if (amount > 0) {
        var dailyinterest = amount * (+apy / 100 / 365);
        var totalInterest = parseFloat(dailyinterest) * currentDuration;
        setuserTotlaInterest(totalInterest);
        setusetDailyInterest(dailyinterest);
      } else {
        toast.error("Insufficient balance");
      }
    } catch (error) {
      toast.error("Please try again later");
    }
  };

  const getStakeTotalDetails = async (currency) => {
    try {
      var obj = {
        currency: currency,
      };
      var data = {
        apiUrl: apiService.getStakingTotal,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        console.log(resp.data);
        settotallockedValue(resp.data.totalLocked);
        settotalStakedValue(resp.data.totalStaked);
        settotalStakedValueINR(resp.data.totalStakedINR);
        settotallockedValueINR(resp.data.totalLockedINR);
        setStakedCurrency(currency);
      }
    } catch (error) {
      // toast.error("Please try again later");
    }
  };

  const claimNow = async (claimData) => {
    console.log("0998098908908");
    try {
      var obj = {
        _id: claimData._id,
      };
      var data = {
        apiUrl: apiService.claimNowapi,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        toast.success(resp.Message);
        getStakingHistory();
      } else {
        toast.error(resp.Message);
      }
    } catch (error) {
      // toast.error("Please try again later");
    }
  };

  const claimNowFlexible = async (claimData) => {
    try {
      var obj = {
        _id: claimData._id,
      };
      var data = {
        apiUrl: apiService.claimNowapiFlexible,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        toast.success(resp.Message);
        getStakingHistory();
      } else {
        toast.error(resp.Message);
      }
    } catch (error) {
      // toast.error("Please try again later");
    }
  };

  const yieldYear = async (yearType) => {
    try {
      var year =
        yearType == "oneyear" ? 365 : yearType == "threeyear" ? 1095 : 1825;
      setcurrentYieldYear(year);
      var statkeType =
        yearType == "oneyear" ? 1 : yearType == "threeyear" ? 3 : 5;
      setyiledStakeType(statkeType);
      console.log(year, "==-=year=-=-=year=-=-=-year");
      // dataCalculation("","",year);
      var myDate = new Date(new Date().getTime() + year * 24 * 60 * 60 * 1000);
      console.log(myDate, "==-=-myDate--=-=");
      var toDate =
        (await myDate.getDate()) +
        "/" +
        (myDate.getMonth() + 1) +
        "/" +
        myDate.getFullYear() +
        " " +
        myDate.getHours() +
        ":" +
        myDate.getMinutes();
      console.log(toDate, "=-=-=-=-===-==-=-=toDate==-=-=-==-=-=--=");
      setyiledToDate(toDate);
    } catch (error) {}
  };

  const activity = async (activityType) => {
    console.log("]]]]]]]]]", "activityType", activityType);
    try {
      if (activityType == "yield") {
        yieldYear("oneyear");
      }
    } catch (error) {}
  };

  const yieldCalculate = async () => {
    console.log("]]]]]]]]]", "activityType", stakeValue);

    try {
      if (stakeValue > 0) {
        var obj = {
          investValue: Number(stakeValue),
          APRPercentage: yieldAPY,
          days: yieldDuration,
          yiledStakeType: yiledStakeType * 365,
        };
        var data = {
          apiUrl: apiService.yieldcalculation,
          payload: obj,
        };
        var resp = await postMethod(data);
        console.log(resp, "-=-=-=obj-=-=-=-=-");
        setYieldEstimation(resp.Message);
        if (resp.status) {
          // toast.success(resp.Message);
          // getStakingHistory();
        } else {
          toast.error(resp.Message);
        }
      } else {
        toast.error("Please enter stake amount");
      }
    } catch (error) {}
  };

  const claimNow_yield = async (claimData) => {
    try {
      var obj = {
        _id: claimData._id,
      };
      var data = {
        apiUrl: apiService.claimNow_yield,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        toast.success(resp.Message);
        getStakingHistory();
      } else {
        toast.error(resp.Message);
      }
    } catch (error) {
      // toast.error("Please try again later");
    }
  };

  const onSelect = async (option) => {
    console.log("handleOnChange_from", option.value);
    set_type(option.value);
    getStakingHistory();
  };

  const [passHide, setPasshide] = useState(false);
  const [inputType, setinputType] = useState("password");

  const passwordHide = (data) => {
    if (data == "hide") {
      setPasshide(true);
      setinputType("text");
    } else {
      setPasshide(false);
      setinputType("password");
    }
  };

  return (
    <div className="header ">
      <Header />
      <div>
        <div className="body_hXuuO ">
          <div className="lrtcss-wxjc90 e161ieae2">
            <div className="lrtcss-1p2kb7b e161ieae0">
            {siteLoader == true ? <i class="fa-solid fa-spinner fa-spin"></i> : (
             <> <div className="row staking_header">
                <section className="staking_banner_section">
                  <div className="col-lg-7">
                    <div className="container">
                      <div className="banner_left_content">
                        <h2>Staking</h2>
                        <p>
                          On-chain staking that's steady and reliable. Stake
                          assets at any time to earn.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-5">
                    <section className="staking_right_section">
                      <div className="right_content">
                        <h3 className="stak_asset">
                          Assets(USDT){" "}
                          <span className="ml-2">
                            {passHide == false ? (
                              <i
                                className="fa fa-eye icons"
                                onClick={() => passwordHide("hide")}
                              ></i>
                            ) : (
                              <i
                                className="fa fa-eye-slash icons"
                                onClick={() => passwordHide("show")}
                              ></i>
                            )}
                          </span>
                        </h3>
                        <p>
                          Total Staked :
                          <span>
                            {inputType == "password" ? (
                              <span className="ml-2">****</span>
                            ) : (
                              <span className="ml-2">
                                {parseFloat(totalStakedValue).toFixed(
                                  fixedvalue
                                )}
                                {stakedcurrency}
                              </span>
                            )}
                          </span>{" "}
                        </p>

                        <p className="mt-0">
                          Total Value Locked :
                          <span>
                            {inputType == "password" ? (
                              <span className="ml-2">****</span>
                            ) : (
                              <span className="ml-2">
                                {parseFloat(totallockedValue).toFixed(
                                  fixedvalue
                                )}{" "}
                                {stakedcurrency}
                              </span>
                            )}
                          </span>{" "}
                        </p>
                      </div>

                      <div className="asset_myholdings d-flex">
                        <div className="hold_green_text">My Holdings</div>
                        <div className="hold_green_text">History</div>
                      </div>
                    </section>
                  </div>

                  <div class="left_bg"></div>
                  <div class="right_bg"></div>
                </section>
              </div>

              <section className="trading_tutorial_section">
                <div className="container">
                  <div className="trading_tutorial">
                    <div className="container">
                      <div className="tutorial-steps">
                        <div className="tutorial-step1 stack_card">
                          <div className="staking_now_box">
                            <h3>Enter ETH 2.0 Staking</h3>
                            <p>
                              It's easy. Earn steadily, and redeem at any time.
                            </p>
                            <span className="view-green">
                              Stake Now <i class="ri-arrow-right-s-line"></i>
                            </span>
                          </div>
                        </div>
                        <div className="tutorial-step1 stack_card">
                          <div className="staking_now_box">
                            <h3>Stake KCS for Higher Returns</h3>
                            <p>
                              Staked KCS can be used to pay trading fees on
                              KuCoin.
                            </p>
                            <span className="view-green">
                              Stake Now <i class="ri-arrow-right-s-line"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="staking_table">
                <div className="container">
                  <div className="search_checkbox">
                    <div className="staking_table_heading">Staking Options</div>
                    <div className="staking_input">
                      <div className="search_box">
                        <div className="search-box-content">
                          <div className="search-line">
                            <i class="ri-search-line"></i>
                          </div>
                          <div>
                            <input
                              type="text"
                              placeholder="Search coins"
                              className="input"
                            ></input>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <section className="p2p_buy_sell_section">
                    <div className="container">
                      <div className="p2p_tabs mb-5">
                        <div class="p2p_padding my-5">
                          <ul class="nav nav-tabs" id="myTabs">
                            <li class="nav-item">
                              <a
                                class="nav-link active"
                                id="home-tab"
                                data-toggle="tab"
                                href="#home"
                                onClick={() => activity("fixed")}
                              >
                                Fixed staking
                              </a>
                            </li>
                            <li class="nav-item">
                              <a
                                class="nav-link"
                                id="profile-tab"
                                data-toggle="tab"
                                href="#profile"
                                onClick={() => activity("flexible")}
                              >
                                Flexible staking
                              </a>
                            </li>
                            <li class="nav-item">
                              <a
                                class="nav-link"
                                id="profile-tab"
                                data-toggle="tab"
                                href="#staking"
                                onClick={() => activity("yield")}
                              >
                                Yield staking
                              </a>
                            </li>
                            <li class="nav-item">
                              <a
                                class="nav-link"
                                id="profile-tab"
                                data-toggle="tab"
                                href="#stakinghistory"
                                onClick={() => activity("history")}
                              >
                                Staking history
                              </a>
                            </li>
                          </ul>

                          <div class="p2p_tab tab-content mt-2">
                            <div class="tab-pane fade show active" id="home">
                              <div className="p2p_table">
                                <table class="table table-striped">
                                  <thead>
                                    <tr>
                                      <th scope="col">Currency</th>
                                      <th scope="col">
                                        {" "}
                                        APY
                                        {/* <i class="ri-expand-up-down-fill"></i> */}
                                      </th>
                                      <th scope="col">Duration</th>
                                      <th scope="col">Type</th>
                                      <th scope="col">Minimum Stake</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {stakeDetails.length > 0
                                      ? stakeDetails.map((item, i) => {
                                          return (
                                            <tr className="p2p_row stake_row">
                                              <th scope="row">
                                                {" "}
                                                <div className="p2p_row_trades">
                                                  {" "}
                                                  <div className="row_profile_pic">
                                                    <img
                                                      src={item.currencyImage}
                                                    />
                                                  </div>{" "}
                                                  <div className="profile_content">
                                                    {item.currencySymbol}
                                                  </div>{" "}
                                                </div>
                                              </th>
                                              <td className="p2p_row_price">
                                                {staking1 &&
                                                staking1.id == item._id ? (
                                                  <div className="text-success">
                                                    {apy} %{" "}
                                                  </div>
                                                ) : (
                                                  <div className="text-success">
                                                    0 %{" "}
                                                  </div>
                                                )}
                                              </td>
                                              <td>
                                                <div className="p2p_order_limit">
                                                  {staking1 &&
                                                  staking1.status == "stake1" &&
                                                  staking1.id == item._id ? (
                                                    <Button
                                                      className="active"
                                                      value="stake1"
                                                      onClick={() =>
                                                        chooseDuration(
                                                          "stake1",
                                                          item,
                                                          item.firstDuration
                                                        )
                                                      }
                                                    >
                                                      {item.firstDuration}
                                                      <small>Days</small>
                                                    </Button>
                                                  ) : (
                                                    <Button
                                                      value="stake1"
                                                      onClick={() =>
                                                        chooseDuration(
                                                          "stake1",
                                                          item,
                                                          item.firstDuration
                                                        )
                                                      }
                                                    >
                                                      {item.firstDuration}
                                                      <small>Days</small>
                                                    </Button>
                                                  )}
                                                  {staking1 &&
                                                  staking1.status == "stake2" &&
                                                  staking1.id == item._id ? (
                                                    <Button
                                                      value="stake2"
                                                      className="active"
                                                      selected
                                                      onClick={() =>
                                                        chooseDuration(
                                                          "stake2",
                                                          item,
                                                          item.secondDuration
                                                        )
                                                      }
                                                    >
                                                      {item.secondDuration}
                                                      <small>Days</small>
                                                    </Button>
                                                  ) : (
                                                    <Button
                                                      value="stake2"
                                                      selected
                                                      onClick={() =>
                                                        chooseDuration(
                                                          "stake2",
                                                          item,
                                                          item.secondDuration
                                                        )
                                                      }
                                                    >
                                                      {item.secondDuration}
                                                      <small>Days</small>
                                                    </Button>
                                                  )}
                                                  {staking1 &&
                                                  staking1.status == "stake3" &&
                                                  staking1.id == item._id ? (
                                                    <Button
                                                      value="stake3"
                                                      className="active"
                                                      selected
                                                      onClick={() =>
                                                        chooseDuration(
                                                          "stake3",
                                                          item,
                                                          item.thirdDuration
                                                        )
                                                      }
                                                    >
                                                      {item.thirdDuration}
                                                      <small>Days</small>
                                                    </Button>
                                                  ) : (
                                                    <Button
                                                      value="stake3"
                                                      selected
                                                      onClick={() =>
                                                        chooseDuration(
                                                          "stake3",
                                                          item,
                                                          item.thirdDuration
                                                        )
                                                      }
                                                    >
                                                      {item.thirdDuration}
                                                      <small>Days</small>
                                                    </Button>
                                                  )}
                                                  {staking1 &&
                                                  staking1.status == "stake4" &&
                                                  staking1.id == item._id ? (
                                                    <Button
                                                      value="stake4"
                                                      className="active"
                                                      selected
                                                      onClick={() =>
                                                        chooseDuration(
                                                          "stake4",
                                                          item,
                                                          item.fourthDuration
                                                        )
                                                      }
                                                    >
                                                      {item.fourthDuration}
                                                      <small>Days</small>
                                                    </Button>
                                                  ) : (
                                                    <Button
                                                      value="stake4"
                                                      selected
                                                      onClick={() =>
                                                        chooseDuration(
                                                          "stake4",
                                                          item,
                                                          item.fourthDuration
                                                        )
                                                      }
                                                    >
                                                      {item.fourthDuration}
                                                      <small>Days</small>
                                                    </Button>
                                                  )}
                                                </div>
                                              </td>
                                              <td className="staking_period">
                                                {" "}
                                                <p>Lock</p>
                                              </td>
                                              <td className="p2p_row_button subscribed">
                                                <div className="stake-now-btn">
                                                  <p>
                                                    {item.minimumStaking}{" "}
                                                    {item.currencySymbol}{" "}
                                                  </p>
                                                  {authToken == false ? (
                                                    <button
                                                      type="button"
                                                      data-toggle="modal"
                                                      data-target="#largeScreenModaldata"
                                                      onClick={() =>
                                                        stakeNow(item, "fixed")
                                                      }
                                                    >
                                                      <Link to="/login">
                                                        Login to continue!
                                                      </Link>
                                                    </button>
                                                  ) : staking1 &&
                                                    staking1.id == item._id ? (
                                                    <button
                                                      className="active"
                                                      type="button"
                                                      data-toggle="modal"
                                                      data-target="#largeScreenModaldata"
                                                      onClick={() =>
                                                        stakeNow(item, "fixed")
                                                      }
                                                    >
                                                      Stake Now
                                                    </button>
                                                  ) : (
                                                    <button
                                                      className="notactive"
                                                      type="button"
                                                      onClick={() =>
                                                        choosePlan(item)
                                                      }
                                                    >
                                                      Stake Now
                                                    </button>
                                                  )}
                                                </div>
                                                {/* <button
                                        data-toggle="modal"
                                        data-target="#largeScreenModaldata"
                                      >
                                        Subscribe
                                      </button>{" "} */}
                                              </td>
                                            </tr>
                                          );
                                        })
                                      : 0}

                                    {/* <tr className="p2p_row stake_row">
                                  <th scope="row">
                                    {" "}
                                    <div className="p2p_row_trades">
                                      {" "}
                                      <div className="row_profile_pic">
                                        <img
                                          src={require("../Image/Swap/HYDRA _logo.png")}
                                        />
                                      </div>{" "}
                                      <div className="profile_content">
                                        <h3>HYDRA</h3>
                                      </div>{" "}
                                    </div>
                                  </th>
                                  <td className="p2p_row_price">
                                    <p className="text-success">15%</p>
                                  </td>
                                  <td>
                                    <div className="p2p_order_limit">
                                      <h3>Flexible</h3>
                                    </div>
                                  </td>
                                  <td className="staking_period">
                                    {" "}
                                    <p>1 day</p>
                                  </td>
                                  <td className="p2p_row_button subscribe">
                                    {" "}
                                    <button>Subscribe</button>{" "}
                                  </td>
                                </tr> */}
                                  </tbody>
                                </table>
                              </div>
                              {stakeDetails && stakeDetails.length > 0 ? (
                                <Pagination
                                  itemClass="page-item"
                                  linkClass="page-link"
                                  activePage={currentPage}
                                  itemsCountPerPage={recordPerPage}
                                  totalItemsCount={totalactive}
                                  pageRangeDisplayed={pageRange}
                                  onChange={activePageChange}
                                />
                              ) : (
                                ""
                              )}
                            </div>

                            <div class="tab-pane fade" id="profile">
                              <div className="p2p_table">
                                <table class="table table-striped">
                                  <thead>
                                    <tr>
                                      <th scope="col"> Currency</th>
                                      <th scope="col">
                                        APR
                                        {/* <i class="ri-expand-up-down-fill"></i> */}
                                      </th>
                                      <th scope="col">Type</th>
                                      <th scope="col"> Minimum Stake </th>
                                      <th scope="col"> Stake Now </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {stakeDetails.length > 0
                                      ? stakeDetails.map((item, i) => {
                                          return (
                                            <tr className="p2p_row stake_row">
                                              <th scope="row">
                                                {" "}
                                                <div className="p2p_row_trades">
                                                  {" "}
                                                  <div className="row_profile_pic">
                                                    <img
                                                      src={item.currencyImage}
                                                    />
                                                  </div>{" "}
                                                  <div className="profile_content">
                                                    <h3>
                                                      {item.currencySymbol}
                                                    </h3>
                                                  </div>{" "}
                                                </div>
                                              </th>
                                              <td className="p2p_row_price">
                                                <p className="text-success">
                                                  {item.APRinterest} %
                                                </p>
                                              </td>
                                              <td>
                                                <div className="p2p_order_limit">
                                                  <h3>Flexible</h3>
                                                </div>
                                              </td>
                                              <td className="staking_period">
                                                {" "}
                                                <p>
                                                  {item.minimumStakingflex}{" "}
                                                  {item.currencySymbol}{" "}
                                                </p>
                                              </td>
                                              <td className="p2p_row_button subscribed">
                                                {" "}
                                                {authToken == false ? (
                                                  <button
                                                    data-toggle="modal"
                                                    data-target="#largeScreenModaldata"
                                                    onClick={() =>
                                                      stakeNow(item, "flexible")
                                                    }
                                                  >
                                                    <Link to="/login">
                                                      Login to continue!
                                                    </Link>
                                                  </button>
                                                ) : (
                                                  <button
                                                    data-toggle="modal"
                                                    data-target="#largeScreenModaldata"
                                                    onClick={() =>
                                                      stakeNow(item, "flexible")
                                                    }
                                                  >
                                                    Stake Now
                                                  </button>
                                                )}{" "}
                                              </td>
                                            </tr>
                                          );
                                        })
                                      : 0}
                                  </tbody>
                                </table>
                              </div>
                              {stakeDetails && stakeDetails.length > 0 ? (
                                <Pagination
                                  itemClass="page-item"
                                  linkClass="page-link"
                                  activePage={currentPage}
                                  itemsCountPerPage={recordPerPage}
                                  totalItemsCount={totalactive}
                                  pageRangeDisplayed={pageRange}
                                  onChange={activePageChange}
                                />
                              ) : (
                                ""
                              )}
                            </div>

                            <div class="tab-pane fade" id="stakinghistory">
                              <div className="p2p_table">
                                {authToken == true ? (
                                  <div className="staking-history-dropdown">
                                    <Dropdown
                                      placeholder="Filter "
                                      options={data}
                                      onChange={(o) => onSelect(o)}
                                    />
                                  
                                  </div>
                                ) : (
                                  ""
                                )}
                                <table class="table table-striped">
                                  <thead>
                                    <tr>
                                      <th scope="col">Package</th>
                                      <th scope="col">
                                        Total Amount
                                        {/* <i class="ri-expand-up-down-fill"></i> */}
                                      </th>
                                      <th scope="col">APY / APR</th>
                                      <th scope="col">Stake Date</th>
                                      <th scope="col">Interest Cycle</th>
                                      <th scope="col">Type</th>
                                      <th scope="col">Stake End Date</th>
                                      <th scope="col">Next Claim date</th>
                                      <th scope="col">Total Interest</th>
                                      <th scope="col">Interest per cycle</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {stakeHistoryref.current &&
                                    stakeHistoryref.current.length > 0 ? (
                                      stakeHistoryref.current.map((item, i) => {
                                        var startdate = moment(
                                          item.startDate
                                        ).format("DD/MM/YYYY");
                                        var endtdate = moment(
                                          item.endDate
                                        ).format("DD/MM/YYYY");
                                        var get_time = new Date(
                                          item.date
                                        ).getTime();
                                        var interest_cycle =
                                          item.type == "fixed"
                                            ? item.stakingPlan
                                            : item.YieldDuration;
                                        var added_date =
                                          get_time +
                                          +interest_cycle * 24 * 60 * 60 * 1000;
                                        var claim_date = "";
                                        if (item.type == "fixed") {
                                          claim_date = item.endDate;
                                        } else if (item.type == "yield") {
                                          claim_date = item.nextclaimDate;
                                        } else {
                                          claim_date = "-";
                                        }
                                        claim_date =
                                          moment(claim_date).format(
                                            "DD/MM/YYYY"
                                          );

                                        return (
                                          <tr className="p2p_row stake_row">
                                            <th scope="row">
                                              {" "}
                                              <div className="p2p_row_trades">
                                                {" "}
                                                <div className="row_profile_pic">
                                                  <img
                                                    src={item.currencyImage}
                                                  />
                                                </div>{" "}
                                                <div className="profile_content">
                                                  <h3>
                                                    {item.stakeCurrencsymbol}
                                                  </h3>
                                                </div>{" "}
                                              </div>
                                            </th>
                                            <td>
                                              {item.stakeAmont}{" "}
                                              {item.stakeCurrencsymbol}
                                            </td>
                                            <td className="p2p_row_price">
                                              <p className="text-success">
                                                {" "}
                                                {item.currentAPY}%
                                              </p>
                                            </td>
                                            <td>{startdate} </td>
                                            <td>
                                              {item.type == "fixed"
                                                ? item.stakingPlan + " days"
                                                : ""}{" "}
                                              {item.type == "yield"
                                                ? item.YieldDuration + " days"
                                                : ""}{" "}
                                              {item.type == "flexible"
                                                ? "-"
                                                : ""}{" "}
                                            </td>
                                            <td>
                                              <div className="p2p_order_limit">
                                                <h3>{item.type}</h3>
                                              </div>
                                            </td>
                                            <td>
                                              {" "}
                                              {item.type == "fixed" ||
                                              item.type == "yield"
                                                ? endtdate
                                                : "-"}
                                            </td>
                                            <td className="staking_period">
                                              {" "}
                                              {item.type == "fixed" ||
                                              item.type == "yield"
                                                ? claim_date
                                                : "-"}
                                            </td>
                                            <td>
                                              <div>
                                                <p>
                                                  {parseFloat(
                                                    item.totalInterest
                                                  ).toFixed(4)}{" "}
                                                  {item.stakeCurrencsymbol}
                                                </p>
                                              </div>
                                            </td>
                                            <td className="p2p_row_button subscribe">
                                              {" "}
                                              {item.type == "fixed" ? (
                                                <div>
                                                  <p>
                                                    {parseFloat(
                                                      item.totalInterest
                                                    ).toFixed(4)}{" "}
                                                    {item.stakeCurrencsymbol}
                                                  </p>
                                                  {item.status == 1 ? (
                                                    <button
                                                      className="active"
                                                      onClick={() =>
                                                        claimNow(item)
                                                      }
                                                    >
                                                      Claim Now
                                                    </button>
                                                  ) : item.status == 0 ? (
                                                    <button className="notactive">
                                                      {" "}
                                                      Claim
                                                    </button>
                                                  ) : (
                                                    <button className="notactive">
                                                      {" "}
                                                      Claimed
                                                    </button>
                                                  )}
                                                </div>
                                              ) : item.type == "yield" ? (
                                                <div>
                                                  <p>
                                                    {parseFloat(
                                                      item.dailyinterest
                                                    ).toFixed(4)}{" "}
                                                    {item.stakeCurrencsymbol}
                                                  </p>
                                                  {item.status == 1 ? (
                                                    <button
                                                      className="active"
                                                      onClick={() =>
                                                        claimNow_yield(item)
                                                      }
                                                    >
                                                      Claim Now
                                                    </button>
                                                  ) : item.status == 0 ? (
                                                    <button className="notactive">
                                                      {" "}
                                                      Claim
                                                    </button>
                                                  ) : (
                                                    <button className="notactive">
                                                      {" "}
                                                      Claimed
                                                    </button>
                                                  )}
                                                </div>
                                              ) : (
                                                <div>
                                                  <p>
                                                    {parseFloat(
                                                      item.totalInterest
                                                    ).toFixed(4)}{" "}
                                                    {item.stakeCurrencsymbol}
                                                  </p>
                                                  {item.status == 0 ? (
                                                    <button
                                                      className="active"
                                                      onClick={() =>
                                                        claimNowFlexible(item)
                                                      }
                                                    >
                                                      Claim Now
                                                    </button>
                                                  ) : (
                                                    <button
                                                      style={{ color: "black" }}
                                                    >
                                                      Claimed
                                                    </button>
                                                  )}
                                                </div>
                                              )}
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <tr>
                                        {" "}
                                        <td colSpan="10">
                                          {" "}
                                          No Records Found!{" "}
                                        </td>{" "}
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            <div class="tab-pane fade" id="staking">
                              <div>
                                <ul className="yield_staking_years">
                                  <li class="active">
                                    <a
                                      data-toggle="pill"
                                      href="#oneyear"
                                      class="active"
                                      onClick={() => yieldYear("oneyear")}
                                    >
                                      1 Year
                                    </a>
                                  </li>
                                  <li class="">
                                    <a
                                      data-toggle="pill"
                                      href="#oneyear"
                                      onClick={() => yieldYear("threeyear")}
                                    >
                                      3 Year
                                    </a>
                                  </li>
                                  <li class="">
                                    <a
                                      data-toggle="pill"
                                      href="#oneyear"
                                      onClick={() => yieldYear("fiveyear")}
                                    >
                                      5 Year
                                    </a>
                                  </li>
                                </ul>

                                <div id="oneyear" class="fade in active show">
                                  <div className="p2p_table">
                                    <table class="table table-striped">
                                      <thead>
                                        <tr>
                                          <th scope="col">Currency</th>
                                          <th scope="col">
                                            APY
                                            {/* <i class="ri-expand-up-down-fill"></i> */}
                                          </th>
                                          <th scope="col">Duration</th>
                                          <th scope="col">Type</th>
                                          <th scope="col"> Minimum Stake </th>
                                          <th scope="col">Maximum Stake</th>
                                          <th scope="col"> Stake Now</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {stakeDetails.length > 0
                                          ? stakeDetails.map((item, i) => {
                                              console.log(item);
                                              return (
                                                <tr className="p2p_row stake_row">
                                                  <th scope="row">
                                                    {" "}
                                                    <div className="p2p_row_trades">
                                                      {" "}
                                                      <div className="row_profile_pic">
                                                        <img
                                                          src={
                                                            item.currencyImage
                                                          }
                                                        />
                                                      </div>{" "}
                                                      <div className="profile_content">
                                                        <h3>
                                                          {item.currencySymbol}
                                                        </h3>
                                                      </div>{" "}
                                                    </div>
                                                  </th>
                                                  <td className="p2p_row_price">
                                                    {yieldID == item._id ? (
                                                      <div className="text-success">
                                                        {yieldAPY} %{" "}
                                                      </div>
                                                    ) : (
                                                      <div className="text-success">
                                                        0 %{" "}
                                                      </div>
                                                    )}
                                                  </td>
                                                  <td className="p2p_row_button subscribe">
                                                    <div className="staking_btn">
                                                      <button
                                                        className={
                                                          yieldDuration ==
                                                            "stake1" &&
                                                          item._id == yieldID
                                                            ? "active"
                                                            : ""
                                                        }
                                                        value="stake1"
                                                        onClick={() =>
                                                          chooseDurationYield(
                                                            "stake1",
                                                            item
                                                          )
                                                        }
                                                      >
                                                        {item &&
                                                        yiledStakeType == 1 ? (
                                                          <small>
                                                            {
                                                              item.yiled_1_firstDuration
                                                            }{" "}
                                                            Days
                                                          </small>
                                                        ) : yiledStakeType ==
                                                          3 ? (
                                                          <small>
                                                            {
                                                              item.yiled_3_firstDuration
                                                            }{" "}
                                                            Days
                                                          </small>
                                                        ) : yiledStakeType ==
                                                          5 ? (
                                                          <small>
                                                            {
                                                              item.yiled_5_firstDuration
                                                            }{" "}
                                                            Days
                                                          </small>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </button>

                                                      <button
                                                        className={
                                                          yieldDuration ==
                                                            "stake2" &&
                                                          item._id == yieldID
                                                            ? "active"
                                                            : ""
                                                        }
                                                        value="stake2"
                                                        onClick={() =>
                                                          chooseDurationYield(
                                                            "stake2",
                                                            item
                                                          )
                                                        }
                                                      >
                                                        {item &&
                                                        yiledStakeType == 1 ? (
                                                          <small>
                                                            {
                                                              item.yiled_1_secondDuration
                                                            }{" "}
                                                            Days
                                                          </small>
                                                        ) : yiledStakeType ==
                                                          3 ? (
                                                          <small>
                                                            {
                                                              item.yiled_3_secondDuration
                                                            }{" "}
                                                            Days
                                                          </small>
                                                        ) : yiledStakeType ==
                                                          5 ? (
                                                          <small>
                                                            {
                                                              item.yiled_5_secondDuration
                                                            }{" "}
                                                            Days
                                                          </small>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </button>

                                                      <button
                                                        className={
                                                          yieldDuration ==
                                                            "stake3" &&
                                                          item._id == yieldID
                                                            ? "active"
                                                            : ""
                                                        }
                                                        value="stake3"
                                                        onClick={() =>
                                                          chooseDurationYield(
                                                            "stake3",
                                                            item
                                                          )
                                                        }
                                                      >
                                                        {item &&
                                                        yiledStakeType == 1 ? (
                                                          <small>
                                                            {
                                                              item.yiled_1_thirdDuration
                                                            }{" "}
                                                            Days
                                                          </small>
                                                        ) : yiledStakeType ==
                                                          3 ? (
                                                          <small>
                                                            {
                                                              item.yiled_3_thirdDuration
                                                            }{" "}
                                                            Days
                                                          </small>
                                                        ) : yiledStakeType ==
                                                          5 ? (
                                                          <small>
                                                            {
                                                              item.yiled_5_thirdDuration
                                                            }{" "}
                                                            Days
                                                          </small>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </button>

                                                      <button
                                                        className={
                                                          yieldDuration ==
                                                            "stake4" &&
                                                          item._id == yieldID
                                                            ? "active"
                                                            : ""
                                                        }
                                                        value="stake4"
                                                        onClick={() =>
                                                          chooseDurationYield(
                                                            "stake4",
                                                            item
                                                          )
                                                        }
                                                      >
                                                        {item &&
                                                        yiledStakeType == 1 ? (
                                                          <small>
                                                            {
                                                              item.yiled_1_fourthDuration
                                                            }{" "}
                                                            Days
                                                          </small>
                                                        ) : yiledStakeType ==
                                                          3 ? (
                                                          <small>
                                                            {
                                                              item.yiled_3_fourthDuration
                                                            }{" "}
                                                            Days
                                                          </small>
                                                        ) : yiledStakeType ==
                                                          5 ? (
                                                          <small>
                                                            {
                                                              item.yiled_5_fourthDuration
                                                            }{" "}
                                                            Days
                                                          </small>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </button>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="p2p_order_limit">
                                                      <h3>Yield</h3>
                                                    </div>
                                                  </td>
                                                  <td className="staking_period">
                                                    {" "}
                                                    <p>
                                                      {item.minimumStakingYield}{" "}
                                                      {item.currencySymbol}{" "}
                                                    </p>
                                                  </td>
                                                  <td className="staking_period">
                                                    {" "}
                                                    <p>
                                                      {item.maximumStakingYield}{" "}
                                                      {item.currencySymbol}{" "}
                                                    </p>
                                                  </td>
                                                  <td className="p2p_row_button subscribed">
                                                    {authToken == false ? (
                                                      <button
                                                        type="button"
                                                        data-toggle="modal"
                                                        data-target="#stacknowYield"
                                                      >
                                                        <Link to="/login">
                                                          Login to continue!
                                                        </Link>
                                                      </button>
                                                    ) : yieldID == item._id ? (
                                                      <button
                                                        className="active"
                                                        type="button"
                                                        data-toggle="modal"
                                                        data-target="#stacknowYield"
                                                        onClick={() =>
                                                          stakeNowYield(
                                                            item,
                                                            "yield"
                                                          )
                                                        }
                                                      >
                                                        Stake Now
                                                      </button>
                                                    ) : (
                                                      <button
                                                        disabled
                                                        className="active"
                                                        type="button"
                                                        data-toggle="modal"
                                                        data-target="#stacknowYield"
                                                        onClick={() =>
                                                          stakeNowYield(
                                                            item,
                                                            "yield"
                                                          )
                                                        }
                                                      >
                                                        Stake Now
                                                      </button>
                                                    )}
                                                  </td>
                                                </tr>
                                              );
                                            })
                                          : 0}
                                      </tbody>
                                    </table>
                                  </div>
                                  {stakeDetails && stakeDetails.length > 0 ? (
                                    <Pagination
                                      itemClass="page-item"
                                      linkClass="page-link"
                                      activePage={currentPage}
                                      itemsCountPerPage={recordPerPage}
                                      totalItemsCount={totalactive}
                                      pageRangeDisplayed={pageRange}
                                      onChange={activePageChange}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>

                                <div id="twoyear" class="fade">
                                  <div className="p2p_table">
                                    <table class="table table-striped">
                                      <thead>
                                        <tr>
                                          <th scope="col">Currency</th>
                                          <th scope="col"> APY </th>
                                          <th scope="col">Duration</th>
                                          <th scope="col">Type</th>
                                          <th scope="col"> Minimum Stake </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {stakeDetails.length > 0
                                          ? stakeDetails.map((item, i) => {
                                              return (
                                                <tr className="p2p_row stake_row">
                                                  <th scope="row">
                                                    {" "}
                                                    <div className="p2p_row_trades">
                                                      {" "}
                                                      <div className="row_profile_pic">
                                                        <img
                                                          src={
                                                            item.currencyImage
                                                          }
                                                        />
                                                      </div>{" "}
                                                      <div className="profile_content">
                                                        <h3>
                                                          {item.currencySymbol}
                                                        </h3>
                                                      </div>{" "}
                                                    </div>
                                                  </th>
                                                  <td className="p2p_row_price">
                                                    {staking1 &&
                                                    staking1.id == item._id ? (
                                                      <div className="aPY">
                                                        {apy} %{" "}
                                                      </div>
                                                    ) : (
                                                      <div className="aPY">
                                                        0 %{" "}
                                                      </div>
                                                    )}
                                                  </td>
                                                  <td className=" ">
                                                    <div>
                                                      {staking1 &&
                                                      staking1.status ==
                                                        "stake1" &&
                                                      staking1.id ==
                                                        item._id ? (
                                                        <button
                                                          className="active"
                                                          value="stake1"
                                                          onClick={() =>
                                                            chooseDuration(
                                                              "stake1",
                                                              item,
                                                              item.firstDuration
                                                            )
                                                          }
                                                        >
                                                          {item.firstDuration}
                                                          <small>Days</small>
                                                        </button>
                                                      ) : (
                                                        <button
                                                          value="stake1"
                                                          onClick={() =>
                                                            chooseDuration(
                                                              "stake1",
                                                              item,
                                                              item.firstDuration
                                                            )
                                                          }
                                                        >
                                                          {item.firstDuration}
                                                          <small>Days</small>
                                                        </button>
                                                      )}

                                                      {staking1 &&
                                                      staking1.status ==
                                                        "stake2" &&
                                                      staking1.id ==
                                                        item._id ? (
                                                        <button
                                                          value="stake2"
                                                          className="active"
                                                          selected
                                                          onClick={() =>
                                                            chooseDuration(
                                                              "stake2",
                                                              item,
                                                              item.secondDuration
                                                            )
                                                          }
                                                        >
                                                          {item.secondDuration}
                                                          <small>Days</small>
                                                        </button>
                                                      ) : (
                                                        <button
                                                          value="stake2"
                                                          selected
                                                          onClick={() =>
                                                            chooseDuration(
                                                              "stake2",
                                                              item,
                                                              item.secondDuration
                                                            )
                                                          }
                                                        >
                                                          {item.secondDuration}
                                                          <small>Days</small>
                                                        </button>
                                                      )}

                                                      {staking1 &&
                                                      staking1.status ==
                                                        "stake3" &&
                                                      staking1.id ==
                                                        item._id ? (
                                                        <button
                                                          value="stake3"
                                                          className="active"
                                                          selected
                                                          onClick={() =>
                                                            chooseDuration(
                                                              "stake3",
                                                              item,
                                                              item.thirdDuration
                                                            )
                                                          }
                                                        >
                                                          {item.thirdDuration}
                                                          <small>Days</small>
                                                        </button>
                                                      ) : (
                                                        <button
                                                          value="stake3"
                                                          selected
                                                          onClick={() =>
                                                            chooseDuration(
                                                              "stake3",
                                                              item,
                                                              item.thirdDuration
                                                            )
                                                          }
                                                        >
                                                          {item.thirdDuration}
                                                          <small>Days</small>
                                                        </button>
                                                      )}

                                                      {staking1 &&
                                                      staking1.status ==
                                                        "stake4" &&
                                                      staking1.id ==
                                                        item._id ? (
                                                        <button
                                                          value="stake4"
                                                          className="active"
                                                          selected
                                                          onClick={() =>
                                                            chooseDuration(
                                                              "stake4",
                                                              item,
                                                              item.fourthDuration
                                                            )
                                                          }
                                                        >
                                                          {item.fourthDuration}
                                                          <small>Days</small>
                                                        </button>
                                                      ) : (
                                                        <button
                                                          value="stake4"
                                                          selected
                                                          onClick={() =>
                                                            chooseDuration(
                                                              "stake4",
                                                              item,
                                                              item.fourthDuration
                                                            )
                                                          }
                                                        >
                                                          {item.fourthDuration}
                                                          <small>Days</small>
                                                        </button>
                                                      )}
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="p2p_order_limit">
                                                      <h3>Lock</h3>
                                                    </div>
                                                  </td>
                                                  <td className="staking_period subscribed ">
                                                    {" "}
                                                    <p>
                                                      {item.minimumStaking}{" "}
                                                      {item.currencySymbol}{" "}
                                                    </p>
                                                    <p>
                                                      {item.minimumStaking}{" "}
                                                      {item.currencySymbol}{" "}
                                                    </p>
                                                    ` `{" "}
                                                    {authToken == false ? (
                                                      <button
                                                        type="button"
                                                        data-toggle="modal"
                                                        data-target="#largeScreenModaldata"
                                                        onClick={() =>
                                                          stakeNow(
                                                            item,
                                                            "fixed"
                                                          )
                                                        }
                                                      >
                                                        <Link to="/login">
                                                          Login to continue!
                                                        </Link>
                                                      </button>
                                                    ) : staking1 &&
                                                      staking1.id ==
                                                        item._id ? (
                                                      <button
                                                        className="active"
                                                        type="button"
                                                        data-toggle="modal"
                                                        data-target="#largeScreenModaldata"
                                                        onClick={() =>
                                                          stakeNow(
                                                            item,
                                                            "fixed"
                                                          )
                                                        }
                                                      >
                                                        Stake Now
                                                      </button>
                                                    ) : (
                                                      <button
                                                        className="notactive"
                                                        type="button"
                                                        onClick={() =>
                                                          choosePlan(item)
                                                        }
                                                      >
                                                        Stake Now
                                                      </button>
                                                    )}
                                                  </td>
                                                </tr>
                                              );
                                            })
                                          : 0}
                                      </tbody>
                                    </table>
                                  </div>
                                  {stakeDetails && stakeDetails.length > 0 ? (
                                    <Pagination
                                      itemClass="page-item"
                                      linkClass="page-link"
                                      activePage={currentPage}
                                      itemsCountPerPage={recordPerPage}
                                      totalItemsCount={totalactive}
                                      pageRangeDisplayed={pageRange}
                                      onChange={activePageChange}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </section>

              <section className="trading_accordion_section mb-5">
                <div className="container">
                  <div className="trading_accordion">
                    <h1>FAQ</h1>
                    <div class="container">
                      <div id="accordion" class="accordion">
                        <div class="card p2p mb-0">
                          <div
                            class="card-header collapsed p2p"
                            data-toggle="collapse"
                            href="#collapseOne"
                          >
                            <a class="card-title p2p staking">
                              What is staking?
                            </a>
                          </div>
                          <div
                            id="collapseOne"
                            class="card-body collapse"
                            data-parent="#accordion"
                          >
                            <p>
                              Anim pariatur cliche reprehenderit, enim eiusmod
                              high life accusamus terry richardson ad squid. 3
                              wolf moon officia aute, non cupidatat skateboard
                              dolor brunch. Food truck quinoa nesciunt laborum
                              eiusmod. Brunch 3 wolf moon tempor, sunt aliqua
                              put a bird on it squid single-origin coffee nulla
                              assumenda shoreditch et. Nihil anim keffiyeh
                              helvetica, craft beer labore wes anderson cred
                              nesciunt sapiente ea proident. Ad vegan excepteur
                              butcher vice lomo. Leggings occaecat craft beer
                              farm-to-table, raw denim aesthetic synth nesciunt
                              you probably haven't heard of them accusamus
                              labore sustainable VHS.
                            </p>
                          </div>
                          <div
                            class="card-header collapsed p2p"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseTwo"
                          >
                            <a class="card-title p2p staking">
                              How does APR work in staking?
                            </a>
                          </div>
                          <div
                            id="collapseTwo"
                            class="card-body collapse"
                            data-parent="#accordion"
                          >
                            <p>
                              Anim pariatur cliche reprehenderit, enim eiusmod
                              high life accusamus terry richardson ad squid. 3
                              wolf moon officia aute, non cupidatat skateboard
                              dolor brunch. Food truck quinoa nesciunt laborum
                              eiusmod. Brunch 3 wolf moon tempor, sunt aliqua
                              put a bird on it squid single-origin coffee nulla
                              assumenda shoreditch et. Nihil anim keffiyeh
                              helvetica, craft beer labore wes anderson cred
                              nesciunt sapiente ea proident. Ad vegan excepteur
                              butcher vice lomo. Leggings occaecat craft beer
                              farm-to-table, raw denim aesthetic synth nesciunt
                              you probably haven't heard of them accusamus
                              labore sustainable VHS.
                            </p>
                          </div>
                          <div
                            class="card-header collapsed p2p"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseThree"
                          >
                            <a class="card-title p2p staking">
                              Where can I view my earnings?
                            </a>
                          </div>
                          <div
                            id="collapseThree"
                            class="collapse"
                            data-parent="#accordion"
                          >
                            <div class="card-body">
                              Anim pariatur cliche reprehenderit, enim eiusmod
                              high life accusamus terry richardson ad squid. 3
                              wolf moon officia aute, non cupidatat skateboard
                              dolor brunch. Food truck quinoa nesciunt laborum
                              eiusmod. Brunch 3 wolf moon tempor, sunt aliqua
                              put a bird on it squid single-origin coffee nulla
                              assumenda shoreditch et. samus labore sustainable
                              VHS.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <div
                class="modal fade"
                id="largeScreenModaldata"
                tabindex="-1"
                role="dialog"
                aria-labelledby="largeScreenModalLabel"
                aria-hidden="true"
              >
                <div
                  class="modal-dialog modal-xl modal-dialog-centered "
                  role="document"
                >
                  <div class="modal-content p2p staking">
                    <div class="modal-body staking_body_content">
                      <div className="row">
                        <div className="col-lg-6 modal_text left-popup">
                          <div className="p2p_row_trades  ">
                            {" "}
                            <div className="row_profile_pic">
                              <img
                                src={require("../Image/Swap/HYDRA _logo.png")}
                              />
                            </div>{" "}
                            <div className="profile_content stak_heading">
                              <h2>Staking</h2>
                            </div>{" "}
                          </div>
                          <div className="p2p_modal_contant">
                            <div className="modal_available left_popup mt-3">
                              <div>
                                <h3>Reference APR</h3>
                              </div>
                              <div className="pop_color_text">
                                <p>
                                  {stakingType == "fixed" ? apy : interest} %{" "}
                                </p>
                              </div>
                            </div>
                            <div className="modal_available left_popup">
                              <div>
                                <h3>Term</h3>
                              </div>
                              <div className="flexible_text">
                                <p>
                                  {stakingType == "fixed"
                                    ? "Lock"
                                    : "Flexibile"}{" "}
                                </p>
                              </div>
                            </div>
                            <div className="modal_available left_popup">
                              <div>
                                <h3>Redemption Period</h3>
                              </div>
                              {stakingType == "fixed" ? (
                                <div className="staking_period">
                                  {staking1 && staking1.status == "stake1" ? (
                                    <button className="active" value="stake1">
                                      {currentPack.firstDuration}
                                      <small>Days</small>
                                    </button>
                                  ) : (
                                    <button value="stake1">
                                      {currentPack.firstDuration}
                                      <small>Days</small>
                                    </button>
                                  )}
                                  {staking1 && staking1.status == "stake2" ? (
                                    <button value="stake2" className="active">
                                      {currentPack.secondDuration}
                                      <small>Days</small>
                                    </button>
                                  ) : (
                                    <button value="stake2" selected>
                                      {currentPack.secondDuration}
                                      <small>Days</small>
                                    </button>
                                  )}
                                  {staking1 && staking1.status == "stake3" ? (
                                    <button value="stake3" className="active">
                                      {currentPack.thirdDuration}
                                      <small>Days</small>
                                    </button>
                                  ) : (
                                    <button value="stake3" selected>
                                      {currentPack.thirdDuration}
                                      <small>Days</small>
                                    </button>
                                  )}
                                  {staking1 && staking1.status == "stake4" ? (
                                    <button value="stake4" className="active">
                                      {currentPack.fourthDuration}
                                      <small>Days</small>
                                    </button>
                                  ) : (
                                    <button value="stake4" selected>
                                      {currentPack.fourthDuration}
                                      <small>Days</small>
                                    </button>
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>

                            <div className="popup_line"></div>
                          </div>
                          <div>
                            <h4 className="color_white_sedr">Amount</h4>
                          </div>
                          <div className="buy-amt left_popup">
                            <div className="flex-grow-1">
                              <input
                                type="number"
                                value={stakeValue}
                                className="bg_trans_non"
                                placeholder="Enter amount"
                                onChange={stakeAmount}
                                min="0"
                              />
                            </div>
                            <div className="pop_color_text">
                              {" "}
                              {currentPack.currencySymbol} |{" "}
                              <span onClick={maximum}>Max</span>
                            </div>
                          </div>
                          <div className="modal_available left_popup mt-3">
                            <div>
                              <h3>
                                Funding Account
                                <i class="ri-arrow-down-s-fill"></i>
                              </h3>
                            </div>
                            <div className="flexible_text">
                              <p>
                                0 {currentPack.currencySymbol}{" "}
                                <i class="ri-add-circle-line"></i>
                              </p>
                            </div>
                          </div>{" "}
                          <div className="modal_available left_popup">
                            <div>
                              <h3>Max Amount</h3>
                            </div>
                            <div className="hydra-text">
                              <p>
                                {parseFloat(
                                  stakeBalance.currencyBalance
                                ).toFixed(4)}{" "}
                                {currentPack.currencySymbol}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 modal-text right-popup">
                          <div class="modal-header preview">
                            <h2>Preview</h2>
                            <h5
                              class="modal-title"
                              id="exampleModalLongTitle"
                            ></h5>
                            <button
                              type="button"
                              class="close btn"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>

                          <div className="modal_available right_popup">
                            <div>
                              <h3>Subscription Date</h3>
                            </div>
                            <div>
                              <p>2/11/2024, 23:25</p>
                            </div>
                          </div>
                          <div className="modal_available right_popup">
                            <div>
                              <h3>Accrual Date</h3>
                            </div>
                            <div className="payment_methods">
                              <p>
                                {stakingType == "fixed"
                                  ? fromDates
                                  : flexibleStartDate}{" "}
                              </p>
                            </div>
                          </div>
                          <div className="modal_available right_popup">
                            <div>
                              <h3>Profit Distribution Date</h3>
                            </div>
                            <div className="payment_methods">
                              <p> {stakingType == "fixed" ? toDates : "-"}</p>
                            </div>
                          </div>
                          <div className="modal_available right_popup mt-3">
                            <div>
                              <h3>Profit Received</h3>
                            </div>
                            <div className="payment_methods">
                              <p>Daily</p>
                            </div>
                          </div>
                          <h2 className="returns_text mt-4">
                            Estimated Returns
                          </h2>
                          <div className="modal_available right_popup">
                            <div>
                              <h3>{currentPack.currencySymbol} Earnings</h3>
                            </div>
                            <div>
                              <p>
                                {stakingType == "fixed"
                                  ? parseFloat(userTotlaInterest).toFixed(8)
                                  : parseFloat(FlexibleEarn).toFixed(10)}{" "}
                                {stakeBalance.currencySymbol}
                              </p>
                            </div>
                          </div>
                          <div className="estimat_return">
                            <input
                              type="checkbox"
                              className="coin-checkbox"
                            ></input>{" "}
                            <span className="read_agree_text">
                              I have read and agree to the{" "}
                              <span className="pop_color">
                                Cryptocurrency Mining Agreement
                              </span>{" "}
                            </span>
                            {authToken && authToken == true ? (
                              buttonLoader == false ? (
                                <button onClick={confirmStack}>Confirm</button>
                              ) : (
                                <button>Loading...</button>
                              )
                            ) : (
                              <button>login to continue</button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="modal fade"
                id="stacknowYield"
                tabindex="-1"
                role="dialog"
                aria-labelledby="largeScreenModalLabel"
                aria-hidden="true"
              >
                <div
                  class="modal-dialog modal-xl modal-dialog-centered "
                  role="document"
                >
                  <div class="modal-content p2p staking">
                    <div class="modal-body staking_body_content">
                      <div className="row">
                        <div className="col-lg-6 modal_text left-popup">
                          <div className="p2p_row_trades  ">
                            {" "}
                            <div className="row_profile_pic">
                              <img
                                src={require("../Image/Swap/HYDRA _logo.png")}
                              />
                            </div>{" "}
                            <div className="profile_content stak_heading">
                              <h2>Staking</h2>
                            </div>{" "}
                          </div>
                          <div className="p2p_modal_contant">
                            <div className="modal_available left_popup mt-3">
                              <div>
                                <h3>Reference APR</h3>
                              </div>
                              <div className="pop_color_text">
                                <p> {yieldAPY} % </p>
                              </div>
                            </div>
                            <div className="modal_available left_popup">
                              <div>
                                <h3>Term</h3>
                              </div>
                              <div className="flexible_text">
                                <p>Yield</p>
                              </div>
                            </div>
                            <div className="modal_available left_popup">
                              <div>
                                <h3>Redemption Period</h3>
                              </div>
                              {stakingType == "fixed" ? (
                                <div className="staking_period">
                                  {staking1 && staking1.status == "stake1" ? (
                                    <button className="active" value="stake1">
                                      {currentPack.firstDuration}
                                      <small>Days</small>
                                    </button>
                                  ) : (
                                    <button value="stake1">
                                      {currentPack.firstDuration}
                                      <small>Days</small>
                                    </button>
                                  )}
                                  {staking1 && staking1.status == "stake2" ? (
                                    <button value="stake2" className="active">
                                      {currentPack.secondDuration}
                                      <small>Days</small>
                                    </button>
                                  ) : (
                                    <button value="stake2" selected>
                                      {currentPack.secondDuration}
                                      <small>Days</small>
                                    </button>
                                  )}
                                  {staking1 && staking1.status == "stake3" ? (
                                    <button value="stake3" className="active">
                                      {currentPack.thirdDuration}
                                      <small>Days</small>
                                    </button>
                                  ) : (
                                    <button value="stake3" selected>
                                      {currentPack.thirdDuration}
                                      <small>Days</small>
                                    </button>
                                  )}
                                  {staking1 && staking1.status == "stake4" ? (
                                    <button value="stake4" className="active">
                                      {currentPack.fourthDuration}
                                      <small>Days</small>
                                    </button>
                                  ) : (
                                    <button value="stake4" selected>
                                      {currentPack.fourthDuration}
                                      <small>Days</small>
                                    </button>
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>

                            <div className="popup_line"></div>
                          </div>
                          <div>
                            <h4 className="color_white_sedr">Yield Stake</h4>
                            <h6>Amount</h6>
                          </div>
                          <div className="buy-amt left_popup">
                            <div className="flex-grow-1">
                              <input
                                type="number"
                                value={stakeValue}
                                className="bg_trans_non"
                                placeholder="Enter amount"
                                onChange={stakeAmount}
                                min="0"
                              />
                            </div>
                            <div className="pop_color_text">
                              {" "}
                              {currentPack.currencySymbol} |{" "}
                              <span onClick={maximum}>Max</span>
                            </div>
                            {validation == true ? (
                              <p className="text-danger">
                                {" "}
                                Stake amount is required{" "}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="modal_available left_popup mt-3">
                            <button onClick={yieldCalculate}>Calculate </button>
                          </div>{" "}
                        </div>

                        <div className="col-lg-6 modal-text right-popup">
                          <div class="modal-header preview">
                            <h2>Preview</h2>
                            <h5
                              class="modal-title"
                              id="exampleModalLongTitle"
                            ></h5>
                            <button
                              type="button"
                              class="close btn"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>

                          <div className="modal_available right_popup">
                            <div>
                              <h3> Stake Date </h3>
                            </div>
                            <div>
                              <p>{fromDates}</p>
                            </div>
                          </div>
                          <div className="modal_available right_popup">
                            <div>
                              <h3> End Date</h3>
                            </div>
                            <div className="payment_methods">
                              <p>{yiledToDate}</p>
                            </div>
                          </div>
                          <div className="modal_available right_popup">
                            <div>
                              <h3> Stake Type</h3>
                            </div>
                            <div className="payment_methods">
                              <p>
                                {yiledStakeType}
                                {""} Yield staking
                              </p>
                            </div>
                          </div>
                          <div className="modal_available right_popup mt-3">
                            <div>
                              <h3>Profit Received</h3>
                            </div>
                            <div className="payment_methods">
                              <p>Daily</p>
                            </div>
                          </div>
                          <h2 className="returns_text mt-4">
                            Estimated Returns
                          </h2>
                          <div className="modal_available right_popup">
                            <div>
                              <h3>{currentPack.currencySymbol} Earnings</h3>
                            </div>
                            <div>
                              <p>{parseFloat(YieldEstimation).toFixed(8)}</p>
                            </div>
                          </div>
                          <div className="estimat_return">
                            <input
                              type="checkbox"
                              className="coin-checkbox"
                            ></input>{" "}
                            <span>
                              I have read and agree to the{" "}
                              <span className="pop_color">
                                Cryptocurrency Mining Agreement
                              </span>{" "}
                            </span>
                            {authToken && authToken == true ? (
                              buttonLoader == false ? (
                                <button onClick={confirmStackYield}>
                                  Confirm
                                </button>
                              ) : (
                                <button>Loading...</button>
                              )
                            ) : (
                              <button>login to continue</button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
</> )}    
       </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Home;
