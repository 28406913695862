import { useEffect } from "react";
import useState from "react-usestateref";

import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useParallax, ParallaxProvider, Parallax } from "react-scroll-parallax";
import { toast } from "react-toastify";
import apiService from "../../core/service/detail";
import { getMethod, postMethod } from "../../core/service/common.api";
import axios from "axios";
function TableNew() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Function to check if the device is a mobile device
    const detectMobile = () => {
      const mobileCheck = window.matchMedia("(max-width: 768px)").matches;
      setIsMobile(mobileCheck);
    };
    home_currency();
    api();
    api2();
    api3();
    api4();
    // Initial check on component mount
    detectMobile();

    // Event listener for window resize
    const handleResize = () => {
      detectMobile();
    };
    window.addEventListener("resize", handleResize);

    // Cleanup function
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const navigate = useNavigate();

  const [currencylistData, setcurrencylistData] = useState([]);
  const [currencydesendingdata, setcurrencydesendingdata] = useState([]);
  const [gainers, setgainers] = useState([]);
  const [losers, setlosers] = useState([]);
  const [data2, setdata2] = useState([]);
  const [loader, setloader, loaderref] = useState(false);


  const home_currency = async () => {
    try {
      var data = {
        apiUrl: apiService.home_currency,
      };
      setloader(true);
      console.log(loaderref.current, "loader");
      var resp = await getMethod(data);

      setcurrencylistData(resp.Message);

      setdata2(resp.Message);
      setloader(false);
      console.log(loaderref.current, "loader1");


    } catch (err) { }
  };

  const [top_gainers, set_top_gainers] = useState([]);
  const [top_losers, set_top_losers] = useState([]);
  const [top_volume, set_top_volume] = useState([]);
  const [top_new_list, set_top_new_list] = useState([]);

  var topgainers = async () => {
    let highArray = [];
    data2.forEach((item) => {
      let priceChange = parseFloat(Number(item.coin_change));
      console.log(priceChange, "priceChange");
      if (priceChange > 0) {
        var data = {
          currencyName: item.currencyName,
          currencySymbol: item.currencySymbol,
          estimatedValueInUSDT: item.estimatedValueInUSDT,
          coin_change: item.coin_change,
          coin_volume: item.coin_volume,
          Currency_image: item.Currency_image,
        };
        highArray.push(data);
        setgainers(highArray);
        console.log(highArray, "highArray");
      } else {
        console.log("===");
      }
    });
  };

  const toplosers = async () => {
    let lowArray = [];
    console.log("-----");
    data2.forEach((item) => {
      let priceChange = parseFloat(Number(item.coin_change));
      console.log(priceChange, "priceChange");
      if (priceChange < 0) {
        var data = {
          currencyName: item.currencyName,
          currencySymbol: item.currencySymbol,
          estimatedValueInUSDT: item.estimatedValueInUSDT,
          coin_change: item.coin_change,
          coin_volume: item.coin_volume,
          Currency_image: item.Currency_image,
        };
        lowArray.push(data);
        setlosers(lowArray);
      } else {
        console.log("---");
      }
    });
  };
  var descending_order = async () => {
    var data = data2.reverse();
    setcurrencydesendingdata(data);
  };

  const redirect_url = async (url) => {
    console.log("redirect url===", url);
    navigate(url);
  };
  var api = async () => {
    var data = {
      apiUrl: apiService.topgainers,
    };
    var resp = await getMethod(data);
    console.log(resp, "res");
    set_top_gainers(resp.topgainers);
    var data = {
      apiUrl: apiService.test,
    };
    var resp = await getMethod(data)
  };
  var api2 = async () => {
    var data = {
      apiUrl: apiService.toplosers,
    };
    var resp = await getMethod(data);
    set_top_losers(resp.toplosers);
  };
  var api3 = async () => {
    var data = {
      apiUrl: apiService.hotPairs,
    };
    var resp = await getMethod(data);
    set_top_volume(resp.topgainers);
  };
  var api4 = async () => {
    var data = {
      apiUrl: apiService.newPairs,
    };
    var resp = await getMethod(data);
    set_top_new_list(resp.hotpairs);
  };


  const apiKey = '0d007089-ba49-4934-8fef-2abdaee0d4d2';
  const url = 'https://pro-api.coinmarketcap.com/v1/cryptocurrency/trending/gainers-losers';

  axios.get(url, {
    headers: {
      'X-CMC_PRO_API_KEY': apiKey
    }
  })
    .then(response => {
      console.log(response.data, "--0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-");
    })
    .catch(error => {
      console.error('Error:', error);
    });

  return (
    <>
      <ParallaxProvider>
        <section className="section_commen padin_bottom_new pt-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <h2 className="heading_h2 text-center">
                  Popular cryptocurrencies
                </h2>
              </div>
              {/* <div className="col-lg-5 d-flex justify-content-end align-items-center">
                <Button className="veiw_btn">View All</Button>
              </div> */}
              <div className="col-lg-8">
                <Parallax speed={0} disabled={isMobile}>
                  <div className="card_table mt-5 pb-0 table_tabe">
                    <ul class="nav nav-tabs ">
                      <li class="active">
                        <a
                          data-toggle="tab"
                          href="#home"
                          className="active"
                          onClick={home_currency}
                        >
                          Hot List
                        </a>
                      </li>
                      <li>
                        <a
                          data-toggle="tab"
                          href="#menu1"
                          onClick={descending_order}
                        >
                          New Coins
                        </a>
                      </li>
                    </ul>
                    <div class="tab-content">
                      <div id="home" class="tab-pane fade in active show">
                        <div className="table-responsive ">
                          <table class="table home_table">
                            <thead>
                              <tr>
                                <th className="text-left">Name</th>
                                <th>Last Price</th>
                                <th>24h Change</th>
                                <th>Volume</th>
                                <th>Trade</th>
                              </tr>
                            </thead>

                            <tbody className="min200">



                              {/* loaderref.current == false ?

                                <tr className="mt-5">
                                  <td colSpan={5} className="mt-5">
                                  <div className="coin_section">
                                    <i class="fa-solid fa-spinner mt-5 fa-spin"></i>
                                    </div>
                                  </td>
                                </tr>

                                :  */}

                              {


                                (
                                  top_volume && top_volume.length > 0 ? (
                                    top_volume.map((obj, i) => {
                                      return (
                                        <tr className="cursor_pointer">
                                          <td>
                                            <div className="coin_section">
                                              <img
                                                src={obj.imageUrl}
                                                className="blackshow"
                                              />
                                              <span>{obj.name}</span>{" "}
                                              <small>{obj.symbol}</small>
                                            </div>
                                          </td>
                                          <td>
                                            <p>${obj.price}</p>
                                          </td>
                                          {obj.change24Hour <= 0 ? (
                                            <td>
                                              <p className="text-red">
                                                {obj.change24Hour}%
                                              </p>
                                            </td>
                                          ) : (
                                            <td>
                                              <p className="green_text">
                                                +{obj.change24Hour}%
                                              </p>
                                            </td>
                                          )}

                                          <td>
                                            <p className="">{obj.volume}</p>
                                          </td>
                                          <td
                                            className=""
                                            // onClick={() =>
                                            //   redirect_url(
                                            //     "/trade/" + obj.symbol + "_USDT"
                                            //   )
                                            // }

                                            onClick={() =>
                                              redirect_url(
                                                "/trade/BTC_USDT"
                                              )
                                            }
                                          >
                                            <Button className="trade_button">
                                              Trade
                                            </Button>
                                          </td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <tr>
                                      <td colSpan={5}>
                                        <div className="empty_data">
                                          <div className="empty_data_img">
                                            <img
                                              src={require("../../Image/Table_image/no-record-light.png")}
                                            />
                                          </div>

                                          <div className="no_records_text">
                                            No Records found
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                )}



                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div id="menu1" class="tab-pane">
                        <div className="table-responsive ">
                          <table class="table">
                            <thead>
                              <tr>
                                <th className="text-left">Name</th>
                                <th>Last Price</th>
                                <th>24h Change</th>
                                <th>Volume</th>

                                <th>Trade</th>
                              </tr>
                            </thead>

                            {/* <tbody>
                              {currencydesendingdata &&
                              currencydesendingdata.length > 0
                                ? currencydesendingdata.map((obj, i) => {
                                    return (
                                      <tr className="cursor_pointer">
                                        <td>
                                          <div className="coin_section">
                                            <img
                                              src={obj.Currency_image}
                                              className="blackshow"
                                            />
                                            <span>{obj.currencyName}</span>{" "}
                                            <small>{obj.currencySymbol}</small>
                                          </div>
                                        </td>
                                        <td>
                                          <p>${obj.estimatedValueInUSDT}</p>
                                        </td>
                                        {obj.coin_change < 0 ? (
                                          <td>
                                            <p className="text-red">
                                              {obj.coin_change}%
                                            </p>
                                          </td>
                                        ) : (
                                          <td>
                                            <p className="green_text">
                                              +{obj.coin_change}%
                                            </p>
                                          </td>
                                        )}

                                        <td>
                                          <p className="text-right">
                                            {obj.coin_volume}
                                          </p>
                                        </td>
                                        <td
                                          className="text-right"
                                          onClick={() =>
                                            redirect_url(
                                              "/trade/" +
                                                obj.currencySymbol +
                                                "_USDT"
                                            )
                                          }
                                        >
                                          <Button className="trade_button">
                                            Trade
                                          </Button>
                                        </td>
                                      </tr>
                                    );
                                  })
                                : "No List Found"}
                            </tbody> */}
                            <tbody>
                              {top_new_list && top_new_list.length > 0 ? (
                                top_new_list.map((obj, i) => {
                                  return (
                                    <tr className="cursor_pointer">
                                      <td>
                                        <div className="coin_section">
                                          <img
                                            src={obj.imageUrl}
                                            className="blackshow"
                                          />
                                          <span>{obj.fullName}</span>{" "}
                                          {/* <small>{obj.currencySymbol}</small> */}
                                        </div>
                                      </td>
                                      <td>
                                        <p>${obj.price}</p>
                                      </td>
                                      {obj.change24Hour <= 0 ? (
                                        <td>
                                          <p className="text-red">
                                            {obj.change24Hour}%
                                          </p>
                                        </td>
                                      ) : (
                                        <td>
                                          <p className="green_text">
                                            +{obj.change24Hour}%
                                          </p>
                                        </td>
                                      )}

                                      <td>
                                        <p>
                                          {obj.volume24Hour}
                                        </p>
                                      </td>
                                      <td
                                        className=""
                                        // onClick={() =>
                                        //   redirect_url(
                                        //     "/trade/" +
                                        //       obj.currencySymbol +
                                        //       "_USDT"
                                        //   )
                                        // }

                                        onClick={() =>
                                          redirect_url(
                                            "/trade/BTC_USDT"
                                          )
                                        }
                                      >
                                        <Button className="trade_button">
                                          Trade
                                        </Button>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <td colSpan={5}>
                                  <div className="empty_data">
                                    <div className="empty_data_img">
                                      <img
                                        src={require("../../Image/Table_image/no-record-light.png")}
                                      />
                                    </div>

                                    <div className="no_records_text">
                                      No Records found
                                    </div>
                                  </div>
                                </td>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </Parallax>
              </div>
              <div className="col-lg-4 d-flex">
                <div className="card_table mt-5 pb-0 table_tabe w-100">
                  <ul class="nav nav-tabs ">
                    <li class="active">
                      <a
                        data-toggle="tab"
                        href="#home1"
                        className="active"
                        onClick={topgainers}
                      >
                        Top Gainers
                      </a>
                    </li>
                    <li>
                      <a data-toggle="tab" href="#menu2" onClick={toplosers}>
                        Top Losers
                      </a>
                    </li>
                  </ul>
                  <div class="tab-content">
                    <div id="home1" class="tab-pane fade in active show">
                      <div className="table-responsive ">
                        <table class="table">
                          <thead>
                            <tr>
                              <th className="text-left">Name</th>
                              <th>Price</th>
                              <th> Change</th>
                            </tr>
                          </thead>
                          <tbody>
                            {top_gainers && top_gainers.length > 0 ? (
                              top_gainers.map((obj, i) => {
                                return (
                                  <tr className="cursor_pointer">
                                    <td>
                                      <div className="coin_section">
                                        <img
                                          src={obj.imageUrl}
                                          className="blackshow"
                                        />
                                        <span>{obj.name}</span>{" "}
                                        <small>{obj.symbol}</small>
                                      </div>
                                    </td>
                                    <td>
                                      <p>${obj.price}</p>
                                    </td>

                                    {obj.change24Hour <= 0 ? (
                                        <td>
                                          <p className="text-red">
                                            {obj.change24Hour}%
                                          </p>
                                        </td>
                                      ) : (
                                        <td>
                                          <p className="green_text">
                                            +{obj.change24Hour}%
                                          </p>
                                        </td>
                                      )}
                                  </tr>
                                );
                              })
                            ) : (
                              <td colSpan={5}>
                                <div className="empty_data">
                                  <div className="empty_data_img">
                                    <img
                                      src={require("../../Image/Table_image/no-record-light.png")}
                                    />
                                  </div>
                                  <div className="no_records_text">
                                    No Records found
                                  </div>
                                </div>
                              </td>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div id="menu2" class="tab-pane">
                      <div className="table-responsive ">
                        <table class="table">
                          <thead>
                            <tr>
                              <th className="text-left">Name</th>
                              <th>Price</th>
                              <th>Change</th>
                            </tr>
                          </thead>
                          <tbody>
                            {top_losers && top_losers.length > 0 ? (
                              top_losers.map((obj, i) => {
                                return (
                                  <tr className="cursor_pointer">
                                    <td>
                                      <div className="coin_section ">
                                      <div className="text-left">
                                      <img
                                          src={obj.imageUrl}
                                          className="blackshow"
                                        />
                                        <span>{obj.name}</span>{" "}
                                      </div>
                                        <small>{obj.symbol}</small>
                                      </div>
                                    </td>
                                    <td>
                                      <p>${obj.price}</p>
                                    </td>

                                    {obj.change24Hour <= 0 ? (
                                        <td>
                                          <p className="text-red">
                                            {obj.change24Hour}%
                                          </p>
                                        </td>
                                      ) : (
                                        <td>
                                          <p className="green_text">
                                            +{obj.change24Hour}%
                                          </p>
                                        </td>
                                      )}

                                    {/* <td>
                                      <p className="text-right">
                                        {obj.coin_volume}
                                      </p>
                                    </td> */}
                                  </tr>
                                );
                              })
                            ) : (
                              <td colSpan={5}>
                                <div className="empty_data">
                                  <div className="empty_data_img">
                                    <img
                                      src={require("../../Image/Table_image/no-record-light.png")}
                                    />
                                  </div>
                                  <div className="no_records_text">
                                    No Records found
                                  </div>
                                </div>
                              </td>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </ParallaxProvider>
    </>
  );
}

export default TableNew;
