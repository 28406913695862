import React, {useState, useEffect} from "react";
import {Button} from "@mui/material";
import {Link} from "react-router-dom";
import {NavLink} from "react-router-dom";
function Dashboard() {
  return (
    <>
      <ul className="sidemenu">
        <li>
          <NavLink to="/dashboard" className="sidemenu">
            <i class="ri-dashboard-line"></i>
            <span>Dashboard</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/Security" className="sidemenu">
            <i class="ri-lock-2-line"></i>
            <span>Security</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/Identity" className="sidemenu">
            <i class="ri-user-follow-line"></i>
            <span>Identity Verification</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/deposit" className="sidemenu">
            <i class="ri-skip-down-line"></i>
            <span>Deposit</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/Withdraw" className="sidemenu">
            <i class="ri-skip-up-line"></i>
            <span>Withdrawal</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/History" className="sidemenu">
            <i class="ri-history-line"></i>
            <span>History</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/Orders" className="sidemenu">
          <i class="ri-file-list-3-line"></i>
            <span>Orders</span>
          </NavLink>
        </li>
        {/* <li>
          <NavLink to="/paymentmethod" className="sidemenu">
          <i class="ri-bank-line"></i>
            <span>Payment Methods</span>
          </NavLink>
        </li> */}
        <li>
          <NavLink to="/support" className="sidemenu">
          <i class="ri-questionnaire-line"></i>
            <span>Support</span>
          </NavLink>
        </li>
      </ul>
    </>
  );
}

export default Dashboard;
