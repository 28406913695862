import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Dropdown } from "semantic-ui-react";
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import moment from "moment";
import useState from "react-usestateref";
import useStateRef from "react-usestateref";

function Home() {
    const navigate = useNavigate();

    const initialValues = {
        send: "",
    };

    const initialValues1 = {
        receive: "",
    };

    const [fiat, setfiat, fiatref] = useState("");
    const [crypto, setcrypto, cryptoref] = useState("");
    const [formValue, setFormValue, formValueref] = useState(initialValues);
    const [formValue1, setFormValue1, formValue1ref] = useState(initialValues1);
    const [showDiv, setShowDiv] = useState(false);
    const [receiveshowDiv, setreceiveShowDiv] = useState(false);
    const [SelectedFiat, setSelectedFiat, SelectedFiatref] = useState("");
    const [SelectedCrypto, setSelectedCrypto, SelectedCryptoref] = useState("");
    const [fiatdata, setfiatdata, fiatdataref] = useState("");
    const [cryptodata, setcryptodata, cryptodataref] = useState("");
    const [fromamount, Setfromamount, fromamountref] = useState("");
    const [paymentmethods, Setpaymentmethods, paymentmethodsref] = useState("");
    const [gateways, Setgateways, gatewaysref] = useState([]);
    const [gate, Setgate, gateref] = useState([]);
    const [payvalue, Setpayvalue, payvalueref] = useState();
    const [payment, setpayment, paymentref] = useState("");
    const [buycryptodeatil, setbuycryptodeatil, buycryptodeatilref] =
        useState("");
    const [loader, setloader, loaderref] = useState(false);
    const [Loader, setLoader, Loaderref] = useState(false);

    const [siteLoader, setSiteLoader] = useState(false);

    const { send } = formValue;
    const { receive } = formValue1;

    useEffect(() => {
        var token = localStorage.getItem("user_token");
        console.log(token, "=-=-=-=-=-=-=-=--=-=-");
        if (token != null) {
            onRamp();
            Supportedfiat("INR");
            Supportedcrypto("ETH");
        } else {
            navigate("/login");
        }
    }, [0]);

    const onSelect = async (option, e) => {
        console.log(e, "e");
        console.log(option, "option");
        setpayment(option.value);
        console.log(paymentref.current, "paymentref");
        marketprices();
    };

    const onSelect1 = async (option, e) => {
        console.log(option, "option");
        Setgate(option.value);
        const filteredGateway = gatewaysref.current.filter(
            (gateway) => gateway.value == option.value
        );
        Setpayvalue(filteredGateway);
        Setgate(filteredGateway[0].value);
        console.log(filteredGateway[0].cryptoAmount, "cryptoAmount");
        var formattedValue = (filteredGateway[0].cryptoAmount / Math.pow(10, 18))
            .toFixed(8)
            .replace(/\.?0+$/, "");
        setFormValue1({ receive: formattedValue });
        console.log(gatewaysref.current, "filteredGateway");
        console.log(filteredGateway, "filteredGateway");
        console.log(option.value, "filteredGateway");
    };

    const handleToggle = () => {
        setShowDiv(!showDiv);
    };

    const handleFiatSelect = (data) => {
        setSelectedFiat(data);
        Supportedfiat(data.id);
        console.log(data, "data");
        marketprices();
    };

    const handleCryptoSelect = (data) => {
        console.log(data, "data");
        // Supportedcrypto(data.ticker);
        setSelectedCrypto(data);
        marketprices();
    };

    const handleChange = (e) => {
        try {

            console.log(e.target.value.length, "e.target");
            e.preventDefault();
            const { name, value } = e.target;

            if (value.length < 8) {
            let formdata = { ...formValue, ...{ [name]: value } };
            setFormValue(formdata);
            marketprices();
            }
           
        } catch (error) {
            console.log(error, "error");
        }
    };

    const handleChange1 = (e) => {
        try {
            e.preventDefault();
            const { name, value } = e.target;
            let formdata = { ...formValue1, ...{ [name]: value } };
            setFormValue1(formdata);
        } catch (error) {
            console.log(error, "error");    
        }
    };

    const buycrypto = async () => {
        setLoader(true);
        try {
            var obj = {
                cefiGateway: gateref.current,
                fiat: SelectedFiatref.current
                    ? SelectedFiatref.current.id
                    : fiatdataref.current.id,
                fiatAmount: formValueref.current.send * 100,
                payment: paymentref.current,
                chain: SelectedCryptoref.current
                    ? SelectedCryptoref.current.chain.toUpperCase()
                    : "eth",
                crypto: SelectedCryptoref.current
                    ? SelectedCryptoref.current.address
                    : "",
            };
            console.log(obj, "objsssss");
            var data = {
                apiUrl: apiService.buycrypto,
                payload: obj,
            };
            var resp = await postMethod(data);
            setLoader(false);

            if (resp.status) {
                setbuycryptodeatil(resp.data);
                console.log("viewref====", resp);
            } else {
            }
        } catch (error) {
            console.log(error, "error");
        }
    };

    var onRamp = async () => {
        setSiteLoader(true);
        var data = {
            apiUrl: apiService.onRamp,
        };
        var resp = await getMethod(data);
        console.log(resp, "Respdata");

        if (resp.status == true) {
            setSiteLoader(false);
            const Fiat = resp.data.Fiat;
            const Crypto = resp.data.Crypto;

            setfiat(Fiat);
            setcrypto(Crypto);
        } else {
            setSiteLoader(false);

            console.log(resp.Message);
        }
    };

    var Supportedfiat = async (item) => {
        var obj = {
            data: item,
        };

        var data = {
            apiUrl: apiService.Supportedfiat,
            payload: obj,
        };

        var resp = await postMethod(data);

        if (resp.status == true) {
            setfiatdata(resp.data);
            setFormValue({ send: resp.data.placeholder / 100 });

            var Paymentarray = [];

            const Fiat = resp.data.payment;

            console.log(Fiat, "Fiat");
            for (var i = 0; i < Fiat.length; i++) {
                var obj = {
                    value: Fiat[i].id,
                    key: Fiat[i].name,
                    text: Fiat[i].name,
                    image: Fiat[i].image,
                };

                Paymentarray.push(obj);
            }

            Setpaymentmethods(Paymentarray);
            setpayment(Paymentarray[0].value);

            marketprices();
        } else {
            console.log(resp.Message);
        }
    };

    var Supportedcrypto = async (item) => {
        var obj = {
            data: item,
        };

        console.log(obj, "obj");

        var data = {
            apiUrl: apiService.Supportedcrypto,
            payload: obj,
        };
        var resp = await postMethod(data);
        if (resp.status == true) {
            setcryptodata(resp.data);
        } else {
            console.log(resp.Message);
        }
    };

    var marketprices = async () => {
        setloader(true);
        var obj = {
            fiat: SelectedFiatref.current
                ? SelectedFiatref.current.id
                : fiatdataref.current.id,
            fiatAmount: formValueref.current.send * 100,
            payment: paymentref.current,
            chain: SelectedCryptoref.current
                ? SelectedCryptoref.current.chain
                : "eth",
            crypto: SelectedCryptoref.current
                ? SelectedCryptoref.current.address
                : "",
        };

        console.log(obj, "obj");

        var data = {
            apiUrl: apiService.marketprices,
            payload: obj,
        };
        var resp = await postMethod(data);
        setloader(false);
        console.log(resp, "resp");

        if (resp.status == true) {
            var Gatewayarray = [];

            const Fiat = resp.data.gateways;

            console.log(Fiat, "Fiat");
            for (var i = 0; i < Fiat.length; i++) {
                var obj = {
                    value: Fiat[i].gateway,
                    key: Fiat[i].gateway,
                    text: Fiat[i].gateway,
                    type: Fiat[i].type,
                    cryptoAmount: Fiat[i].cryptoAmount,
                    cryptoDecimal: Fiat[i].cryptoDecimal,
                    transactionFee: Fiat[i].transactionFee,
                    chain: Fiat[i].chain,
                    networkFee: Fiat[i].networkFee,
                };

                Gatewayarray.push(obj);
            }

            Setgateways(Gatewayarray);

            console.log(gatewaysref.current, "gatewaysref.current");

            Setgate(Gatewayarray[0].value);

            console.log(Gatewayarray[0].cryptoAmount, "cryptoAmount");

            var formattedValue = (Gatewayarray[0].cryptoAmount / Math.pow(10, 18))
                .toFixed(8)
                .replace(/\.?0+$/, "");
            setFormValue1({ receive: formattedValue });

            console.log(formattedValue, "cryptoAmount");
        } else {
            console.log(resp.Errormessage, "Errormessage");
        }
    };

    return (
        <div className="header ">
            <Header />
            <div>
                <div className="body_hXuuO height_100">
                    <div className="lrtcss-wxjc90 e161ieae2">
                        <div className="lrtcss-1p2kb7b e161ieae0">
                            {siteLoader == true ? (
                                <i class="fa-solid fa-spinner fa-spin"></i>
                            ) : (
                                <>
                                    <section className="convert_section">
                                        <div className="convert-bg">
                                            <img
                                                src={require("../Image/Swap/convert-bg.png")}
                                                className="convert-image"
                                            />
                                        </div>

                                        <section className="convert-form uniramp">
                                            <div className="container">
                                                <div className="row justify-content-center  ">
                                                    <div className="col-lg-5 col-md-8 swap-convert bg-black">
                                                        {buycryptodeatilref.current.cefiInitiate ? (
                                                            <>
                                                                <div className="convert-content mb-3">
                                                                    <div className="contvert-text">
                                                                        Buy Crypto
                                                                    </div>
                                                                    <div className="history-icon">
                                                                        <a href="/buycrypto">
                                                                            {" "}
                                                                            <i class="fa-solid fa-xmark text-white h2"></i>
                                                                        </a>
                                                                    </div>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <iframe
                                                                            src={
                                                                                buycryptodeatilref.current.cefiInitiate
                                                                                    .url
                                                                            }
                                                                            title="programiz pro website"
                                                                            width="100%"
                                                                            height="600px"
                                                                        ></iframe>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <div className=" ">
                                                                <div className="convert-content">
                                                                    <div className="contvert-text">
                                                                        Buy Crypto
                                                                    </div>
                                                                    {/* <div
                                                            className="history-icon"
                                                        // onClick={historyNav}
                                                        >
                                                            <i class="ri-file-history-line"></i>
                                                            <p>History</p>
                                                        </div> */}
                                                                </div>

                                                                <div className="pay_balance mt-4">
                                                                    <div className="pay">Spend</div>
                                                                </div>

                                                                <div className="pay-currency mt-4">
                                                                    <div className="max-dropdown">
                                                                        <Button
                                                                            className="priomary"
                                                                            data-toggle="modal"
                                                                            data-target="#myModal"
                                                                        >
                                                                            {SelectedFiatref.current ? (
                                                                                <>
                                                                                    <img
                                                                                        src={SelectedFiatref.current.image}
                                                                                        style={{
                                                                                            height: "35px",
                                                                                            width: "35px",
                                                                                            marginRight: "10px",
                                                                                        }}
                                                                                    />
                                                                                    {SelectedFiatref.current
                                                                                        ? SelectedFiatref.current.id.toUpperCase()
                                                                                        : ""}
                                                                                    <i class="ri-arrow-drop-down-line h3 mt-2"></i>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <img
                                                                                        src={fiatdataref.current.image}
                                                                                        style={{
                                                                                            height: "35px",
                                                                                            width: "35px",
                                                                                            marginRight: "10px",
                                                                                        }}
                                                                                    />
                                                                                    {fiatdataref.current
                                                                                        ? fiatdataref.current.id.toUpperCase()
                                                                                        : ""}
                                                                                    <i class="ri-arrow-drop-down-line h3 mt-2"></i>
                                                                                </>
                                                                            )}
                                                                        </Button>
                                                                        <div></div>
                                                                    </div>
                                                                    <div className="input">
                                                                        <input
                                                                            type="number"
                                                                            placeholder="Amount"
                                                                            min="0"
                                                                            name="send"
                                                                            className="textright"
                                                                            value={send}
                                                                            onChange={handleChange}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="pay_balance mt-2">
                                                                    <div className="pay">Receive</div>
                                                                </div>

                                                                <div className="pay-currency mt-4">
                                                                    <div className="max-dropdown">
                                                                        <Button
                                                                            className="priomary"
                                                                            data-toggle="modal"
                                                                            data-target="#myModalsecond"
                                                                        >
                                                                            {SelectedCryptoref.current ? (
                                                                                <>
                                                                                    <img
                                                                                        src={
                                                                                            SelectedCryptoref.current.image
                                                                                        }
                                                                                        style={{
                                                                                            height: "35px",
                                                                                            width: "35px",
                                                                                            marginRight: "10px",
                                                                                        }}
                                                                                    />
                                                                                    {SelectedCryptoref.current
                                                                                        ? SelectedCryptoref.current.ticker.toUpperCase()
                                                                                        : ""}

                                                                                    <i class="ri-arrow-drop-down-line h3 mt-2"></i>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <img
                                                                                        src={cryptodataref.current.image}
                                                                                        style={{
                                                                                            height: "35px",
                                                                                            width: "35px",
                                                                                            marginRight: "10px",
                                                                                        }}
                                                                                    />
                                                                                    {cryptodataref.current
                                                                                        ? cryptodataref.current.ticker.toUpperCase()
                                                                                        : ""}

                                                                                    <i class="ri-arrow-drop-down-line h3 mt-2"></i>
                                                                                </>
                                                                            )}
                                                                        </Button>

                                                                        <div></div>
                                                                    </div>
                                                                    <div className="input">
                                                                        <input
                                                                            type="number"
                                                                            placeholder="Amount"
                                                                            className="textright"
                                                                            value={receive}
                                                                            disabled
                                                                            name="receive"
                                                                            onChange={handleChange1}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="pay_balance mt-4">
                                                                    <div className="pay d-flex ">
                                                                        By
                                                                        <div
                                                                            className="ml-3 d-flex paymentby justify-content-between"
                                                                            data-toggle="modal"
                                                                            data-target="#myModalfourth"
                                                                        >
                                                                            {loaderref.current == true ? (
                                                                                <>
                                                                                    <p className="m-0">
                                                                                        {" "}
                                                                                        <i class="fa-solid fa-rotate-right fa-spin"></i>{" "}
                                                                                        Fetching{" "}
                                                                                    </p>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <div className="pay m-0 ">
                                                                                        {gateref.current}
                                                                                    </div>
                                                                                    <div className="pay m-0 ml-2">
                                                                                        <i class="ri-arrow-drop-down-line h5"></i>
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className="pay">
                                                                        {SelectedCryptoref.current ? (
                                                                            <>
                                                                                <img
                                                                                    src={SelectedCryptoref.current.image}
                                                                                    style={{
                                                                                        height: "20px",
                                                                                        width: "20px",
                                                                                        marginRight: "10px",
                                                                                    }}
                                                                                />
                                                                                {SelectedCryptoref.current
                                                                                    ? SelectedCryptoref.current.chain.toUpperCase()
                                                                                    : ""}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <img
                                                                                    src={cryptodataref.current.image}
                                                                                    style={{
                                                                                        height: "20px",
                                                                                        width: "20px",
                                                                                        marginRight: "10px",
                                                                                    }}
                                                                                />
                                                                                {cryptodataref.current
                                                                                    ? cryptodataref.current.chain.toUpperCase()
                                                                                    : ""}
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                                <div className="pay_balance mt-2">
                                                                    <div className="pay">Payment</div>
                                                                </div>

                                                                <div
                                                                    className="pay-currency d-flex justify-content-between mt-4 "
                                                                    data-toggle="modal"
                                                                    data-target="#myModalthird"
                                                                >
                                                                    <div className="pay m-0">
                                                                        {paymentref.current}
                                                                    </div>
                                                                    <div className="pay m-0">
                                                                        <i class="ri-arrow-drop-down-line h3"></i>
                                                                    </div>
                                                                </div>

                                                                <div className="confirm-btn mt-5">
                                                                    {Loaderref.current == true ? (
                                                                        <button onClick={buycrypto}>
                                                                            {" "}
                                                                            Buy{" "}
                                                                            {SelectedCryptoref.current
                                                                                ? SelectedCryptoref.current.ticker.toUpperCase()
                                                                                : "ETH"}{" "} <i class="fa-solid fa-rotate-right fa-spin"></i>
                                                                        </button>
                                                                    ) : (
                                                                        <button onClick={buycrypto}>
                                                                            {" "}
                                                                            Buy{" "}
                                                                            {SelectedCryptoref.current
                                                                                ? SelectedCryptoref.current.ticker.toUpperCase()
                                                                                : "ETH"}{" "}
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </section>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <Footer />

            <div class="modal fade " id="myModal" role="dialog">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content select_coin">
                        <div class="modal-header">
                            <h4 class="modal-title">Fiat</h4>
                            <button type="button" class="close" data-dismiss="modal">
                                &times;
                            </button>
                        </div>
                        <div class="modal-body">
                            <section className="cryptocoin_items" data-dismiss="modal">
                                {fiatref.current && fiatref.current.length > 0
                                    ? fiatref.current.map((item, i) => {
                                        return (
                                            <div
                                                className="cryptocoin_list"
                                                onClick={() => handleFiatSelect(item)}
                                            >
                                                <div className="cryptocoin_logo">
                                                    <img src={item.image} />
                                                </div>
                                                <div className="list_content">
                                                    <h3>{item ? item.id : ""}</h3>
                                                    <span> {item.name} </span>
                                                </div>
                                            </div>
                                        );
                                    })
                                    : ""}
                            </section>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade " id="myModalsecond" role="dialog">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content select_coin">
                        <div class="modal-header">
                            <h4 class="modal-title">Crypto</h4>
                            <button type="button" class="close" data-dismiss="modal">
                                &times;
                            </button>
                        </div>
                        <div class="modal-body">
                            <section className="cryptocoin_items" data-dismiss="modal">
                                {cryptoref.current && cryptoref.current.length > 0
                                    ? cryptoref.current.map((item, i) => {
                                        return (
                                            <div
                                                className="cryptocoin_list"
                                                onClick={() => handleCryptoSelect(item)}
                                            >
                                                <div className="d-flex justify-content-between w100">
                                                    <div className="flexgap">
                                                        <div className="cryptocoin_logo">
                                                            <img src={item.image} />
                                                        </div>
                                                        <div className="list_content">
                                                            <h3>{item ? item.ticker : ""}</h3>
                                                            <span> {item.name} </span>
                                                        </div>
                                                    </div>
                                                    <div className="list_content">
                                                        <span> {item.chain} </span>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                    : ""}
                            </section>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade " id="myModalthird" role="dialog">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content select_coin">
                        <div class="modal-header">
                            <h4 class="modal-title">Payment</h4>
                            <button type="button" class="close" data-dismiss="modal">
                                &times;
                            </button>
                        </div>
                        <div class="modal-body">
                            <section className="cryptocoin_items" data-dismiss="modal">
                                {paymentmethodsref.current &&
                                    paymentmethodsref.current.length > 0
                                    ? paymentmethodsref.current.map((item, i) => {
                                        return (
                                            <div
                                                className="cryptocoin_list"
                                                onClick={() => onSelect(item)}
                                            >
                                                <div className="cryptocoin_logo">
                                                    <img src={item.image} />
                                                </div>

                                                <div className="list_content">
                                                    <h3>{item.value}</h3>
                                                </div>
                                            </div>
                                        );
                                    })
                                    : ""}
                            </section>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade " id="myModalfourth" role="dialog">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content select_coin">
                        <div class="modal-header">
                            <h4 class="modal-title">Payment</h4>
                            <button type="button" class="close" data-dismiss="modal">
                                &times;
                            </button>
                        </div>
                        <div class="modal-body">
                            <section className="cryptocoin_items" data-dismiss="modal">
                                {gatewaysref.current && gatewaysref.current.length > 0
                                    ? gatewaysref.current.map((item, i) => {
                                        console.log(item, "itemitem")
                                        return (
                                            <div
                                                className="cryptocoin_list"
                                                onClick={() => onSelect1(item)}
                                            >
                                                <div className="list_content">
                                                    <h3>{item.text}</h3>
                                                    <span> {item.type} </span>
                                                </div>
                                            </div>
                                        );
                                    })
                                    : ""}
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
