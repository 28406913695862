import React, { useEffect } from "react";
import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "./Innerpages/Sidebar";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import CountryDropdown from "./Componant/Countryselect";
import useState from "react-usestateref";
import Pagination from "react-js-pagination";
import Moment from "moment";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";

function Home() {
  var settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  const options = [
    {
      value: 1,
      label: "Leanne Graham",
    },
    {
      value: 2,
      label: "Ervin Howell",
    },
  ];

  const [perpage, setperpage] = useState(5);
  const [activeOrderDatas, setActiveOders] = useState([]);
  const [tradeHistoryData, settradeHistory] = useState([]);
  const [cancelOrders, setcancelOrders] = useState([]);
  const [stopOrders, setstopOrders] = useState([]);
  const [withdrawHistory, setwithdrawHistory] = useState([]);
  const [depositHistory, setdepositHistory] = useState([]);
  const [sessionHistory, setsessionHistory] = useState([]);
  const [TfaHistory, setTfaHistory] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalpages] = useState(0);

  const [totalactive, settotalactive] = useState(0);
  const [currentPagecan, setCurrentPagecan] = useState(1);
  const [totalcan, settotalcan] = useState(0);
  const [currentPageHis, setcurrentPageHis] = useState(1);
  const [totalHist, settotalHist] = useState(0);
  const [currentPagestop, setCurrentPagestop] = useState(1);
  const [totalStop, settotalStop] = useState(0);

  const [depositcurrentpage, setdepositcurrentpage] = useState(1);
  const [deposittotalpage, setdepositTotalpages] = useState(0);

  const [withdrawcurrentpage, setwithdrawcurrentpage] = useState(1);
  const [withdrawtotalpage, setwithdrawTotalpages] = useState(0);

  const [logincurrentpage, setlogincurrentpage] = useState(1);
  const [logintotalpage, setloginTotalpages] = useState(0);

  const [tfacurrentpage, settfacurrentpage] = useState(1);
  const [tfatotalpage, settfaTotalpages] = useState(0);
  const [siteLoader, setSiteLoader] = useState(false);

  const recordPerPage = 5;
  const pageRange = 5;
  const recordPerPagecan = 5;
  const pageRangecan = 5;

  const recordPerPageHist = 5;
  const pageRangeHis = 5;

  const recordPerPageStop = 5;
  const pageRangeStop = 5;

  const recordPerPageLogin = 5;
  const pageRangeLogin = 5;

  const navigate = useNavigate();

  useEffect(() => {
    getActiveOrders(1);
    tradeHistory(1);
    getCancelOrders(1);
    getStopOrders(1);
    getdepositHistory(1);
    getwithdrawHistory(1);
    getLoginHistory(1);
    Tfa_History(1);
  }, [0]);

  const getActiveOrders = async (pages) => {
    try {
      var obj = {
        FilPerpage: perpage,
        FilPage: pages,
      };
      var data = {
        apiUrl: apiService.getActiveOrders,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        settotalactive(resp.count);
        setActiveOders(resp.result);
      } else {
      }
    } catch (error) { }
  };

  const tradeHistory = async (pages) => {
    try {
      var obj = {
        FilPerpage: perpage,
        FilPage: pages,
      };
      var data = {
        apiUrl: apiService.tradeHistory,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        settradeHistory(resp.result);
        settotalHist(resp.count);
      } else {
      }
    } catch (error) { }
  };

  const getCancelOrders = async (pages) => {
    try {
      var obj = {
        FilPerpage: perpage,
        FilPage: pages,
      };
      var data = {
        apiUrl: apiService.getCancelOrders,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        setcancelOrders(resp.result);
        settotalcan(resp.count);
      } else {
      }
    } catch (error) { }
  };

  const getStopOrders = async (pages) => {
    try {
      var obj = {
        FilPerpage: perpage,
        FilPage: pages,
      };
      var data = {
        apiUrl: apiService.getStopOrders,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        settotalStop(resp.count);
        setstopOrders(resp.result);
      } else {
      }
    } catch (error) { }
  };

  const orderCancel = async (cancelDatas) => {
    try {
      var obj = {
        _id: cancelDatas._id,
      };
      var data = {
        apiUrl: apiService.cancelOrder,
        payload: obj,
      };
      var fetchTradeHisotory = await postMethod(data);
      if (fetchTradeHisotory) {
        toast.success(
          "Order cancelled successfully, your amount credit to your wallet"
        );
        getActiveOrders(1);
      } else {
        toast.error("Please try again later");
      }
    } catch (error) {
      toast.error("Please try again later");
    }
  };

  const getLoginHistory = async (page) => {
    try {
      var payload = {
        perpage: 5,
        page: page,
      };
      var data = {
        apiUrl: apiService.getSessionHisotry,
        payload: payload,
      };
      setSiteLoader(true);
      var resp = await postMethod(data);
      setSiteLoader(false);
      if (resp.status) {
        setsessionHistory(resp.data.data);
        setloginTotalpages(resp.data.total);
      }
    } catch (error) { }
  };

  const activePageChange = (pageNumber) => {
    setCurrentPage(pageNumber); // call API to get data based on pageNumber
    getActiveOrders(pageNumber);
  };

  const cancelPageChange = (pageNumber) => {
    setCurrentPagecan(pageNumber); // call API to get data based on pageNumber
    getCancelOrders(pageNumber);
  };

  const orderhistoryactive = (pageNumber) => {
    setcurrentPageHis(pageNumber); // call API to get data based on pageNumber
    tradeHistory(pageNumber);
  };

  const orderstopactive = (pageNumber) => {
    setCurrentPagestop(pageNumber); // call API to get data based on pageNumber
    getStopOrders(pageNumber);
  };

  const getdepositHistory = async (page) => {
    var obj = {
      apiUrl: apiService.deposit_history,
      payload: { FilPerpage: 5, FilPage: page },
    };
    var deposit_history_list = await postMethod(obj);
    if (deposit_history_list) {
      setdepositHistory(deposit_history_list.result);
      setdepositTotalpages(deposit_history_list.total);
      console.log("deposit_history_list.pages===", deposittotalpage);
    }
  };

  const depositrecordpage = 5;
  const depositpagerange = 5;
  const handlepagedeposit = (page) => {
    getdepositHistory(page);
    setdepositcurrentpage(page);
  };

  const getwithdrawHistory = async (page) => {
    var data = {
      apiUrl: apiService.withdraw_history,
      payload: { FilPerpage: 5, FilPage: page },
    };
    var withdraw_history_list = await postMethod(data);
    if (withdraw_history_list) {
      setwithdrawHistory(withdraw_history_list.result);
      setwithdrawTotalpages(withdraw_history_list.pages);
    }
  };

  const withdrawrecordpage = 5;
  const withdrawpagerange = 5;
  const handlepagewithdraw = (page) => {
    getwithdrawHistory(page);
    setwithdrawcurrentpage(page);
  };

  const loginrecordpage = 5;
  const loginpagerange = 5;

  const handlepagelogin = (pageNumber) => {
    setlogincurrentpage(pageNumber);
    getLoginHistory(pageNumber);
  };

  const Tfa_History = async (page) => {
    var data = {
      apiUrl: apiService.Tfa_History,
      payload: { perpage: 5, page: page },
    };
    var Tfa_History = await postMethod(data);
    if (Tfa_History) {
      setTfaHistory(Tfa_History.data.data);
      settfaTotalpages(Tfa_History.data.total);
    }
  };
  const tfarecordpage = 5;
  const tfapagerange = 5;
  const handlepagetfachange = (pageNumber) => {
    Tfa_History(pageNumber);
    settfacurrentpage(pageNumber);
  };

  const copy = async (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Address copied");
  };

  return (
    <div className="header ">
      <Header />
      <div>
        <div className="body_hXuuO history">
          <div className="lrtcss-wxjc90 e161ieae2">
            <div className="lrtcss-q484j3 e161ieae1">
              <div className="KuxMenu-root lrtcss-yqpjhy">
                <Sidebar />
              </div>
            </div>
            <div className="lrtcss-1p2kb7b ma-000">
              {siteLoader == true ? <i class="fa-solid fa-spinner fa-spin"></i> : (<>
                <div class="lrtcss-ac8m87 e8w7gdo16 pt-0">
                  <section className="p2p_buy_sell_section w-100">
                    <div className="">
                      <div className="p2p_tabs mb-5">
                        <div class="p2p_padding ">
                          <ul class="nav nav-tabs history_tabs mb-3" id="myTabs">
                            <li class="nav-item">
                              <a
                                class="nav-link active"
                                id="home-tab"
                                data-toggle="tab"
                                href="#Login"
                              >
                                Login History
                              </a>
                            </li>
                            <li class="nav-item">
                              <a
                                class="nav-link"
                                id="profile-tab"
                                data-toggle="tab"
                                href="#Withdrawal"
                              >
                                Withdrawal History
                              </a>
                            </li>
                            <li class="nav-item">
                              <a
                                class="nav-link"
                                id="profile-tab"
                                data-toggle="tab"
                                href="#Tradehistory"
                              >
                                Trade History
                              </a>
                            </li>
                            <li class="nav-item">
                              <a
                                class="nav-link"
                                id="home-tab"
                                data-toggle="tab"
                                href="#Deposit"
                              >
                                Deposit History
                              </a>
                            </li>
                            <li class="nav-item">
                              <a
                                class="nav-link"
                                id="home-tab"
                                data-toggle="tab"
                                href="#TFAhistory"
                              >
                                2FA History
                              </a>
                            </li>
                          </ul>

                          <div class="p2p_tab tab-content  mt-2">
                            <div class="tab-pane fade" id="Deposit">
                              <div className="p2p_table">
                                <table class="table table-striped market">
                                  <thead>
                                    <tr>
                                      <th scope="col">Date</th>
                                      <th scope="col">Currency</th>
                                      <th scope="col">Amount</th>
                                      <th scope="col">Transaction Id</th>
                                      <th scope="col">Status</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {depositHistory &&
                                      depositHistory.length > 0 ? (
                                      depositHistory.map((item, i) => {
                                        return (
                                          <tr className="p2p_row">
                                            <td>
                                              {Moment(item.date).format(
                                                "DD.MM.YYYY hh:mm a"
                                              )}
                                            </td>
                                            <td>{item.currencySymbol}</td>
                                            <td>
                                              {parseFloat(item.amount).toFixed(8)}
                                            </td>
                                            <td>
                                              <span className="hash_code">
                                                {item.txnid != undefined
                                                  ? item.txnid.slice(0, 10)
                                                  : "-"}{" "}
                                                {""}
                                              </span>
                                              <i
                                                class="fa fa-clone"
                                                aria-hidden="true"
                                                onClick={() => copy(item.txnid)}
                                                style={{ cursor: "pointer" }}
                                              ></i>
                                            </td>
                                            <td>
                                              <span className="text-green">
                                                Completed
                                              </span>
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <td colSpan={5}>
                                        <div className="empty_data">
                                          <div className="empty_data_img">
                                            <img
                                              src={require("../Image/Table_image/no-record-light.png")}
                                            />
                                          </div>

                                          <div className="no_records_text">
                                            No Records Found
                                          </div>
                                        </div>
                                      </td>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                              {depositHistory && depositHistory.length > 0 ? (
                                <Pagination
                                  itemClass="page-item" // add it for bootstrap 4
                                  linkClass="page-link" // add it for bootstrap 4
                                  activePage={depositcurrentpage}
                                  itemsCountPerPage={depositrecordpage}
                                  totalItemsCount={deposittotalpage}
                                  pageRangeDisplayed={depositpagerange}
                                  onChange={handlepagedeposit}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                            <div class="tab-pane fade" id="Withdrawal">
                              <div className="tab_methods wallet_tab">
                                {/* <ul
                                class="nav nav-pills tab mt-5 mb-3"
                                id="pills-tab"
                                role="tablist"
                              >
                                <li class="nav-item buy" role="presentation">
                                  <button
                                    class="nav-link buy-btn"
                                    id="pills-profile-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-profile"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-profile"
                                    aria-selected="false"
                                  >
                                    USDT
                                  </button>
                                </li>
                                <li class="nav-item buy" role="presentation">
                                  <button
                                    class="nav-link buy-btn "
                                    id="pills-profile-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-profile"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-profile"
                                    aria-selected="false"
                                  >
                                    BTC
                                  </button>
                                </li>
                                <li class="nav-item buy" role="presentation">
                                  <button
                                    class="nav-link buy-btn"
                                    id="pills-profile-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-profile"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-profile"
                                    aria-selected="false"
                                  >
                                    ETH
                                  </button>
                                </li>
                                <li class="nav-item buy" role="presentation">
                                  <button
                                    class="nav-link buy-btn"
                                    id="pills-profile-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-profile"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-profile"
                                    aria-selected="false"
                                  >
                                    KCS
                                  </button>
                                </li>
                                <li class="nav-item buy" role="presentation">
                                  <button
                                    class="nav-link buy-btn"
                                    id="pills-profile-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-profile"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-profile"
                                    aria-selected="false"
                                  >
                                    USDC
                                  </button>
                                </li>

                                <div className="p2p_dropdown ">
                                  <li>
                                    <div class="dropdown amount">
                                      {/* <DropdownMenu /> */}
                                {/* </div>
                                  </li>
                                </div>
                            </ul> */}
                              </div>

                              <div className="p2p_table">
                                <table class="table table-striped market">
                                  <thead>
                                    <tr>
                                      {/* <th scope="col">Merchant</th>
                                    <th scope="col">Price</th>
                                    <th scope="col">Available | Order Limit</th>
                                    <th scope="col">Payment Methods</th>
                                    <th scope="col" className="trade_fees">
                                      Trade <span>0 fee(s)</span>{" "}
                                    </th> */}
                                      <th scope="col">Transaction ID</th>
                                      <th scope="col">Amount</th>
                                      <th scope="col">Currency</th>
                                      <th scope="col">Date</th>
                                      <th scope="col">Status</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {withdrawHistory.length > 0 ? (
                                      withdrawHistory.map((data, i) => {
                                        return (
                                          <tr className="p2p_row">
                                            <td>
                                              {" "}
                                              {data.txn_id != undefined
                                                ? data.txn_id.slice(0, 10)
                                                : "-"}{" "}
                                              {""}
                                              <i
                                                class="fa fa-clone"
                                                aria-hidden="true"
                                                onClick={() => copy(data.txn_id)}
                                                style={{ cursor: "pointer" }}
                                              ></i>
                                            </td>
                                            <td>
                                              {" "}
                                              {data.amount == undefined
                                                ? 0
                                                : data.amount.toFixed(4)}
                                            </td>
                                            <td>{data.currency}</td>
                                            <td>
                                              {Moment(data.date).format("lll")}
                                            </td>
                                            <td>
                                              {data.txn_id != "--------" ? (
                                                <span className="text-green">
                                                  Completed
                                                </span>
                                              ) : (
                                                <span className="text-red">
                                                  Pending
                                                </span>
                                              )}
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <td colSpan={5}>
                                        <div className="empty_data">
                                          <div className="empty_data_img">
                                            <img
                                              src={require("../Image/Table_image/no-record-light.png")}
                                            />
                                          </div>

                                          <div className="no_records_text">
                                            No Records Found
                                          </div>
                                        </div>
                                      </td>
                                    )}

                                    {/* <tr className="p2p_row">
                                    <th scope="row">
                                      {" "}
                                      <div className="p2p_row_trades">
                                        {" "}
                                        <div className="row_profile">
                                          DA
                                        </div>{" "}
                                        <div className="profile_content">
                                          <h3>
                                            Dastgeer Traders{" "}
                                            <i class="ri-bookmark-3-fill"></i>
                                          </h3>{" "}
                                          <p>1,627 order(s) | 99.14%</p>
                                        </div>{" "}
                                      </div>
                                    </th>
                                    <td className="p2p_row_price">
                                      <h3>
                                        1.02<span>USD</span>
                                      </h3>
                                    </td>
                                    <td>
                                      <div className="p2p_order_limit">
                                        <h3>
                                          8,336.800653 <span> USDT</span>
                                        </h3>
                                        <h3>
                                          100 - 15,000 <span>USD</span>
                                        </h3>
                                      </div>
                                    </td>
                                    <td className="p2p_row_img">
                                      {" "}
                                      <img
                                        src={require("../Image/Table_image/p2p_table.png")}
                                        alt="pic"
                                      />
                                    </td>
                                    <td className="p2p_row_button_sell">
                                      {" "}
                                      <button
                                        data-toggle="modal"
                                        data-target="#lgScreen"
                                      >
                                        Sell
                                      </button>{" "}
                                    </td>
                                  </tr>
                                  <tr className="p2p_row">
                                    <th scope="row">
                                      {" "}
                                      <div className="p2p_row_trades">
                                        {" "}
                                        <div className="row_profile">
                                          DA
                                        </div>{" "}
                                        <div className="profile_content">
                                          <h3>
                                            Dastgeer Traders{" "}
                                            <i class="ri-bookmark-3-fill"></i>
                                          </h3>{" "}
                                          <p>1,627 order(s) | 99.14%</p>
                                        </div>{" "}
                                      </div>
                                    </th>
                                    <td className="p2p_row_price">
                                      <h3>
                                        1.02<span>USD</span>
                                      </h3>
                                    </td>
                                    <td>
                                      <div className="p2p_order_limit">
                                        <h3>
                                          8,336.800653 <span> USDT</span>
                                        </h3>
                                        <h3>
                                          100 - 15,000 <span>USD</span>
                                        </h3>
                                      </div>
                                    </td>
                                    <td className="p2p_row_img">
                                      {" "}
                                      <img
                                        src={require("../Image/Table_image/p2p_table.png")}
                                        alt="pic"
                                      />
                                    </td>
                                    <td className="p2p_row_button_sell">
                                      {" "}
                                      <button
                                        data-toggle="modal"
                                        data-target="#lgScreen"
                                      >
                                        Sell
                                      </button>{" "}
                                    </td>
                                  </tr>
                                  <tr className="p2p_row">
                                    <th scope="row">
                                      {" "}
                                      <div className="p2p_row_trades">
                                        {" "}
                                        <div className="row_profile">
                                          DA
                                        </div>{" "}
                                        <div className="profile_content">
                                          <h3>
                                            Dastgeer Traders{" "}
                                            <i class="ri-bookmark-3-fill"></i>
                                          </h3>{" "}
                                          <p>1,627 order(s) | 99.14%</p>
                                        </div>{" "}
                                      </div>
                                    </th>
                                    <td className="p2p_row_price">
                                      <h3>
                                        1.02<span>USD</span>
                                      </h3>
                                    </td>
                                    <td>
                                      <div className="p2p_order_limit">
                                        <h3>
                                          8,336.800653 <span> USDT</span>
                                        </h3>
                                        <h3>
                                          100 - 15,000 <span>USD</span>
                                        </h3>
                                      </div>
                                    </td>
                                    <td className="p2p_row_img">
                                      {" "}
                                      <img
                                        src={require("../Image/Table_image/p2p_table.png")}
                                        alt="pic"
                                      />
                                    </td>
                                    <td className="p2p_row_button_sell">
                                      {" "}
                                      <button
                                        data-toggle="modal"
                                        data-target="#lgScreen"
                                      >
                                        Sell
                                      </button>{" "}
                                    </td>
                                  </tr>
                                  <tr className="p2p_row">
                                    <th scope="row">
                                      {" "}
                                      <div className="p2p_row_trades">
                                        {" "}
                                        <div className="row_profile">
                                          DA
                                        </div>{" "}
                                        <div className="profile_content">
                                          <h3>
                                            Dastgeer Traders{" "}
                                            <i class="ri-bookmark-3-fill"></i>
                                          </h3>{" "}
                                          <p>1,627 order(s) | 99.14%</p>
                                        </div>{" "}
                                      </div>
                                    </th>
                                    <td className="p2p_row_price">
                                      <h3>
                                        1.02<span>USD</span>
                                      </h3>
                                    </td>
                                    <td>
                                      <div className="p2p_order_limit">
                                        <h3>
                                          8,336.800653 <span> USDT</span>
                                        </h3>
                                        <h3>
                                          100 - 15,000 <span>USD</span>
                                        </h3>
                                      </div>
                                    </td>
                                    <td className="p2p_row_img">
                                      {" "}
                                      <img
                                        src={require("../Image/Table_image/p2p_table.png")}
                                        alt="pic"
                                      />
                                    </td>
                                    <td className="p2p_row_button_sell">
                                      {" "}
                                      <button
                                        data-toggle="modal"
                                        data-target="#lgScreen"
                                      >
                                        Sell
                                      </button>{" "}
                                    </td>
                                  </tr>
                                  <tr className="p2p_row">
                                    <th scope="row">
                                      {" "}
                                      <div className="p2p_row_trades">
                                        {" "}
                                        <div className="row_profile">
                                          DA
                                        </div>{" "}
                                        <div className="profile_content">
                                          <h3>
                                            Dastgeer Traders{" "}
                                            <i class="ri-bookmark-3-fill"></i>
                                          </h3>{" "}
                                          <p>1,627 order(s) | 99.14%</p>
                                        </div>{" "}
                                      </div>
                                    </th>
                                    <td className="p2p_row_price">
                                      <h3>
                                        1.02<span>USD</span>
                                      </h3>
                                    </td>
                                    <td>
                                      <div className="p2p_order_limit">
                                        <h3>
                                          8,336.800653 <span> USDT</span>
                                        </h3>
                                        <h3>
                                          100 - 15,000 <span>USD</span>
                                        </h3>
                                      </div>
                                    </td>
                                    <td className="p2p_row_img">
                                      {" "}
                                      <img
                                        src={require("../Image/Table_image/p2p_table.png")}
                                        alt="pic"
                                      />
                                    </td>
                                    <td className="p2p_row_button_sell">
                                      {" "}
                                      <button
                                        data-toggle="modal"
                                        data-target="#lgScreen"
                                      >
                                        Sell
                                      </button>{" "}
                                    </td>
                                  </tr> */}
                                  </tbody>
                                </table>
                              </div>
                              {withdrawHistory && withdrawHistory.length > 0 ? (
                                <Pagination
                                  itemClass="page-item" // add it for bootstrap 4
                                  linkClass="page-link" // add it for bootstrap 4
                                  activePage={withdrawcurrentpage}
                                  itemsCountPerPage={withdrawrecordpage}
                                  totalItemsCount={withdrawtotalpage}
                                  pageRangeDisplayed={withdrawpagerange}
                                  onChange={handlepagewithdraw}
                                />
                              ) : (
                                ""
                              )}
                            </div>

                            <div class="tab-pane fade" id="Tradehistory">
                              <div className="p2p_table">
                                <table class="table table-striped market">
                                  <thead>
                                    <tr>
                                      <th scope="col">Date</th>
                                      <th scope="col">Pair</th>
                                      <th scope="col">Type</th>
                                      <th scope="col">Price</th>
                                      <th scope="col">Amount</th>
                                      <th scope="col">Total</th>
                                      <th scope="col">Status</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {tradeHistoryData.length > 0 ? (
                                      tradeHistoryData.map((item, i) => {
                                        var datesHis = Moment(
                                          item.created_at
                                        ).format("DD.MM.YYYY hh:mm a");
                                        return (
                                          <tr>
                                            <td>{datesHis} </td>
                                            <td>{item.pair} </td>
                                            <td>{item.type} </td>
                                            <td> {item.askPrice} </td>
                                            <td> {item.askAmount} </td>
                                            <td>{item.total}</td>
                                            <td>
                                              {item.type == "buy" ? (
                                                <span className="text-green">
                                                  Filled
                                                </span>
                                              ) : (
                                                <span className="text-red">
                                                  Filled
                                                </span>
                                              )}
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <td colSpan={7}>
                                        <div className="empty_data">
                                          <div className="empty_data_img">
                                            <img
                                              src={require("../Image/Table_image/no-record-light.png")}
                                            />
                                          </div>

                                          <div className="no_records_text">
                                            No Records Found
                                          </div>
                                        </div>
                                      </td>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                              {tradeHistoryData && tradeHistoryData.length > 0 ? (
                                <Pagination
                                  itemClass="page-item"
                                  linkClass="page-link"
                                  activePage={currentPageHis}
                                  itemsCountPerPage={recordPerPageHist}
                                  totalItemsCount={totalHist}
                                  pageRangeDisplayed={pageRangeHis}
                                  onChange={orderhistoryactive}
                                />
                              ) : (
                                ""
                              )}
                            </div>

                            <div class="tab-pane fade show active " id="Login">
                              <div className="p2p_table">
                                <table class="table table-striped market">
                                  <thead>
                                    <tr>
                                      <th scope="col">Date</th>
                                      <th scope="col">Ip Address</th>
                                      <th scope="col">Device</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {sessionHistory.length > 0 &&
                                      sessionHistory ? (
                                      sessionHistory.map((item, i) => {
                                        return (
                                          <tr>
                                            <td>
                                              {Moment(item.createdDate).format(
                                                "DD.MM.YYYY hh:mm a"
                                              )}
                                            </td>
                                            <td>{item.ipAddress}</td>
                                            <td>{item.platform}</td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <td colSpan={3}>
                                        <div className="empty_data">
                                          <div className="empty_data_img">
                                            <img
                                              src={require("../Image/Table_image/no-record-light.png")}
                                            />
                                          </div>

                                          <div className="no_records_text">
                                            No Records Found
                                          </div>
                                        </div>
                                      </td>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                              {sessionHistory && sessionHistory.length > 0 ? (
                                <Pagination
                                  itemClass="page-item"
                                  linkClass="page-link"
                                  activePage={logincurrentpage}
                                  itemsCountPerPage={loginrecordpage}
                                  totalItemsCount={logintotalpage}
                                  pageRangeDisplayed={loginpagerange}
                                  onChange={handlepagelogin}
                                />
                              ) : (
                                ""
                              )}
                            </div>

                            <div class="tab-pane fade " id="TFAhistory">
                              <div className="p2p_table">
                                <table class="table table-striped market">
                                  <thead>
                                    <tr>
                                      <th scope="col">Data & Time</th>
                                      <th scope="col">Ip Address</th>
                                      <th scope="col">Browser</th>
                                      <th scope="col">Status</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {TfaHistory.length > 0 ? (
                                      TfaHistory.map((item, i) => {
                                        return (
                                          <tr>
                                            <td>{item.createdDate}</td>
                                            <td>{item.ipAddress}</td>
                                            <td>{item.browser}</td>
                                            <td>{item.status == "disabled" ? (
                                              <span className="text-red">
                                                Disabled
                                              </span>
                                            ) : (
                                              <span className="text-green">
                                                enabled
                                              </span>

                                            )}</td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <td colSpan={4}>
                                        <div className="empty_data">
                                          <div className="empty_data_img">
                                            <img
                                              src={require("../Image/Table_image/no-record-light.png")}
                                            />
                                          </div>

                                          <div className="no_records_text">
                                            No Records Found
                                          </div>
                                        </div>
                                      </td>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                              {TfaHistory && TfaHistory.length > 0 ? (
                                <Pagination
                                  itemClass="page-item"
                                  linkClass="page-link"
                                  activePage={tfacurrentpage}
                                  itemsCountPerPage={tfarecordpage}
                                  totalItemsCount={tfatotalpage}
                                  pageRangeDisplayed={tfapagerange}
                                  onChange={handlepagetfachange}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>

                          <div
                            class="modal fade"
                            id="largeScreenModal"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="largeScreenModalLabel"
                            aria-hidden="true"
                          >
                            <div
                              class="modal-dialog modal-xl modal-dialog-centered "
                              role="document"
                            >
                              <div class="modal-content p2p">
                                <div class="modal-header">
                                  <h5
                                    class="modal-title"
                                    id="exampleModalLongTitle"
                                  ></h5>
                                  <button
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>

                                <div class="modal-body">
                                  <div className="row">
                                    <div className="col-lg-4 modal_text">
                                      <div className="p2p_row_trades">
                                        {" "}
                                        <div className="row_profile">DA</div>{" "}
                                        <div className="profile_content">
                                          <h3>
                                            Dastgeer Traders{" "}
                                            <i class="ri-bookmark-3-fill"> </i>
                                            <i class="ri-medal-line"></i>
                                          </h3>{" "}
                                          <p>1,627 order(s) | 99.14%</p>
                                        </div>{" "}
                                      </div>
                                      <div className="p2p_modal_contant">
                                        <h3>Remarks</h3>
                                        <p>
                                          My income is from Russia. i
                                          amtransfering my own money from Russia
                                          to your account. I am transfering my own
                                          money from Russia to your account.So i
                                          will deal in cash deposit only... My
                                          income is from Russia. i amtransfering
                                          my own money from Russia to your
                                          account. I am transfering my own money
                                          from Russia to your account.So i will
                                          deal in cash deposit only... My income
                                          is from Russia. i amtransfering my own
                                          money from Russia to your account. I am
                                          transfering my own money from Russia to
                                          your account.So i will deal in cash
                                          deposit only...
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-lg-3">
                                      <div className="modal_available">
                                        <div>
                                          <h3>Available</h3>
                                          <p>446.013334</p>
                                        </div>
                                        <div>
                                          <h3>Limit</h3>
                                          <p>1-456 INR</p>
                                        </div>
                                      </div>
                                      <div className="modal_available mt-3">
                                        <div>
                                          <h3>Price</h3>
                                          <p>90 INR/USDT</p>
                                        </div>
                                        <div className="payment_methods">
                                          <h3>Payment Methods</h3>
                                          <img
                                            src={require("../Image/Table_image/modal_currency.png")}
                                            alt="pic"
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-lg-5 buy_order">
                                      <div className="daily_limit">
                                        <p>
                                          Buy(Remaining Daily Limit:40,000,000INR)
                                        </p>
                                      </div>
                                      <div className="buy-amt">
                                        <div className="flex-grow-1">
                                          <input
                                            type="text"
                                            className="bg_trans_non"
                                          />
                                        </div>
                                        <div>INR</div>
                                      </div>
                                      <div className="usd-amt">
                                        <div className="flex-grow-1">
                                          <input
                                            type="text"
                                            className="bg_trans_non"
                                          />
                                        </div>
                                        <div>USDT</div>
                                      </div>

                                      <div className="place-order">
                                        <p>
                                          Trading password is not required when
                                          placing buy <br />
                                          orders.
                                        </p>
                                        <button>Place Order</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            class="modal fade"
                            id="lgScreen"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="largeScreenModalLabel"
                            aria-hidden="true"
                          >
                            <div
                              class="modal-dialog modal-xl modal-dialog-centered "
                              role="document"
                            >
                              <div class="modal-content p2p">
                                <div class="modal-header">
                                  <h5
                                    class="modal-title"
                                    id="exampleModalLongTitle"
                                  >
                                    Modal title
                                  </h5>
                                  <button
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>

                                <div class="modal-body">
                                  <div className="row">
                                    <div className="col-lg-4 modal_text">
                                      <div className="p2p_row_trades">
                                        {" "}
                                        <div className="row_profile">DA</div>{" "}
                                        <div className="profile_content">
                                          <h3>
                                            Dastgeer Traders{" "}
                                            <i class="ri-bookmark-3-fill"> </i>
                                            <i class="ri-medal-line"></i>
                                          </h3>{" "}
                                          <p>1,627 order(s) | 99.14%</p>
                                        </div>{" "}
                                      </div>
                                      <div className="p2p_modal_contant">
                                        <h3>Remarks</h3>
                                        <p>
                                          My income is from Russia. i
                                          amtransfering my own money from Russia
                                          to your account. I am transfering my own
                                          money from Russia to your account.So i
                                          will deal in cash deposit only... My
                                          income is from Russia. i amtransfering
                                          my own money from Russia to your
                                          account. I am transfering my own money
                                          from Russia to your account.So i will
                                          deal in cash deposit only...
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-lg-3">
                                      <div className="modal_available">
                                        <div>
                                          <h3>Available</h3>
                                          <p>446.013334</p>
                                          <h3>Price</h3>
                                          <p>90 INR/USDT</p>
                                        </div>
                                      </div>
                                      <div className="modal_available sell ">
                                        <div className="mt-3">
                                          <h3>Limit</h3>
                                          <p>1-456 INR</p>
                                        </div>
                                        <div className="payment_methods mt-4">
                                          <h3>Payment Methods</h3>
                                          <img
                                            src={require("../Image/Table_image/modal_currency.png")}
                                            alt="pic"
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-lg-5 buy_order">
                                      <div className="daily_limit">
                                        <p>
                                          Sell(Remaining Daily
                                          Limit:40,000,000INR)
                                        </p>
                                      </div>
                                      <div className="buy-amt sell">
                                        <div className="flex-grow-1">
                                          <input
                                            type="text"
                                            className="bg_trans_non"
                                          />
                                        </div>
                                        <div>INR</div>
                                      </div>
                                      <div className="text-danger">
                                        <p>
                                          The amount cannot be less than 200000
                                        </p>
                                      </div>

                                      <div className="usd-amt">
                                        <div className="flex-grow-1">
                                          <input
                                            type="text"
                                            className="bg_trans_non"
                                          />
                                        </div>
                                        <div>USDT</div>
                                      </div>

                                      <div className="place-order">
                                        {/* <p>
                                      Trading password <br />
                                    </p>
                                    <div className="star">
                                      <div className="star-1">*</div>
                                      <div className="star-1">*</div>
                                      <div className="star-1">*</div>
                                      <div className="star-1">*</div>
                                      <div className="star-1">*</div>
                                      <div className="star-1">*</div>
                                    </div> */}
                                        <button>Place Order</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div></>)}

            </div>
          </div>
        </div>
      </div>
      <Footer />
      <div class="modal fade " id="myModal" role="dialog">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content select_coin">
            <div class="modal-header">
              <h4 class="modal-title ml-0">Identity Verification</h4>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div class="modal-body pt-0">
              {/* <div>
                <label className="lable_KYC">Issuing country/region</label>
                <CountryDropdown />
              </div> */}
              <div className="check_radio">
                <label className="lable_KYC">Select Verification Type</label>
                <ul class="nav nav-tabs">
                  <li class="active">
                    <a data-toggle="tab" href="#Passport" className="active">
                      <div className="image_div">
                        <div className="cicler">
                          <img
                            src="https://assets.staticimg.com/g-biz/externals/2022-06-01/43ef79d27324eaf9.svg"
                            className=""
                          />
                        </div>
                        Address Proof
                      </div>
                      <div>
                        <i class="ri-checkbox-blank-circle-line"></i>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a data-toggle="tab" href="#menu1">
                      <div className="image_div">
                        <div className="cicler">
                          <img
                            src="https://assets.staticimg.com/g-biz/externals/2022-06-01/43ef79d27324eaf9.svg"
                            className=""
                          />
                        </div>
                        ID Proof <small>Recommended</small>
                      </div>
                      <div>
                        <i class="ri-checkbox-blank-circle-line"></i>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a data-toggle="tab" href="#menu2">
                      <div className="image_div">
                        <div className="cicler">
                          <img
                            src="https://assets.staticimg.com/g-biz/externals/2022-06-01/43ef79d27324eaf9.svg"
                            className=""
                          />
                        </div>
                        Driver's License
                      </div>

                      <div>
                        <i class="ri-checkbox-blank-circle-line"></i>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="upload">
                <div class="mb-6 pt-4">
                  <label class="lable_KYC">Upload File</label>

                  <div class="formbold-mb-5 formbold-file-input">
                    <input type="file" name="file" id="file" />
                    <label for="file">
                      <div>
                        <span class="formbold-drop-file">
                          {" "}
                          Drop files here{" "}
                        </span>
                        <span class="formbold-or"> Or </span>
                        <span class="formbold-browse"> Browse </span>
                      </div>
                    </label>
                  </div>

                  <div class="formbold-file-list formbold-mb-5">
                    <div class="formbold-file-item">
                      <span class="formbold-file-name">
                        {" "}
                        banner-design.png{" "}
                      </span>
                      <button>
                        <svg
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0.279337 0.279338C0.651787 -0.0931121 1.25565 -0.0931121 1.6281 0.279338L9.72066 8.3719C10.0931 8.74435 10.0931 9.34821 9.72066 9.72066C9.34821 10.0931 8.74435 10.0931 8.3719 9.72066L0.279337 1.6281C-0.0931125 1.25565 -0.0931125 0.651788 0.279337 0.279338Z"
                            fill="currentColor"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0.279337 9.72066C-0.0931125 9.34821 -0.0931125 8.74435 0.279337 8.3719L8.3719 0.279338C8.74435 -0.0931127 9.34821 -0.0931123 9.72066 0.279338C10.0931 0.651787 10.0931 1.25565 9.72066 1.6281L1.6281 9.72066C1.25565 10.0931 0.651787 10.0931 0.279337 9.72066Z"
                            fill="currentColor"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <Button>Continue</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
