import React, { useState, useEffect } from "react";
import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./login_regi_footer";
import Select from "react-dropdown-select";
import { Link } from "react-router-dom";

function Home() {
  const options = [
    {
      value: 1,
      label: "Leanne Graham",
    },
    {
      value: 2,
      label: "Ervin Howell",
    },
  ];
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const initialFormValue = {
    password: "",
    confirmPassword: "",
  };

  const [passwordValidate, setpasswordValidate, passwordValidateref] =
    useState(false);
  const [formValue, setformValue] = useState(initialFormValue);
  const [
    confirmPasswordValidate,
    setconfirmPasswordValidate,
    confirmPasswordValidateref,
  ] = useState(false);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [passHide, setPasshide] = useState(false);
  const [inputType, setinputType] = useState("password");
  const [passHidconf, setPasshideconf] = useState(false);
  const [inputTypeconf, setinputTypeconf] = useState("password");
  const [siteLoader, setSiteLoader] = useState(false);

  const { password, confirmPassword } = formValue;

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setformValue(formData);
    validate(formData);
  };

  const validate = async (values) => {
    const errors = {};

    if (!values.password) {
      errors.password = "Password is a required field";
      setpasswordValidate(true);
    } else if (values.password.length < 5 || values.password.length > 25) {
      setpasswordValidate(true);
      errors.password =
        "Password should not below 5 above 25 letters !";
    } else if (!values.password.match(/[a-z]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least lower character !";
    } else if (!values.password.match(/[A-Z]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least upper character !";
    } else if (!values.password.match(/[0-9]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at One digit character !";
    } else if (!values.password.match(/[!@#$%^&*]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least one special character !";
    } else {
      setpasswordValidate(false);
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = "Confirm password is a required field";
      setconfirmPasswordValidate(true);
    } else if (
      values.password &&
      values.confirmPassword &&
      values.password !== values.confirmPassword
    ) {
      // errors.password = 'Password and confirm password does not match';
      errors.confirmPassword = "Password and Confirm password does not match";
      setconfirmPasswordValidate(true);
    } else {
      setconfirmPasswordValidate(false);
    }

    setvalidationnErr(errors);
    return errors;
  };

  const passwordHide = (data) => {
    if (data == "hide") {
      setPasshide(true);
      setinputType("text");
    } else {
      setPasshide(false);
      setinputType("password");
    }
  };

  const passwordHideconf = (data) => {
    if (data == "hide") {
      setPasshideconf(true);
      setinputTypeconf("text");
    } else {
      setPasshideconf(false);
      setinputTypeconf("password");
    }
  };

  const formSubmitchange = async () => {
    validate(formValue);
    console.log(formValue);
    if (
      passwordValidateref.current === false &&
      confirmPasswordValidateref.current === false
    ) {
      console.log(formValue);
    } else {
    }
  };

  return (
    <div className="header ">
      <Header />
      <main>
        <div className="login_form">
          <div className="container">
            {siteLoader == true ? (
              <i class="fa-solid fa-spinner fa-spin"></i>
            ) : (
              <>
                <div className="row justify-content-center">
                  <div className="col-lg-5">
                    <div className="card_login mt25">
                      <h3 className="mb-2">Reset Password</h3>
                      <div>
                        <label className="lable_text">New Password</label>
                        <div class="form_login_input">
                          <div className="flex_input_posion">
                            <input
                              type={inputType}
                              class="form-control"
                              placeholder="New Password"
                              name="password"
                              value={password}
                              onChange={handleChange}
                            />
                            <div className="eye_content">
                              {passHide == false ? (
                                <i
                                  className="fa fa-eye-slash icons"
                                  onClick={() => passwordHide("hide")}
                                ></i>
                              ) : (
                                <i
                                  className="fa fa-eye icons"
                                  onClick={() => passwordHide("show")}
                                ></i>
                              )}
                            </div>
                          </div>
                        </div>
                        <div>
                          {passwordValidate == true ? (
                            <p className="errorcss">
                              {" "}
                              {validationnErr.password}{" "}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <label className="lable_text">Confirm Password</label>

                        <div class="form_login_input">
                          <div className="flex_input_posion">
                            <input
                              type={inputTypeconf}
                              class="form-control"
                              placeholder="Confirm New Password"
                              name="confirmPassword"
                              value={confirmPassword}
                              onChange={handleChange}
                            />
                            <div className="eye_content">
                              {passHidconf == false ? (
                                <i
                                  className="fa fa-eye-slash icons"
                                  onClick={() => passwordHideconf("hide")}
                                ></i>
                              ) : (
                                <i
                                  className="fa fa-eye icons"
                                  onClick={() => passwordHideconf("show")}
                                ></i>
                              )}
                            </div>
                          </div>
                        </div>
                        <div>
                          {confirmPasswordValidate == true ? (
                            <p className="errorcss">
                              {" "}
                              {validationnErr.confirmPassword}{" "}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>

                        <div class="form_login_input mt-4">
                          {buttonLoader == false ? (
                            <Button className="mt-5" onClick={formSubmitchange}>
                              Change Password
                            </Button>
                          ) : (
                            <Button>Loading...</Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Home;
