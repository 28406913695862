import React, { useState, useEffect } from "react";
import useStateRef from "react-usestateref";

import Header from "./Header";
import Footer from "./Footer";
import { Dropdown } from "semantic-ui-react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import moment from "moment";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { getMethod } from "../core/service/common.api";

function Home() {
    const navigate = useNavigate();
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        var get_id = window.location.href.split("launchpad_buy/")[1];

        console.log(get_id, "get_id");
        get_launchpad_details(get_id);
        getUserbalance();
        getBalance();
        var token = localStorage.getItem("user_token");
        if (!token) {
            navigate("/login");
        }
    }, [0]);
    const [supplyPercentage, setsupplyPercentage] = useState();
    const cancel_function = () => {
        console.log("===========work");
        navigate("/launchpadlistnew");
    };
    const [launchpadDatails, setLaunchpadDetails, launchpadDatailsref] =
        useStateRef("");

    var get_launchpad_details = async (get_id) => {
        setIsLoaded(true);
        var obj = {
            id: get_id,
        };
        var data = {
            apiUrl: apiService.getOneLaunchpad,
            payload: obj,
        };
        var resp = await postMethod(data);
        setIsLoaded(false);

        setLaunchpadDetails(resp.Message);
        console.log(launchpadDatailsref.current);
        var percentage =
            (launchpadDatailsref.current.totalSupply /
                launchpadDatailsref.current.token_supply) *
            100;
        console.log(percentage);
        setsupplyPercentage(percentage);
        setfees(resp.fees);
        holding_currency();
    };
    const [totalBalance, setTotalBalance] = useState();
    const [fees, setfees] = useState();

    const getUserbalance = async () => {
        var data = {
            apiUrl: apiService.getUSDTBalance,
        };
        var resp = await getMethod(data);
        if (resp.status) {
            setTotalBalance(resp.balance);
        }
    };

    const [calculation, setCalculation, calculationref] = useStateRef(true);
    const [maxcurrency, setMaxCurrency] = useState();
    const opencalculating1 = () => {
        if (calculationref.current == false) {
            setCalculation(true);
        } else {
            setCalculation(false);
        }

        var calculating = Number(totalBalance / launchpadDatailsref.current.price);
        var fees_calc = (calculating * fees) / 100;
        calculating = calculating - +fees_calc;
        setMaxCurrency(calculating);
    };

    const [calculation2, setCalculation2, calculation2ref] = useStateRef(false);
    const opencalculating2 = () => {
        setCalculation(true);
        opencalculating1();
        if (calculation2ref.current == false) {
            setCalculation2(true);
        } else {
            setCalculation2(false);
        }
    };

    const [calculation3, setCalculation3, calculation3ref] = useStateRef(false);

    const delay = 5;

    const opencalculating3 = () => {
        if (calculation3ref.current == false) {
            setCalculation3(true);
        } else {
            setCalculation3(false);
        }
        setTimeout(() => {
            setCalculation4(true);
            setCalculation3(false);
        }, 3000);
    };

    const [calculation4, setCalculation4, calculation4ref] = useStateRef(false);
    const opencalculating4 = () => {
        setCalculation3(true);
        if (calculation4ref.current == false) {
            setCalculation4(true);
        } else {
            setCalculation4(false);
        }
    };
    const [allCurrencyFiat, setallCurrencyFiat] = useState([]);
    const [currentCurrency, setcurrentCurrency, currentCurrencyref] =
        useStateRef();
    const [currentBalance, setcurrentBalance] = useState(0);
    const [records, setrecords] = useState([]);
    const [CurrentINRValue, setCurrentINRValue] = useState(0);

    const getBalance = async () => {
        try {
            var data = {
                apiUrl: apiService.launchPadcurrency,
            };
            var resp = await getMethod(data);
            if (resp) {
                console.log(resp.data);
                var currArrayCrypto = [];
                var currArrayFiat = [];
                var data = resp.Message;
                setrecords(data);
                for (var i = 0; i < data.length; i++) {
                    var obj = {
                        key: data[i].currid,
                        value: data[i].currencysymbol,
                        text: data[i].currencysymbol,
                        balance: data[i].currencyBalance,
                    };
                    currArrayCrypto.push(obj);
                }
                withCash(currArrayCrypto[0]);
                setallCurrencyFiat(currArrayCrypto);
                console.log(currArrayCrypto, "=-=-=-");
            }
        } catch (error) { }
    };

    const withCash = async (e, value) => {
        if (value != "" && value != null && value != undefined) {
            setcurrentCurrency(value.value);
            let indexing = records.findIndex((x) => x.currencysymbol == value.value);
            console.log(indexing, "indexing");

            if (indexing != -1) {
                var getBalanceData = records[indexing].currencyBalance;
                console.log(getBalanceData, "getBalanceData");

                setcurrentBalance(getBalanceData);
            }
            var obj = {
                currency: currentCurrencyref.current,
            };
            var data = {
                apiUrl: apiService.getCurrencyConvertion,
                payload: obj,
            };
            var resp = await postMethod(data);
            if (resp) {
                console.log(resp.inr_value, "resp.inr_value");
                setCurrentINRValue(+resp.inr_value);
                setcurrenctConversionPrice(+resp.inr_value);
            }
        }
    };

    const initialFormValue = {
        typeAmount: "",
    };
    const [formValue, setFormValue] = useState(initialFormValue);
    const [totalBuyingAmount, settotalBuyingAmount, totalBuyingAmountref] =
        useStateRef(0);
    const [
        currenctConversionPrice,
        setcurrenctConversionPrice,
        currenctConversionPriceref,
    ] = useStateRef();
    const [admin_fees, setadmin_fees, admin_feesref] = useStateRef(0);
    const [commitAmount, setcommitAmount, commitAmountref] = useStateRef(0);
    const [opentab, setopentab, opentabref] = useStateRef(false);

    const handleChange = async (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        let formData = { ...formValue, ...{ [name]: value } };
        setFormValue(formData);
        if (formData.typeAmount < currentBalance) {
            var currencyPrice =
                +currenctConversionPriceref.current * +formData.typeAmount;
            settotalBuyingAmount(+currencyPrice / launchpadDatailsref.current.price);
            var adminfees = Number((fees * totalBuyingAmountref.current) / 100);

            setadmin_fees(adminfees);
            console.log("--0-0-0-", admin_feesref.current);
            console.log("--fees-0-0-", fees);
            console.log("--0-0-0-", adminfees);
            var data = totalBuyingAmountref.current - adminfees;
            setcommitAmount(data);
            console.log(totalBuyingAmountref.current);
            console.log(data, "data");
            console.log(currenctConversionPriceref.current);
            console.log(launchpadDatailsref.current.price + fees);
            if (launchpadDatailsref.current.hardCap >= formData.typeAmount) {
                if (launchpadDatailsref.current.softCap <= formData.typeAmount) {
                    setopentab(true);
                } else {
                    toast.error(
                        "Enter greater than " + launchpadDatailsref.current.softCap
                    );
                }
            } else {
                toast.error("Enter less than " + launchpadDatailsref.current.hardCap);
            }
        } else {
            toast.error("Insufficient Balance");
        }
    };

    const [add_amount, set_add_amount, add_amountref] = useStateRef();
    const [deduct_amount, set_deduct_amount, deduct_amountref] = useStateRef(0);
    const [deduct_currency, set_deduct_currency, deduct_currencyref] =
        useStateRef(0);
    var tokenpurchase = async () => {
        var get_id = window.location.href.split("/").at(4);
        console.log(get_id, "get_id");
        var obj = {
            givetoken: currentCurrencyref.current,
            buytoken: launchpadDatails.symbol,
            givetokenprice: formValue.typeAmount,
            buytokenprice: commitAmountref.current,
            fees: admin_feesref.current,
            token_id: get_id,
        };

        var data = {
            apiUrl: apiService.LaunchpadtokenPurchase,
            payload: obj,
        };
        var resp = await postMethod(data);
        if (resp.status == true) {
            set_add_amount(resp.add_amount);
            set_deduct_amount(resp.deduct_amount);
            set_deduct_currency(resp.deduct_currency);
            toast.success(resp.Message);
            // navigate("/Dashboardpage")
            opencalculating3();
            opencalculating2();
            opencalculating1();
        } else {
            toast.error(resp.Message);
        }
    };
    const [holding, setholding] = useState();
    const holding_currency = async () => {
        var obj = {
            currency_name: launchpadDatailsref.current.symbol,
        };
        var data = {
            apiUrl: apiService.holding_currency,
            payload: obj,
        };
        var resp = await postMethod(data);
        setholding(resp.balance);
    };

    return (
        <div className="header ">
            <Header />
            <div>
                <div className="body_hXuuO ">
                    <div className="lrtcss-wxjc90 e161ieae2">
                        <div className="lrtcss-1p2kb7b e161ieae0">
                            {isLoaded == true ? (
                                " "
                            ) : (
                                <section className="launch_pad_apply_section">
                                    <div className="container">
                                        <div className="row my-5">
                                            <div className="col-lg-12">
                                                <div className={` step6 card_login w-100 mb-5`}>
                                                    <div className="launch_now_step1 mb-3 mt-3">
                                                        <div className="in_progress_btn">In Progress</div>
                                                        <p className="step5_content">
                                                            <span className="mx-3">
                                                                Start Date :{" "}
                                                                {moment(launchpadDatails.startDate).format("L")}
                                                            </span>{" "}
                                                            <span className="mx-3">
                                                                End Date :{" "}
                                                                {moment(launchpadDatails.endDate).format("L")}
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className="price_pair">
                                                        <p className="step5_content">
                                                            <div className="paitr_content">
                                                                <img
                                                                    src={launchpadDatails.currency_image}
                                                                    width="50px"
                                                                />
                                                                <> {launchpadDatails.symbol}</>
                                                            </div>
                                                        </p>
                                                    </div>
                                                    <div className="launch_now_step1 mb-3 mt-3">
                                                        <h2 className="step1_title">
                                                            Subscription Timeline
                                                        </h2>
                                                    </div>
                                                    <div className="launch_now_step1 mb-3 mt-3">
                                                        <p className="step5_content">
                                                            <span>{launchpadDatails.description}</span>{" "}
                                                        </p>
                                                    </div>
                                                    <div className="row justify-content-center">
                                                        <div className="col-lg-6">
                                                            <div className="lanchbuy">
                                                                <p>Type</p>
                                                                <h5>{launchpadDatails.address_type}</h5>
                                                            </div>
                                                            <div className="lanchbuy">
                                                                <p>Network </p>
                                                                <h5>{launchpadDatails.network}</h5>
                                                            </div>

                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="lanchbuy">
                                                                <p>Total Funds</p>
                                                                <h5>{launchpadDatails.totalSupply} {launchpadDatails.symbol}</h5>
                                                            </div>
                                                            <div className="lanchbuy">
                                                                <p>Price</p>
                                                                <h5> 1 {launchpadDatails.symbol} ={" "}
                                                                    {launchpadDatails.price} USDT</h5>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <Button className="lanbutton"
                                                                data-toggle="modal"
                                                                data-target="#myModal">
                                                                Deposit
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3"></div>
                                        </div>
                                    </div>
                                </section>
                            )}
                        </div>
                    </div>
                </div>


                <div class="modal fade bd-example-modal-lg" id="myModal" role="dialog">
                    <div class="modal-dialog modal-dialog-centered modal-lg">
                        <div class="modal-content select_coin">
                            <div class="modal-header">
                                <h4 class="modal-title ml-0">Buy {launchpadDatails.symbol}</h4>
                                <button type="button" class="close" data-dismiss="modal">
                                    &times;
                                </button>
                            </div>
                            <div class="modal-body pt-0">
                                <div className="lanchbuy d-flex flex-col">
                                    <p>Max commitment limit:</p>
                                    <h5 className="ml-3">{launchpadDatails.address_type}</h5>
                                </div>
                                <div className="lanchbuy d-flex flex-col">
                                    <p>Wallet balance</p>
                                    <h5 className="ml-3">{launchpadDatails.address_type}</h5>
                                </div>
                            </div>
                            <label className="lable_text">Select Network</label>

<div class="form_login_input position_eye">
  <select
    className="network_dropdown"
    // value={selectedOption}
    // onChange={handleChangedrop}
  >
    {/* {countryOptions &&
      countryOptions.map((item, i) => (
        <option key={i} value={item.value}>
          {item.value}
        </option>
      ))} */}
  </select>
</div>
{/* {endDatevalidateref.current == true ? (
  <p className="text-danger">
    {" "}
    {validationnErr.network}{" "}
  </p>
) : (
  ""
)} */}
                            <div class="modal-footer">

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default Home;
