import React, {useState, useEffect} from "react";
import Header from "./Header";
import {Button} from "@mui/material";
import Footer from "./login_regi_footer";
import Select from "react-dropdown-select";
import {Link} from "react-router-dom";
import OTPInput, {ResendOTP} from "otp-input-react";
function Home() {
  const [OTP, setOTP] = useState("");
  const options = [
    {
      value: 1,
      label: "Leanne Graham",
    },
    {
      value: 2,
      label: "Ervin Howell",
    },
  ];
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  const [siteLoader, setSiteLoader] = useState(false);
  return (
    <div className="header ">
      <Header />
      <main>
        <div className="login_form">
          <div className="container">
          {siteLoader == true ? <i class="fa-solid fa-spinner fa-spin"></i> : (<>
              <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="card_login">
                  <span className="check">
                    <i class="ri-check-line"></i>
                  </span>
                  <h3>Verification completed</h3>
                  <div>
                    <div class="form_login_input">
                      <label className="mb-4">
                        Congratulations! your registration is confirmed!
                      </label>
                    </div>

                    <div class="form_login_input">
                      <Button>Login</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div></>)}
          
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Home;
