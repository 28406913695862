import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";

import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Moment from "moment";

import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { getMethod } from "../core/service/common.api";

function Home() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getLaunchpad();
  }, []);

  const [upcoming, setupcoming] = useState([]);
  const [inprogress, setinprogress] = useState([]);
  const [expired, setexpired] = useState([]);
  const [cuurTab, setcuurTab] = useState([]);

  const getLaunchpad = async () => {
    var data = {
      apiUrl: apiService.getAllLaunchpad,
      payload: { search: "" },
    };
    var resp = await postMethod(data);
    console.log(resp, "=-=-=-=-=resp");
    var responseData = resp.data;
    if (resp.status) {
      setupcoming(responseData.UpcomingTokens);
      setinprogress(responseData.inprogressToken);
      setexpired(responseData.expiredTokens);
    }
  };

  const navigatebar = () => {
    navigate("/LaunchpadApply");
  };
  const handleSearch = async (value) => {
    console.log(value, "=-=-=-value");
    var data = {
      apiUrl: apiService.getAllLaunchpad,
      payload: { search: value },
    };
    var resp = await postMethod(data);
    console.log(resp, "=-=-=-=-=resp");
    var responseData = resp.data;
    if (resp.status) {
      setupcoming(responseData.UpcomingTokens);
      setinprogress(responseData.inprogressToken);
      setexpired(responseData.expiredTokens);
    }
  };

  const details_page_moving = (data) => {
    navigate(`/launchpad_buy/${data._id}`);
  };

  const [siteLoader, setSiteLoader] = useState(false);

  return (
    <div className="header ">
      <Header />
      <div>
        <div className="body_hXuuO ">
          <div className="lrtcss-wxjc90 e161ieae2">
            <div className="lrtcss-1p2kb7b e161ieae0">
              {siteLoader == true ? <i class="fa-solid fa-spinner fa-spin"></i> : (<>
                <section className="staking_banner_section launchpad_section">
                  <div className="container">
                    <div className="row ">
                      <div className="col-lg-7">
                        <div className="banner_left_content launchpad pl-0">
                          <h2>Launchpad</h2>
                          <p className="launchpad_content mt-4">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat.
                          </p>
                        </div>
                        <div className="launch_apply-btn mt-5">
                          <a href="/launchpad_apply">Apply Now</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="p2p_buy_sell_section">
                  <div className="container">
                    <div className="p2p_tabs mb-5">
                      <div class="p2p_padding mt-5">
                        <ul class="nav nav-tabs" id="myTabs">
                          <li class="nav-item overview">
                            <a
                              class="nav-link active"
                              id="home-tab"
                              data-toggle="tab"
                              href="#home"
                            >
                              Upcoming
                            </a>
                          </li>
                          <li class="nav-item overview">
                            <a
                              class="nav-link"
                              id="profile-tab"
                              data-toggle="tab"
                              href="#profile"
                            >
                              In Progress
                            </a>
                          </li>
                          <li class="nav-item overview">
                            <a
                              class="nav-link"
                              id="profile-tab"
                              data-toggle="tab"
                              href="#completed"
                            >
                              Expired
                            </a>
                          </li>
                        </ul>

                        <div class="p2p_tab tab-content mt-5">

                          <div class="tab-pane fade show active" id="home">
                            <section className="launchpad_card_section">
                              <div className="container">
                                <div className="row">
                                  <div className="col-lg-4">

                                    {upcoming.length > 0 ? (
                                      upcoming.map((data, i) => {
                                        return (
                                          <div className="launchpad_card">
                                            <img
                                              src={data.currency_image}
                                            />
                                            <div className="launchpad_card_1">
                                              <div className="coming_soon_card">
                                                <div className="coming_soon_card_title mt-3">
                                                  {data.currency_name} {" "} ({data.symbol})
                                                </div>
                                                <div
                                                  className="coming_soon_btn mt-3"
                                                  data-toggle="modal"
                                                  data-target="#lgScreen"
                                                  onClick={() => details_page_moving(data)}
                                                >
                                                  Coming Soon
                                                </div>
                                              </div>
                                              <p>
                                                {data.description}
                                              </p>
                                              <div className="token_content">
                                                <div className="token_text">
                                                  Tokens Offered:
                                                </div>
                                                <div className="token_syn">
                                                  {data.totalSupply} {" "} {data.symbol}
                                                </div>
                                              </div>
                                              <div className="sale_content mt-2">
                                                <div className="token_text">
                                                  Sale Price:
                                                </div>
                                                <div className="token_syn">
                                                  1 {data.symbol} =$ {data.price}
                                                </div>
                                              </div>
                                              <div className="sale_content mt-2">
                                                <div className="token_text">
                                                  Start Date:
                                                </div>
                                                <div className="token_syn">
                                                  {Moment(data.startDate).format("lll")}
                                                </div>
                                              </div>
                                              {/* <div className="card_timer mt-3">
                                                Time until you can invest:{" "}
                                                <span>13</span> days <span>02 </span>
                                                hours <span>37</span>mins
                                              </div> */}
                                            </div>
                                          </div>
                                        );
                                      })
                                    ) : (
                                      ""
                                    )}


                                  </div>

                                </div>
                              </div>
                            </section>
                          </div>
                          <div class="tab-pane fade" id="profile">
                            <div className="col-lg-4">
                              {inprogress.length > 0 ? (
                                inprogress.map((data, i) => {
                                  return (
                                    <div className="launchpad_card">
                                      <img
                                        src={data.currency_image}
                                      />
                                      <div className="launchpad_card_1">
                                        <div className="coming_soon_card">
                                          <div className="coming_soon_card_title mt-3">
                                            {data.currency_name} {" "} ({data.symbol})
                                          </div>
                                          <div className="in_progress_btn mt-3"
                                            data-toggle="modal"
                                            data-target="#lgScreen"
                                            onClick={() => details_page_moving(data)}
                                            >
                                            In Progress
                                          </div>
                                        </div>
                                        <p>
                                          {data.description}
                                        </p>
                                        <div className="token_content">
                                          <div className="token_text">
                                            Tokens Offered:
                                          </div>
                                          <div className="token_syn">
                                            {data.totalSupply} {" "} {data.symbol}
                                          </div>
                                        </div>
                                        <div className="sale_content mt-2">
                                          <div className="token_text">
                                            Sale Price:
                                          </div>
                                          <div className="token_syn">
                                            1 {data.symbol} =$ {data.price}
                                          </div>
                                        </div>
                                        <div className="sale_content mt-2">
                                          <div className="token_text">
                                            End Date:
                                          </div>
                                          <div className="token_syn">
                                            {Moment(data.endDate).format("lll")}
                                          </div>
                                        </div>
                                        {/* <div className="card_timer mt-3">
                                                Time until you can invest:{" "}
                                                <span>13</span> days <span>02 </span>
                                                hours <span>37</span>mins
                                              </div> */}
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div class="tab-pane fade" id="completed">
                            <div className="col-lg-4">
                              {expired.length > 0 ? (
                                expired.map((data, i) => {
                                  return (
                                    <div className="launchpad_card">
                                      <img
                                        src={data.currency_image}
                                      />
                                      <div className="launchpad_card_1">
                                        <div className="coming_soon_card">
                                          <div className="coming_soon_card_title mt-3">
                                            {data.currency_name} {" "} ({data.symbol})
                                          </div>
                                          <div className="completed_btn mt-3"
                                          
                                            >
                                            Completed
                                          </div>
                                        </div>
                                        <p>
                                          {data.description}
                                        </p>
                                        <div className="token_content">
                                          <div className="token_text">
                                            Tokens Offered:
                                          </div>
                                          <div className="token_syn">
                                            {data.totalSupply} {" "} {data.symbol}
                                          </div>
                                        </div>
                                        <div className="sale_content mt-2">
                                          <div className="token_text">
                                            Sale Price:
                                          </div>
                                          <div className="token_syn">
                                            1 {data.symbol} =$ {data.price}
                                          </div>
                                        </div>
                                        <div className="sale_content mt-2">
                                          <div className="token_text">
                                            Start Date:
                                          </div>
                                          <div className="token_syn">
                                            {Moment(data.startDate).format("lll")}
                                          </div>
                                        </div>
                                        <div className="sale_content mt-2">
                                          <div className="token_text">
                                            End Date:
                                          </div>
                                          <div className="token_syn">
                                            {Moment(data.endDate).format("lll")}
                                          </div>
                                        </div>
                                        {/* <div className="card_timer mt-3">
                                                Time until you can invest:{" "}
                                                <span>13</span> days <span>02 </span>
                                                hours <span>37</span>mins
                                              </div> */}
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                ""
                              )}
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <div
                  class="modal fade"
                  id="lgScreen"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="largeScreenModalLabel"
                  aria-hidden="true"
                >
                  <div
                    class="modal-dialog modal-xl modal-dialog-centered "
                    role="document"
                  >
                    <div class="modal-content p2p">
                      <div class="modal-header">
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>

                      </div>
                      <div className="start_end_date">
                        <p>Start Date <span>{Moment(isModalOpen.startDate).format("lll")}</span></p>
                        <p>End Date <span>{Moment(isModalOpen.endDate).format("lll")}</span></p>
                      </div>

                      <div class="modal-body">
                        <div className="container">
                          <div className="launch_popup_title">
                            <div className="pop_up_logo">
                              <img
                                src={isModalOpen.currency_image}
                                width="50px"
                              />
                            </div>

                            <div className="pop_up_heading">{isModalOpen.symbol}</div>
                          </div>

                          <div className="launch_popup_content mt-5">
                            <h2>{isModalOpen.currency_name}</h2>
                            <p>{isModalOpen.description}</p>
                          </div>

                          <div className="row">

                            <div className="col-lg-6 mt-4">
                              <div className="subscription_text">
                                <p>Type</p>
                                <h3>{isModalOpen.address_type}</h3>
                              </div>
                              <div className="subscription_text">
                                <p>Network</p>
                                <h3>{isModalOpen.network}</h3>
                              </div>
                            </div>
                            <div className="col-lg-6 mt-4">
                              <div className="subscription_text">
                                <p>Total Funds</p>
                                <h3>{isModalOpen.totalSupply}</h3>
                              </div>
                              <div className="subscription_text">
                                <p>Price</p>
                                <h3>1 {isModalOpen.symbol} = {isModalOpen.price} $</h3>
                              </div>
                            </div>
                          </div>

                          <div className="buy_button">
                            <div>Buy</div></div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </>)} </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Home;
