import React, {useState, useEffect} from "react";
import Header from "./Header";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import {Link} from "react-router-dom";
import {Dropdown} from "semantic-ui-react";

function Home() {
  const friendOptions = [
    {
      key: "BTC",
      text: "BTC",
      value: "BTC",
      image: {
        avatar: true,
        src: "https://react.semantic-ui.com/images/avatar/small/jenny.jpg",
      },
    },
    {
      key: "AAVE",
      text: "AAVE",
      value: "AAVE",
      image: {
        avatar: true,
        src: "https://react.semantic-ui.com/images/avatar/small/elliot.jpg",
      },
    },
    {
      key: "ACH",
      text: "ACH",
      value: "ACH",
      image: {
        avatar: true,
        src: "https://react.semantic-ui.com/images/avatar/small/stevie.jpg",
      },
    },
    {
      key: "ADA",
      text: "ADA",
      value: "ADA",
      image: {
        avatar: true,
        src: "https://react.semantic-ui.com/images/avatar/small/christian.jpg",
      },
    },
    {
      key: "AGIX",
      text: "AGIX",
      value: "AGIX",
      image: {
        avatar: true,
        src: "https://react.semantic-ui.com/images/avatar/small/matt.jpg",
      },
    },
    {
      key: "AIOZ",
      text: "AIOZ",
      value: "AIOZ",
      image: {
        avatar: true,
        src: "https://react.semantic-ui.com/images/avatar/small/justen.jpg",
      },
    },
  ];
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  return (
    <div className="header ">
      <Header />
      <div>
        <div className="body_hXuuO ">
          <div className="lrtcss-wxjc90 e161ieae2">
            <div className="lrtcss-1p2kb7b e161ieae0 chat">
              <div className="container">
                <div className="row">
                  <div className="col-lg-7">
                    <section className="place_order_header">
                      <div className="place_order_content">
                        <h1>Order Placed</h1>
                        <p>
                          Complete the payment within <span>29</span>:
                          <span>41</span>. Otherwise,the order will be canceled
                        </p>
                        <h3 className="order-text">
                          Please go to the payment platform you have selected to
                          Complete the payment.Once the payment <br /> is
                          complete, return to Sellcrypt to proceed with your order
                        </h3>
                      </div>

                      <div className="p2p_buy_box">
                        <div className="buy_box_logo">
                          <div>
                            {" "}
                            <img
                              src={require("../Image/Table_image/chat_logo.png")}
                            />
                          </div>
                          <div className="text-buy">Buy</div>
                          <div className="text-usdt">USDT</div>
                        </div>

                        <div className="total amt-usd mt-4">
                          <div className="total-text amt">Total Amount</div>
                          <div className="usd-bold">
                            500 USD
                            <i class="ri-file-copy-line"></i>
                          </div>
                        </div>

                        <div className="total mt-4">
                          <div className="total-text">Price</div>
                          <div className="sub-text">1 USD/USDT</div>
                        </div>
                        <div className="total mt-4">
                          <div className="total-text">Oty</div>
                          <div className="sub-text">500 USDT</div>
                        </div>
                        <div className="total mt-4">
                          <div className="total-text">Other Party</div>
                          <div className="sub-text">Volcano()</div>
                        </div>
                        <div className="total mt-4">
                          <div className="total-text">Oeder ID</div>
                          <div className="sub-text order">
                            64a3b97902fcc100014be
                            <i class="ri-file-copy-line"></i>
                          </div>
                        </div>
                        <div className="total amt-usd mt-4">
                          <div className="total-text">Time</div>
                          <div className="sub-text">2023-07-04 14:17:31</div>
                        </div>

                        <div className="payment-method mt-4">
                          <div className="total-text">Payment Method</div>
                          <div className="payment-dropdown">
                            {/* <Dropdown
                              selection
                              options={friendOptions}
                              placeholder="SPB- SYstem of fast payment"
                            /> */}
                          </div>
                        </div>
                        <div className="pay-btn mt-3">
                          <button className="total-text">Pay</button>
                        </div>
                        <div className="cancel mt-4">Cancel Order</div>
                      </div>
                    </section>
                  </div>

                  <div className="col-lg-5">
                    <section className="p2p_chat_section">
                      <div className="p2p_chat">
                        <div className="chat_profile">VO</div>
                        <div className="profile_content">
                          {" "}
                          <h3 className="volcano">
                            Volcano <i class="ri-shield-star-fill"></i>
                          </h3>
                          <p>41 order(s) | 73.21%</p>
                        </div>
                      </div>
                      <div className="msg-box">
                        <div className="sender">
                          <p>Payment Completed</p>
                        </div>
                        <div className="receiver mt-3">
                          <p>Ok</p>
                        </div>
                        <div className="sender mt-3">
                          <p>Payment Completed</p>
                        </div>
                        <div className="receiver mt-3">
                          <p>Ok</p>
                        </div>
                        <div className="sender mt-3">
                          <p>Payment Completed</p>
                        </div>
                        <div className="receiver mt-3">
                          <p>Ok</p>
                        </div>
                        <div className="sender mt-3">
                          <p>Payment Completed</p>
                        </div>
                        <div className="receiver mt-3">
                          <p>Ok</p>
                        </div>
                        <div className="sender mt-3">
                          <p>Payment Completed</p>
                        </div>
                        <div className="receiver mt-3">
                          <p>Ok</p>
                        </div>
                        <div className="sender mt-3">
                          <p>Payment Completed</p>
                        </div>
                        <div className="receiver mt-3">
                          <p>Ok</p>
                        </div>
                        <div className="sender mt-3">
                          <p>Payment Completed</p>
                        </div>
                        <div className="receiver mt-3">
                          <p>Ok</p>
                        </div>
                        <div className="sender mt-3">
                          <p>Payment Completed</p>
                        </div>
                        <div className="receiver mt-3">
                          <p>Ok</p>
                        </div>
                        <div className="sender mt-3">
                          <p>Payment Completed</p>
                        </div>
                        <div className="receiver mt-3">
                          <p>Ok</p>
                        </div>
                        <div className="sender mt-3">
                          <p>Payment Completed</p>
                        </div>
                        <div className="receiver mt-3">
                          <p>Ok</p>
                        </div>
                      </div>
                      <div className="msg-text-box">
                        <input type="text" placeholder="Enter Message" />
                        <i class="ri-send-plane-2-fill"></i>
                      </div>
                    </section>
                  </div>
                </div>
                <section className="trading_accordion_section mb-5 mt-5">
                  <div className="container">
                    <div className="trading_accordion">
                      <h1>FAQ</h1>
                      <div class="container">
                        <div id="accordion" class="accordion">
                          <div class="card p2p mb-0">
                            <div
                              class="card-header collapsed p2p chat"
                              data-toggle="collapse"
                              href="#collapseOne"
                            >
                              <a class="card-title p2p">
                                What is P2P cryptocurrency trading?
                              </a>
                            </div>
                            <div
                              id="collapseOne"
                              class="card-body collapse"
                              data-parent="#accordion"
                            >
                              <p>
                                Anim pariatur cliche reprehenderit, enim eiusmod
                                high life accusamus terry richardson ad squid. 3
                                wolf moon officia aute, non cupidatat skateboard
                                dolor brunch. Food truck quinoa nesciunt laborum
                                eiusmod. Brunch 3 wolf moon tempor, sunt aliqua
                                put a bird on it squid single-origin coffee
                                nulla assumenda shoreditch et. Nihil anim
                                keffiyeh helvetica, craft beer labore wes
                                anderson cred nesciunt sapiente ea proident. Ad
                                vegan excepteur butcher vice lomo. Leggings
                                occaecat craft beer farm-to-table, raw denim
                                aesthetic synth nesciunt you probably haven't
                                heard of them accusamus labore sustainable VHS.
                              </p>
                            </div>
                            <div
                              class="card-header collapsed p2p chat"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseTwo"
                            >
                              <a class="card-title p2p">
                                Which is the best P2P crypto exchange?
                              </a>
                            </div>
                            <div
                              id="collapseTwo"
                              class="card-body collapse"
                              data-parent="#accordion"
                            >
                              <p>
                                Anim pariatur cliche reprehenderit, enim eiusmod
                                high life accusamus terry richardson ad squid. 3
                                wolf moon officia aute, non cupidatat skateboard
                                dolor brunch. Food truck quinoa nesciunt laborum
                                eiusmod. Brunch 3 wolf moon tempor, sunt aliqua
                                put a bird on it squid single-origin coffee
                                nulla assumenda shoreditch et. Nihil anim
                                keffiyeh helvetica, craft beer labore wes
                                anderson cred nesciunt sapiente ea proident. Ad
                                vegan excepteur butcher vice lomo. Leggings
                                occaecat craft beer farm-to-table, raw denim
                                aesthetic synth nesciunt you probably haven't
                                heard of them accusamus labore sustainable VHS.
                              </p>
                            </div>
                            <div
                              class="card-header collapsed p2p chat"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseThree"
                            >
                              <a class="card-title p2p">
                                I am a buyer. What should I do if I forgot to
                                click Payment Complete after paying?
                              </a>
                            </div>
                            <div
                              id="collapseThree"
                              class="collapse"
                              data-parent="#accordion"
                            >
                              <div class="card-body">
                                Anim pariatur cliche reprehenderit, enim eiusmod
                                high life accusamus terry richardson ad squid. 3
                                wolf moon officia aute, non cupidatat skateboard
                                dolor brunch. Food truck quinoa nesciunt laborum
                                eiusmod. Brunch 3 wolf moon tempor, sunt aliqua
                                put a bird on it squid single-origin coffee
                                nulla assumenda shoreditch et. samus labore
                                sustainable VHS.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Home;
