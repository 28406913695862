import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Moment from "moment";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import Pagination from "react-js-pagination";

function Home() {
  const options = [
    {
      value: 1,
      label: "Leanne Graham",
    },
    {
      value: 2,
      label: "Ervin Howell",
    },
  ];
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const [refferance, setrefferance] = useState("");
  const [refferancecode, setrefferancecode] = useState("");
  const [totalRef, settotalRef] = useState([]);
  const [Rewardamount, setRewardamount] = useState("");
  const [refferaldata, setrefferaldata] = useState([]);
  const [todayBonus, settodayBonus] = useState([])
  const [total_bonus, settotal_bonus] = useState([])
  const [yesterdayBonus, setyesterdayBonus] = useState([]);
  const [profileData, setprofileData] = useState("");
  const [sessionHistory, setsessionHistory] = useState([]);
  const [totalPage, setTotalpages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [rewardHistory, setrewardHistory] = useState([]);
  const [totalPage_reward, setTotalpages_reward] = useState(0);
  const [currentPage_reward, setCurrentPage_reward] = useState(1);
  const [siteLoader, setSiteLoader] = useState(false);


  useEffect(() => {
    getReferralDetails();
    getReward();
    referralHistory(1);
    get_rewardHistory(1);
  }, [0]);

  const recordPerPage = 5;
  const pageRange = 5;

  const recordPerPage_reward = 5;
  const pageRange_reward = 5;

  const getReferralDetails = async () => {
    try {
      var data = {
        apiUrl: apiService.getUserDetails,
      };
      var resp = await getMethod(data);

      if (resp.status) {

        var currURL = window.location.href.split("Refferal")[0];
        var link = currURL + "register?/invite/" + resp.data.referralCode;

        setprofileData(resp.data);
        console.log(resp);
        setrefferance(resp.refer_link);
        setrefferancecode(resp.refer_link.split("invite=").at(1));
      } else {
        setprofileData("");
      }
    } catch (error) { }
  };

  const getReward = async () => {
    var get = {
      apiUrl: apiService.getReward,
    };
    var response = await getMethod(get);
    setrefferaldata(response.data);
    settotal_bonus(response.data.total_bonus)
    var get2 = {
      apiUrl: apiService.gettodayReward,
    };
    var response2 = await getMethod(get2);
    settodayBonus(response2.data.total_bonus);

    console.log(todayBonus, "todayBonus");

    var get3 = {
      apiUrl: apiService.getbeforedayReward,
    };
    var response3 = await getMethod(get3);
    setyesterdayBonus(response3.data.total_bonus);
    console.log(yesterdayBonus, "yesterdayBonus");

    // setRewardamount(responce.data);
  };

  const referralHistory = async (page) => {
    try {
      var payload = {
        perpage: 5,
        page: page,
      };
      var data = {
        apiUrl: apiService.referralHistory,
        payload: payload,
      };
      setSiteLoader(true);
      var resp = await postMethod(data);
      if (resp.status) {
        setSiteLoader(false);
        console.log("-----", resp.data);
        setsessionHistory(resp.data.data);
        console.log(sessionHistory.length, "sessionHistory.length");
        setTotalpages(resp.data.total);
      }
    } catch (error) { }
  };

  const get_rewardHistory = async (page) => {
    try {
      var payload = {
        perpage: 5,
        page: page,
      };
      var data = {
        apiUrl: apiService.earningHistory,
        payload: payload,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        setrewardHistory(resp.data.data);
        setTotalpages_reward(resp.data.total);
      }
    } catch (error) { }
  };

  const handlePageChange = (pageNumber) => {
    get_rewardHistory(pageNumber);
    setCurrentPage(pageNumber);
  };
  const title = "Welcome to Sellcrypt Exchange";

  const handlePageChange_reward = (pageNumber) => {
    get_rewardHistory(pageNumber);
    setCurrentPage_reward(pageNumber);
  };
  const copy = async (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Code copied");
  };


  return (
    <div className="header ">
      <Header />
      <div>
        <div className="body_hXuuO ">
          <div className="lrtcss-wxjc90 e161ieae2">
            <div className="lrtcss-1p2kb7b e161ieae0">
              {siteLoader == true ? <i class="fa-solid fa-spinner fa-spin"></i> : (
                <><section className="staking_banner_section referal">
                  <div className="container">
                    <div className="row ">
                      <div className="col-lg-7">
                        <div className="banner_left_content refer pl-0">
                          <h2>Earn Together With Your Friends</h2>
                          <h4>
                            Share Up to 20% Commission From Your Referees' Trades
                          </h4>

                          <img
                            src={require("../Image/referral.png")}
                            alt="pic" className="mx-auto d-block mt-5" width="60%"
                          />
                        </div>
                      </div>

                      <div className="col-lg-5">
                        <section className="refferal_settings_section">
                          <div className="container">
                            <div className="settings_box">
                              <div className="settings_box_header">

                                <div className="ref-settings_content">
                                  Referral Settings
                                </div>
                              </div>

                              <div className="bottom_box">
                                <div className="commission_rate_percentage">
                                  <div className="commission_rate_text">
                                    Your Commission Rate :<span> {" "} 0.02%</span>
                                  </div>

                                </div>

                                <div className="commission_rate ref_link mt-3">
                                  <div>
                                    <input
                                      type="text"
                                      placeholder="Referral Code"
                                      value={refferancecode}
                                    />
                                  </div>
                                  <div className="copy_input">
                                    <div className="copy_input_text"> </div>

                                    <div
                                      className="invite_qr curpoint"
                                      onClick={() => copy(refferancecode)}
                                    >
                                      {" "}
                                      <i class="ri-file-copy-line  text-white"></i>
                                    </div>
                                  </div>
                                </div>

                                <div className="commission_rate ref_link mt-3">
                                  <div className="w-100">
                                    <input
                                      type="text"
                                      className="w-100"
                                      placeholder="Referral Link"
                                      value={refferance}
                                    />
                                  </div>
                                  <div className="copy_input">
                                    <div className="copy_input_text"> </div>

                                    <div
                                      className="invite_qr curpoint"
                                      onClick={() => copy(refferance)}
                                    >
                                      {" "}
                                      <i class="ri-file-copy-line text-white"></i>
                                    </div>
                                  </div>
                                </div>

                                <div className="invite_button_ref mt-3">
                                  <div className="invite_btn"> Invite Now</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </section>

                  <section className="p2p_buy_sell_section">
                    <div className="container">
                      <div className="p2p_tabs mb-5">
                        <div class="p2p_padding mt-5">
                          <ul class="nav nav-tabs" id="myTabs">
                            <li class="nav-item overview">
                              <a
                                class="nav-link active"
                                id="home-tab"
                                data-toggle="tab"
                                href="#home"
                              >
                                Overview
                              </a>
                            </li>
                            <li class="nav-item overview">
                              <a
                                class="nav-link"
                                id="profile-tab"
                                data-toggle="tab"
                                href="#profile"
                              >
                                Referral History
                              </a>
                            </li>
                            <li class="nav-item overview">
                              <a
                                class="nav-link"
                                id="profile-tab"
                                data-toggle="tab"
                                href="#profile1"
                              >
                                My Commission
                              </a>
                            </li>
                          </ul>

                          <div class="p2p_tab tab-content mt-5">
                            <div class="tab-pane fade show active" id="home">
                              <section className="earning_card_section">
                                <div className="row">
                                  <div className="col-lg-3">
                                    <div className="earning_card_1">
                                      <p>Today Earnings</p>
                                      <h3>{todayBonus == "" ? 0 : todayBonus.toFixed(2)} USDT</h3>
                                    </div>
                                  </div>
                                  <div className="col-lg-3">
                                    <div className="earning_card_1">
                                      <p>Yesterday's Earnings</p>
                                      <h3>{yesterdayBonus == "" ? 0 : yesterdayBonus.toFixed(2)} USDT</h3>

                                    </div>
                                  </div>
                                  <div className="col-lg-3">
                                    <div className="earning_card_1">
                                      <p>Number of Friends Signed Up</p>
                                      <h3>{totalPage} Members</h3>
                                    </div>
                                  </div>
                                  <div className="col-lg-3">
                                    <div className="earning_card_1">
                                      <p>Total Earnings</p>
                                      <h3>{total_bonus == "" ? 0 : (total_bonus).toFixed(2)} USDT</h3>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            </div>
                            <div class="tab-pane fade" id="profile">

                              <div className="p2p_table">
                                <table class="table table-striped">
                                  <thead>
                                    <tr>

                                      <th scope="col">S.No</th>
                                      <th scope="col">Email</th>
                                      <th scope="col">User Name</th>
                                      <th scope="col">Date / time</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {sessionHistory && sessionHistory.length > 0 ? (
                                      sessionHistory.map((item, i) => {
                                        console.log(
                                          "0------====000----0-0-0-0-",
                                          item
                                        );
                                        return (
                                          <tr className="p2p_row">

                                            <td>
                                              <p>{item.id}</p>
                                            </td>


                                            <td className="p2p_row_price">
                                              <p>
                                                {item.email}
                                              </p>
                                            </td>
                                            <td>
                                              <p>
                                                {item.username}
                                              </p>

                                            </td>
                                            <td>
                                              {Moment(item.createdDate).format(
                                                "lll"
                                              )}
                                            </td>

                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <td colSpan={4}>
                                        <div className="empty_data">
                                          <div className="empty_data_img">
                                            <img
                                              src={require("../Image/Table_image/no-record-light.png")}
                                            />
                                          </div>

                                          <div className="no_records_text">
                                            No Records Found
                                          </div>
                                        </div>
                                      </td>
                                    )}
                                  </tbody>
                                  {sessionHistory.length > 0 ? (
                                    <Pagination
                                      itemClass="page-item" // add it for bootstrap 4
                                      linkClass="page-link" // add it for bootstrap 4
                                      activePage={currentPage}
                                      itemsCountPerPage={recordPerPage}
                                      totalItemsCount={totalPage}
                                      pageRangeDisplayed={pageRange}
                                      onChange={handlePageChange}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </table>
                              </div>
                            </div>
                            <div class="tab-pane fade" id="profile1">
                              <div className="p2p_table">
                                <table class="table table-striped">
                                  <thead>
                                    <tr>
                                      <th scope="col">S.No</th>
                                      <th scope="col">User Name</th>
                                      <th scope="col">Type</th>
                                      <th scope="col">Amount</th>
                                      <th scope="col">Currency</th>
                                      <th scope="col">Date / time</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {rewardHistory && rewardHistory.length > 0 ? (
                                      rewardHistory.map((item, i) => {
                                        console.log(
                                          "0------====000----0-0-0-0-",
                                          item
                                        );
                                        return (
                                          <tr className="p2p_row">

                                            <td>{item.id}</td>
                                            <td>{item.from}</td>
                                            <td>{item.type}</td>
                                            <td>{item.amount}</td>
                                            <td>{item.currency}</td>
                                            <td>

                                              {item.date}
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <td colSpan={6}>
                                        <div className="empty_data">
                                          <div className="empty_data_img">
                                            <img
                                              src={require("../Image/Table_image/no-record-light.png")}
                                            />
                                          </div>

                                          <div className="no_records_text">
                                            No Records Found
                                          </div>
                                        </div>
                                      </td>
                                    )}
                                  </tbody>
                                  {rewardHistory.length > 0 ? (
                                    <Pagination
                                      itemClass="page-item" // add it for bootstrap 4
                                      linkClass="page-link" // add it for bootstrap 4
                                      activePage={currentPage_reward}
                                      itemsCountPerPage={recordPerPage_reward}
                                      totalItemsCount={totalPage_reward}
                                      pageRangeDisplayed={pageRange_reward}
                                      onChange={handlePageChange_reward}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </table>
                              </div>
                            </div>
                          </div>

                          <div
                            class="modal fade"
                            id="largeScreenModal"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="largeScreenModalLabel"
                            aria-hidden="true"
                          >
                            <div
                              class="modal-dialog modal-xl modal-dialog-centered "
                              role="document"
                            >
                              <div class="modal-content p2p">
                                <div class="modal-header">
                                  <h5
                                    class="modal-title"
                                    id="exampleModalLongTitle"
                                  ></h5>
                                  <button
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>

                                <div class="modal-body">
                                  <div className="row">
                                    <div className="col-lg-4 modal_text">
                                      <div className="p2p_row_trades">
                                        {" "}
                                        <div className="row_profile">DA</div>{" "}
                                        <div className="profile_content">
                                          <h3>
                                            Dastgeer Traders{" "}
                                            <i class="ri-bookmark-3-fill"> </i>
                                            <i class="ri-medal-line"></i>
                                          </h3>{" "}
                                          <p>1,627 order(s) | 99.14%</p>
                                        </div>{" "}
                                      </div>
                                      <div className="p2p_modal_contant">
                                        <h3>Remarks</h3>
                                        <p>
                                          My income is from Russia. i amtransfering
                                          my own money from Russia to your account.
                                          I am transfering my own money from Russia
                                          to your account.So i will deal in cash
                                          deposit only... My income is from Russia.
                                          i amtransfering my own money from Russia
                                          to your account. I am transfering my own
                                          money from Russia to your account.So i
                                          will deal in cash deposit only... My
                                          income is from Russia. i amtransfering my
                                          own money from Russia to your account. I
                                          am transfering my own money from Russia to
                                          your account.So i will deal in cash
                                          deposit only...
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-lg-3">
                                      <div className="modal_available">
                                        <div>
                                          <h3>Available</h3>
                                          <p>446.013334</p>
                                        </div>
                                        <div>
                                          <h3>Limit</h3>
                                          <p>1-456 INR</p>
                                        </div>
                                      </div>
                                      <div className="modal_available mt-3">
                                        <div>
                                          <h3>Price</h3>
                                          <p>90 INR/USDT</p>
                                        </div>
                                        <div className="payment_methods">
                                          <h3>Payment Methods</h3>
                                          <img
                                            src={require("../Image/Table_image/modal_currency.png")}
                                            alt="pic"
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-lg-5 buy_order">
                                      <div>
                                        <p>
                                          Buy(Remaining Daily Limit:40,000,000INR)
                                        </p>
                                      </div>
                                      <div className="buy-amt">
                                        <div className="flex-grow-1">
                                          <input
                                            type="text"
                                            className="bg_trans_non"
                                          />
                                        </div>
                                        <div>INR</div>
                                      </div>
                                      <div className="usd-amt">
                                        <div className="flex-grow-1">
                                          <input
                                            type="text"
                                            className="bg_trans_non"
                                          />
                                        </div>
                                        <div>USDT</div>
                                      </div>

                                      <div className="place-order">
                                        <p>
                                          Trading password is not required when
                                          placing buy <br />
                                          orders.
                                        </p>
                                        <button>Place Order</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            class="modal fade"
                            id="lgScreen"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="largeScreenModalLabel"
                            aria-hidden="true"
                          >
                            <div
                              class="modal-dialog modal-xl modal-dialog-centered "
                              role="document"
                            >
                              <div class="modal-content p2p">
                                <div class="modal-header">
                                  <h5
                                    class="modal-title"
                                    id="exampleModalLongTitle"
                                  >
                                    Modal title
                                  </h5>
                                  <button
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>

                                <div class="modal-body">
                                  <div className="row">
                                    <div className="col-lg-4 modal_text">
                                      <div className="p2p_row_trades">
                                        {" "}
                                        <div className="row_profile">DA</div>{" "}
                                        <div className="profile_content">
                                          <h3>
                                            Dastgeer Traders{" "}
                                            <i class="ri-bookmark-3-fill"> </i>
                                            <i class="ri-medal-line"></i>
                                          </h3>{" "}
                                          <p>1,627 order(s) | 99.14%</p>
                                        </div>{" "}
                                      </div>
                                      <div className="p2p_modal_contant">
                                        <h3>Remarks</h3>
                                        <p>
                                          My income is from Russia. i amtransfering
                                          my own money from Russia to your account.
                                          I am transfering my own money from Russia
                                          to your account.So i will deal in cash
                                          deposit only... My income is from Russia.
                                          i amtransfering my own money from Russia
                                          to your account. I am transfering my own
                                          money from Russia to your account.So i
                                          will deal in cash deposit only...
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-lg-3">
                                      <div className="modal_available">
                                        <div>
                                          <h3>Available</h3>
                                          <p>446.013334</p>
                                          <h3>Price</h3>
                                          <p>90 INR/USDT</p>
                                        </div>
                                      </div>
                                      <div className="modal_available sell ">
                                        <div className="mt-3">
                                          <h3>Limit</h3>
                                          <p>1-456 INR</p>
                                        </div>
                                        <div className="payment_methods mt-4">
                                          <h3>Payment Methods</h3>
                                          <img
                                            src={require("../Image/Table_image/modal_currency.png")}
                                            alt="pic"
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-lg-5 buy_order">
                                      <div>
                                        <p>
                                          Sell(Remaining Daily Limit:40,000,000INR)
                                        </p>
                                      </div>
                                      <div className="buy-amt sell">
                                        <div className="flex-grow-1">
                                          <input
                                            type="text"
                                            className="bg_trans_non"
                                          />
                                        </div>
                                        <div>INR</div>
                                      </div>
                                      <div className="text-danger">
                                        <p>The amount cannot be less than 200000</p>
                                      </div>

                                      <div className="usd-amt">
                                        <div className="flex-grow-1">
                                          <input
                                            type="text"
                                            className="bg_trans_non"
                                          />
                                        </div>
                                        <div>USDT</div>
                                      </div>

                                      <div className="place-order">

                                        <button>Place Order</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section></>
              )}

            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Home;
