import { useState, useEffect } from "react";
import { useParallax, ParallaxProvider, Parallax } from "react-scroll-parallax";
function Steps() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Function to check if the device is a mobile device
    const detectMobile = () => {
      const mobileCheck = window.matchMedia("(max-width: 768px)").matches;
      setIsMobile(mobileCheck);
    };

    // Initial check on component mount
    detectMobile();

    // Event listener for window resize
    const handleResize = () => {
      detectMobile();
    };
    window.addEventListener("resize", handleResize);

    // Cleanup function
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <ParallaxProvider>
        <section className="section_commen Steps_section pt-0">
          {/* <Parallax
            speed={-4}
            translateX={["-300px", "0px"]}
            scale={[0.75, 1]}
            rotate={[-130, 0]}
            easing="easeInQuad"
            disabled={isMobile}
          >
           
            <iframe
              src="https://player.vimeo.com/video/877094523?badge=0&autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=1&controls=0&app_id=58479&autopause=0"
              className="frame-1"
            ></iframe>
          </Parallax>

          <iframe
            src="https://player.vimeo.com/video/877094570?badge=0&autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=1&controls=0&app_id=58479&autopause=0"
            className="frame-2"
          ></iframe> */}

          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Parallax
                // speed={-3}
                // opacity={[0.9, 1]}
                //   scale={[1, 0.75]}
                // easing="easeInQuad"
                // disabled={isMobile}
                >
                  {" "}
                  <h2 className="heading_h2 text-center">
                    Build your crypto portfolio
                  </h2>
                </Parallax>
                <Parallax
                // speed={-3}
                // opacity={[0.9, 1]}
                // disabled={isMobile}
                // //   scale={[1, 0.75]}
                // easing="easeInQuad"
                >
                  <p className="heading_p text-center">
                    Start your first trade with these easy steps.
                  </p>
                </Parallax>
                <div className="options1 mt-5">
                  <div className="row">
                    <div className="col-lg-4">
                      <Parallax
                      // speed={4}
                      // opacity={[0.9, 1]}
                      // easing="easeInQuad"
                      // disabled={isMobile}
                      >
                        <div className="card_table card_table_border">
                          <div className="row m-0">
                            <div className="col-lg-12">
                              <img
                                src={require("../../Image/new_image_home/rgister.png")}
                                className="new_icon"
                              />
                              <div className="heading_sub">
                                <h3>Verify your identity</h3>
                                <p>
                                  Complete the identity verification process to
                                  secure your account and transactions.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Parallax>
                    </div>
                    <div className="col-lg-4">
                      <Parallax
                      // speed={9}
                      // opacity={[0.9, 1]}
                      // easing="easeInQuad"
                      // disabled={isMobile}
                      >
                        <div className="card_table card_table_border">
                          <div className="row m-0">
                            <div className="col-lg-12">
                              <img
                                src={require("../../Image/new_image_home/fund.png")}
                                className="new_icon"
                              />
                              <div className="heading_sub">
                                <h3>Fund your account</h3>
                                <p>
                                  Add funds to your crypto account to start
                                  trading crypto. You can add funds with a
                                  variety of payment methods.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Parallax>
                    </div>
                    <div className="col-lg-4">
                      <Parallax
                      // speed={4}
                      // opacity={[0.9, 1]}
                      // easing="easeInQuad"
                      // disabled={isMobile}
                      >
                        <div className="card_table card_table_border">
                          <div className="row m-0">
                            <div className="col-lg-12">
                              <div className="heading_sub">
                                <img
                                  src={require("../../Image/new_image_home/trade.png")}
                                  className="new_icon"
                                />
                                <h3>Start trading</h3>
                                <p>
                                  Start trading cryptocurrencies today. Explore
                                  markets and execute trades
                                  with ease on our user-friendly platform. 
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Parallax>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </ParallaxProvider>
    </>
  );
}

export default Steps;
