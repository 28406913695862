import React, { useEffect } from "react";
import { Button } from "@mui/material";
import useState from "react-usestateref";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

import Moment from "moment";
function Footer() {
  const [siteData, setSiteData] = useState({});
  const [loginCheck, setloginCheck] = useState(false);

  useEffect(() => {
    let userToken = localStorage.getItem("user_token");

    if (userToken) {
      setloginCheck(true);
    } else {
      setloginCheck(false);
    }
    fetch_site_data();
  }, [0]);

  const fetch_site_data = async () => {
    try {
      var data = {
        apiUrl: apiService.getSiteDatas,
      };
      var resp = await getMethod(data);
      console.log(resp.data, "=--=-=-resp-=-=-=-=-=resp=-=-");
      setSiteData(resp.data);
    } catch (error) { }
  };

  const initialFormValue = {
    email: "",
  };

  const [emailValidate, setemailValidate, emailValidateref] = useState(false);
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validationnErr, setvalidationnErr] = useState("");

  const { email } = formValue;

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/\s/g, "");
    let formData = { ...formValue, ...{ [name]: sanitizedValue } };
    setFormValue(formData);
    validate(formData);
  };
  const validate = async (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Email is a required field!";
      setemailValidate(true);
    } else if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address!";
      setemailValidate(true);
    } else if (
      !/^[a-zA-Z0-9._%+-]*[a-zA-Z][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(
        values.email
      )
    ) {
      errors.email =
        "Invalid email address! Email must contain at least one character.";
      setemailValidate(true);
    } else if (values.email.length > 254) {
      errors.email = "Email address is too long!";
      setemailValidate(true);
    } else {
      setemailValidate(false);
    }
    setvalidationnErr(errors);
    return errors;
  };
  const addSubscriber = async () => {
    if (emailValidateref.current === false) {
      var data = {
        apiUrl: apiService.addSubscriber,
        payload: formValue,
      };
      var resp = await postMethod(data);
      if (resp.status == true) {
        toast.success(resp.Message);
      } else {
        toast.error(resp.Message);
      }
    } else {
    }
  };

  return (
    <>
      <footer>
        <div className="px-5">
          <div className="row">
            <div className="col-lg-8">
              <div className="row">
                <div className="col-lg-3">
                  <div className="logo_section_f">
                    <Link className="nav_brand" to="/">
                      {" "}
                      {/* <img
                src={require("../Image/WhatsAp.png")}
                className="blackshow"
              /> */}
                      <img src={require("../Image/nav_bar/Logolight.png")}
                        className="blackshow"
                      />

                    </Link>
                    <p>Now is your time. With Sellcrypt you can buy, sell and trade crypto assets with amazing ease, confidence and trust</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="link_section">
                    <h4>About</h4>
                    <ul>
                      <li>
                        <a href="/privacy" target="_blank">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="/terms" target="_blank">Terms Of Condition</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="link_section">
                    <h4>Services</h4>
                    <ul>
                      <li>
                        <a href="/trade/BTC_USDT">Spot</a>
                      </li>
                      {loginCheck == false ?
                        <li>
                          <a href="/login">Swap</a>
                        </li>
                        :
                        <li>
                          <a href="/convert">Swap</a>
                        </li>
                      }


                      {/* <li>
                        <a href="">Swap</a>
                      </li>
                      <li>
                        <a href="">Internal Transfer</a>
                      </li>
                      <li>
                        <a href="">Margin</a>
                      </li> */}
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="logo_section_f">


                    <ul className="social">
                      <li>
                        <a href={siteData.linkedin_url} target="_blank">
                          <i class="ri-linkedin-fill"></i>
                        </a>
                      </li>
                      <li>
                        <a href={siteData.twitter_url} target="_blank">
                          <i class="ri-twitter-line"></i>
                        </a>
                      </li>
                      <li>
                        <a href={siteData.fb_url} target="_blank">
                          <i class="ri-facebook-fill"></i>
                        </a>
                      </li>
                      <li>
                        <a href={siteData.whatsapp_url} target="_blank">
                          <i class="bi bi-whatsapp"></i>
                        </a>
                      </li>
                      <li>
                        <a href={siteData.youtube_url} target="_blank">
                          <i class="ri-youtube-line"></i>
                        </a>
                      </li>
                      <li>
                        <a href={siteData.telegram_url} target="_blank">
                          <i class="ri-telegram-line"></i>
                        </a>
                      </li>
                      <li>
                        <a href={siteData.dribble_url} target="_blank">
                          <i class="ri-dribbble-line"></i>
                        </a>
                      </li>
                      {/* <li>
                        <a href={siteData.youtube_url}>
                          <i class="ri-reddit-line"></i>
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="link_section">
                <h4>Stay tuned for Update</h4>
                <div className="email_form">
                  <input
                    type="text"
                    placeholder="your email address"
                    value={email}
                    name="email"
                    onChange={handleChange}
                  />


                  <button onClick={addSubscriber}>Subscribe</button>
                </div>
                {emailValidateref.current == true ? (
                  <p className="errorcss"> {validationnErr.email} </p>
                ) : (
                  ""
                )}
                <div className="note_footer">
                  * Will send you weekly updates for your better finance
                  management.
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="copywrigt">
                <p> {siteData.copy_right_text} </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
