import React, { useState, useEffect } from "react";
import Header from "./Header";
import { Button } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Footer from "./Footer";
import BannerUI from "./Banner";
import {Link, useNavigate} from "react-router-dom";
import Spline from "@splinetool/react-spline";
import BannerNew from "./LandingpagecCom/BannerNew";
import TableNew from "./LandingpagecCom/TableNew";
import Steps from "./LandingpagecCom/Steps";
import Security from "./LandingpagecCom/Security";
import OurCryptoex from "./LandingpagecCom/OurCryptoex";
import StartTrade from "./LandingpagecCom/StartTrade";
import Blogsection from "./LandingpagecCom/Blogsection";
import Help from "./LandingpagecCom/Help";
import ReactPlayer from "react-player";
import bg_baner from "../Image/new_image_home/mexc_1.mp4";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import axios from 'axios';

function Home() {
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  var settings_2 = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 5000,
  };
  const [loginCheck, setloginCheck] = useState(false);

  useEffect(() => {
    let userToken = localStorage.getItem("user_token");
    if (userToken) {
      setloginCheck(true);
    } else {
      setloginCheck(false);
    }
    home_currency();
  }, []);
  const navigate = useNavigate();


  const navigatePage=(type)=>{
if(type=="register"){
  navigate("/register");

}else{
  navigate("/trade/BTC_USDT");

}
  }
  const [currencylistData, setcurrencylistData] = useState([]);

  const home_currency = async () => {
    try {
      var data = {
        apiUrl: apiService.home_currency,
      };
      var resp = await getMethod(data);
      setcurrencylistData(resp.Message);
    } catch (err) {}
  };

  const [siteLoader, setSiteLoader] = useState(false);


  return (
    <div>
      <Header />
      {siteLoader == true ? <i class="fa-solid fa-spinner fa-spin"></i> : (
 <>
      <main className="home_id">
        <section className="banner_v_section">
          {/* <div className="banner_V">
            <ReactPlayer
              url={bg_baner}
              playing={true}
              loop={true}
              muted={true}
            />
          </div> */}

          <div className="postion_absalue">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="content_section">
                    <h1>
                    {/* Trade, Learn, Conquer the Crypto Galaxy! */}
                    Unlock the future of finance
</h1>
                    <p>
                      {/* Invest in 350+ Coins and crypto baskets with actionable
                      insights at your fingertips */}
                      Trade cryptocurrencies effortlessly on our cutting-edge platform. Join now for seamless transactions and limitless possibilities.

                    </p>
                    {loginCheck==true ?
                    <Button onClick={()=>navigatePage("trade")}>Get Start</Button>
                    :
                    <Button onClick={()=>navigatePage("register")}>Get Start</Button>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="marquee_section">
          <div className="container">
            <div className="margwwww">
              <marquee>
                <div className="margee_flex ">
                  {currencylistData &&
                    currencylistData.map((obj, i) => {
                      return (
                        <div className="crypto_news">
                          <h3>{obj.currencyName}</h3>
                          <div className="price_section">
                            <span>$ {" "}{obj.estimatedValueInUSDT}</span>
                            {/* <small> 2.48 %</small> */}
                            {obj.coin_change <= 0 ? (
                              <td>
                                <p className="text-red">▼{obj.coin_change}%</p>
                              </td>
                            ) : (
                              <td>
                                <p className="green_text">
                                ▲ {obj.coin_change}%
                                </p>
                              </td>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>

                {/* <div className="margee_flex ">
                  <div className="crypto_news">
                    <h3>Bitcoin</h3>
                    <div className="price_section">
                      <span>₹49.191</span>
                      <small>▲ 2.48 %</small>
                    </div>
                  </div>
                  <div className="crypto_news">
                    <h3>Ethereum</h3>
                    <div className="price_section">
                      <span>₹49.191</span>
                      <small>▲ 2.48 %</small>
                    </div>
                  </div>
                  <div className="crypto_news">
                    <h3>Fetch.AI </h3>
                    <div className="price_section">
                      <span>₹49.191</span>
                      <small>▲ 2.48 %</small>
                    </div>
                  </div>
                  <div className="crypto_news">
                    <h3>Bitcoin</h3>
                    <div className="price_section">
                      <span>₹49.191</span>
                      <small>▲ 2.48 %</small>
                    </div>
                  </div>
                  <div className="crypto_news">
                    <h3>Tether USD</h3>
                    <div className="price_section">
                      <span>₹49.191</span>
                      <small className="down">▼ 2.48 %</small>
                    </div>
                  </div>
                  <div className="crypto_news">
                    <h3>Ethereum</h3>
                    <div className="price_section">
                      <span>₹49.191</span>
                      <small>▲ 2.48 %</small>
                    </div>
                  </div>
                  <div className="crypto_news">
                    <h3>Fetch.AI </h3>
                    <div className="price_section">
                      <span>₹49.191</span>
                      <small>▲ 2.48 %</small>
                    </div>
                  </div>
                </div> */}
              </marquee>

              {/* <marquee>
             
            </marquee> */}
            </div>
          </div>
        </section>
        <TableNew />
        <Steps />
        <OurCryptoex />
        <Security />
        {/* <Help /> */}
        {/* <Blogsection /> */}

        <StartTrade />
      </main>
      <div></div>
      <Footer />
      </>)}
    </div>
  );
}

export default Home;
