import React, { useEffect } from "react";
import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./login_regi_footer";
import Select from "react-dropdown-select";
import {useLocation, Link, useNavigate} from "react-router-dom";
import useState from "react-usestateref";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import {postMethod} from "../core/service/common.api";
import {setAuthorization} from "../core/service/axios";

function Home() {
  const initialFormValue = {
    tfa: "",
  };
  const [formValue, setFormValue] = useState(initialFormValue);
  const [tfaValidate, settfaValidate] = useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [buttonLoader, setbuttonLoader] = useState(false);
  const navigate = useNavigate();

  const {tfa} = formValue;
  const {state} = useLocation();
  const [siteLoader, setSiteLoader] = useState(false);

  const handleChange = async (e) => {
    const {name, value} = e.target;
    let formData = {...formValue, ...{[name]: value}};
    setFormValue(formData);
    validate(formData);
  };
  const formSubmit = async () => {
    validate(formValue);
    if (formValue.tfa !== "") {
      var data = {
        apiUrl: apiService.tfaVerify,
        payload: {
          userToken: tfa,
          socketToken: state?.socketToken,
        },
      };
      var resp = await postMethod(data);
      console.log("tfa login===", resp);
      if (resp.status) {
        toast.success(resp.Message);
        await setAuthorization(resp.token);
        localStorage.setItem("user_token", resp.token);
        localStorage.setItem("tfa_status", resp.tfa);
        localStorage.setItem("socket_token", resp.socketToken);
        navigate("/dashboard");
      } else {
        toast.error(resp.Message);
      }
    }
  };
  const validate = async (values) => {
    const errors = {};
    if (!values.tfa) {
      errors.tfa = "2FA is Required";
      settfaValidate(true);
    }
    setvalidationnErr(errors);
    return errors;
  };      

  return (
    <div className="header ">
      <Header />
      <main>
        <div className="login_form">
          <div className="container">
          {siteLoader == true ? <i class="fa-solid fa-spinner fa-spin"></i> : ( <>
            <div className="row justify-content-center">
              <div className="col-lg-5">
                <div className="card_login mt25">
                  <h3 className="mb-1">Enter 2FA code to login </h3>
                  <div>
                    <div class="form_login_input">
                      <label className="lable_text mb-3">Your 2FA</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Your 2FA"
                        name="tfa"
                        value={tfa}
                        onChange={handleChange}
                      />
                       {tfaValidate == true ? (
                                        <p className="text-danger">
                                          {" "}
                                          {validationnErr.tfa}{" "}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                    </div>
                  

                    <div class="form_login_input mt-3">
                      {buttonLoader == false ? (
                        <Button onClick={formSubmit} className="mt-3">
                          Login
                        </Button>
                      ) : (
                        <Button className="mt-3">Loading...</Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </>)}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Home;
