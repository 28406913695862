import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Button } from "@mui/material";
import OTPInput, { ResendOTP } from "otp-input-react";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "./Innerpages/Sidebar";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { getMethod } from "../core/service/common.api";
import moment from "moment";

function Home() {
  const value = {
    Subject: "",
    Category: "Choose Category",
    text: "",
  };

  const [Formdata, setFormdata] = useState(value);

  const [SubjectErr, setSubjectErr, SubjectErrref] = useState(false);
  const [CategoryErr, setCategoryErr, CategoryErrref] = useState(false);
  const [textErr, settextErr, textErrref] = useState(false);

  const [formErr, setformErr] = useState("");

  const [user, setuser] = useState([]);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalpages] = useState(0);
  const [supportCategories, setCategories] = useState(0);
  const [viewChat, setViewChat] = useState(false);
  const [replyMess, replyMessage, replyMessref] = useState("");
  const [chatDatas, setChatDatas] = useState("");
  const [chatHistory, setchatHistory] = useState([]);

  const { Subject, Category, text } = Formdata;

  const getItem = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    let sanitizedValue = value;
    if (sanitizedValue.startsWith(" ")) {
      sanitizedValue = sanitizedValue.trimLeft();
    }
    let formData = { ...Formdata, ...{ [name]: sanitizedValue } };
    setFormdata(formData);
    validate(formData);
  };

  const withCash = async (option) => {
    console.log(option, "option");

    setCategoryErr(false);

    var obj = {
      Subject: Formdata.Subject,
      Category: option.category,
      text: Formdata.text,
    };
    setFormdata(obj);
  };

  const recordPerPage = 5;
  const totalRecords = 15;
  const pageRange = 5;

  const handlePageChange = (pageNumber) => {
    viewData(pageNumber);
    setCurrentPage(pageNumber);
  };
  useEffect(() => {
    viewData();
    getCategory();
  }, [0]);

  const checkKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      reply();
    }
  };

  const validate = async (condition) => {
    var error = {};
    if (condition.Subject == "") {
      error.Subject = "Subject is a required field";
      setSubjectErr(true);
    } else if (!/^[a-zA-Z]/.test(condition.Subject)) {
      error.Subject = "Subject must start with a letter !";
      setSubjectErr(true);
    } else if (condition.Subject.length < 4 || condition.Subject.length > 25) {
      error.Subject = "Subject must be between 4 to 25 characters !";
      setSubjectErr(true);
    } else if (!/^[a-zA-Z0-9_]+$/.test(condition.Subject)) {
      error.Subject =
        "Subject can only contain letters, numbers, and underscores !";
      setSubjectErr(true);
    } else if (condition.Category == "Choose Category") {
      setSubjectErr(false);
      error.Category = "Category is Required";
      setCategoryErr(true);
    } else if (condition.text == "") {
      setCategoryErr(false);
      error.text = "Message is a required field";
      settextErr(true);
    } else if (!/^[a-zA-Z]/.test(condition.text)) {
      error.text = "Message must start with a letter !";
      settextErr(true);
    } else if (condition.text.length < 3 || condition.text.length > 250) {
      error.text = "Message must be between 3 to 250 characters !";
      settextErr(true);
    } else {
      setCategoryErr(false);
      setSubjectErr(false);

      settextErr(false);
    }
    setformErr(error);
  };

  const submit = async () => {
    validate(Formdata);
    // return false;
    console.log(Formdata, "Formdata");


    if (
      SubjectErrref.current == false &&
      CategoryErrref.current == false &&
      textErrref.current == false) {
      toast.success(
        "Your ticket created successfully, Please wait for admin reply"
      );
      var data = {
        apiUrl: apiService.createdSupport,
        payload: Formdata,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setbuttonLoader(false);
      const obj = {
        Subject: "",
        Category: "Choose Category",
        text: "",
      };
      setFormdata(obj);
      viewData();
    } else {
      console.log("All are required fields");
    }
  };
  const viewData = async () => {
    try {
      var api = {
        apiUrl: apiService.findedSupport,
      };
      var view = await postMethod(api);
      console.log(view, "=-=-view=-=-=-");
      if (view.status) setuser(view.data.data);
      setTotalpages(view.data.total);
      console.log(view.data.total);
    } catch (error) { }
  };

  const getCategory = async () => {
    try {
      var api = {
        apiUrl: apiService.supportCategories,
      };
      var response = await getMethod(api);
      if (response.status) {
        setCategories(response.data);
      }
    } catch (error) { }
  };

  const userChat = async (data) => {
    try {
      console.log("=-=-==data=--=-=", data);
      setChatDatas(data);
      setViewChat(true);
      var obj = {
        _id: data._id,
      };
      var api = {
        apiUrl: apiService.getSupportDetails,
        payload: obj,
      };
      var view = await postMethod(api);
      if (view) {
        setchatHistory(view.Message.reply);
      } else {
        setchatHistory([]);
      }
      console.log(view, "=-=-view=-=-=-view=-=-=-view=-=");
      console.log(view.data.total);
    } catch (error) { }
  };
  const closeTicket = async (data) => {
    try {
      var obj = {
        _id: data._id,
      };
      var api = {
        apiUrl: apiService.closeTicket,
        payload: obj,
      };
      var view = await postMethod(api);
      if (view) {
        // userChat(chatDatas);
        viewData();
        toast.success(view.Message);
      } else {
        toast.error(view.Message);
      }
    } catch (error) { }
  };

  const back = async (data) => {
    try {
      setViewChat(false);
    } catch (error) { }
  };

  const reply = async () => {
    try {
      if (Object.keys(replyMessref.current).length > 0) {
        var obj = {
          message: replyMessref.current,
          chatId: chatDatas._id,
          tag: "user",
          image: "",
          status: "0",
        };
        var api = {
          apiUrl: apiService.addUserSupport,
          payload: obj,
        };
        var view = await postMethod(api);
        if (view) {
          console.log("**********");
          userChat(chatDatas);
          replyMessage("");
          toast.success(view.Message);
        }
      } else {
        toast.error("Please enter the message!");
      }
    } catch (error) { }
  };

  return (
    <div className="header ">
      <Header />
      <div>
        <div className="body_hXuuO ">
          <div className="lrtcss-wxjc90 e161ieae2">
            <div className="lrtcss-q484j3 e161ieae1">
              <div className="KuxMenu-root lrtcss-yqpjhy">
                <Sidebar />
              </div>
            </div>
            <div className="lrtcss-1p2kb7b ma-000">
              <div className="step_2">
                <div class="lrtcss-1urys0g e1epgi046">
                  <span className="headeing_with_back">Support</span>
                </div>
                <div class="lrtcss-ac8m87 e8w7gdo16">
                  <div className="w-100">
                    <div className="mb-5">
                      <div className="row justify-content-center w-100">
                        <div className="col-lg-10">
                          <div className="card_login w-100 mb-5">
                            <div className="row justify-content-center">
                              <div className="col-lg-6">
                                <label className="lable_text">Subject</label>
                                <div class="form_login_input ">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Subject"
                                    name="Subject"
                                    value={Subject}
                                    minLength={4}
                                    maxLength={25}
                                    onChange={getItem}
                                  />
                                  {SubjectErr == true ? (
                                    <small className="errorcss">
                                      {formErr.Subject}{" "}
                                    </small>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <label className="lable_text">
                                  Select Category
                                </label>
                                <div class=" position_eye">
                                  <div class="dropdown">
                                    <button
                                      class="form-control dropdesign dropdown-toggle"
                                      type="button"
                                      id="dropdownMenuButton"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      {Category == "Choose Category"
                                        ? "Choose Category"
                                        : Category}
                                    </button>
                                    <div
                                      class="dropdown-menu dropdesigns"
                                      aria-labelledby="dropdownMenuButton"
                                    >
                                      {supportCategories &&
                                        supportCategories.map((item, j) => {
                                          return (
                                            <p
                                              class="dropdown-item"
                                              onClick={() => withCash(item)}
                                            >
                                              {item.category}
                                            </p>
                                          );
                                        })}
                                    </div>
                                  </div>
                                  {CategoryErr == true ? (
                                    <small className="errorcss">
                                      {formErr.Category}{" "}
                                    </small>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-12">
                                <label className="lable_text">Message</label>
                                <div class="form_login_input ">
                                  <textarea
                                    name="text"
                                    value={text}
                                    minLength={3}
                                    maxLength={250}
                                    placeholder="Message"
                                    onChange={getItem}
                                  />
                                  {textErr == true ? (
                                    <small className="errorcss">
                                      {formErr.text}{" "}
                                    </small>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div class="form_login_input">
                                  {buttonLoader == false ? (
                                    <Button className="mt-4" onClick={submit}>
                                      Submit
                                    </Button>
                                  ) : (
                                    <Button className="mt-4">Loading</Button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <section className="Deposit_history_section w-100">
                    <h3 className="deposit_table_title">Support History</h3>
                    <div className="deposit_history">
                      <table class="table market">
                        <thead>
                          <tr class="market-header deposit">
                            <th scope="col">Ticket ID </th>
                            <th scope="col"> Subject</th>
                            <th scope="col"> Message</th>
                            <th scope="col"> Category</th>
                            <th scope="col"> Date / Time</th>
                            <th scope="col"> Action</th>
                            <th scope="col"> Ticket Close</th>
                          </tr>
                        </thead>

                        <tbody>
                          {user && user.length > 0 ? (
                            user.map((data, i) => {
                              return (
                                <tr>
                                  <td>#{data._id.substring(0, 8)}</td>
                                  <td>{data.subject}</td>
                                  <td>{data.message}</td>
                                  <td>{data.category}</td>
                                  <td>
                                    {moment(data.updated_at).format("lll")}
                                  </td>
                                  <td onClick={() => userChat(data)}> View </td>
                                  {data.status == "0" ? (
                                    <td
                                      onClick={() => closeTicket(data)}
                                      style={{ color: "green" }}
                                    >
                                      {" "}
                                      Close{" "}
                                    </td>
                                  ) : (
                                    <td style={{ color: "red" }}> Closed </td>
                                  )}
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan={7}>
                                <div className="empty_data">
                                  <div className="empty_data_img">
                                    <img
                                      src={require("../Image/Table_image/no-record-light.png")}
                                    />
                                  </div>

                                  <div className="no_records_text">
                                    No Records Found
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
