import React, { useEffect } from "react";
import Header from "./Header";
import { Button, Container } from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import useState from "react-usestateref";
import { getAuthToken } from "../core/lib/localStorage";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import Pagination from "./pagination";
import DropdownMenu from "./DropdownMenu";
import countrylist from "./country.json";
import $ from "jquery";
import useStateRef from "react-usestateref";

function Home() {
  const initialFormValue = {
    quantity: "",
    price: "",
    limitFrom: "",
    limitTo: "",
    isTerms: "",
  };

  const [formValue, setFormValue] = useState(initialFormValue);
  const [allCurrency, setallCurrency] = useState([]);
  const [allCurrencyFiat, setallCurrencyFiat] = useState([]);
  const [cryptoCurrency, setcryptoCurrency, cryptoCurrencyref] = useState("");
  const [crytpoWithCash, setcrytpoWithCash, crytpoWithCashref] = useState("");
  const [prferPay, setprferPay] = useState("All payments");
  const [Terms, setTerms] = useState(false);
  const [errquantity, seterrquantity, seterrquantityref] = useState(true);
  const [priceError, serpriceError, serpriceErrorref] = useState("");
  const [errcryptoCurrency, seterrcryptoCurrency] = useState("");
  const [errcrytpoWithCash, seterrcrytpoWithCash] = useState("");
  const [errprferPay, seterrprferPay] = useState("");
  const [validationnErr, setvalidationnErr] = useState("");
  const [termsValidation, setTermsValidation] = useState(false);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [firstCurrency, setfirstCurrency] = useState(
    "619948553cda890ff0256d3c"
  );
  const [secondCurrency, setsecondCurrency] = useState(
    "62f625b70a05cc3f9e4222bb"
  );
  const [kycstatus, setkycstatus] = useState(0);
  const [cryptoPrices, setcryptoPrices, cryptoPricesref] = useState([]);
  const [coinPrice, setcoinPrice, coinPriceref] = useState("");
  const [coinBalance, setcoinBalance, coinBalanceref] = useState("");
  const [fiat, setfiat, fiatref] = useState("INR");
  const [highprice, sethighprice, highpriceref] = useState(0);
  const [lowprice, setlowprice, lowpriceref] = useState(0);
  const [fromCurrency, setfromCurrency, fromCurrencyref] = useState("");
  const [toCurrency, settoCurrency, toCurrencyref] = useState("");
  const [payment_time, setpayment_time, payment_timeref] = useState("");
  const [errpaymentTime, seterrpaymentTime] = useState("");
  let navigate = useNavigate();

  const { quantity, price, limitFrom, limitTo, isTerms } = formValue;

  const handleChange = async (e) => {
    console.log(e, "-=-=-=-=-");
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    console.log(formData, "formData=-=-=-=");
    setFormValue(formData);
  };

  const getAllcurrency = async () => {
    console.log("fiatref.current===", fiatref.current);
    var payload = {
      fiat: fiatref.current,
    };
    var data = {
      apiUrl: apiService.getP2Pbalance,
      payload: payload,
    };
    var resp = await postMethod(data);
    console.log(resp, "-=-=-resp=-=-");
    if (resp.status == true) {
      var currArrayCrypto = [];
      var currArrayFiat = [];
      var currPrices = [];
      var data = resp.data;
      console.log(data, "][][][][][][");
      for (var i = 0; i < data.length; i++) {
        if (data[i].coinType == "1") {
          var obj = {
            key: data[i].currencySymbol,
            text: data[i].currencySymbol,
            value: data[i]._id,
            image: {
              avatar: true,
              src: data[i].Currency_image,
            },
            price: data[i].estimatedValueInUSDT,
          };
          currArrayCrypto.push(obj);
        }
        if (data[i].coinType == "2") {
          var obj = {
            key: data[i].currencySymbol,
            text: data[i].currencySymbol,
            value: data[i]._id,
            image: {
              avatar: true,
              src: data[i].Currency_image,
            },
          };
          currArrayFiat.push(obj);
        }
        var obj1 = {
          id: data[i]._id,
          symbol: data[i].currencySymbol,
          price: data[i].estimatedValueInUSDT,
          balance: data[i].currencyBalance,
        };
        currPrices.push(obj1);
      }
      setallCurrency(currArrayCrypto);
      setallCurrencyFiat(currArrayFiat);
      console.log("allCurrencyFiat", currArrayFiat);
      setcryptoPrices(currPrices);
      let index = currPrices.filter((x) => x.symbol == fromCurrencyref.current);
      formValue.price = index[0].price;
      setcoinPrice(index[0].price);
      setcoinBalance(index[0].balance);
    }
    // console.log(allCurrency,'allCurrencyallCurrencyallCurrency=--=-=-=-==-=')
  };

  var defaulatCurrency = "BTC";
  const defaulatCurrFiat = allCurrencyFiat[0];

  const chooseCrypto = async (option) => {
    console.log(option, "-=-chooseCrypto");

    let index = cryptoPricesref.current.filter((x) => x.id == option.value);
    console.log("currency index====", index);
    if (index != null) {
      console.log(index, "indexindexindex");
      setcryptoCurrency(index[0].symbol);
      setfirstCurrency(option.value);
      getAllcurrency();
      formValue.price = index[0].price;
      console.log("formValue.price====", formValue.price);
      setcoinPrice(index[0].price);
      setcoinBalance(index[0].balance);
      setfromCurrency(index[0].symbol);
      fiat_price();
    }
  };
  const withCash = async (option) => {
    console.log("fromCurrencyref.current====", fromCurrencyref.current);
    let index = cryptoPricesref.current.filter((x) => x.id == option.value);
    // console.log("crypto currency index====",index);
    if (index != null) {
      setcrytpoWithCash(index[0].symbol);
      setsecondCurrency(option.value);
      setfiat(index[0].symbol);
      getAllcurrency();
      settoCurrency(index[0].symbol);

      formValue.price = index[0].price;
      console.log("formValue.price====", formValue.price);
      setcoinPrice(index[0].price);
      fiat_price();
    }
  };

  const prferPayments = async (option) => {
    console.log(option.value, "-=-prferPayments");
    setprferPay(option.value);
  };

  //    const handleOnChange_from = (e, data) => {
  //     console.log("handleOnChange_from",data);
  //     setfromSwapRef(data.value);
  //     var findIndexing = fromTab.findIndex(
  //       (x) => x.currid == data.value
  //     );
  //     console.log("findIndexing===",findIndexing);
  //     if (findIndexing != -1) {
  //       console.log("fromTab[findIndexing]",fromTab[findIndexing]);
  //       setappendFromData(fromTab[findIndexing]);
  //       setFromcurrencyImage(fromTab[findIndexing].image);
  //       setfromCurrency(fromTab[findIndexing].currencySymbol);
  //       swapPrice();
  //     }

  //  }

  const preferPayment = [
    { value: "All payments", text: "All payments" },
    {
      value: "Bank Transfer",
      text: "Bank Transfer",
    },
    { value: "UPI ID", text: "UPI ID" },
    { value: "Paytm", text: "Paytm" },
  ];

  const preferPaymentOption = preferPayment[0];

  const payTime = [
    { value: "15", text: "15 Minutes" },
    {
      value: "30",
      text: "30 Minutes",
    },
    { value: "45", text: "45 Minutes" },
    { value: "60", text: "1 Hour" },
    { value: "120", text: "2 Hours" },
    { value: "180", text: "3 Hours" },
    { value: "240", text: "4 Hours" },
    { value: "300", text: "5 Hours" },
    { value: "360", text: "6 Hours" },
  ];

  const choosePaytime = async (option) => {
    console.log("payment time==", option.value);
    setpayment_time(option.value);
  };

  useEffect(() => {
    getAllcurrency();

    let getToken = localStorage.getItem("user_token");
    if (getToken != "" && getToken != undefined && getToken != null) {
      getKycData();
    } else {
    }
    fiat_price();
  }, [0]);

  const [currencyvalid, setcurrencyvalid, currencyvalidref] = useState(false);
  const [fiatcurrencyvalid, setfiatcurrencyvalid, fiatcurrencyvalidref] =
    useState(false);
  const [LimitFromvalid, setLimitFromvalid, LimitFromvalidref] =
    useState(false);
  const [limitTovalidate, setlimitTovalidate, setlimitTovalidateref] =
    useState("");

  const validate = async (values) => {
    const errors = {};

    if (cryptoCurrencyref.current == "") {
      errors.currency = "Currency is required field";
      setcurrencyvalid(true);
    } else {
      setcurrencyvalid(false);
    }

    if (values.quantity == "") {
      errors.quantity = "Quantity is a required field";
      seterrquantity(true);
    } else if (values.quantity <= 0) {
      errors.quantity = "Enter Valid Amount";
      seterrquantity(true);
    } else {
      seterrquantity(false);
    }

    if (crytpoWithCashref.current == "") {
      setfiatcurrencyvalid(true);
      errors.crytpoWithCash = "Choose any fiat currency";
    } else {
      setfiatcurrencyvalid(false);
    }

    if (values.limitFrom == "") {
      errors.limitFrom = "Minimum Quantity is a required field";
      setLimitFromvalid(true);
    } else if (values.limitFrom <= 0) {
      errors.limitFrom = "Enter valid amount";
      setLimitFromvalid(true);
    } else {
      setLimitFromvalid(false);
    }

    if (values.limitTo == "") {
      errors.limitTo = "Maximum Quantity is a required field";
      setlimitTovalidate(true);
    } else if (values.limitTo <= 0) {
      errors.limitTo = "Enter valid Amount";
      setlimitTovalidate(true);
    } else {
      setlimitTovalidate(false);
    }

    setvalidationnErr(errors);

    return;

    if (values.price == "") {
      errors.price = "Price is a required field";
      serpriceError(true);
    } else if (values.price <= 0) {
      errors.price = "Enter valid Amount";
      serpriceError(true);
    } else {
      serpriceError(false);
    }

    if (values.cryptoCurrency == "") {
      // errors.password = 'Password and confirm password does not match';
      errors.cryptoCurrency = "Crypto Currency required field";
      seterrcryptoCurrency(true);
    }
    if (values.crytpoWithCash == "") {
      // errors.password = 'Password and confirm password does not match';
      errors.crytpoWithCash = "WithCash is a required field";
      seterrcrytpoWithCash(true);
    }
    if (values.prferPay == "") {
      // errors.password = 'Password and confirm password does not match';
      errors.prferPay = "Prefer payment is a required field";
      seterrprferPay(true);
    }
    if (Terms == false) {
      errors.terms = "Terms is a required field";
      setTermsValidation(true);
    } else {
      setTermsValidation(false);
    }

    if (payment_timeref.current == "") {
      errors.payment_time = "Payment Time required field";
      seterrpaymentTime(true);
    }
    return errors;
  };

  const submitPost = async (type) => {
    //if(kycstatus == 1){

    validate(formValue);
    formValue["fromCurrency"] = cryptoCurrencyref.current;
    formValue["toCurrency"] = crytpoWithCashref.current;
    formValue["preferpayment"] = prferPay;
    formValue["orderType"] = type;
    formValue["firstCurrency"] = firstCurrency;
    formValue["secondCurrency"] = secondCurrency;
    formValue["pay_time"] = payment_timeref.current;
    if (
      LimitFromvalidref.current == false &&
      seterrquantityref.current == false &&
      currencyvalidref.current == false &&
      seterrquantityref.current == false &&
      serpriceErrorref.current == false &&
      setlimitTovalidateref.current == false
    ) {
      var data = {
        apiUrl: apiService.p2pOrder,
        payload: formValue,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setbuttonLoader(false);
      if (resp.status) {
        toast.success(resp.Message);
        formValue["quantity"] = "";
        formValue["price"] = "";
        formValue["limitFrom"] = "";
        formValue["limitTo"] = "";
        formValue["isTerms"] = "";
        formValue["firstCurrency"] = "";
        formValue["secondCurrency"] = "";
        //navigate("/p2p/chat/"+resp.orderId);
        navigate("/p2p");
      } else {
        console.log("error response====", resp);
        if (resp.bank) {
          toast.error(resp.Message);
          navigate("/Paymentmethod");
        } else {
          toast.error(resp.Message);
        }
      }
    } else {
      toast.error("Please give valid fields");
    }
    console.log(formValue, "ererwearewr");
    // }else{
    //   if(kycstatus == 2)
    //   {
    //     toast.error("Your kyc verification is pending, please wait for admin approval");
    //     navigate('/kyc');
    //   }
    //   else
    //   {
    //     toast.error("Please verify your kyc");
    //     navigate('/kyc');
    //   }

    // }
  };

  const getKycData = async () => {
    try {
      var data = {
        apiUrl: apiService.getKYCDetails,
      };
      var resp = await getMethod(data);
      if (resp.status) {
        var responseData = resp.data.kycstatus;
        setkycstatus(responseData);
      } else {
        var responseData = 0;
        setkycstatus(responseData);
      }
    } catch (error) {
      var responseData = 0;
      setkycstatus(responseData);
    }
  };

  const fiat_price = async () => {
    var payload = {
      fromCurrency: fromCurrencyref.current,
      toCurrency: toCurrencyref.current,
    };
    // var payload = {
    //   fromCurrency: "USDT",
    //   toCurrency: "INR"
    // }
    var data = {
      apiUrl: apiService.fetch_price,
      payload: payload,
    };

    var resp = await postMethod(data);
    console.log(resp.data, "fiat price -=-=-resp=-=-");
    console.log("cryptoPricesref.current===", cryptoPricesref.current);
    if (resp) {
      let index = cryptoPricesref.current.filter(
        (x) => x.symbol == fromCurrencyref.current
      );
      var high =
        resp.data.highprice != 0 ? resp.data.highprice : index[0].price;
      var low = resp.data.lowprice != 0 ? resp.data.lowprice : index[0].price;
      sethighprice(high);
      setlowprice(low);
    }
  };

  const [tab, setTab] = useState("buy");
  const tabSelect = async (type) => {
    getAllcurrency();
    setTab(type);
    if (type == "buy") {
      cryptoCurrencyref.current = fromCurrencyref.current;
    } else {
      cryptoCurrencyref.current = fromCurrencyref.current;
    }
  };

  const navback = async () => {
    navigate("/p2p");
  };

  return (
    <div className="header ">
      <Header />
      <div>
        <div className="body_hXuuO ">
          <div className="lrtcss-wxjc90 e161ieae2">
            <div className="lrtcss-1p2kb7b e161ieae0">
              <section className="p2p_title_section">
                <div className="container">
                  <div className=" p2p_bg">
                    <div className="p2p_header">
                      <h1>
                        Buy/Sell with<span>Zero Fees</span>on the <br /> Peer to
                        peer(P2P) <br />
                        Markrtplace
                      </h1>

                      <p>
                        Buy USDT using USD at the best prices, no matter your
                        location. Choose from a variety of flexible payment{" "}
                        <br /> methods, including credit and debit cards, bank
                        transfers, and more. P2P trading on Sellcrypt is both
                        secure and <br /> free.
                      </p>
                    </div>
                  </div>
                </div>
              </section>

              <section className="p2p_buy_sell_section">
                <div className="container">
                  <div className="p2p_tabs mb-5">
                    <div class="p2p_padding mt-5">
                      <ul
                        class="nav nav-tabs justify-content-center "
                        id="myTabs"
                      >
                        <li class="nav-item">
                          <a
                            class="nav-link active"
                            id="home-tab"
                            data-toggle="tab"
                            href="#Buy"
                            data-tab="Buy"
                          >
                            I want to Buy
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            id="profile-tab"
                            data-toggle="tab"
                            href="#Sell"
                            data-tab="Sell"
                          >
                            I want to Sell
                          </a>
                        </li>
                      </ul>

                      <div class="p2p_tab tab-content mt-2">
                        <div class="tab-pane fade show active" id="Buy">
                          <div class="lrtcss-ac8m87 e8w7gdo16">
                            <div className="w-100">
                              <div className="mb-5">
                                <div className="row w-100">
                                  <div className="col-lg-12">
                                    <div className="card_login w-100 mb-5">
                                      <div className="row justify-content-center">
                                        <div className="col-lg-4">
                                          <div>
                                            <label className="lable_text">
                                              Crypto Currency
                                            </label>
                                            <div class=" position_eye">
                                              <div class="dropdown">
                                                <button
                                                  class="form-control dropdesign dropdown-toggle"
                                                  type="button"
                                                  id="dropdownMenuButton"
                                                  data-toggle="dropdown"
                                                  aria-haspopup="true"
                                                  aria-expanded="false"
                                                >
                                                  {fromCurrencyref.current == ""
                                                    ? "Select Crypto"
                                                    : fromCurrencyref.current}
                                                </button>
                                                <div
                                                  class="dropdown-menu dropdesigns"
                                                  aria-labelledby="dropdownMenuButton"
                                                >
                                                  {allCurrency &&
                                                    allCurrency.map(
                                                      (item, j) => {
                                                        return (
                                                          <p
                                                            class="dropdown-item"
                                                            onClick={() =>
                                                              chooseCrypto(item)
                                                            }
                                                          >
                                                            {item.key}
                                                          </p>
                                                        );
                                                      }
                                                    )}
                                                </div>
                                              </div>

                                              {currencyvalidref.current ==
                                              true ? (
                                                <small className="errorcss">
                                                  {validationnErr.currency}
                                                </small>
                                              ) : (
                                                ""
                                              )}
                                            </div>

                                            <label className="lable_text">
                                              Min. Limit Amount *
                                            </label>
                                            <div class="form_login_input position_eye">
                                              <input
                                                type="number"
                                                class="form-control"
                                                id="exampleInputPassword1"
                                                placeholder="Min. Limit Amount"
                                                name="limitFrom"
                                                value={limitFrom}
                                                onChange={handleChange}
                                                min="0"
                                              />

                                              <div className="eye_content">
                                                <small>
                                                  {cryptoCurrencyref.current}
                                                </small>
                                              </div>

                                              {LimitFromvalidref.current ==
                                              true ? (
                                                <small className="errorcss">
                                                  {validationnErr.limitFrom}
                                                </small>
                                              ) : (
                                                ""
                                              )}
                                            </div>

                                            <label className="lable_text">
                                              Lowest Price*
                                            </label>

                                            <div class="form_login_input position_eye">
                                              <input
                                                type="email"
                                                class="form-control"
                                                id="exampleInputPassword1"
                                                value={lowpriceref.current}
                                                readOnly
                                              />
                                              <div className="eye_content">
                                                <small>
                                                  {crytpoWithCashref.current}
                                                </small>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-lg-4">
                                          <div>
                                            <label className="lable_text">
                                              Crypto Quantity *
                                            </label>
                                            <div class="form_login_input position_eye">
                                              <input
                                                type="number"
                                                class="form-control"
                                                id="exampleInputPassword1"
                                                min="0"
                                                placeholder="Enter quantity"
                                                name="quantity"
                                                value={quantity}
                                                onChange={handleChange}
                                              />
                                              {seterrquantityref.current ==
                                              true ? (
                                                <small className="errorcss">
                                                  {validationnErr.quantity}
                                                </small>
                                              ) : (
                                                ""
                                              )}
                                            </div>

                                            <label className="lable_text">
                                              Max. Limit Amount *
                                            </label>
                                            <div class="form_login_input position_eye">
                                              <input
                                                type="number"
                                                class="form-control"
                                                id="exampleInputPassword1"
                                                placeholder="Max. Limit Amount"
                                                name="limitTo"
                                                value={limitTo}
                                                onChange={handleChange}
                                                min="0"
                                              />
                                              <div className="eye_content">
                                                <small>
                                                  {cryptoCurrencyref.current}
                                                </small>
                                              </div>
                                            </div>
                                            <label className="lable_text">
                                              Payment Method
                                            </label>
                                            <div class=" position_eye">
                                              <div class="dropdown">
                                                <button
                                                  class="form-control dropdesign dropdown-toggle"
                                                  type="button"
                                                  id="dropdownMenuButton"
                                                  data-toggle="dropdown"
                                                  aria-haspopup="true"
                                                  aria-expanded="false"
                                                >
                                                  {prferPay == ""
                                                    ? "Select Payment Method"
                                                    : prferPay}
                                                </button>
                                                <div
                                                  class="dropdown-menu dropdesigns"
                                                  aria-labelledby="dropdownMenuButton"
                                                >
                                                  {preferPayment &&
                                                    preferPayment.map(
                                                      (item, j) => {
                                                        return (
                                                          <p
                                                            class="dropdown-item"
                                                            onClick={() =>
                                                              prferPayments(
                                                                item
                                                              )
                                                            }
                                                          >
                                                            {item.value}
                                                          </p>
                                                        );
                                                      }
                                                    )}
                                                </div>
                                              </div>

                                              {errprferPay.current == true ? (
                                                <small className="errorcss">
                                                  {validationnErr.prferPay}
                                                </small>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-lg-4">
                                          <div>
                                            <label className="lable_text">
                                              With Cash
                                            </label>
                                            <div class=" position_eye">
                                              <div class="dropdown">
                                                <button
                                                  class="form-control dropdesign dropdown-toggle"
                                                  type="button"
                                                  id="dropdownMenuButton"
                                                  data-toggle="dropdown"
                                                  aria-haspopup="true"
                                                  aria-expanded="false"
                                                >
                                                  {toCurrencyref.current == ""
                                                    ? " Select Fiat"
                                                    : toCurrencyref.current}
                                                </button>
                                                <div
                                                  class="dropdown-menu dropdesigns"
                                                  aria-labelledby="dropdownMenuButton"
                                                >
                                                  {allCurrencyFiat &&
                                                    allCurrencyFiat.map(
                                                      (item, j) => {
                                                        console.log(
                                                          item,
                                                          "item======"
                                                        );
                                                        return (
                                                          <p
                                                            class="dropdown-item"
                                                            onClick={() =>
                                                              withCash(item)
                                                            }
                                                          >
                                                            {item.text}
                                                          </p>
                                                        );
                                                      }
                                                    )}
                                                </div>
                                              </div>

                                              {fiatcurrencyvalidref.current ==
                                              true ? (
                                                <small className="errorcss">
                                                  {
                                                    validationnErr.crytpoWithCash
                                                  }
                                                </small>
                                              ) : (
                                                ""
                                              )}
                                            </div>

                                            <label className="lable_text">
                                              Price*
                                            </label>
                                            <div class="form_login_input position_eye">
                                              <input
                                                type="number"
                                                class="form-control"
                                                min="0"
                                                id="exampleInputPassword1"
                                                placeholder="Enter lowest price"
                                                value={price}
                                                onChange={handleChange}
                                              />
                                              {priceError == true ? (
                                                <small className="errorcss">
                                                  {validationnErr.price}
                                                </small>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                            <label className="lable_text">
                                              Payment Time *
                                            </label>
                                            <div class=" position_eye">
                                              <div class="dropdown">
                                                <button
                                                  class="form-control dropdesign dropdown-toggle"
                                                  type="button"
                                                  id="dropdownMenuButton"
                                                  data-toggle="dropdown"
                                                  aria-haspopup="true"
                                                  aria-expanded="false"
                                                >
                                                  {payment_timeref.current == ""
                                                    ? " Payment Time"
                                                    : `${payment_timeref.current} Minutes`}
                                                </button>
                                                <div
                                                  class="dropdown-menu dropdesigns"
                                                  aria-labelledby="dropdownMenuButton"
                                                >
                                                  {payTime &&
                                                    payTime.map((item, j) => {
                                                      return (
                                                        <p
                                                          class="dropdown-item"
                                                          onClick={() =>
                                                            choosePaytime(item)
                                                          }
                                                        >
                                                          {item.text}
                                                        </p>
                                                      );
                                                    })}
                                                </div>
                                              </div>

                                              {errpaymentTime.current ==
                                              true ? (
                                                <small className="errorcss">
                                                  {validationnErr.payment_time}
                                                </small>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-lg-4">
                                          <div class="form_login_input">
                                            <Button
                                              className="mt-4"
                                              onClick={() => navback()}
                                            >
                                              Cancel
                                            </Button>
                                          </div>
                                        </div>

                                        <div className="col-lg-4">
                                          <div class="form_login_input">
                                            {buttonLoader == false ? (
                                              <Button
                                                className="mt-4"
                                                onClick={() =>
                                                  submitPost("buy")
                                                }
                                              >
                                                Post Ad
                                              </Button>
                                            ) : (
                                              <Button className="mt-4">
                                                Loading
                                              </Button>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane fade" id="Sell">
                          <div class="lrtcss-ac8m87 e8w7gdo16">
                            <div className="w-100">
                              <div className="mb-5">
                                <div className="row w-100">
                                  <div className="col-lg-12">
                                    <div className="card_login w-100 mb-5">
                                      <div className="row justify-content-center">
                                        <div className="col-lg-4">
                                          <div>
                                            <label className="lable_text">
                                              Crypto Currency
                                            </label>
                                            <div class=" position_eye">
                                              <div class="dropdown">
                                                <button
                                                  class="form-control dropdesign dropdown-toggle"
                                                  type="button"
                                                  id="dropdownMenuButton"
                                                  data-toggle="dropdown"
                                                  aria-haspopup="true"
                                                  aria-expanded="false"
                                                >
                                                  {fromCurrencyref.current == ""
                                                    ? "Select Crypto"
                                                    : fromCurrencyref.current}
                                                </button>
                                                <div
                                                  class="dropdown-menu dropdesigns"
                                                  aria-labelledby="dropdownMenuButton"
                                                >
                                                  {allCurrency &&
                                                    allCurrency.map(
                                                      (item, j) => {
                                                        return (
                                                          <p
                                                            class="dropdown-item"
                                                            onClick={() =>
                                                              chooseCrypto(item)
                                                            }
                                                          >
                                                            {item.key}
                                                          </p>
                                                        );
                                                      }
                                                    )}
                                                </div>
                                              </div>

                                              {errcryptoCurrency == true ? (
                                                <small className="errorcss">
                                                  {
                                                    validationnErr.cryptoCurrency
                                                  }
                                                </small>
                                              ) : (
                                                ""
                                              )}
                                            </div>

                                            <label className="lable_text">
                                              Min. Limit Amount *
                                            </label>
                                            <div class="form_login_input position_eye">
                                              <input
                                                type="number"
                                                class="form-control"
                                                id="exampleInputPassword1"
                                                placeholder="Min. Limit Amount"
                                                name="limitFrom"
                                                value={limitFrom}
                                                onChange={handleChange}
                                                min="0"
                                              />

                                              <div className="eye_content">
                                                <small>
                                                  {cryptoCurrencyref.current}
                                                </small>
                                              </div>

                                              {LimitFromvalidref.current ==
                                              true ? (
                                                <small className="errorcss">
                                                  {validationnErr.limitFrom}
                                                </small>
                                              ) : (
                                                ""
                                              )}
                                            </div>

                                            <label className="lable_text">
                                              Highest Price*
                                            </label>

                                            <div class="form_login_input position_eye">
                                              <input
                                                type="email"
                                                class="form-control"
                                                id="exampleInputPassword1"
                                                value={highpriceref.current}
                                                readOnly
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-lg-4">
                                          <div>
                                            <label className="lable_text">
                                              Crypto Quantity *
                                            </label>
                                            <div class="form_login_input position_eye">
                                              <input
                                                type="number"
                                                class="form-control"
                                                id="exampleInputPassword1"
                                                min="0"
                                                placeholder="Enter quantity"
                                                name="quantity"
                                                value={quantity}
                                                onChange={handleChange}
                                              />
                                              {seterrquantityref.current ==
                                              true ? (
                                                <small className="errorcss">
                                                  {validationnErr.quantity}
                                                </small>
                                              ) : (
                                                ""
                                              )}
                                            </div>

                                            <label className="lable_text">
                                              Max. Limit Amount *
                                            </label>
                                            <div class="form_login_input position_eye">
                                              <input
                                                type="number"
                                                class="form-control"
                                                id="exampleInputPassword1"
                                                placeholder="Max. Limit Amount"
                                                name="limitTo"
                                                value={limitTo}
                                                onChange={handleChange}
                                                min="0"
                                              />
                                              <div className="eye_content">
                                                <small>
                                                  {cryptoCurrencyref.current}
                                                </small>
                                              </div>

                                              {setlimitTovalidateref.current ==
                                              true ? (
                                                <small className="errorcss">
                                                  {validationnErr.limitTo}
                                                </small>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                            <label className="lable_text">
                                              Payment Method
                                            </label>
                                            <div class=" position_eye">
                                              <div class="dropdown">
                                                <button
                                                  class="form-control dropdesign dropdown-toggle"
                                                  type="button"
                                                  id="dropdownMenuButton"
                                                  data-toggle="dropdown"
                                                  aria-haspopup="true"
                                                  aria-expanded="false"
                                                >
                                                  {prferPay == ""
                                                    ? "Select Payment Method"
                                                    : prferPay}
                                                </button>
                                                <div
                                                  class="dropdown-menu dropdesigns"
                                                  aria-labelledby="dropdownMenuButton"
                                                >
                                                  {preferPayment &&
                                                    preferPayment.map(
                                                      (item, j) => {
                                                        return (
                                                          <p
                                                            class="dropdown-item"
                                                            onClick={() =>
                                                              prferPayments(
                                                                item
                                                              )
                                                            }
                                                          >
                                                            {item.value}
                                                          </p>
                                                        );
                                                      }
                                                    )}
                                                </div>
                                              </div>

                                              {errprferPay.current == true ? (
                                                <small className="errorcss">
                                                  {validationnErr.prferPay}
                                                </small>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-lg-4">
                                          <div>
                                            <label className="lable_text">
                                              With Cash
                                            </label>
                                            <div class=" position_eye">
                                              <div class="dropdown">
                                                <button
                                                  class="form-control dropdesign dropdown-toggle"
                                                  type="button"
                                                  id="dropdownMenuButton"
                                                  data-toggle="dropdown"
                                                  aria-haspopup="true"
                                                  aria-expanded="false"
                                                >
                                                  {toCurrencyref.current == ""
                                                    ? " Select Fiat"
                                                    : toCurrencyref.current}
                                                </button>
                                                <div
                                                  class="dropdown-menu dropdesigns"
                                                  aria-labelledby="dropdownMenuButton"
                                                >
                                                  {allCurrencyFiat &&
                                                    allCurrencyFiat.map(
                                                      (item, j) => {
                                                        console.log(
                                                          item,
                                                          "item======"
                                                        );
                                                        return (
                                                          <p
                                                            class="dropdown-item"
                                                            onClick={() =>
                                                              withCash(item)
                                                            }
                                                          >
                                                            {item.text}
                                                          </p>
                                                        );
                                                      }
                                                    )}
                                                </div>
                                              </div>

                                              {errcrytpoWithCash.current ==
                                              true ? (
                                                <small className="errorcss">
                                                  {
                                                    validationnErr.crytpoWithCash
                                                  }
                                                </small>
                                              ) : (
                                                ""
                                              )}
                                            </div>

                                            <label className="lable_text">
                                              Price*
                                            </label>
                                            <div class="form_login_input position_eye">
                                              <input
                                                type="number"
                                                class="form-control"
                                                min="0"
                                                id="exampleInputPassword1"
                                                placeholder="Enter lowest price"
                                                value={price}
                                                onChange={handleChange}
                                              />
                                              {priceError == true ? (
                                                <small className="errorcss">
                                                  {validationnErr.price}
                                                </small>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                            <label className="lable_text">
                                              Payment Time *
                                            </label>
                                            <div class=" position_eye">
                                              <div class="dropdown">
                                                <button
                                                  class="form-control dropdesign dropdown-toggle"
                                                  type="button"
                                                  id="dropdownMenuButton"
                                                  data-toggle="dropdown"
                                                  aria-haspopup="true"
                                                  aria-expanded="false"
                                                >
                                                  {payment_timeref.current == ""
                                                    ? " Payment Time"
                                                    : `${payment_timeref.current} Minutes`}
                                                </button>
                                                <div
                                                  class="dropdown-menu dropdesigns"
                                                  aria-labelledby="dropdownMenuButton"
                                                >
                                                  {payTime &&
                                                    payTime.map((item, j) => {
                                                      return (
                                                        <p
                                                          class="dropdown-item"
                                                          onClick={() =>
                                                            choosePaytime(item)
                                                          }
                                                        >
                                                          {item.text}
                                                        </p>
                                                      );
                                                    })}
                                                </div>
                                              </div>

                                              {errpaymentTime.current ==
                                              true ? (
                                                <small className="errorcss">
                                                  {validationnErr.payment_time}
                                                </small>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-lg-4">
                                          <div class="form_login_input">
                                            <Button
                                              className="mt-4"
                                              onClick={() => navback()}
                                            >
                                              Cancel
                                            </Button>
                                          </div>
                                        </div>

                                        <div className="col-lg-4">
                                          <div class="form_login_input">
                                            {buttonLoader == false ? (
                                              <Button
                                                className="mt-4"
                                                onClick={() =>
                                                  submitPost("sell")
                                                }
                                              >
                                                Post Ad
                                              </Button>
                                            ) : (
                                              <Button className="mt-4">
                                                Loading
                                              </Button>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Home;
