import React, { useState, useEffect } from "react";
import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "./Innerpages/Sidebar";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Moment from "moment";
import { env } from "../core/service/envconfig";
import useStateRef from "react-usestateref";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import { socket } from "./context/socket";
import Pagination from "react-js-pagination";
import io from 'socket.io-client';

function Home() {
  var settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  useEffect(() => {
    const socket = io('http://localhost:3033'); // Replace with your server URL
    socket.on('connect', () => {
      console.log('Connected to server');
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const eventData = "Hello";

  socket.emit('eventName', eventData);


  const [perpage, setperpage] = useState(5);
  const [page, setpage] = useState(1);
  const [search, setsearch, searchref] = useStateRef("");
  const [balanceDetails, setbalanceDetails] = useState([]);
  const [balance_overallusdt, setbalance_overallusde] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [total, settotal] = useState(0);
  const [totalINRPrice, setToatalINRPrice] = useState(0);
  const [AvailablePrice, setAvailablePrice] = useState(0);
  const [inorderPrice, setinorderPrice] = useState(0);
  const [profileData, setprofileData] = useState("");
  const [lastLogin, setLastLogin] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [siteLoader, setSiteLoader] = useState(false);

  const [balanceDatas, setbalanceDatas] = useState([]);

  const handleChange = (e) => {
    try {
      const sanitizedValue = e.target.value.replace(/\s/g, '');
      setsearch(sanitizedValue);
      searchWalletList();
    } catch (error) { }
  };

  const recordPerPage = 5;
  const totalRecords = 7;
  const pageRange = 5;
  const navigate = useNavigate();

  const depositNav = () => {
    navigate("/deposit");
  };
  const navigatereferal = () => {
    navigate("/referral");
  };
  const buycryptoNav = () => {
    navigate("/p2p");
  };
  const withdrawNav = () => {
    navigate("/Withdraw");
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    var current_page = +pageNumber * 5;
    var prev_page = +current_page - 5;
    var resp_balance = [];
    for (var i = prev_page; i < current_page; i++) {
      resp_balance.push(balanceDatas[i]);
    }
    setbalanceDetails(resp_balance);
  };

  const getProfile = async () => {
    try {
      var data = {
        apiUrl: apiService.getUserDetails,
      };
      var resp = await getMethod(data);

      if (resp.status == true) {
        setprofileData(resp.data);
        // console.log(resp.data);
        setLastLogin(resp.lastLogin);

        const timestamp = resp.lastLogin.createdDate;
        const date = new Date(timestamp);
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        // const timeZoneCountry = Intl.DateTimeFormat(undefined, {timeZoneName: 'long'}).formatToParts(date)
        //   .find(part => part.type === 'timeZoneName').value;
        setTimeZone(timeZone);

        localStorage.setItem("tfa_status", resp.data.tfastatus);
      }
    } catch (error) { }
  };

  const getUserTotalbalance = async (pages) => {
    var obj = {
      perpage: perpage,
      page: pages,
      search: search,
    };
    var data = {
      apiUrl: apiService.getUserTotalbalance,
      payload: obj,
    };
    setSiteLoader(true);

    var resp = await postMethod(data);
    if (resp.status == true) {
      var balanceData = resp.balance;
      setSiteLoader(false);
      console.log(balanceData);
      console.log(balanceData.total_balance);
      console.log(balanceData.available_balance);
      console.log(balanceData.inorder_balance);
      setToatalINRPrice(balanceData.total_balance);
      setAvailablePrice(balanceData.available_balance);
      setinorderPrice(balanceData.inorder_balance);
    }
  };

  const getUserbalance = async (pages) => {
    setSiteLoader(false);

    var obj = {
      perpage: perpage,
      page: pages,
      search: search,
    };

    var data = {
      apiUrl: apiService.getUserBalance,
      payload: obj,
    };
    var resp = await postMethod(data);
    if (resp.status == true) {
      setSiteLoader(false);
      console.log(resp.Message, "=-=-=-resp.Message=-=-=-");
      var balanceData = resp.Message;
      setbalanceDatas(balanceData);

      var current_page = +resp.current * 5;
      var prev_page = +current_page - 5;
      var resp_balance = [];
      for (var i = prev_page; i < current_page; i++) {
        resp_balance.push(balanceData[i]);
      }
      setbalanceDetails(resp_balance);
      var totalnumber = resp.total;
      settotal(resp.total);
      console.log(resp.total, "resp.totalresp.total");
      var balanceData = resp.balance;
      // setToatalINRPrice(balanceData.total_balance);
      // setAvailablePrice(balanceData.available_balance);
      // setinorderPrice(balanceData.inorder_balance);
      // setAvailablePrice(availableAmount);
      // setinorderPrice(holdAMount);
    } else {
    }
  };

  const searchWalletList = async () => {
    if (
      searchref.current !== "" &&
      searchref.current !== undefined &&
      searchref.current !== null
    ) {
      const regexPattern = new RegExp(searchref.current, "i");
      const searchWallet = balanceDatas.filter((data) =>
        regexPattern.test(data.currencysymbol)
      );
      // const searchWallet = balanceDatas.filter(data => data.currencysymbol.toLowerCase() === searchref.current.toLowerCase());
      if (searchWallet.length > 0) {
        setbalanceDetails(searchWallet);
        setDataHide(false);
        settotal(1);
      } else {
        getUserbalance(1);
      }
    } else {
      getUserbalance(1);
    }
  };

  const searchWalletListTwo = async () => {
    const regexPattern = new RegExp(searchref.current, "i");
    console.log(balanceDatas);
    var data = [];
    for (let index = 0; index < balanceDatas.length; index++) {
      const element = balanceDatas[index];
      if (element.currencyBalance > 0) {
        console.log(element);
        data.push(element);
      }
      setbalanceDetails(data);
    }
    setDataHide(true);
    settotal(1);
  };

  const getPortfolio = async () => {
    var data = {
      apiUrl: apiService.portfolioBalance,
    };
    var resp = await getMethod(data);
    if (resp.status == true) {
      // console.log(resp, "=-=-=-resp.Message=-=-=-");
      var balanceData = resp.balance;
      setToatalINRPrice(balanceData.total_balance);
      setAvailablePrice(balanceData.available_balance);
      setinorderPrice(balanceData.inorder_balance);
    } else {
    }
  };

  useEffect(() => {
    let token_socket = localStorage.getItem("user_token");
    if (!token_socket) {
      navigate("/login");
    }
    getPortfolio();
    socket.on("sitesettings", function (res) {
      console.log(res);
    });
    getProfile();
    FindData();
    Kycdata();
    getUserbalance(currentPage);
    getUserTotalbalance(currentPage);
    refreshUse();
    console.log(profileData, "-=profileData=-");
  }, [0]);

  const refreshUse = async () => {
    var data = {
      apiUrl: apiService.transaction,
    };
    var resp = await getMethod(data);
    if (resp.message == true) {
      getUserbalance(1);
    }
  };

  const [textToCopy, setTextToCopy] = useState("");
  const [copied, setCopied] = useState(false);
  const [changeCode, setchangeCode] = useState(false);
  const [getKYCData, setgetKYCData] = useState("");

  const FindData = async () => {
    var data = {
      apiUrl: apiService.findDetails,
    };
    var responce = await postMethod(data);
    if (responce.data != null) {
      if (responce.data.APcode != "") {
        setchangeCode(true);
      } else {
        setchangeCode(false);
      }
    } else {
      setchangeCode(false);
    }
  };

  const Kycdata = async () => {
    var data = {
      apiUrl: apiService.getKYC,
    };
    var resp = await getMethod(data);
    if (resp.status) {
      if (resp.status != "") {
        var kycData = resp.datas.kycDetails;
        console.log(kycData, "kycData");
        setgetKYCData(kycData);
      }
    }
  };

  const copyText = () => {
    const profileId = profileData.uuid;
    navigator.clipboard
      .writeText(profileId)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000);
        toast.success("Copied Successfully !");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const [loader, setloader] = useState(false);

  const refresh = async () => {
    var data = {
      apiUrl: apiService.transaction,
    };
    setloader(true);
    var resp = await getMethod(data);
    setloader(false);
    if (resp.message == true) {
      getUserbalance(1);
    }
  };

  const [dataHide, setDataHide] = useState(false);

  const datasHide = (data) => {
    if (data == false) {
      setDataHide(true);
      searchWalletListTwo();
    } else {
      setDataHide(false);
      searchWalletList();
    }
  };

  const [passHide, setPasshide] = useState(false);
  const [inputType, setinputType] = useState(false);

  const passwordHide = (data) => {
    if (data == "hide") {
      setPasshide(true);
      setinputType(true);
    } else {
      setPasshide(false);
      setinputType(false);
    }
  };

  return (
    <div className="header ">
      <Header />

      <div>
        <div className="body_hXuuO ">
          <div className="lrtcss-wxjc90 e161ieae2">
            <div className="lrtcss-q484j3 e161ieae1">
              <div className="KuxMenu-root lrtcss-yqpjhy">
                <Sidebar />
              </div>
            </div>
            <div className="lrtcss-1p2kb7b e161ieae0">
              {siteLoader == true ? (
                <i class="fa-solid fa-spinner fa-spin"></i>
              ) : (
                <div className="lrtcss-sbd8h eagejp24">
                  <div className="Dashcard">
                    <div class="lrtcss-1fttcpj e1t7lflc22">
                      <div class="lrtcss-1j8rvpu e1t7lflc21">
                        <span class="lrtcss-98pbtq e1t7lflc20">
                          <img
                            src={require("../Image/cahbitlogo.png")}
                            className="img-fluid imgcss"
                          />
                        </span>
                        <h1 class="lrtcss-sq6w90 e1t7lflc19">
                          {profileData.username}
                          {/*, {profileData.email} */}
                        </h1>
                        {/* <div class="lrtcss-1cp6ea7 e1t7lflc18">
                          <svg
                            class="lrtcss-16euqaz e1t7lflc17"
                            viewBox="0 0 1024 1024"
                            xmlns="http://www.w3.org/2000/svg"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                          >
                            <path d="M766.72 158.24a128 128 0 00-181.088.032L216.96 527.36A85.44 85.44 0 00192 587.68v94.976A85.344 85.344 0 00277.344 768h95.744a85.381 85.381 0 0060.352-24.992L801.888 374.4a128.032 128.032 0 000-180.992L766.72 158.24zm-120.736 60.352a42.656 42.656 0 0160.352 0l35.168 35.168c16.64 16.672 16.64 43.68 0 60.32L373.056 682.688h-95.744v-94.976l368.672-369.12zm-496.64 634.752a42.656 42.656 0 000 85.312h768a42.656 42.656 0 000-85.312h-768z"></path>
                          </svg>
                        </div> */}
                      </div>
                      <div class="lrtcss-1iijojv e1t7lflc11">
                        <div class="lrtcss-1j3ym55 e1t7lflc10">
                          <div class="lrtcss-11vn8f6 e1t7lflc8">UID</div>
                          <div class="lrtcss-8d5juj e1t7lflc5">
                            {profileData.uuid}
                            <svg
                              class="lrtcss-1ir9ty7 e1t7lflc2"
                              viewBox="0 0 1024 1024"
                              xmlns="http://www.w3.org/2000/svg"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              onClick={copyText}
                            >
                              <path d="M772 64a42.656 42.656 0 010 85.312H166.656a17.344 17.344 0 00-17.344 17.344V772A42.656 42.656 0 0164 772V166.656A102.656 102.656 0 01166.656 64H772z"></path>
                              <path d="M337.344 234.656a102.656 102.656 0 00-102.656 102.656v520a102.656 102.656 0 00102.656 102.656h520A102.656 102.656 0 00960 857.312v-520a102.656 102.656 0 00-102.656-102.656h-520zM320 337.344c0-9.568 7.744-17.344 17.344-17.344h520c9.568 0 17.344 7.744 17.344 17.344v520a17.344 17.344 0 01-17.344 17.344h-520A17.344 17.344 0 01320 857.344v-520z"></path>
                              {copied ? 'Copied!' : 'Copy'}
                            </svg>
                          </div>
                        </div>
                        <div class="lrtcss-1j3ym55 e1t7lflc10">
                          <a
                            href="/Identity"
                            class="lrtcss-131r450 e1t7lflc9"
                          >
                            Identity Verification
                            <svg
                              class="lrtcss-q2kz5y e1t7lflc6"
                              viewBox="0 0 1024 1024"
                              xmlns="http://www.w3.org/2000/svg"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                            >
                              <path d="M375.168 225.824a42.656 42.656 0 0160.352 0l256 256a42.656 42.656 0 010 60.352l-256 256a42.656 42.656 0 11-60.352-60.352L600.992 512 375.168 286.176a42.656 42.656 0 010-60.352z"></path>
                            </svg>
                          </a>
                          <div type="WARN" class="lrtcss-xvls7q e1t7lflc1">
                            <svg
                              class="lrtcss-1oby5b8 e1t7lflc0"
                              viewBox="0 0 1024 1024"
                              xmlns="http://www.w3.org/2000/svg"
                              p-id="2280"
                            >
                              <path
                                d="M512 512m-512 0a512 512 0 1 0 1024 0 512 512 0 1 0-1024 0ZM457.581714 240.201143m54.418286 0l0 0q54.418286 0 54.418286 54.418286l0 262.363428q0 54.418286-54.418286 54.418286l0 0q-54.418286 0-54.418286-54.418286l0-262.363428q0-54.418286 54.418286-54.418286ZM448 649.801143m64 0l0 0q64 0 64 64l0 0q0 64-64 64l0 0q-64 0-64-64l0 0q0-64 64-64Z"
                                p-id="2281"
                                data-spm-anchor-id="a313x.icontype_auditing.1998910419.i5.5fe93a81rJnl5R"
                              ></path>
                            </svg>{" "}


                            {getKYCData == null ? (
                              "Not Uploaded"
                            ) :
                              getKYCData.kycStatus == 3 ? (
                                "Rejected"
                              ) : getKYCData.kycStatus == 2 ? (
                                "Pending"
                              ) : getKYCData.kycStatus == 1 ? (
                                "Verified"
                              ) : (
                                "Not Uploaded"
                              )}




                          </div>
                        </div>
                        <div class="lrtcss-1j3ym55 e1t7lflc10">
                          <div class="KuxDropDown-container lrtcss-9ncwx">
                            <div class="KuxDropDown-trigger">
                              <a
                                href="/Security"
                                class="lrtcss-131r450 e1t7lflc9"
                              >
                                <span class="lrtcss-7ul6zh e1t7lflc7">
                                  Security
                                </span>
                                <svg
                                  class="lrtcss-q2kz5y e1t7lflc6"
                                  viewBox="0 0 1024 1024"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="1em"
                                  height="1em"
                                  fill="currentColor"
                                >
                                  <path d="M375.168 225.824a42.656 42.656 0 0160.352 0l256 256a42.656 42.656 0 010 60.352l-256 256a42.656 42.656 0 11-60.352-60.352L600.992 512 375.168 286.176a42.656 42.656 0 010-60.352z"></path>
                                </svg>
                              </a>
                            </div>
                          </div>
                          <div class="lrtcss-105sj3w e1t7lflc4">
                            <svg
                              class="lrtcss-1s70318 e1t7lflc3"
                              viewBox="0 0 1024 1024"
                              xmlns="http://www.w3.org/2000/svg"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                            >
                              <path d="M755.488 414.176a42.656 42.656 0 10-60.352-60.352L469.312 579.648 350.144 460.48a42.656 42.656 0 10-60.352 60.352l149.344 149.344a42.656 42.656 0 0060.352 0l256-256z"></path>
                              <path d="M571.968 58.336a213.216 213.216 0 00-119.584 0L208.192 129.6A170.688 170.688 0 0085.344 293.44v133.856A604.192 604.192 0 00441.6 978.272a172.001 172.001 0 00140.928 0 604.16 604.16 0 00356.16-550.88V293.408A170.624 170.624 0 00815.872 129.6L572 58.368zm-95.68 81.92a127.84 127.84 0 0171.744 0l243.872 71.232a85.344 85.344 0 0161.408 81.92v133.984a518.784 518.784 0 01-305.824 473.056 86.496 86.496 0 01-70.944 0 518.88 518.88 0 01-305.92-473.152V293.44c0-37.92 25.024-71.296 61.44-81.92l244.192-71.264z"></path>
                            </svg>
                            {profileData.mobileNumber == "" &&
                              localStorage.getItem("tfa_status") == 0 &&
                              changeCode == false
                              ? "Low"
                              : profileData.mobileNumber == "" ||
                                localStorage.getItem("tfa_status") == 0 ||
                                changeCode == false
                                ? "Medium"
                                : "High"}
                          </div>
                        </div>
                        <div class="lrtcss-1j3ym55 e1t7lflc10">
                          <div class="lrtcss-11vn8f6 e1t7lflc8">Time Zone</div>
                          <div class="lrtcss-8d5juj e1t7lflc5">
                            {timeZone}
                            {/* <svg
                              class="lrtcss-sstw6x e1t7lflc16"
                              viewBox="0 0 1024 1024"
                              xmlns="http://www.w3.org/2000/svg"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                            >
                              <path d="M766.72 158.24a128 128 0 00-181.088.032L216.96 527.36A85.44 85.44 0 00192 587.68v94.976A85.344 85.344 0 00277.344 768h95.744a85.381 85.381 0 0060.352-24.992L801.888 374.4a128.032 128.032 0 000-180.992L766.72 158.24zm-120.736 60.352a42.656 42.656 0 0160.352 0l35.168 35.168c16.64 16.672 16.64 43.68 0 60.32L373.056 682.688h-95.744v-94.976l368.672-369.12zm-496.64 634.752a42.656 42.656 0 000 85.312h768a42.656 42.656 0 000-85.312h-768z"></path>
                            </svg> */}
                          </div>
                        </div>
                        <div class="lrtcss-1j3ym55 e1t7lflc10">
                          <a
                            href="/History"
                            class="lrtcss-131r450 e1t7lflc9"
                          >
                            Last Login
                            <svg
                              class="lrtcss-q2kz5y e1t7lflc6"
                              viewBox="0 0 1024 1024"
                              xmlns="http://www.w3.org/2000/svg"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                            >
                              <path d="M375.168 225.824a42.656 42.656 0 0160.352 0l256 256a42.656 42.656 0 010 60.352l-256 256a42.656 42.656 0 11-60.352-60.352L600.992 512 375.168 286.176a42.656 42.656 0 010-60.352z"></path>
                            </svg>
                          </a>
                          <div class="lrtcss-8d5juj e1t7lflc5">
                            {Moment(lastLogin.createdDate).format("lll")} ( IP :{" "}
                            {lastLogin.ipAddress} )
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-lg-8">
                      <div className="Dashcard bg_trance">
                        {/* <div class="lrtcss-17ouo08 ecm7o3z11">
                          <h2 class="lrtcss-135twnz ecm7o3z10">Total Assets</h2>
                          <a
                            href="/wallet"
                            target="_blank"
                            class="lrtcss-kernjd ecm7o3z9"
                          >
                            Assets Overview
                          </a>
                          <span class="lrtcss-19hp5no ecm7o3z8"></span>
                          <a
                            href="/Orders"
                            target="_blank"
                            class="lrtcss-kernjd ecm7o3z9"
                          >
                            Orders
                          </a>
                        </div> */}
                        <div className="proice">
                          <h4> {AvailablePrice == "" || AvailablePrice == null || AvailablePrice == undefined ? 0.00 : AvailablePrice.toFixed(4)} USD</h4>
                        </div>
                        <div className="flexbutton">
                          <Button className="priomary" onClick={depositNav}>
                            Deposit
                          </Button>
                          {/* <Button
                            className="priomary-outline"
                            onClick={buycryptoNav}
                          >
                            Buy Crypto
                          </Button> */}
                          <Button
                            className="priomary-outline"
                            onClick={withdrawNav}
                          >
                            Withdraw
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="refframnl_card">
                        <Slider {...settings}>
                          <div className="content_card">
                            <h4>Invite Friends for Rewards</h4>
                            {/* <p>Invite friends and get 20% commissions.</p> */}
                            <Link to="/referral">
                              Invite Friends
                              <svg
                                class="lrtcss-bqoaxe emenq5p5"
                                viewBox="0 0 1024 1024"
                                xmlns="http://www.w3.org/2000/svg"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                              >
                                <path d="M605.536 225.824a42.656 42.656 0 000 60.352l183.168 183.168H123.712a42.656 42.656 0 000 85.312h664.992L605.536 737.824a42.656 42.656 0 1060.352 60.352l256-255.968v-.032l.096-.096A42.176 42.176 0 00934.4 512c0-11.744-4.736-22.368-12.416-30.08l-.096-.096v-.032l-256-255.968a42.656 42.656 0 00-60.352 0z"></path>
                              </svg>
                            </Link>
                            {/* <div className="dots">
                              <span className="dot active"></span>
                              <span className="dot "></span>
                            </div> */}
                          </div>
                          <div className="content_card">
                            <h4>Invite Friends for Rewards</h4>
                            {/* <p>Invite friends and get 20% commissions.</p> */}
                            <Link to="/referral">
                              Invite Friends
                              <svg
                                class="lrtcss-bqoaxe emenq5p5"
                                viewBox="0 0 1024 1024"
                                xmlns="http://www.w3.org/2000/svg"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                              >
                                <path d="M605.536 225.824a42.656 42.656 0 000 60.352l183.168 183.168H123.712a42.656 42.656 0 000 85.312h664.992L605.536 737.824a42.656 42.656 0 1060.352 60.352l256-255.968v-.032l.096-.096A42.176 42.176 0 00934.4 512c0-11.744-4.736-22.368-12.416-30.08l-.096-.096v-.032l-256-255.968a42.656 42.656 0 00-60.352 0z"></path>
                              </svg>
                            </Link>
                            {/* <div className="dots">
                              <span className="dot "></span>
                              <span className="dot active"></span>
                            </div> */}
                          </div>
                        </Slider>
                      </div>
                    </div>
                  </div>

                  <div className="wallet-table">
                    <div className="dashboardtablebtn">
                      <div className="balance_input">
                        <div className="input_border">
                          <div onClick={() => searchWalletList()}>
                            <i class="ri-search-line"></i>
                          </div>
                          <div>
                            <input
                              type="text"
                              placeholder="Search"
                              name="search"
                              minLength={1}
                              maxLength={15}
                              onChange={handleChange}
                              value={searchref.current}

                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex">
                        {dataHide == false ? (
                          <>
                            <div class="lrtcss-1gnhm8d e1t7lflc15 mr-2">
                              <span
                                class="lrtcss-112vdob e1t7lflc14"
                                onClick={() => datasHide(false)}
                              >
                                <i
                                  className="fa fa-eye-slash icons"
                                //  onClick={searchWalletListTwo}
                                ></i>
                                <span>Hide Zero balance</span>
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            <div class="lrtcss-1gnhm8d e1t7lflc15 mr-2">
                              <span
                                class="lrtcss-112vdob e1t7lflc14"
                                onClick={() => datasHide(true)}
                              >
                                <i
                                  className="fa fa-eye icons"
                                //  onClick={searchWalletList}
                                ></i>
                                <span>Show Zero balance</span>
                              </span>
                            </div>
                          </>
                        )}

                        {passHide == false ? (
                          <>
                            <div class="lrtcss-1gnhm8d e1t7lflc15">
                              <span
                                class="lrtcss-112vdob e1t7lflc14"
                                onClick={() => passwordHide("hide")}
                              >
                                <i className="fa fa-eye-slash icons"></i>
                                <span>Hide Info</span>
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            <div class="lrtcss-1gnhm8d e1t7lflc15">
                              <span
                                class="lrtcss-112vdob e1t7lflc14"
                                onClick={() => passwordHide("show")}
                              >
                                <i className="fa fa-eye icons"></i>
                                <span>Show Info</span>
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <table class="table market">
                      <thead>
                        <tr class="market-header">
                          <th scope="col" className="textleft">Asset</th>
                          <th scope="col">Fiat A/c</th>
                          <th scope="col">Crypto A/c</th>
                          <th scope="col">Total Balance</th>
                        </tr>
                      </thead>
                      <tbody>
                        {balanceDetails && balanceDetails.length > 0 ? (
                          balanceDetails.map((item, i) => {
                            if (!item) {
                              console.error(`Item at index ${i} is undefined.`);
                              return null; // Skip rendering this item
                            }
                            return (
                              <tr key={i}>
                                <th className="table_img1">
                                  <img src={item.currencyImage} alt="pic" />
                                  <div className="ox">
                                    {item.currencysymbol}
                                    <span>{item.currencyName}</span>{" "}
                                  </div>
                                </th>
                                <td>
                                  <div className="count">
                                    {inputType == false ? (
                                      <span className="ml-2">
                                        {parseFloat(item.holdAmount).toFixed(4)}{" "}
                                        {item.currencysymbol} <br />
                                        <span>
                                          {" "}
                                          {parseFloat(
                                            item.estimatedUSDThold
                                          ).toFixed(4)}
                                          $
                                        </span>
                                      </span>
                                    ) : (
                                      <span className="ml-2">******</span>
                                    )}
                                  </div>{" "}
                                </td>
                                <td>
                                  <div className="count">
                                    {inputType == false ? (
                                      <span className="ml-2">
                                        {parseFloat(
                                          item.currencyBalance
                                        ).toFixed(4)}{" "}
                                        {item.currencysymbol}
                                        <br />
                                        <span>
                                          {parseFloat(
                                            item.estimatedUSDTbalance
                                          ).toFixed(4)}
                                          $
                                        </span>
                                      </span>
                                    ) : (
                                      <span className="ml-2">******</span>
                                    )}
                                  </div>{" "}
                                </td>
                                <td>
                                  <div className="count">
                                    {inputType == false ? (
                                      <span className="ml-2">
                                        {parseFloat(
                                          item.currencyBalance +
                                          parseFloat(item.holdAmount)
                                        ).toFixed(4)}{" "}
                                        {item.currencysymbol} <br />
                                        <span>
                                          {" "}
                                          {parseFloat(
                                            item.estimatedUSDTtotal
                                          ).toFixed(4)}{" "}
                                          $
                                        </span>
                                      </span>
                                    ) : (
                                      <span className="ml-2">******</span>
                                    )}
                                  </div>{" "}
                                </td>
                                <td colSpan={2}>
                                  <div className="balance_tabs">
                                    <ul
                                      class="nav nav-pills tab mb-3"
                                      id="pills-tab"
                                      role="tablist"
                                    >
                                      <li
                                        class="nav-item earn"
                                        role="presentation"
                                      >
                                        {" "}
                                        <Link to="/deposit">
                                          <button
                                            class="nav-link view3"
                                            id="pills-profile-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-profile"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-profile"
                                            aria-selected="false"
                                          >
                                            Deposit
                                          </button>
                                        </Link>
                                      </li>

                                      <li
                                        class="nav-item earn"
                                        role="presentation"
                                      >
                                        <Link to="/Withdraw">
                                          <button
                                            class="nav-link view3"
                                            id="pills-profile-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-profile"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-profile"
                                            aria-selected="false"
                                          >
                                            Withdraw
                                          </button>
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>{" "}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="4">
                              <span className="w-100 text-center text-white d-block">
                                No Records found!
                              </span>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <div className="paginationcss">
                      {balanceDetails && balanceDetails.length > 0 ? (
                        <Pagination
                          className="paginationinside"
                          itemClass="page-item"
                          linkClass="page-link"
                          activePage={currentPage}
                          itemsCountPerPage={recordPerPage}
                          totalItemsCount={total}
                          pageRangeDisplayed={pageRange}
                          onChange={handlePageChange}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Home;
