import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Button } from "@mui/material";
import OTPInput, { ResendOTP } from "otp-input-react";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "./Innerpages/Sidebar";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { getMethod } from "../core/service/common.api";
import Moment from "moment";

function Home() {
  const initialFormValue = {
    AccountHolderName: "",
    AccountNumber: "",
    IFSCCode: "",
    BankName: "",
    BranchName: "",
    BranchAddress: "",
    gpay_number: "",
    paytm_number: "",
  };

  const cleardata = {
    AccountHolderName: "",
    AccountNumber: "",
    IFSCCode: "",
    BankName: "",
    BranchName: "",
    BranchAddress: "",
    gpay_number: "",
    paytm_number: "",
  };
  const [formValue, setFormValue] = useState(initialFormValue);
  const [
    AccountHolderNameErr,
    SetAccountHolderNameErr,
    AccountHolderNameErrref,
  ] = useState(false);
  const [AccountNumberErr, SetAccountNumberErr, AccountNumberErrref] =
    useState(false);
  const [IFSCCodeErr, SetIFSCCodeErr, IFSCCodeErrref] = useState(false);
  const [BankNameErr, SetBankNameErr, BankNameErrref] = useState(false);
  const [BranchNameErr, SetBranchNameErr, BranchNameErrref] = useState(false);
  const [gpayNumber, setgpayNumber, gpayNumberref] = useState(false);
  const [paytmNumber, setpaytmNumber, paytmNumberref] = useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [BranchAddressErr, SetBranchAddressErr, BranchAddressErrref] =
    useState(false);
  const [Bankdetails, SetBankdetails] = useState([]);
  const [loading, setloading] = useState(false);

  const {
    AccountHolderName,
    AccountNumber,
    IFSCCode,
    BankName,
    BranchName,
    BranchAddress,
    gpay_number,
    paytm_number,
  } = formValue;

  const navigate = useNavigate();

  const handleChange = async (e) => {
    console.log("e====", e);
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    console.log("formData====", formData);
    setFormValue(formData);
  };

  const validate = async (values) => {
    const errors = {};
    if (values.AccountHolderName == "") {
      errors.AccountHolderName = "Holder Name is require !";
      SetAccountHolderNameErr(true);
    } else if (
      values.AccountHolderName.length < 5 ||
      values.AccountHolderName.length > 25
    ) {
      errors.AccountHolderName =
        "Account Holder Name must hava an 5 to 25 characters!";
      SetAccountHolderNameErr(true);
    } else {
      SetAccountHolderNameErr(false);
    }
    if (values.AccountNumber == "") {
      errors.AccountNumber = "Account Number is require !";
      SetAccountNumberErr(true);
    } else if (
      values.AccountNumber.length < 5 ||
      values.AccountNumber.length > 25
    ) {
      errors.AccountNumber = "Account Number  must hava an 5 to 25 Digits!";
      SetAccountNumberErr(true);
    } else {
      SetAccountNumberErr(false);
    }
    if (values.IFSCCode == "") {
      errors.IFSCCode = "IFSC Code is require !";
      SetIFSCCodeErr(true);
    } else if (values.IFSCCode.length < 5 || values.IFSCCode.length > 25) {
      errors.IFSCCode = "IFSC Code must have an 5 to 25 characters!";
      SetIFSCCodeErr(true);
    } else {
      SetIFSCCodeErr(false);
    }
    if (values.BankName == "") {
      errors.BankName = "Bank Name is require !";
      SetBankNameErr(true);
    } else if (values.BankName.length < 5 || values.BankName.length > 25) {
      errors.BankName = "Bank Name must have an 5 to 25 characters!";
      SetBankNameErr(true);
    } else {
      SetBankNameErr(false);
    }
    if (values.BranchName == "") {
      errors.BranchName = "Branch Name is require !";
      SetBranchNameErr(true);
    } else if (values.BranchName.length < 5 || values.BranchName.length > 25) {
      errors.BranchName = "Branch Name must have an 5 to 25 !";
      SetBranchNameErr(true);
    } else {
      SetBranchNameErr(false);
    }
    if (values.BranchAddress == "") {
      errors.BranchAddress = "Branch Address is require !";
      SetBranchAddressErr(true);
    } else if (
      values.BranchAddress.length < 5 ||
      values.BranchAddress.length > 150
    ) {
      errors.BranchAddress = "Branch Address must have an 5 to 150 characters!";
      SetBranchAddressErr(true);
    } else {
      SetBranchAddressErr(false);
    }
    // if (values.gpay_number == "") {
    //     errors.gpay_number = "Gpay Details is require !";
    //     setgpayNumber(true);
    // }

    // if (values.paytm_number == "") {
    //     errors.paytm_number = "Paytm Details is require !";
    //     setpaytmNumber(true);
    // }

    setvalidationnErr(errors);
    return errors;
  };

  useEffect(() => {
    getbankdetails(1);
  }, [0]);

  const defaultBank = async (editData) => {
    console.log(editData, "=-=-=-=defaultBank");
    try {
      var data = {
        apiUrl: apiService.defaultBanks,
        payload: editData,
      };
      console.log(data, "=-=datap-0-");
      var resp = await postMethod(data);
      console.log(resp, "=-=resp--=");
      if (resp.status) {
        toast(resp.Message);
        getbankdetails();
      } else {
        toast(resp.Message);
      }
    } catch (error) {}
  };

  //======================================================================//

  const [currTabs, setcurrTabs] = useState("one");
  const [editpage, seteditpage] = useState(false);

  const [Bankdetailss, setBankdetails] = useState([]);
  const [currentPagebank, setcurrentPagebank] = useState(1);
  const [totalbank, settotalbank] = useState([]);
  const recordPerPagebank = 5;
  const pageRangebank = 5;
  const handlePageChangebank = (pagenum) => {
    console.log(pagenum, "=-=-=-=pagenum");
    setcurrentPagebank(pagenum);
    getbankdetails(pagenum);
  };

  const getbankdetails = async (page) => {
    try {
      var data = {
        apiUrl: apiService.Getbankdetails,
      };
      var resp = await getMethod(data);
      if (resp.status == true) {
        console.log(resp, "resp");
        setBankdetails(resp.data);
        settotalbank(resp.total);
      }
    } catch (error) {
      console.log(error, "=-=error=-=-=");
    }
  };

  const submitID = async (e) => {
    try {
      e.preventDefault();
      validate(formValue);
      if (
        formValue.AccountHolderName != "" &&
        // formValue.AccountHolderName.length >= 5 &&
        // formValue.AccountHolderName.length <= 25 &&
        formValue.AccountNumber != "" &&
        // formValue.AccountNumber.length >= 5 &&
        // formValue.AccountNumber.length <= 25 &&
        formValue.IFSCCode != "" &&
        // formValue.IFSCCode.length >= 5 &&
        // formValue.IFSCCode.length <= 25 &&
        formValue.BankName != "" &&
        // formValue.BankName.length >= 5 &&
        // formValue.BankName.length <= 25 &&
        formValue.BranchName != "" &&
        // formValue.BranchName.length >= 5 &&
        // formValue.BranchName.length <= 25 &&
        formValue.BranchAddress != ""
        // formValue.BranchAddress.length >= 5 &&
        // formValue.BranchAddress.length <= 150 &&
        // formValue.gpay_number != "" &&
        // formValue.paytm_number != ""
      ) {
        var data = {
          apiUrl: apiService.Bankdetails,
          payload: formValue,
        };

        var resp = await postMethod(data);
        console.log(resp, "=-=-resp=--=-=");
        if (resp.status == true) {
          getbankdetails();
          setFormValue(initialFormValue);
          toast.success(resp.Message);
        } else {
          toast.error(resp.Message);
        }
      } else {
        console.log("ALL FIELD NEED");
      }
    } catch (error) {}
  };

  const editbankData = async (value) => {
    console.log(value,"value");
    seteditpage(true);
    formValue.AccountHolderName = value.Accout_HolderName;
    formValue.AccountNumber = value.Account_Number;
    formValue.BankName = value.Bank_Name;
    formValue.BranchAddress = value.Branch_Address;
    formValue.BranchName = value.Branch_Name;
    formValue.IFSCCode = value.IFSC_code;
    formValue.gpay_number = value.Gpay_Number;
    formValue.paytm_number = value.Paytm_Number;
    formValue._id = value._id;
  };

  const submitID1 = async (e) => {
    console.log(e, "edit bank details ");
    e.preventDefault();
    try {
      if (
        formValue.AccountHolderName != "" &&
        // formValue.AccountHolderName.length >= 5 &&
        // formValue.AccountHolderName.length <= 25 &&
        formValue.AccountNumber != "" &&
        // formValue.AccountNumber.length >= 5 &&
        // formValue.AccountNumber.length <= 25 &&
        formValue.IFSCCode != "" &&
        // formValue.IFSCCode.length >= 5 &&
        // formValue.IFSCCode.length <= 25 &&
        formValue.BankName != "" &&
        // formValue.BankName.length >= 5 &&
        // formValue.BankName.length <= 25 &&
        formValue.BranchName != "" &&
        // formValue.BranchName.length >= 5 &&
        // formValue.BranchName.length <= 25 &&
        formValue.BranchAddress != ""
        // formValue.BranchAddress.length >= 5 &&
        // formValue.BranchAddress.length <= 150 &&
        // formValue.gpay_number != "" &&
        // formValue.paytm_number != ""
      ) {
        var data = {
          apiUrl: apiService.updateBankdetails,
          payload: formValue,
        };

        var resp = await postMethod(data);
        console.log(resp, "=-=-resp=--=-=");
        if (resp.status == true) {
          getbankdetails();
          toast.success(resp.Message);
          // navigate("/Historynew");
          window.location.reload();
        } else {
          toast.error(resp.Message);
        }
      } else {
        toast.error("Please fill all the require  fields");
        console.log("ALL FIELD NEED");
      }
    } catch (error) { }
  };

  const deletebank = async (deleteData) => {
    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(
        "Are you sure you want to permanently delete this bank details ?"
      ) == true
    ) {
      try {
        var data = {
          apiUrl: apiService.deletbank,
          payload: deleteData,
        };
        var resp = await postMethod(data);
        if (resp.status == true) {
          getbankdetails();
          toast.success(resp.Message);
        } else {
          toast.error(resp.Message);
        }
      } catch (error) {}
    }
  };

  return (
    <div className="header ">
      <Header />
      <div>
        <div className="body_hXuuO ">
          <div className="lrtcss-wxjc90 e161ieae2">
            <div className="lrtcss-q484j3 e161ieae1">
              <div className="KuxMenu-root lrtcss-yqpjhy">
                <Sidebar />
              </div>
            </div>
            <div className="lrtcss-1p2kb7b ma-000">
              <div className="step_2">
                <div class="lrtcss-1urys0g e1epgi046">
                  <span className="headeing_with_back">Payment Methods</span>
                </div>
                <div class="lrtcss-ac8m87 e8w7gdo16">
                  <div className="w-100">
                    <div className="mb-5">
                      <div className="row justify-content-center w-100">
                        <div className="col-lg-10">
                          <div className="card_login w-100 mb-5">
                            <h3 className="mb-3">Add Bank Details</h3>
                            <div className="row justify-content-center">
                              <div className="col-lg-6">
                                <label className="lable_text">
                                  Account holder name
                                </label>
                                <div class="form_login_input position_eye">
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="exampleInputPassword1"
                                    placeholder="Account holder name"
                                    name="AccountHolderName"
                                    value={AccountHolderName}
                                    onChange={handleChange}
                                  />
                                  {AccountHolderNameErrref.current == true ? (
                                    <small className="errorcss">
                                      {" "}
                                      {validationnErr.AccountHolderName}{" "}
                                    </small>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                <label className="lable_text">IFSC code</label>
                                <div class="form_login_input position_eye">
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="exampleInputPassword1"
                                    placeholder="IFSC code"
                                    name="IFSCCode"
                                    value={IFSCCode}
                                    onChange={handleChange}
                                  />
                                  {IFSCCodeErrref.current == true ? (
                                    <small className="errorcss">
                                      {" "}
                                      {validationnErr.IFSCCode}{" "}
                                    </small>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <label className="lable_text">
                                  Account opening branch
                                </label>
                                <div class="form_login_input position_eye">
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="exampleInputPassword1"
                                    placeholder="Account opening branch"
                                    name="BranchName"
                                    value={BranchName}
                                    onChange={handleChange}
                                  />
                                  {BranchNameErrref.current == true ? (
                                    <small className="errorcss">
                                      {" "}
                                      {validationnErr.BranchName}{" "}
                                    </small>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <label className="lable_text">UPI ID</label>
                                <div class="form_login_input position_eye">
                                  <input
                                    type="number"
                                    class="form-control"
                                    id="exampleInputPassword1"
                                    placeholder="UPI ID"
                                    name="gpay_number"
                                    value={gpay_number}
                                    onChange={handleChange}
                                  />
                                  {gpayNumberref.current == true ? (
                                    <small className="errorcss">
                                      {" "}
                                      {validationnErr.gpay_number}{" "}
                                    </small>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <label className="lable_text">Account no</label>
                                <div class="form_login_input position_eye">
                                  <input
                                    type="number"
                                    class="form-control"
                                    id="exampleInputPassword1"
                                    placeholder="Account no"
                                    name="AccountNumber"
                                    value={AccountNumber}
                                    onChange={handleChange}
                                  />
                                  {AccountNumberErrref.current == true ? (
                                    <small className="errorcss">
                                      {" "}
                                      {validationnErr.AccountNumber}{" "}
                                    </small>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <label className="lable_text">Bank name</label>
                                <div class="form_login_input position_eye">
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="exampleInputPassword1"
                                    placeholder="Bank name"
                                    name="BankName"
                                    value={BankName}
                                    onChange={handleChange}
                                  />
                                  {BankNameErrref.current == true ? (
                                    <small className="errorcss">
                                      {" "}
                                      {validationnErr.BankName}{" "}
                                    </small>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <label className="lable_text">
                                  Branch Address
                                </label>
                                <div class="form_login_input position_eye">
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="exampleInputPassword1"
                                    placeholder="Branch Address"
                                    name="BranchAddress"
                                    value={BranchAddress}
                                    onChange={handleChange}
                                  />
                                  {BranchAddressErrref.current == true ? (
                                    <small className="errorcss">
                                      {" "}
                                      {validationnErr.BranchAddress}{" "}
                                    </small>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <label className="lable_text">Paytm</label>
                                <div class="form_login_input position_eye">
                                  <input
                                    type="number"
                                    class="form-control"
                                    id="exampleInputPassword1"
                                    placeholder="Paytm"
                                    name="paytm_number"
                                    value={paytm_number}
                                    onChange={handleChange}
                                  />
                                  {paytmNumberref.current == true ? (
                                    <small className="errorcss">
                                      {" "}
                                      {validationnErr.paytm_number}{" "}
                                    </small>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div class="form_login_input">

                                {editpage == true ? (
                                    <Button className="mt-4" onClick={submitID1}>
                                    Update
                                  </Button>
                                ) : (
                                    <Button className="mt-4" onClick={submitID}>
                                    Submit
                                  </Button>
                                )}

                                  
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <section className="Deposit_history_section w-100">
                    <h3 className="deposit_table_title">Bank Detail History</h3>
                    <div className="deposit_history">
                      <table class="table market">
                        <thead>
                          <tr class="market-header deposit">
                            <th scope="col">Default</th>
                            <th scope="col"> Account Number</th>
                            <th scope="col"> IFSC code</th>
                            <th scope="col"> Bank name</th>
                            <th scope="col"> Branch name</th>
                            <th scope="col"> Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {Bankdetailss && Bankdetailss.length > 0 ? (
                            Bankdetailss.map((item, i) => {
                              return (
                                <tr>
                                  <td>
                                    <div className="data_inner">
                                      <input
                                        className="cursor-pointer"
                                        type="radio"
                                        name="flexRadioDefault"
                                        id="flexRadioDefault2"
                                        checked={item.Status == 1}
                                        onClick={() => {
                                          defaultBank(item);
                                        }}
                                      />
                                    </div>
                                  </td>
                                  <td>{item.Account_Number}</td>
                                  <td>{item.IFSC_code}</td>
                                  <td>{item.Bank_Name}</td>
                                  <td>{item.Branch_Name}</td>

                                  <td className="d-flex delete_edit_icon">
                                    <span>
                                      <a
                                        onClick={() => {
                                          deletebank(item);
                                        }}
                                      >
                                        {" "}
                                        <i
                                          class="bi bi-trash-fill"
                                          style={{
                                            "font-size": "20px",
                                            color: "red",
                                            cursor: "pointer",
                                            "margin-left": "10px",
                                          }}
                                        ></i>
                                      </a>
                                    </span>
                                    <span>
                                      <a>
                                        <i
                                          onClick={() => editbankData(item)}
                                          style={{
                                            "font-size": "20px",
                                            color: "#fff",
                                            cursor: "pointer",
                                            "margin-left": "10px",
                                            marginTop: "6px",
                                          }}
                                          class="fas"
                                        >
                                          &#xf304;
                                        </i>
                                      </a>
                                    </span>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan={7}>
                                <div className="empty_data">
                                  <div className="empty_data_img">
                                    <img
                                      src={require("../Image/Table_image/no-record-light.png")}
                                    />
                                  </div>

                                  <div className="no_records_text text-light">
                                    No Records Found
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
