import React, { useEffect } from "react";
import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./login_regi_footer";
import Select from "react-dropdown-select";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import { setAuthorization } from "../core/service/axios";

function Home() {

  const initialFormValue = {
    email: "",
    password: "",
  };

  const navigate = useNavigate();

  const [emailValidate, setemailValidate, emailValidateref] = useState(false);
  const [passwordValidate, setpasswordValidate, passwordValidateref] =
    useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [passHide, setPasshide] = useState(false);
  const [inputType, setinputType] = useState("password");
  const [siteLoader, setSiteLoader] = useState(false);

  const { email, password } = formValue;

  useEffect(() => {
    var token = localStorage.getItem("user_token");
    if (token) {
      navigate("/dashboard");
    }
  }, []);

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/\s/g, '');
    let formData = { ...formValue, ...{ [name]: sanitizedValue } };
    setFormValue(formData);
    validate(formData);
  };

  const validate = async (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Email is a required field!";
      setemailValidate(true);
  } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address!";
      setemailValidate(true);
  } else if (!/^[a-zA-Z0-9._%+-]*[a-zA-Z][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address! Email must contain at least one character.";
      setemailValidate(true);
  } else if (values.email.length > 254) { // RFC 5321 specifies a maximum length of 254 characters
      errors.email = "Email address is too long!";
      setemailValidate(true);
  } else if (values.password == "") {
    setemailValidate(false);
    setpasswordValidate(true);
    errors.password = "password is required !";
  } else if (values.password.length < 5 || values.password.length > 25) {
    setpasswordValidate(true);
    errors.password =
      "Password should not below 5 above 25 letters !";
  } else if (!values.password.match(/[a-z]/g)) {
    setpasswordValidate(true);
    errors.password = "Please enter at least lower character !";
  } else if (!values.password.match(/[A-Z]/g)) {
    setpasswordValidate(true);
    errors.password = "Please enter at least upper character !";
  } else if (!values.password.match(/[0-9]/g)) {
    setpasswordValidate(true);
    errors.password = "Please enter at One digit character !";
  } else if (!values.password.match(/[!@#$%^&*]/g)) {
    setpasswordValidate(true);
    errors.password = "Please enter at least one special character !";
  }
  else {
    setpasswordValidate(false);
  }

    setvalidationnErr(errors);
    return errors;
  };

  const passwordHide = (data) => {
    if (data == "hide") {
      setPasshide(true);
      setinputType("text");
    } else {
      setPasshide(false);
      setinputType("password");
    }
  };

  const formSubmit = async () => {
    validate(formValue);
    if (
      emailValidateref.current === false &&
      passwordValidateref.current === false
    ) {
      console.log(formValue);
      var data = {
        apiUrl: apiService.signin,
        payload: formValue,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setbuttonLoader(false);
      setFormValue(initialFormValue);
      if (resp?.tfa === 1) {
        navigate("/verify-tfa", {
          state: {
            socketToken: resp?.socketToken,
          },
        });
      } else {
        if (resp.status == true) {
          setFormValue(initialFormValue);
          toast.success(resp.Message);
          console.log(resp, "[--=-=resp");
          await setAuthorization(resp.token);
          localStorage.setItem("user_token", resp.token);
          localStorage.setItem("tfa_status", resp.tfa);
          localStorage.setItem("socket_token", resp.socketToken);
          localStorage.setItem("jwNkiKmttscotlox", resp.jwNkiKmttscotlox);
          navigate("/dashboard");
        } else {
          setbuttonLoader(false);
          toast.error(resp.Message);
        }
      }
    }
  };

  return (
    <div className="header ">
      <Header />
      <main>
        <div className="login_form minheight100">
          <div className="container">
            {siteLoader == true ? (
              <i class="fa-solid fa-spinner fa-spin"></i>
            ) : (
              <>
                <div className="row justify-content-center ">
                  <div className="col-lg-5">
                    <div className="card_login mt25">
                      <h3 className="mb-3">Welcome Back!</h3>

                      <div>
                        <label className="lable_text">Email</label>
                        <div class="form_login_input">
                          <input
                            type="text"
                            name="email"
                            value={email}
                            onChange={handleChange}
                            class="form-control"
                            placeholder="Email address"
                          />
                        </div>
                        {emailValidate == true ? (
                          <p className="errorcss"> {validationnErr.email} </p>
                        ) : (
                          ""
                        )}

                        <label className="lable_text">Password</label>
                        <div class="form_login_input position_eye">
                          <input
                            type={inputType}
                            name="password"
                            value={password}
                            onChange={handleChange}
                            class="form-control"
                            placeholder="Password"
                            maxLength={40}
                          />
                          <div className="eye_content">
                            {passHide == false ? (
                              <i
                                className="fa fa-eye-slash icons"
                                onClick={() => passwordHide("hide")}
                              ></i>
                            ) : (
                              <i
                                className="fa fa-eye icons"
                                onClick={() => passwordHide("show")}
                              ></i>
                            )}
                          </div>
                          {passwordValidate == true ? (
                            <p className="errorcss">
                              {" "}
                              {validationnErr.password}{" "}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <Link
                          to="/forget"
                          className="pass_forgee mt-2 mb-3 d-block text-right"
                        >
                          Forget Password ?
                        </Link>
                        <div class="form_login_input">
                          {buttonLoader == false ? (
                            <Button className="mt-4" onClick={formSubmit}>
                              Login
                            </Button>
                          ) : (
                            <Button className="mt-4">Loading...</Button>
                          )}
                        </div>
                        <p className="color_hss mb-0 mt-3">
                          {" "}
                          Don’t have an Account?{" "}
                          <Link to="/register">Create account</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </main>
      {/* <Footer /> */}
    </div>
  );
}

export default Home;
