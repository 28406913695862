import React from "react";
import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./login_regi_footer";
import Select from "react-dropdown-select";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import "../../src/App.css";
import useState from "react-usestateref";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import { removeAuthorization } from "../core/service/axios"; 
import { removeAuthToken } from "../core/lib/localStorage"; 

function Home() {
  const options = [
    {
      value: 1,
      label: "Leanne Graham",
    },
    {
      value: 2,
      label: "Ervin Howell",
    },
  ];
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const initialFormValue = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const [validationnErr, setvalidationnErr] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [oldPasswordValidate, setOldPasswordValidate, oldPasswordValidateref] = useState(false);
  const [newPasswordValidate, setNewPasswordValidate, newPasswordValidateref] = useState(false);
  const [
    confirmPasswordValidate,
    setconfirmPasswordValidate,
    confirmPasswordValidateref,
  ] = useState(false);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [passHide, setPasshide] = useState(false);
  const [inputType, setinputType] = useState("password");
  const [passHidconf, setPasshideconf] = useState(false);
  const [inputTypeconf, setinputTypeconf] = useState("password");
  const [newpassHid, setNewPasshide] = useState(false);
  const [inputTypenew, setinputTypenew] = useState("password");
  const [siteLoader, setSiteLoader] = useState(false);


  const { oldPassword, newPassword ,confirmPassword } = formValue;

  const navigate = useNavigate();

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    validate(formData);
  };

  const validate = async (values) => {
    let errors = {};

    if (values.oldPassword == "") {
      setOldPasswordValidate(true);
      errors.oldPassword = "Old Password is a required field !";
    } else if (!values.oldPassword.match(/[a-z]/g)) {
        setOldPasswordValidate(true);
      errors.oldPassword = "Please enter at least one lower character";
    } else if (!values.oldPassword.match(/[A-Z]/g)) {
        setOldPasswordValidate(true);
      errors.oldPassword = "Please enter at least one upper character";
    } else if (!values.oldPassword.match(/[0-9]/g)) {
        setOldPasswordValidate(true);
      errors.oldPassword = "Please enter at least one digit";
    } else if (!values.oldPassword.match(/[!@#$%^&*(),.?":{}|<>]/g)) {
        setOldPasswordValidate(true);
      errors.oldPassword = "Please enter at least one special character";
    } else if (values.oldPassword.length < 6) {
        setOldPasswordValidate(true);
      errors.oldPassword = "Atleast enter 6 letters";
    } else if (values.oldPassword.length > 15) {
        setOldPasswordValidate(true);
      errors.oldPassword = "Only enter 15 letters";
    } else {
        setOldPasswordValidate(false);
    }

    if (values.newPassword == "") {
        setNewPasswordValidate(true);
        errors.newPassword = "New Password is a required field !";
      } else if (!values.newPassword.match(/[a-z]/g)) {
        setNewPasswordValidate(true);
        errors.newPassword = "Please enter at least one lower character";
      } else if (!values.newPassword.match(/[A-Z]/g)) {
        setNewPasswordValidate(true);
        errors.newPassword = "Please enter at least one upper character";
      } else if (!values.newPassword.match(/[0-9]/g)) {
        setNewPasswordValidate(true);
        errors.newPassword = "Please enter at least one digit";
      } else if (!values.newPassword.match(/[!@#$%^&*(),.?":{}|<>]/g)) {
        setNewPasswordValidate(true);
        errors.newPassword = "Please enter at least one special character";
      } else if (values.newPassword.length < 6) {
        setNewPasswordValidate(true);
        errors.newPassword = "Atleast enter 6 letters";
      } else if (values.newPassword.length > 15) {
        setNewPasswordValidate(true);
        errors.newPassword = "Only enter 15 letters";
      } else {
        setNewPasswordValidate(false);
      }
  


    if (!values.confirmPassword) {
      errors.confirmPassword = "Confirm password is a required field !";
      setconfirmPasswordValidate(true);
    } else if (
      values.newPassword &&
      values.confirmPassword &&
      values.newPassword !== values.confirmPassword
    ) {
      // errors.password = 'Password and confirm password does not match';
      errors.confirmPassword = "Password and Confirm password does not match";
      setconfirmPasswordValidate(true);
    } else {
      setconfirmPasswordValidate(false);
    }

    setvalidationnErr(errors);

    return errors;
  };

  const passwordHide = (data) => {
    if (data == "hide") {
      setPasshide(true);
      setinputType("text");
    } else {
      setPasshide(false);
      setinputType("password");
    }
  };

  const newPasswordHide = (data) => {
    if (data == "hide") {
        setNewPasshide(true);
        setinputTypenew("text");
    } else {
        setNewPasshide(false);
        setinputTypenew("password");
    }
  };

  const passwordHideconf = (data) => {
    if (data == "hide") {
      setPasshideconf(true);
      setinputTypeconf("text");
    } else {
      setPasshideconf(false);
      setinputTypeconf("password");
    }
  };

  const formSubmit = async (payload) => {
    console.log(formValue,"-=-formvalue=-=")
    validate(formValue);
    if (
        formValue.oldPassword != "" &&
        formValue.newPassword != "" &&
        formValue.confirmPassword != "" &&
        confirmPasswordValidate == false
    ) {
      console.log(formValue);
      var obj = {
        oldPass: formValue.oldPassword,
        password: formValue.newPassword,
        cpass: formValue.confirmPassword,
      };

      var data = {
        apiUrl: apiService.changePassword,
        payload: obj,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      console.log(resp.email);
      localStorage.setItem("useremail", resp.email);
      setbuttonLoader(false);
      if (resp.status) {
        toast(resp.Message);
        await removeAuthorization();
        await removeAuthToken();
        localStorage.clear();
        navigate("/");
        window.location.reload(true);
      } else {
        toast(resp.Message);
      }
    } else {
        console.log("all field requird");
    }
  };

  return (
    <div className="header ">
      <Header />
      <main>
        <div className="login_form">
          <div className="container">
          {siteLoader == true ? <i class="fa-solid fa-spinner fa-spin"></i> : ( <>
            <div className="row justify-content-center">
              <div className="col-lg-5">
                <div className="card_login mt25">
                  <h3 className="mb-3">Change password</h3>

                  <div>
                    {/* <label className="lable_text">Name</label> */}


                    <label className="lable_text">Old Password</label>
                    <div class="form_login_input position_eye">
                      <input
                        type={inputType}
                        name="oldPassword"
                        value={oldPassword}
                        onChange={handleChange}
                        class="form-control"
                        placeholder="Old Password"
                      />
                      <div className="eye_content">
                        {passHide == false ? (
                          <i
                            className="fa fa-eye-slash icons"
                            onClick={() => passwordHide("hide")}
                          ></i>
                        ) : (
                          <i
                            className="fa fa-eye icons"
                            onClick={() => passwordHide("show")}
                          ></i>
                        )}
                      </div>
                      {oldPasswordValidate == true ? (
                        <p className="errorcss">
                          {" "}
                          {validationnErr.oldPassword}{" "}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>

                    <label className="lable_text">New Password</label>
                    <div class="form_login_input position_eye">
                      <input
                        type={inputTypenew}
                        name="newPassword"
                        value={newPassword}
                        onChange={handleChange}
                        class="form-control"
                        placeholder="New Password"
                      />
                      <div className="eye_content">
                        {newpassHid == false ? (
                          <i
                            className="fa fa-eye-slash icons"
                            onClick={() => newPasswordHide("hide")}
                          ></i>
                        ) : (
                          <i
                            className="fa fa-eye icons"
                            onClick={() => newPasswordHide("show")}
                          ></i>
                        )}
                      </div>
                      {newPasswordValidate == true ? (
                        <p className="errorcss">
                          {" "}
                          {validationnErr.newPassword}{" "}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>



                    <label className="lable_text">Confirm Password</label>
                    <div class="form_login_input position_eye">
                      <input
                        type={inputTypeconf}
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={handleChange}
                        class="form-control"
                        placeholder="Confirm Password"
                      />

                      <div className="eye_content">
                        {passHidconf == false ? (
                          <i
                            className="fa fa-eye-slash icons"
                            onClick={() => passwordHideconf("hide")}
                          ></i>
                        ) : (
                          <i
                            className="fa fa-eye icons"
                            onClick={() => passwordHideconf("show")}
                          ></i>
                        )}
                      </div>
                      {confirmPasswordValidate == true ? (
                        <p className="errorcss">
                          {" "}
                          {validationnErr.confirmPassword}{" "}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div class="form_login_input mt-3">
                      {buttonLoader == false ? (
                        <Button className="mt-3" onClick={formSubmit}>Submit</Button>
                      ) : (
                        <Button>Loading...</Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div></>)}
           
          </div>
        </div>
      </main>
      {/* <Footer /> */}
    </div>
  );
}

export default Home;
